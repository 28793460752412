import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';

export const LogoTitle = styled.span`
  margin-left: 10px;
  font-size: 11px;
  line-height: 13px;

  ${screen.md} {
    margin-left: 10px;
    font-size: 12px;
    line-height: 14px;
  }
`;
