//components
import {
  Title,
  SubTitle,
  Text,
  ResponsiveImage,
} from '@/components/Grid/Content';
import { Container } from '@/components/Grid/Container';
import { Section } from '@/components/Grid/Section';
import { Divider } from '@/components/Grid/Divider';

//containers
import {
  DarkSlider,
  Slide,
  SliderImage,
  SliderTitle,
} from '@/containers/Slider';

export const MeetOurTeamSection = () => (
  <>
    <Section>
      <Container>
        <Title>Meet our team, your transportation specialists</Title>
        <Text>
          Your dedicated account manager is your logistics veteran and data
          expert. They are the trusted advisors to some of the top fortune 100
          shippers in North America. They are ready to geek out on the logistics
          of your most complex shipments to achieve your unique service
          requirements and efficiency goals.
        </Text>
      </Container>
    </Section>

    <div role="decoration">
      <Container>
        <Divider color="#c5cbcb" />
      </Container>
    </div>

    <Section>
      <Container>
        <DarkSlider slidesPerPage={2} slidesPerPageOnMobile={1}>
          <Slide>
            <SliderImage>
              <ResponsiveImage
                src={`/images/enterprise-shipping/team/team_section-dan.png`}
                alt="Dan Giannantonio portrait"
                width="620"
                height="324"
              />
            </SliderImage>
            <SliderTitle>
              <SubTitle>Dan Giannantonio</SubTitle>
            </SliderTitle>
            <Text>
              Dan brings +15 years experience. He is a Loadsmart legend in
              enterprise shipping, working with the top Fortune 100 clients and
              bringing home awards for excellent service, operations and
              customer relations.
            </Text>
          </Slide>
          <Slide>
            <SliderImage>
              <ResponsiveImage
                src={`/images/enterprise-shipping/team/team_section-nick.png`}
                alt="Nick Jensen portrait"
                width="620"
                height="324"
              />
            </SliderImage>
            <SliderTitle>
              <SubTitle>Nick Jensen</SubTitle>
            </SliderTitle>
            <Text>
              Nick brings +10 years experience totaling +1000 accounts managed
              from SMB, Mid-Enterprise to the Fortune 100. Customers like him so
              much, he ends up at their BBQ’s when visiting their facilities.
            </Text>
          </Slide>
        </DarkSlider>
      </Container>
    </Section>
  </>
);
