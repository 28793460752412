import styled from 'styled-components';

import { screen } from '@/helpers/screen';

// components
import { Container } from 'components/Grid';

//icon
import ThinArrow from '@/public/images/carrier-tms/features/icons/icon-thin-arrow.svg';

export const ListWrapper = styled.section`
  padding: 40px 0 0;

  ${screen.md} {
    padding: 60px 0;
  }

  ${screen.lg} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const ListHeader = styled.header`
  scroll-margin-top: 120px;
`;

export const StyledTitle1 = styled.h1`
  position: relative;

  display: block;
  margin: 0;

  color: #33333d;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-align: center;

  span {
    display: inline-block;
  }

  .icon-arrow {
    display: inline-block;
    width: 13px;
    margin-right: 12px;

    color: #14d64d;
  }

  ${screen.md} {
    margin: 0;

    font-size: 24px;
    line-height: 46px;
    text-align: left;

    .icon-arrow {
      width: 19px;
      margin-right: 16px;
    }
  }

  ${screen.lg} {
    margin: 0 0 20px;

    font-size: 48px;
    line-height: 54px;

    .icon-arrow {
      width: 27px;
    }
  }
  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72px;

    .icon-arrow {
      width: 31px;
      margin-right: 20px;
    }
  }
`;

export const StyledTitle2 = styled.h2`
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 10px 0;
  margin-top: 40px;
  padding: 0 0 5px 0;
  padding-right: 0;

  color: #333b43;
  font-weight: 700;

  font-size: 16px;
  line-height: 22px;
  text-align: center;

  border-bottom: 2px solid ${(props) => props.theme.color.primary};

  span {
    display: inline-block;
  }

  a {
    display: none;

    color: ${(props) => props.theme.color.primary};
    font-weight: 200;
    font-size: 65%;

    ${screen.md} {
      display: inline-block;
    }
  }

  ${screen.md} {
    padding: 0 20px 5px 0;

    font-size: 18px;
    line-height: 26px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 24px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 45px;
  }
`;

export const ListGrid = styled.div`
  margin-bottom: 20px;

  ${screen.md} {
    margin-bottom: 0;
  }
`;

export const ListItem = styled.article`
  padding: 8px 0;

  ${screen.md} {
    padding: 30px 0;
  }

  ${screen.xxlg} {
    padding: 40px 0;
  }

  &:nth-child(even) {
    background: #f6f7f8;

    ${screen.md} {
      & > div {
        flex-direction: row-reverse;

        .media {
          margin: auto 0 auto auto;
        }
      }
    }
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* @media (max-width: calc(${(props) =>
    props.theme.breakpoint.medium} - 1px)) {
    max-width: 100%;
  } */

  ${screen.md} {
    flex-direction: row;
    align-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  p {
    margin: 24px 0 4px;

    a {
      color: ${(props) => props.theme.color.primary};
    }
  }

  /* @media (max-width: calc(${(props) =>
    props.theme.breakpoint.medium} - 1px)) {
    max-width: 316px;
  } */

  ${screen.md} {
    width: 244px;
    margin: 0;
    p {
      margin: 28px 0 10px;
    }
  }

  ${screen.lg} {
    width: 394px;

    p {
      margin: 55px 0 0;
    }
  }

  ${screen.xxlg} {
    width: 670px;
    p {
      margin: 40px 0;
    }
  }
`;

export const Media = styled.div`
  align-items: flex-start;
  width: 316px;
  margin: auto;

  img {
    max-width: 100%;
    height: auto;
  }

  ${screen.md} {
    display: flex;
    width: 380px;
    margin: auto 0;
  }
  ${screen.lg} {
    width: 476px;
  }
  ${screen.xxlg} {
    width: 555px;
  }
`;

export const Paragraph1 = styled.p`
  margin: 10px 0;

  color: #33333d;
  font-weight: 600;

  font-size: 12px;
  line-height: 18px;
  text-align: center;

  ${screen.md} {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 18px;
    line-height: 26px;
  }

  ${screen.xxlg} {
    font-size: 21px;
    line-height: 35px;
  }
`;

export const Paragraph2 = styled(Paragraph1)`
  margin: 10px 0;

  color: #333b43;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  ${screen.md} {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 18px;
    line-height: 26px;
  }

  ${screen.xxlg} {
    font-size: 21px;
    line-height: 35px;
  }
`;

export const LinkItem = styled.a`
  text-decoration: underline;
`;

export const IconThinArrow = styled(ThinArrow)`
  width: 100%;
  height: 100%;
`;
