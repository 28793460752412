import styled, { keyframes } from 'styled-components';

const dash = keyframes`
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 93,200;
        stroke-dashoffset: -124;
    }
`;

const rotate = keyframes`
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`;

export const LoaderWrapper = styled.div`
    position: relative;

    display: block;
    width: 100%;
    height: 100%;

    text-align: center;
`;

export const SpinnerWrapper = styled.div`
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    z-index: 9000 !important;

    width: 48px;
    margin-top: -22px;
    margin-left: -24px;
`;

export const CircleSpinner = styled.div`
    position: relative;

    width: 48px;
    height: 48px;
    margin: -2px auto 0;

    &::before {
        display: block;
        padding-top: 100%;

        content: '';
    }
`;

export const CircleWrapper = styled.svg`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: auto;

    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;

    -webkit-animation: ${rotate} 2s linear infinite;
    animation: ${rotate} 2s linear infinite;
`;

export const Circle = styled.circle`
    stroke-dasharray: 75, 100;
    stroke-dashoffset: -10;

    -webkit-animation: ${dash} 1.5s ease-in-out infinite;
    animation: ${dash} 1.5s ease-in-out infinite;
    stroke-linecap: butt;
`;
