//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

//components
import { Layout, Title, Paragraph, Link } from '@/components/Typography/Legal';
import { Table } from '@/components/Typography/Table';
import { HubspotFormSimplified } from '@/containers-m/HubspotFormSimplified';

//vendor
import { subprocessorsOptions } from '@/vendor/HubspotFormIntegration';

//styles
import { FormWrapper } from './Legal.styles';

const mainlist = {
  header: ['Entity Name', 'Applicable Services', 'Country'],
  body: [
    [
      'Amazon Web Services',
      'Cloud hosting provider for software as a service offerings',
      'United States',
    ],
    ['Bill.com', 'Financial operations platform', 'United States'],
    ['Datadog', 'Logs and observability services', 'United States'],
    [
      'Digital Ocean',
      'Cloud hosting provider for software as a service offerings',
      'United States',
    ],
    ['Google', 'Email and office productivity tools', 'United States'],
    ['Hubspot', 'Marketing platform', 'United States'],
    ['Mailchimp', 'Email platform', 'United States'],
    ['Mailgun', 'Email platform', 'United States'],
    ['Mixpanel', 'Event analytics', 'United States'],
    ['Microsoft Office 365', 'Office productivity tools', 'United States'],
    ['Netsuite', 'Accounting and financial management', 'United States'],
    ['Salesforce', 'Customer relationship management', 'United States'],
    ['Salesloft', 'Sales platform', 'United States'],
    ['Sentry', 'Application error tracking', 'United States'],
    ['Stripe', 'Payment provider', 'United States'],
    ['Twilio', 'SMS service', 'United States'],
    ['Zendesk', 'Customer Support Service Tool', 'United States'],
  ],
};

const seclist = {
  header: ['Entity Name', 'Country'],
  body: [
    ['Opendock LLC', 'United States'],
    ['Carrier Solutions LLC', 'United States'],
  ],
};

const Subprocessors = () => {
  return (
    <>
      <Navigation />
      <>
        <Layout>
          <Title>List of Sub-Processors</Title>
          <Paragraph>
            <em>Last Updated: September 26, 2023</em>
          </Paragraph>
          <Paragraph>
            This page provides information about Sub-Processors that are
            authorized to Process Personal Information in connection with
            Loadsmart’s provision of Services. Capitalized terms used on this
            page, but not explicitly defined, will have the meaning set forth in
            Loadsmart’s{' '}
            <Link href="/subscription-and-professional-services-agreement/">
              Subscription and Professional Services Agreement
            </Link>{' '}
            or the Data Processing Addendum between Loadsmart and Customer, as
            applicable.
          </Paragraph>
          <Table content={mainlist} />
          <Paragraph>
            In addition, depending on the scope of Services provided by
            Loadsmart, Loadsmart may engage one or more of its affiliates as
            Sub-Processors to provide support with respect to the Services.{' '}
          </Paragraph>
          <Table content={seclist} />
        </Layout>

        <FormWrapper id="fill-the-form">
          <HubspotFormSimplified
            theme="light"
            {...subprocessorsOptions}
            content={{
              title:
                'Would you like to receive notice of updates to Loadsmart’s Sub-Processor list?',
              subtitle:
                'Please fill out this form to receive notifications regarding updates to Loadsmart’s Sub-Processors. If you are a Controller of Personal Information which Loadsmart Processes, you have the right to object to any changes to the above list.',
            }}
          />
        </FormWrapper>
      </>
      <Footer />
    </>
  );
};

export default Subprocessors;
export { Subprocessors };
