import { css } from 'styled-components';

//helpers
import { hideOnLarge, screen } from '@/helpers/screen';

//containers
import { Hero } from '@/containers/Hero';
import { Title, Description } from '@/containers/Hero/Hero.styles';
import { BackgroundImages } from '@/containers/BackgroundImages';

//components
import { FlexRowWrap } from '@/components/Grid/Flex';

import { BaseButton, GhostButton } from '@/components/Button/';

const responsiveBackground = {
  sm: `url(/images/enterprise-shipping/small_hero.png);`,
  md: `url(/images/enterprise-shipping/medium_hero.png);`,
  lg: `url(/images/enterprise-shipping/large_hero.png);`,
  xxlg: `url(/images/enterprise-shipping/xxlarge_hero.png);`,
};

const smallFont = css`
  font-size: 12px;
`;

const ctaStyle = css`
  width: 100%;
  margin: 12px 0;

  white-space: nowrap;
  text-transform: uppercase;

  ${screen.md} {
    width: auto;
    margin-right: 15px;
  }
`;

export const HeroSection = () => (
  <Hero css={BackgroundImages(responsiveBackground)}>
    <Title>Enterprise shipping, reimagined.</Title>
    <Description>
      Access instant, dynamic capacity to move your overland freight — no matter
      the mode or market.
    </Description>
    <FlexRowWrap>
      <BaseButton
        as="a"
        href="#hubspot-form"
        css={[hideOnLarge, ctaStyle, smallFont]}
      >
        Talk to a Transportation Specialist
      </BaseButton>
      <GhostButton css={smallFont} as="a" href="#hubspot-form">
        Request a demo
      </GhostButton>
    </FlexRowWrap>
  </Hero>
);
