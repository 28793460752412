import styled, { css } from 'styled-components';
import { screen } from '@/helpers/screen';
import { FlexColumn } from '@/components/Grid/Flex';

export const ignoreSmall = (keys) => keys !== 'sm';

export const HeroWrapper = styled(FlexColumn)`
  padding-top: 80px;
  justify-content: flex-end;
  color: white;
  padding-bottom: 30px;
  box-sizing: border-box;
  min-height: 587px;

  ${screen.md} {
    min-height: 512px;
    padding-bottom: 50px;
  }

  ${screen.lg} {
    min-height: 680px;
    padding-bottom: 86px;
  }

  ${screen.xxlg} {
    min-height: 860px;
    padding-bottom: 150px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;

  strong {
    font-size: 24px;
  }

  ${screen.md} {
    text-align: left;
    font-size: 34px;
    line-height: 40px;
  }

  ${screen.lg} {
    font-size: 48px;
    line-height: 54px;

    strong {
      font-size: 32px;
    }
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72px;

    strong {
      font-size: 48px;
    }
  }
`;

export const Description = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  margin: 12px 0;

  ${screen.md} {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
  }

  ${screen.lg} {
    font-size: 21px;
    line-height: 34px;
  }

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 38px;
  }
`;
