import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen/';

//components
import { Container } from '@/components/Grid/Container';
import { Feature, FeatureGroup } from '@/components/Feature';
import { BackgroundImages } from '@/containers/BackgroundImages';
import { InvertedSection } from '@/components/Grid/Section';

//logos
import DigitalBrokerLogo from '@/public/images/logos/logo-digital_broker.svg';
import HighwayHeroLogo from '@/public/images/logos/logo-highway_hero.svg';
import FreightWavesAwardLogo from '@/public/images/logos/logo-freight_waves.svg';

const responsiveBackground = {
  sm: `url(/images/enterprise-shipping/awards/award_section-small_background.png);`,
  md: `url(/images/enterprise-shipping/awards/award_section-medium_background.png);`,
  lg: `url(/images/enterprise-shipping/awards/award_section-large_background.png);`,
  xxlg: `url(/images/enterprise-shipping/awards/award_section-xxlarge_background.png);`,
};

const Title = styled.h3`
  margin: 10px 0;

  font-size: 16px;
  line-height: 26px;
  text-align: center;

  ${screen.lg} {
    font-size: 30px;
    line-height: 35px;
  }

  ${screen.xxlg} {
    margin: 20px 0;
  }
`;

const Description = styled.p`
  font-size: 12px;
  font-style: italic;
  line-height: 22px;
  text-align: center;

  ${screen.lg} {
    font-size: 12px;
    line-height: 20px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const BackgroundSection = styled(InvertedSection)`
  ${BackgroundImages(responsiveBackground)};
  color: white;
`;

const ImageWrapper = styled.div`
  height: 114px;
  min-height: 114px;

  svg,
  img {
    max-height: 100%;
    width: auto;
  }

  ${screen.lg} {
    height: 125px;
    min-height: 125px;
  }

  ${screen.xxlg} {
    height: 177px;
    min-height: 177px;
  }
`;

export const AwardsSection = () => (
  <BackgroundSection>
    <Container>
      <FeatureGroup>
        <Feature>
          <ImageWrapper>
            <DigitalBrokerLogo height={177} />
          </ImageWrapper>
          <Title>The Home Depot</Title>
          <Description>
            Digital Broker
            <br />
            Of The Year 2020
          </Description>
        </Feature>
        <Feature>
          <ImageWrapper>
            <FreightWavesAwardLogo height={177} />
          </ImageWrapper>
          <Title>FreightWaves</Title>
          <Description>FreightTech 25 2020</Description>
        </Feature>
        <Feature>
          <ImageWrapper>
            <HighwayHeroLogo height={177} />
          </ImageWrapper>
          <Title>Kraft Heinz</Title>
          <Description>
            Highway Hero: Going the
            <br />
            Extra Mile to Feed America 2020
          </Description>
        </Feature>
      </FeatureGroup>
    </Container>
  </BackgroundSection>
);
