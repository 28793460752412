import React from 'react';

// components
import { Container } from '@/components/Grid/Container';

import { FeatureIcon, responsiveIconStyle } from '@/components/Feature/';

// icons
import {
  IconDeliveryLoad,
  IconOnePlatform,
  IconSupport,
  IconDeal,
} from '@loadsmart/icons';

// styles
import {
  SolutionsWrapper,
  Title,
  StyledFeatureGroup,
  StyledFeature,
  StyledFeatureTitle,
  StyledFeatureDescription,
} from './TransparentRatesSection.styles';

export function TransparentRatesSection() {
  return (
    <SolutionsWrapper>
      <Container>
        <Title>
          Transparent rates, 24/7 support, and <br /> contract solutions to help
          profitability in any market
        </Title>
        <StyledFeatureGroup>
          <StyledFeature>
            <FeatureIcon>
              <IconDeliveryLoad role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <StyledFeatureTitle>Shippers of Choice</StyledFeatureTitle>
            <StyledFeatureDescription>
              Direct access to more freight and better shippers
            </StyledFeatureDescription>
          </StyledFeature>
          <StyledFeature>
            <FeatureIcon>
              <IconOnePlatform role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <StyledFeatureTitle>Integrated Tools</StyledFeatureTitle>
            <StyledFeatureDescription>
              Save time and money by consolidating tools, creating efficiencies
            </StyledFeatureDescription>
          </StyledFeature>
          <StyledFeature>
            <FeatureIcon>
              <IconSupport role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <StyledFeatureTitle>24/7 Support</StyledFeatureTitle>
            <StyledFeatureDescription>
              Around the clock support available via phone, email, and chat
            </StyledFeatureDescription>
          </StyledFeature>
          <StyledFeature>
            <FeatureIcon>
              <IconDeal role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <StyledFeatureTitle>Partnership</StyledFeatureTitle>
            <StyledFeatureDescription>
              We build products together with our carriers. Participate in
              development and test products
            </StyledFeatureDescription>
          </StyledFeature>
        </StyledFeatureGroup>
      </Container>
    </SolutionsWrapper>
  );
}
