//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

import { Layout, Title, Paragraph } from '@/components/Typography/Legal';

//assets
import { OFFICES } from '@/datafiles/offices/address';

const UserAgreement = () => {
  return (
    <>
      <Navigation />
      <>
        {' '}
        <Layout>
          <Title>User Agreement</Title>
          <Paragraph>
            The website “Loadsmart.com” (the “Site”), the Loadsmart associated
            mobile application (“Mobile App”) and Loadsmart’s Privacy Policy
            (“Privacy Policy”) are copyrighted works belonging to Loadsmart,
            Inc., a Delaware Corporation with headquarters in Chicago, IL
            (“Loadsmart”). Loadsmart is a technology platform that brings
            together shippers and receivers of freight (collectively,
            “Shippers”) and motor carriers (“Carriers”) engaged in the business
            of transporting freight for hire (“Transportation Services”), to
            facilitate the booking and management of truckload shipments
            (“Shipments”). The Site, Mobile App, and all software-as-a service
            products or other services provided therein, together, constitute
            the “Loadsmart Service”.
          </Paragraph>

          <Paragraph>
            THIS LOADSMART USER AGREEMENT, AND LOADSMART’S PRIVACY POLICY
            INCORPORATED HEREIN BY REFERENCE (COLLECTIVELY, “AGREEMENT”), SETS
            FORTH THE LEGALLY BINDING TERMS AND CONDITIONS FOR YOUR USE OF THE
            LOADSMART SERVICE. BY CLICKING “ACCEPT” AND REGISTERING FOR, AND/OR
            USING THE SOFTWARE IN ANY MANNER, YOU AND THE ENTITY OR ORGANIZATION
            EMPLOYING OR ENGAGING YOU, OR THAT YOU OTHERWISE REPRESENT, ACCEPT
            AND AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS
            AGREEMENT. YOU MAY NOT ACCESS OR USE THE LOADSMART SERVICE OR ACCEPT
            THIS AGREEMENT IF YOU ARE NOT AT LEAST 18 YEARS OLD. IF YOU DO NOT
            AGREE WITH ALL OF THE PROVISIONS OF THIS AGREEMENT, DO NOT ACCESS OR
            USE THE LOADSMART SERVICE.
          </Paragraph>

          <Paragraph>
            <b>I. LOADSMART SERVICES</b>
          </Paragraph>

          <Paragraph>
            A. General. Loadsmart, as a technology platform, brings together
            Shippers and Carriers for the transportation of Shipments to, from,
            and between points in the United States and Canada, enabling
            Shippers to post details of desired and requested shipping and
            transportation needs, Carriers to accept such requests, and both
            parties to book and manage the resulting Shipments. Loadsmart is not
            a Shipper or Carrier. “User” means any user of the Loadsmart
            Service, which may be a Carrier or a Shipper, but may not be a
            broker or a freight forwarder (unless, as a broker or
            freight-forwarder, the user-broker/freight forwarder represents a
            shipper when initiating a Shipment request pursuant to the Loadsmart
            Service, in which case the broker or freight forwarder will be
            deemed a “Shipper” for purposes of this Agreement). To the extent
            you are a Carrier, the provisions in this Agreement designated for
            “Carriers” apply to you. To the extent you are a Shipper, the
            provisions in this Agreement designated for “Shippers” apply to you.
            To the extent you are a User, the provisions in this Agreement
            designated for “Users” apply to you. All shipments originating, or
            transported between points, in Canada (collectively, “Canadian
            Origin Shipments”) are subject to the provisions of Part IV –
            Canadian Carrier Terms, Part VIII – Canadian Freight Loss or Damage,
            and any other provisions that specifically indicate that they apply
            to Canadian Origin Shipments (collectively, “Canadian Terms”).
            Canadian Origin Shipments ARE NOT subject to Part III – US Carrier
            Terms or Part VII – US Freight Loss or Damage. All other provisions
            of this Agreement apply to Canadian Origin Shipments to the extent
            that such terms do not conflict with any Canadian Terms.
          </Paragraph>

          <Paragraph>
            B. Shipment Posting, Acceptance, and Completion Process.
          </Paragraph>

          <Paragraph>
            (1) Initiating the Shipment. Shipper initiates requests for
            transportation of a Shipment by posting on the Site or Mobile App
            certain details about the Shipment. Shipper must post the origin and
            destination addresses, the requested dates and times of the
            Shipment, a description of the commodities to be shipped (including
            weight), and any specific/special transportation instructions for
            the carrier (e.g. temperature settings, load security), and selects
            the type of trailer or truck needed. For Canadian Origin Shipments,
            if the Shipper wishes the Carrier to be liable for the full actual
            value of the Shipment in the case of loss or damage to the Shipment,
            rather than value as calculated in accordance with Part VIII –
            Canadian Freight Loss or Damage, described below, it must also
            declare the value of the Shipment. Once Shipper receives a price, it
            may request transportation at that price, subject to Loadsmart
            locating a Carrier willing to transport that Shipment at that price.
            It does so by clicking “Book.” Shipper must register with Loadsmart
            prior to booking if not already registered. Loadsmart notifies
            Shipper that its Shipment has been “booked,” and Shipper has to
            provide further details on the Shipment. At any time after booking,
            Loadsmart sends out an electronic request for transport to Carriers
            registered with Loadsmart, furnishing information about the Shipment
            and the price Loadsmart is offering to Carrier. A Carrier receiving
            electronic notice of the Shipment from Loadsmart may, but is not
            required to, accept the Shipment at the price offered. It does so by
            clicking “Accept” on the website screen. The Carrier that accepts a
            booked Shipment is assigned that Shipment, and thereafter, that
            Carrier and Shipper are legally bound, and the Shipment is no longer
            available for other Carriers to accept. Shipper may cancel a
            Shipment at any time prior to its acceptance but shall incur a
            penalty. If Shipper cancels a shipment after it has been accepted,
            Shipper shall be responsible for payment in full of the
            transportation price. This and other cancellation provisions (and
            associated penalties) are set forth in Section VI.C.(2) of this
            Agreement. Loadsmart is not responsible for the accuracy of any
            Shipment description or terms of its transportation. If no Carrier
            accepts a booked Shipment, Loadsmart will notify Shipper and may
            suggest changing the dates of the Shipment or may continue searching
            for Carriers not yet registered with Loadsmart willing and able to
            handle the Shipment.
          </Paragraph>

          <Paragraph>
            (2) Completing the Shipment. Shipper must issue and provide Carrier
            a numbered bill of lading (“Bill of Lading”) for each Shipment. It
            may do so by completing the electronic Bill of Lading form Loadsmart
            provides on its website (as soon as this feature becomes available)
            or by issuing its own form of bill of lading. The Bill of Lading
            must contain, at minimum, the names and addresses of the Carrier and
            Shipper, the origin and destination addresses, the requested pickup
            and delivery dates and times, a description of the commodities to be
            shipped (including weight), and any other relevant terms and
            conditions. For all Canadian Origin Shipments, the Bill of Lading
            must also include a box where the Shipper can declare a value for
            the Shipment, but the Shipper must only declare a value in this box
            where such value was already declared by the Shipper at the time of
            booking, as described in Part I(B)(1). Additionally, where a bill of
            lading was not issued, or was improperly issued, a bill of lading
            shall be deemed to have been issued in the form required by the
            applicable law in the jurisdiction of origin of the Shipment.
            Loadsmart does not issue Bills of Lading. Loadsmart is not
            responsible for the accuracy or legal enforceability of the Bill of
            Lading form used or of the information inserted thereon and
            expressly disclaims any such responsibility or liability for the
            Bill of Lading the parties execute, whether generated by Loadsmart
            or otherwise. See also Section VII of this Agreement for additional
            provisions applicable to Bills of Lading. Carrier must direct any
            questions or concerns regarding the Bill of Lading to Shipper. Once
            Carrier completes delivery of the Shipment, Carrier shall promptly
            upload to the Loadsmart Service a proof of delivery signed by the
            authorized recipient (“Proof of Delivery”), including by signing the
            Bill of Lading on the Mobile App (as soon as this feature becomes
            available).
          </Paragraph>

          <Paragraph>
            C. Fleet Management, Internal Shipment Manager, Analytics, and other
            Features.
          </Paragraph>

          <Paragraph>
            (1) Loadsmart may provide certain ancillary features to Shippers and
            Carriers (“Ancillary Features”). These Ancillary Features may
            include fleet management, asset tracking, internal shipment manager,
            analytics among others. You agree that you will use the Ancillary
            Features at your own risk and that Loadsmart makes no
            representations or warranties regarding the accuracy or completeness
            of any content or these products, and that Loadsmart will have no
            liability for any damage or loss resulting from your use of the
            content or these products.
          </Paragraph>

          <Paragraph>
            <b>II. EXCLUDED SERVICES</b>
          </Paragraph>

          <Paragraph>
            Certain transportation is not within the Loadsmart Service that
            Loadsmart offers through its technology platform and is, therefore,
            beyond the scope of this Agreement. The following transportation
            services and commodities are excluded from the Loadsmart Service:
          </Paragraph>

          <Paragraph>
            (i) shipments not moving entirely by one motor carrier
          </Paragraph>

          <Paragraph>
            (ii) shipments brokered to another motor carrier
          </Paragraph>

          <Paragraph>
            (iii) international shipments (i.e. those having an origin or
            destination outside of the United States or Canada)
          </Paragraph>

          <Paragraph>(iv) household goods</Paragraph>

          <Paragraph>
            (v) hazardous materials, dangerous goods, chemicals
          </Paragraph>

          <Paragraph>
            (vi) oversize or overweight loads requiring special highway permits
            and/or escorts
          </Paragraph>

          <Paragraph>
            (vii) commodities of extraordinary or unusual value
          </Paragraph>

          <Paragraph>(viii) illegal goods</Paragraph>

          <Paragraph> (ix) firearms, explosives, ammunition</Paragraph>

          <Paragraph> (x) glass and glazing products</Paragraph>

          <Paragraph> (xi) livestock</Paragraph>

          <Paragraph> (xii) boats and mobile homes</Paragraph>

          <Paragraph> (xiii) tobacco</Paragraph>

          <Paragraph> (xiv) garbage, refuse, trash</Paragraph>

          <Paragraph>
            <b>III. US CARRIER TERMS</b>
          </Paragraph>

          <Paragraph>
            {' '}
            A. Representations and Warranties. If you are a Carrier, you hereby
            represent and warrant that, and acknowledge that Loadsmart and the
            Shipper are relying upon such representations and warranties in
            entering into this Agreement:
          </Paragraph>

          <Paragraph>
            {' '}
            (1) you have completed the Loadsmart registration process and have
            provided Loadsmart: (i) your assigned “MC” or “DOT” number; and (ii)
            a completed W-9 form;
          </Paragraph>

          <Paragraph>
            {' '}
            (2) you are duly authorized to provide Transportation Services as a
            motor carrier of commodities and desire to provide such services for
            Shipments;
          </Paragraph>

          <Paragraph>
            {' '}
            (3) you have all applicable licenses, certificates, permits,
            approvals, and authority necessary to provide the Transportation
            Services subject to this Agreement, including the Uniform Intermodal
            Interchange & Facilities Access Agreement in place for drayage
            services;
          </Paragraph>

          <Paragraph>
            {' '}
            (4) you have a drug and alcohol testing program meeting DOT and
            applicable foreign, state and local requirements; and
          </Paragraph>

          <Paragraph>
            {' '}
            (5) in cases where you are transporting a Shipment into Canada, you
            also make the representations and warranties set forth in Part
            IV(A)(1)-(4) – Canadian Carrier Terms, below.
          </Paragraph>

          <Paragraph> B. Covenants. If you are a Carrier, you shall:</Paragraph>

          <Paragraph>
            {' '}
            (1) prior to moving your first Shipment through Loadsmart, provide
            to Loadsmart certificate(s) of insurance evidencing minimum coverage
            amounts of $100,000 for cargo liability and $1,000,000 for business
            automobile liability with insurance companies maintaining a rating
            from A. M. Best of A- or higher;
          </Paragraph>

          <Paragraph>
            {' '}
            (2) comply with all applicable American federal and state laws and
            regulations related to providing such Transportation Services,
            including without limitation those of the U.S. Department of
            Transportation and its federal agencies and, if applicable, those of
            the U.S. Food and Drug Administration;
          </Paragraph>

          <Paragraph>
            {' '}
            (3) you will immediately notify Loadsmart if your U.S. DOT safety or
            fitness rating has changed to conditional, unsatisfactory, or unfit
            or if the scores for your FMCSA administered BASICs exceed the
            applicable CSA Intervention Thresholds in two or more categories;
          </Paragraph>

          <Paragraph>
            {' '}
            (4) you will furnish all equipment required for the performance of
            the Transportation Services with respect to every Shipment,
            including temperature controlled vehicles when needed, and will
            maintain all such equipment in good repair and condition;
          </Paragraph>

          <Paragraph>
            {' '}
            (5) you will be solely responsible for all personnel you engage or
            retain to provide services under this Agreement, including paying or
            providing such personnel’s wages, insurance, training and other
            benefits;
          </Paragraph>

          <Paragraph>
            {' '}
            (6) you will not cause or permit any Shipment to be transported by
            any third-party motor carrier, including in interline service or by
            brokering or trip-leasing, or by any other substitute mode of
            transportation;
          </Paragraph>

          <Paragraph>
            {' '}
            (7) you will proceed to pick up all accepted Shipments in accordance
            with the Shipper’s scheduled pick-up date and time;
          </Paragraph>

          <Paragraph>
            {' '}
            (8) you will notify Shipper and Loadsmart immediately when free time
            has expired and detention charges are about to be incurred;
          </Paragraph>

          <Paragraph>
            {' '}
            (9) except as provided otherwise in this Agreement, you will look
            solely to Loadsmart for payment of any fees or charges due to you in
            connection with your services under this Agreement, and you
            expressly waive any claim against any third party consignee under
            the Bills of Lading Act (Canada), the Mercantile Law Amendment Act
            (Ontario), the Bills of Lading Act (Nova Scotia) and any other
            substantially similar provincial, territorial, American federal and
            state, and/or common law that exists or which may be enacted or
            exist (collectively, the “Consignee Recovery Laws”);
          </Paragraph>

          <Paragraph>
            {' '}
            (10) if you believe you are entitled to extra accessorial charges,
            you must notify Loadsmart and Shipper within 24 hours of the
            scheduled delivery time of those charges of which you are aware at
            that time;
          </Paragraph>

          <Paragraph>
            {' '}
            (11) you will not claim, and hereby waive and release any right to
            claim, any lien on any Shipment, and you will not withhold delivery
            of any Shipment on account of any dispute about any fees or charges
            claimed under this Agreement or in order to secure payment of such
            fees or charges;
          </Paragraph>

          <Paragraph>
            {' '}
            (12) upon receipt of payment of the price for the Transportation
            Services by Loadsmart, you automatically assign your right to
            recovery of such price to Loadsmart and Loadsmart shall be entitled
            to seek recovery of the price from any party who may be liable at
            law, including but not limited to the Consignee Recovery Laws, and
            you shall cooperate forthwith with any reasonable requests by
            Loadsmart to assist Loadsmart in the recovery of such price by
            Loadsmart;
          </Paragraph>

          <Paragraph>
            {' '}
            (13) you are solely responsible for any and all liability resulting
            from, or alleged to have resulted from, the transportation of any
            Shipment arranged through the Loadsmart Service, including, but not
            limited to, property damage, cargo loss, damage, or delay, personal
            injury, and death;
          </Paragraph>

          <Paragraph>
            {' '}
            (14) you are solely responsible for and accept liability for loss,
            damage, injury, or delay to the Shipments you transport, in
            accordance with the provisions set out in Part VIII – Canadian
            Freight Loss or Damage;
          </Paragraph>

          <Paragraph>
            {' '}
            (15) except with respect to subsection (B)(14) of this Part IV –
            Canadian Carrier Terms, you will defend, indemnify, and hold
            harmless Loadsmart, Shipper, Loadsmart’s customers, the consignor
            and consignee on each Shipment from and against all claims, losses,
            damages, liabilities, and expenses, including attorneys’ fees,
            arising out of or relating to: (i) your performance of
            Transportation Services under this Agreement; (ii) violation of any
            applicable law; (iii) breach of any material term or condition of
            this Agreement; (iv) inaccuracy or misrepresentation of any
            representation or warranty given by you in this Agreement; and (v)
            your negligent acts or omissions and those of your employees, agents
            and contractors; and
          </Paragraph>

          <Paragraph>
            {' '}
            (16) in cases where you are transporting a Shipment into the United
            States, you agree to the covenants set forth in Part III(B)(1)-(11)
            – US Carrier Terms, above.
          </Paragraph>

          <Paragraph>
            {' '}
            C. Non-solicitation. Carriers will not contact any Shipper directly,
            or indirectly except through the Loadsmart Service, to solicit,
            accept, or book shipments with Shipper for a period of twelve (12)
            months from the last date of contact through the Loadsmart Service
            between Carrier and Shipper. Loadsmart reserves the right to
            terminate Carrier’s Account or this Agreement in its sole discretion
            for a breach of this Section IV(C) of this Agreement. The foregoing
            actions shall be in addition to any other remedies available to
            Loadsmart at law, including but not limited to recovery of damages
            or an injunction order.
          </Paragraph>

          <Paragraph>
            {' '}
            <b>IV. CANADIAN CARRIER TERMS</b>
          </Paragraph>

          <Paragraph>
            {' '}
            The following provisions only apply to Canadian Origin Shipments.
          </Paragraph>

          <Paragraph>
            {' '}
            A. Representations and Warranties. If you are a Carrier, you hereby
            represent and warrant that, and acknowledge that Loadsmart and the
            Shipper are relying upon such representations and warranties in
            entering into this Agreement:
          </Paragraph>

          <Paragraph>
            {' '}
            (1) you have completed the Loadsmart registration process and have
            provided Loadsmart: (i) your safety fitness certificate number for
            each province and territory, as applicable, where you have vehicles
            registered and plated;
          </Paragraph>

          <Paragraph>
            {' '}
            (2) you are duly authorized to provide Transportation Services as a
            motor carrier of commodities and desire to provide such services for
            Shipments; and
          </Paragraph>

          <Paragraph>
            {' '}
            (3) in cases where you are transporting a Shipment into the United
            States, you also make the representations and warranties set forth
            in Part III(A)(1)-(3) – US Carrier Terms, above.
          </Paragraph>

          <Paragraph> B. Covenants. If you are a Carrier, you shall:</Paragraph>

          <Paragraph>
            {' '}
            (1) prior to moving your first Shipment through Loadsmart, provide
            to Loadsmart certificate(s) of insurance evidencing minimum coverage
            amounts of $100,000 for cargo liability and $1,000,000 for business
            automobile liability with insurance companies maintaining a rating
            from A. M. Best of A- or higher;
          </Paragraph>

          <Paragraph>
            (2) comply with all applicable Canadian provincial, territorial,
            federal laws and regulations, and common law related to providing
            such Transportation Services;
          </Paragraph>

          <Paragraph>
            (3) you will immediately notify Loadsmart if your safety rating(s)
            has fallen below “satisfactory” or if you receive notice from any
            issuing authority that your safety rating(s) may be rendered
            inactive, suspended or revoked;
          </Paragraph>

          <Paragraph>
            (4) you will furnish all equipment required for the performance of
            your transportation mandate with respect to every Shipment,
            including temperature controlled vehicles when needed, and will
            maintain all such equipment in good repair and condition;
          </Paragraph>

          <Paragraph>
            (5) you will be solely responsible for all personnel you engage or
            retain to provide services under this Agreement, including paying or
            providing such personnel’s wages, insurance, training and other
            benefits;
          </Paragraph>

          <Paragraph>
            (6) you will not cause or permit any Shipment to be transported by
            any third-party motor carrier, including in interline service or by
            brokering or trip-leasing, or by any other substitute mode of
            transportation;
          </Paragraph>

          <Paragraph>
            (7) you will proceed to pick up all accepted Shipments in accordance
            with the Shipper’s scheduled pick-up date and time;
          </Paragraph>

          <Paragraph>
            (8) you will notify Shipper and Loadsmart immediately when free time
            has expired and detention charges are about to be incurred;
          </Paragraph>

          <Paragraph>
            (9) except as provided otherwise in this Agreement, you will look
            solely to Loadsmart for payment of any fees or charges due to you in
            connection with your services under this Agreement, and you
            expressly waive any claim against any third party consignee under
            the Bills of Lading Act (Canada), the Mercantile Law Amendment Act
            (Ontario), the Bills of Lading Act (Nova Scotia) and any other
            substantially similar provincial, territorial, American federal and
            state, and/or common law that exists or which may be enacted or
            exist (collectively, the “Consignee Recovery Laws”);
          </Paragraph>

          <Paragraph>
            (10) if you believe you are entitled to extra accessorial charges,
            you must notify Loadsmart and Shipper within 24 hours of the
            scheduled delivery time of those charges of which you are aware at
            that time;
          </Paragraph>

          <Paragraph>
            (11) you will not claim, and hereby waive and release any right to
            claim, any lien on any Shipment, and you will not withhold delivery
            of any Shipment on account of any dispute about any fees or charges
            claimed under this Agreement or in order to secure payment of such
            fees or charges;
          </Paragraph>

          <Paragraph>
            (12) upon receipt of payment of the price for the Transportation
            Services by Loadsmart, you automatically assign your right to
            recovery of such price to Loadsmart and Loadsmart shall be entitled
            to seek recovery of the price from any party who may be liable at
            law, including but not limited to the Consignee Recovery Laws, and
            you shall cooperate forthwith with any reasonable requests by
            Loadsmart to assist Loadsmart in the recovery of such price by
            Loadsmart;
          </Paragraph>

          <Paragraph>
            (13) you are solely responsible for any and all liability resulting
            from, or alleged to have resulted from, the transportation of any
            Shipment arranged through the Loadsmart Service, including, but not
            limited to, property damage, cargo loss, damage, or delay, personal
            injury, and death;
          </Paragraph>

          <Paragraph>
            (14) you are solely responsible for and accept liability for loss,
            damage, injury, or delay to the Shipments you transport, in
            accordance with the provisions set out in Part VIII – Canadian
            Freight Loss or Damage;
          </Paragraph>

          <Paragraph>
            (15) except with respect to subsection (B)(14) of this Part IV –
            Canadian Carrier Terms, you will defend, indemnify, and hold
            harmless Loadsmart, Shipper, Loadsmart’s customers, the consignor
            and consignee on each Shipment from and against all claims, losses,
            damages, liabilities, and expenses, including attorneys’ fees,
            arising out of or relating to: (i) your performance of
            Transportation Services under this Agreement; (ii) violation of any
            applicable law; (iii) breach of any material term or condition of
            this Agreement; (iv) inaccuracy or misrepresentation of any
            representation or warranty given by you in this Agreement; and (v)
            your negligent acts or omissions and those of your employees, agents
            and contractors; and
          </Paragraph>

          <Paragraph>
            (16) in cases where you are transporting a Shipment into the United
            States, you agree to the covenants set forth in Part III(B)(1)-(11)
            – US Carrier Terms, above.
          </Paragraph>

          <Paragraph>
            C. Non-solicitation. Carriers will not contact any Shipper directly,
            or indirectly except through the Loadsmart Service, to solicit,
            accept, or book shipments with Shipper for a period of twelve (12)
            months from the last date of contact through the Loadsmart Service
            between Carrier and Shipper. Loadsmart reserves the right to
            terminate Carrier’s Account or this Agreement in its sole discretion
            for a breach of this Section IV(C) of this Agreement. The foregoing
            actions shall be in addition to any other remedies available to
            Loadsmart at law, including but not limited to recovery of damages
            or an injunction order.
          </Paragraph>

          <Paragraph>
            <b>V. SHIPPER TERMS</b>
          </Paragraph>

          <Paragraph>
            A. Representations and Warranties. If you are a Shipper, you hereby
            represent and warrant that, and acknowledge that Loadsmart and the
            Carrier are relying upon such representations and warranties in
            entering into this Agreement:
          </Paragraph>

          <Paragraph>
            (1) you own or have all right and authority necessary to ship or
            arrange for the shipment of the commodities comprising the Shipment;
          </Paragraph>

          <Paragraph>
            (2) you have full power and authority to enter into, execute and
            deliver this Agreement and to perform your obligations under this
            Agreement, and this Agreement is a legal, valid and binding
            obligation;
          </Paragraph>

          <Paragraph>
            (3) the execution and delivery of this Agreement by you and the
            performance of your obligations hereunder has been duly authorized
            by all requisite action on your part; and
          </Paragraph>

          <Paragraph>
            (4) no order has been made or petition filed in any bankruptcy or
            insolvency proceeding concerning you, and you have not made or
            proposed any arrangement for the benefit of your creditors.
          </Paragraph>

          <Paragraph>B. Covenants. If you are a Shipper, you shall:</Paragraph>

          <Paragraph>
            (1) not use the Loadsmart Service to ship or tender Carriers
            commodities or Shipments that are excluded from the scope of this
            Agreement as listed in Section II of this Agreement or Shipments
            that originate at or are destined to a point outside of the United
            States or Canada;
          </Paragraph>

          <Paragraph>
            (3) use the Loadsmart Service to ship items at your own risk and
            agree that Loadsmart will have no liability for any shipped items;
          </Paragraph>

          <Paragraph>
            (4) assume sole responsibility for obtaining any insurance to cover
            any anticipated losses or damage to your Shipments;
          </Paragraph>

          <Paragraph>
            (5) request pick-up and delivery dates and hours that will not
            knowingly require Carrier to violate hours-of-service requirements
            under applicable American federal law or Canadian provincial,
            territorial or federal law, as applicable;
          </Paragraph>

          <Paragraph>
            (6) be solely responsible for any and all loss, damage, claims, and
            expenses including, but not limited to, property damage, personal
            injury, death and reasonable legal fees, resulting from, or alleged
            to have resulted from, the commodities that you ship through the
            Loadsmart Service including but not limited to your failure to
            properly load and secure the commodities, and inherent vice in the
            commodities; and.
          </Paragraph>

          <Paragraph>
            (7) pay Loadsmart the Shipment Fee as defined in and in accordance
            with the provisions of Section VI of this Agreement.
          </Paragraph>
          <Paragraph>
            Additionally, you understand and agree that Loadsmart is not
            responsible for Carriers that are accepted to transport your
            Shipments. You also consent to Loadsmart tracking your Shipment with
            Carrier-installed or -provided GPS technology in order to offer a
            better solution on shipment logistics, including tracing and
            scheduling Shipments while in transit, and consent to Carriers and
            others having live access to a Shipment geolocation through such
            technology without your additional consent, but you acknowledge that
            location can be misinterpreted or misclassified due to a number of
            factors and that Loadsmart is not responsible for any loss, damage
            or expense arising from or related to this tracking service.
          </Paragraph>

          <Paragraph>
            C. Non-solicitation. Shippers will not contact any Carrier directly,
            or indirectly except through the Loadsmart Service, to solicit,
            accept, or book shipments with Carrier for a period of twelve (12)
            months from the last date of contact through the Loadsmart Service
            between Carrier and Shipper. Loadsmart reserves the right to
            terminate Shipper’s Account or this Agreement in its sole discretion
            for a breach of this Section V(C) of this Agreement. The foregoing
            actions shall be in addition to any other remedies available to
            Loadsmart at law, including but not limited to recovery of damages
            or an injunction order.
          </Paragraph>

          <Paragraph>
            <b>VI. PAYMENT TERMS</b>
          </Paragraph>

          <Paragraph>
            A. Shipper Payment. For each Shipment, Shipper will pay Loadsmart
            the price or fee Loadsmart quoted Shipper and listed to Shipper when
            notifying Shipper that its applicable Shipment has been “booked” and
            accepted for posting on the Loadsmart Service (“Shipment Fee”). If
            you are a Shipper and have provided Loadsmart a valid credit card or
            your bank account information, YOU HEREBY AUTHORIZE LOADSMART EITHER
            TO BILL YOUR CREDIT CARD OR TO PROCESS A CHECK FROM YOUR BANK
            ACCOUNT, AS APPROPRIATE, FOR THE SHIPMENT FEE IMMEDIATELY AFTER THE
            SHIPMENT IS BOOKED, AND YOU AGREE THAT NO ADDITIONAL NOTICE OR
            CONSENT IS REQUIRED. YOU REPRESENT AND WARRANT THAT YOU HAVE THE
            LEGAL RIGHT TO USE ANY CREDIT CARD(S), CHECKS, OR OTHER PAYMENT
            MEANS USED TO INITIATE ANY TRANSACTION AND THAT ALL INFORMATION YOU
            PROVIDE LOADSMART OR ITS THIRD-PARTY PAYMENT PROCESSOR IS ACCURATE,
            CURRENT, AND COMPLETE. You agree to immediately notify Loadsmart of
            any change in your billing address or the credit card or bank
            account used for payment hereunder. If Loadsmart processes a check
            from your bank account, your check will be converted into an
            electronic check and presented immediately via the Automated
            Clearing House (“ACH”) system. In the event a check is subsequently
            returned for non-sufficient funds, you agree that Loadsmart may add
            a returned-check fee of $25.00 to the amount of the returned check
            and take any action available at law or in equity to collect such
            full amount. YOU AGREE TO PAY THE RETURNED-CHECK FEE, THE INITIAL
            AMOUNT, INTEREST AT A RATE OF 5% PER ANNUM, AND ANY COLLECTION
            CHARGES (INCLUDING LEGAL FEES) LOADSMART INCURS. In the event you
            contend an unauthorized charge using a credit card or electronic
            check has occurred, you should consult your bank’s rules regarding
            refunds and reversals. Loadsmart complies with all legal
            requirements of your state, province, or territory’s applicable laws
            regarding providing refunds for unauthorized charges. When a charge
            occurs, Loadsmart will send you an email. This is your electronic
            receipt.
          </Paragraph>

          <Paragraph>
            B. Carrier Remittance. For each Shipment, Loadsmart will pay Carrier
            the price listed to Carrier when Carrier accepted the Shipment
            (“Carrier Fee”). Carrier is entitled to its Carrier Fee only after
            completion of the Shipment, and the payment order to Carrier will be
            put in place up to thirty (30) calendar days after Loadsmart’s
            receipt of the Proof of Delivery in accordance with this Agreement
            (it may take up to 5 business days for Carrier to receive the money
            due to third party payment processing). The Carrier may also opt-in
            to our quickpay offering through TriumphPay at a 2% deduction from
            the total invoiced amount by logging into{' '}
            <a href="https://triumphpay.com/" target="_blank" rel="noreferrer">
              Triumphpay.com
            </a>
            . If Shipper notifies Loadsmart electronically, prior to payment to
            Carrier of the Carrier Fee, that Shipper is in a dispute with
            Carrier over delay in delivery of or loss, damage, or shortage to
            the Shipment, supported by notation on the Proof of Delivery or
            delivery receipt uploaded to the Loadsmart Service, and Shipper
            requests Loadsmart not to pay the Carrier Fee until the dispute is
            resolved, Loadsmart will withhold such payment for up to sixty (60)
            days from the completion of the Shipment to allow the parties time
            to resolve their dispute. After honoring such request, Loadsmart
            will pay the Carrier Fee immediately after either it receives
            electronic instruction from Shipper to do so or the sixty (60)-day
            dispute resolution period has expired without receiving such
            instruction, whichever occurs first. If the Shipper instructs
            Loadsmart not to pay the Carrier, to pay an amount other than the
            Carrier Fee, or to extend the dispute resolution period, upon
            receipt by Loadsmart of evidence of such joint agreement between the
            Shipper and Carrier as to such payment or extension, Loadsmart shall
            pay the Carrier or extend the dispute resolution period, as
            applicable.
          </Paragraph>

          <Paragraph>C. Additional General Payment Terms.</Paragraph>

          <Paragraph>
            (1) All payments, except Canadian Origin Shipments, will be in U.S.
            dollars. Canadian Origin Shipments will be in Canadian dollars. Fees
            and prices stated above are inclusive of all fuel costs, charges,
            and surcharges. Carrier free standby time is one hundred twenty
            (120) minutes at origin (from the time the truck arrives, if within
            the scheduled pickup time) and one hundred twenty (120) minutes at
            every destination (from the time the truck checks-in at the
            facility, if within the scheduled delivery time). Shipper will incur
            detention charges thereafter, billable in fifteen (15)-minute
            increments. Carrier shall bill Loadsmart (not Shipper) directly for
            such detention charges which charges Loadsmart will collect from
            Shipper as provided in (3) below. Loadsmart may change pricing for
            the Loadsmart Service from time to time in its sole discretion by
            updating the Site and Mobile App and without any additional notice
            to Shipper or Carrier, except with respect to booked or accepted
            Shipments.
          </Paragraph>

          <Paragraph>
            (2) Shipment Fees are non-refundable. Shippers using the Loadsmart
            Service agree to pay for any Shipments booked through the Loadsmart
            Service and not to circumvent payments for scheduled or completed
            Shipments in any way. Shipper agrees to pay Carrier a penalty (plus
            the agreed price per mile applicable after a 5-mile run) per
            Shipment if Shipper cancels the Shipment after Carrier has
            dispatched its truck to make the pickup. In addition to detention
            charges, Shipper agrees to pay Carrier a layover fee if Carrier is
            unable to pick up or deliver at the originally scheduled time
            because of some fault of Shipper, such as Shipper’s failure or
            inability to accommodate Carrier’s truck at its dock at the
            scheduled pick-up or delivery date and time or Shipper’s unilateral
            change (not accepted by Carrier) in the scheduled pickup or delivery
            date and/or time, and, as a result, Carrier’s truck and driver is
            forced to layover and pick up or deliver the following work-day.
            Users agree they are responsible for the collection and/or payment
            of all taxes for which they are liable in any jurisdiction arising
            from their use of the Loadsmart Service. Loadsmart is not
            responsible for collecting, reporting, paying, or remitting to User
            any such taxes. “Taxes” includes any applicable duties, sales or use
            taxes, and other taxes that may be levied in connection with a
            transaction contemplated by this Agreement.
          </Paragraph>

          <Paragraph>
            (3) Loadsmart will bill Shipper, and Shipper shall pay Loadsmart,
            for all extra charges owed Carrier at the time of completion of the
            Shipment that were not accounted for and included in the Shipment
            Fee at the Booking time (e.g. cancellation fees, lumper charges,
            loading/unloading fees, detention charges, layover fees, rerouting
            charges, etc.). Shipper shall pay and Loadsmart shall collect these
            extra charges from Shipper in the manner provided in Section VI.A.
            of this Agreement and Loadsmart shall inform Shipper of the
            occurrence and amount of such extra charges as soon as they are
            incurred or as soon as reasonably foreseeable.
          </Paragraph>

          <Paragraph>
            (4) Any amount that Shipper fails to pay Loadsmart when due will
            accrue interest at eighteen percent (18%) per annum or the maximum
            rate permitted by applicable law, whichever is less, from the due
            date until paid, Shipper shall be liable for all costs and expenses
            Loadsmart incurs in connection with collection of that Shipment Fee,
            including costs and expenses of a third-party collection agency
            and/or reasonable legal fees.
          </Paragraph>

          <Paragraph>
            (5) Loadsmart may use a third-party payment processor to process
            payments made or received in connection with this Agreement. By
            accepting the terms of this Agreement, Shipper or Carrier, as
            applicable, authorizes Loadsmart to disclose Shipper’s or Carrier’s,
            as applicable, information and payment instructions to the payment
            processor and agrees to be bound by the current version of the
            payment processor’s privacy policy and any applicable terms of
            service.
          </Paragraph>

          <Paragraph>
            <b>VII. US FREIGHT LOSS OR DAMAGE</b>
          </Paragraph>

          <Paragraph>
            The provisions of this Part VII – US Freight Loss or Damage do not
            apply to Canadian Origin Shipments.
          </Paragraph>

          <Paragraph>
            A. Carrier Freight Loss or Damage. Carrier agrees to assume full
            liability for the prompt, safe transportation of all Shipments under
            this Agreement, including compliance with applicable temperature
            requirements. Carrier agrees to be responsible for all loss, damage,
            delay, destruction, theft or liability of whatever nature arising
            from the Transportation Services in accordance with the applicable
            provisions of the Carmack Amendment to the Interstate Commerce Act,
            49 U.S.C. § 14706. Carrier’s liability to Shipper and to any
            involved consignor or consignee shall be the full invoice value of
            the goods transported, without limitation for full truckload and
            rail shipments. Carrier’s liability to Shipper and to any involved
            consignor or consignee shall be the carrier’s tariff liability
            limits or product value, whichever is less, for less than truckload
            shipments. Carrier shall not be liable for incidental, special, or
            consequential damages resulting from or related to loss, damage, or
            delay of any Shipment or its cargo. Signatures on Bills of Lading or
            receipts issued by Carrier shall not constitute Shipper’s written
            acceptance of Carrier’s liability limitations or other changes in
            these terms and conditions, whether appearing in Carrier’s bill of
            lading or tariffs or on its website. Carrier and Shipper agree that
            pursuant to 49 U.S.C. § 14101(b) Carrier expressly waives all rights
            and remedies under Title 49 of the U.S. Code that conflict with the
            terms and conditions of this User Agreement.
          </Paragraph>

          <Paragraph>
            B. Cargo Loading, Unloading, and Securement. Shipper is solely
            responsible for loading, unloading, and securing cargo it transports
            under this Agreement, unless Shipper expressly requires Carrier to
            do so and indicated accordingly when it booked the Shipment, and
            Shipper pays Carrier the corresponding costs and fees, if any.
          </Paragraph>

          <Paragraph>
            C. Cargo Claims Filing. In the event of loss, damage, or delay,
            Shipper shall file a claim directly with Loadsmart within nine (9)
            months of delivery date for shortage and damage and within nine (9)
            months of expected delivery date for lost shipments for full
            truckload and rail shipments; and within nine (9) months of delivery
            date for shortage and damage and within nine (9) months of bill of
            lading date for lost shipments for less than truckload shipments.
            The minimum claim amount for loss or damage to freight is $150.00.
            Carrier shall settle or otherwise dispose of such claim, granting or
            denying the claim in whole or in part, within ninety (90) days of
            receipt of the claim. Carrier hereby assumes all other terms and
            conditions set forth in 49 U.S.C. § 14706. Shipper must commence any
            lawsuit arising from such claim within eighteen (18) months from
            disallowance or denial of all or any part of such claim. Carrier
            shall not dispose of damaged or rejected product without the prior
            written consent of Shipper.
          </Paragraph>

          <Paragraph>
            <b>VII. CANADIAN FREIGHT LOSS OR DAMAGE</b>
          </Paragraph>

          <Paragraph>
            The provisions of this Part VII – Canadian Freight Loss or Damage
            only apply to Canadian Origin Shipments.
          </Paragraph>

          <Paragraph>
            A. Carrier Freight Loss or Damage. Unless the Shipper declared a
            value for the Shipment when it booked the Shipment, Carrier’s
            liability for loss of or damage to any commodities being transported
            under this Agreement, whether or not the loss or damage results from
            negligence or willful misconduct, shall be restricted to the lesser
            of:
          </Paragraph>

          <Paragraph>
            (1) the value of the Commodities at the place and time of shipment,
            including Shipment Fees, if paid; and
          </Paragraph>

          <Paragraph>
            (2) $4.41 CAD per kilogram computed on the total weight of the
            shipment.
          </Paragraph>

          <Paragraph>
            B. Where the Shipper declared a value for the Shipment when it
            booked the Shipment, the Carrier shall assume liability for loss or
            damage up to a the specified declared value amount (the “Declared
            Value”) and in such a case, the Carrier’s liability for loss of or
            damage to any commodities being transported in such Shipment,
            whether or not the loss or damage results from negligence or willful
            misconduct, shall be restricted to the lesser of:
          </Paragraph>

          <Paragraph>
            (1) the value of the commodities at the place and time of shipment,
            including the freight and other charges if paid; and
          </Paragraph>

          <Paragraph>(2) the Declared Value.</Paragraph>

          <Paragraph>
            B. Cargo Loading, Unloading, and Securement. Shipper is solely
            responsible for loading, unloading, and securing cargo it transports
            under this Agreement, unless Shipper expressly requires Carrier to
            do so and indicated accordingly when it booked the Shipment, and
            Shipper pays Carrier the corresponding costs and fees, if any.
          </Paragraph>

          <Paragraph>
            C. Cargo Claims Filing. In the event of loss, damage, or delay,
            Shipper shall file a claim directly with Loadsmart and not with the
            Carrier, within either sixty (60) days of after delivery of the
            commodities, or in the case of failure to deliver the commodities,
            within nine (9) months after the date of Shipment. The minimum claim
            for loss or damage to freight is $150.00. Carrier shall settle or
            otherwise dispose of such claim, granting or denying the claim in
            whole or in part, within sixty (60) days of receipt of the claim.
            Shipper must commence any lawsuit arising from such claim within
            nine (9) months after the date of the Shipment. Carrier shall not
            dispose of damaged or rejected product without the prior written
            consent of Shipper.
          </Paragraph>

          <Paragraph>
            <b>VIII. ELECTRONIC BILLS OF LADING</b>
          </Paragraph>

          <Paragraph>
            As indicated in Part I(b)(2) – Loadsmart Services, Shippers and
            Carriers may generate a Bill of Lading document and number using
            Loadsmart’s website bill of lading in lieu of preparing a paper
            document of their own. They also may upload their own Bills of
            Lading. They may elect to collect signatures electronically for
            pick-up and delivery of loads. Loadsmart is not responsible for
            either the accuracy of the Bill of Lading form used or the legal
            enforceability of the document, whether the paper version or the
            electronic version. Shippers and Carriers are cautioned to use this
            electronic functionality carefully and to read the BILL OF LADING
            CONTRACT TERMS AND CONDITIONS carefully to better understand their
            rights and liabilities. For all Canadian Origin Shipments, where a
            bill of lading was not issued, or was improperly issued, a bill of
            lading shall be deemed to have been issued in the form required by
            the applicable law in the jurisdiction of origin of the Shipment.
          </Paragraph>

          <Paragraph>
            Regardless of which form of Bill of Lading the parties use, if there
            are any inconsistencies between the terms and conditions of that
            Bill of Lading and those of this Agreement, the terms and conditions
            of this Agreement shall govern.
          </Paragraph>

          <Paragraph>
            <b>IX. SECURITY</b>
          </Paragraph>

          <Paragraph>
            User agrees and understands that it is responsible for maintaining
            the confidentiality of passwords associated with any account it uses
            to access the Loadsmart Service. Accordingly, User agrees that it is
            solely responsible to Loadsmart for all activities occurring under
            its Loadsmart account. If User becomes aware of any unauthorized use
            of its password or of its account, it agrees to notify Loadsmart
            immediately.
          </Paragraph>

          <Paragraph>
            <b>X. LICENSES</b>
          </Paragraph>

          <Paragraph>
            A. Site. Subject to the terms of this Agreement, Loadsmart grants
            User a non-transferable, non- exclusive, license to use the Site for
            its internal business use during the term of this Agreement.
          </Paragraph>

          <Paragraph>
            B. Mobile App. Subject to the terms of this Agreement, Loadsmart
            grants User a non-transferable, non-exclusive, license to install
            and use the Mobile App, in executable object code format only,
            solely on User’s own handheld mobile device and for its internal
            business use during the term of this Agreement. See Section XVIII.
            of this Agreement for Mobile App terms.
          </Paragraph>

          <Paragraph>
            C. Certain Restrictions. The rights granted to User in this
            Agreement are subject to the following restrictions: (i) User shall
            not license, sell, rent, lease, transfer, assign, distribute, host,
            or otherwise commercially exploit the Loadsmart Service; (ii) User
            shall not modify, make derivative works of, disassemble, reverse
            compile or reverse engineer any part of the Loadsmart Service; (iii)
            User shall not access the Loadsmart Service in order to build a
            similar or competitive service; and (iv) except as expressly stated
            herein, User may not copy, reproduce, distribute, republish,
            download, display, post, or transmit in any form or by any means any
            part of the Loadsmart Service. All future releases, updates, and
            other additions to the functionality of the Loadsmart Service shall
            be subject to the terms of this Agreement. All copyright and other
            proprietary notices on any Loadsmart Service content must be
            retained on all copies thereof.
          </Paragraph>

          <Paragraph>
            D. Limited Support. Users may contact Loadsmart’s technical support
            center for any support-related issues arising from the use of the
            Loadsmart Service by following the instructions on the Loadsmart
            Service.
          </Paragraph>

          <Paragraph>
            E. App Stores. User acknowledges and agrees that the availability of
            the Mobile App is dependent on the third party from which it
            received the Mobile App (“App Store”). User acknowledges that this
            Agreement is between it and Loadsmart and not with the App Store.
            Each App Store may have its own terms and conditions to which User
            must agree before downloading the Mobile App. User agrees to comply
            with, and its license to use the Mobile App is conditioned upon its
            compliance with, all applicable terms and conditions set by the
            applicable App Store.
          </Paragraph>

          <Paragraph>
            <b>XI. OWNERSHIP</b>
          </Paragraph>

          <Paragraph>
            A. Ownership. Excluding its User Content, defined in Section XII
            below, User acknowledge that all the intellectual property rights,
            including copyrights, patents, trade marks, and trade secrets, in
            the Loadsmart Service are owned by Loadsmart or Loadsmart’s
            licensors. The provision of the Loadsmart Service does not transfer
            to User or any third party any rights, title, or interest in or to
            such intellectual property rights. Loadsmart and its suppliers
            reserve all rights not granted in this Agreement.
          </Paragraph>

          <Paragraph>
            B. Modification. Loadsmart reserves the right, at any time, to
            modify, suspend, or discontinue the Loadsmart Service or any part
            thereof with or without notice. User agrees that Loadsmart will not
            be liable to User or to any third party for any modification,
            suspension, or discontinuance of the Loadsmart Service or any part
            thereof. See Section XXI (A) below for additional terms governing
            revisions.
          </Paragraph>

          <Paragraph>
            C. Feedback. If User provides Loadsmart any feedback, suggestions,
            bug reports, system errors, and other information or ideas regarding
            the Loadsmart Service (“Feedback”), User hereby assigns to Loadsmart
            all rights in the Feedback and agrees that Loadsmart shall have the
            right to act upon and use such Feedback and related information in
            any manner it deems appropriate. Loadsmart will treat any Feedback
            User provides to Loadsmart as non-confidential and non-proprietary.
            User agrees that it will not submit any information or ideas to
            Loadsmart that it considers confidential or proprietary.
          </Paragraph>

          <Paragraph>
            <b>XII. USER CONTENT</b>
          </Paragraph>

          <Paragraph>
            A. User Content. “User Content” means all information, data, and
            other content a User submits to or uses with the Loadsmart Service.
            User Content includes the information provided in a Shipment request
            or acceptance. Each User is solely responsible for its User Content.
            User assumes all risks associated with use of its User Content,
            including any reliance by others on its accuracy, completeness, or
            usefulness, or any disclosure of its own User Content that makes
            that User or any third party personally identifiable. Each User
            hereby represents and warrants that its User Content does not
            violate the Acceptable Use Policy, defined in Section XIII below. To
            avoid doubt, User Content may include third-party content that User
            submits. User agrees not to submit third-party content unless it has
            the consent of the applicable third-party owner of such content.
            User may not state or imply that its User Content is in any way
            provided, sponsored, or endorsed by Loadsmart. Because each User
            alone is responsible for its User Content (and not Loadsmart), User
            may be exposed to liability if, for example, its User Content
            violates the Acceptable Use Policy. Because Loadsmart does not
            control User Content, User acknowledges and agrees that Loadsmart is
            not responsible for any User Content, and Loadsmart makes no
            guarantees regarding the accuracy, currency, suitability, or quality
            of any User Content and assumes no responsibility for any User
            Content or any reliance upon it.
          </Paragraph>
          <Paragraph>
            B. User Content License. User grants, and it represents and warrants
            that it has the right to grant, to Loadsmart an irrevocable,
            nonexclusive, royalty-free and fully paid, sublicensable, worldwide
            license, to use User’s User Content solely for the purposes of
            including its User Content in the Loadsmart Service and to create
            Anonymous Data. All rights in and to the User Content not expressly
            granted to Loadsmart in this Agreement are reserved by User.
          </Paragraph>
          <Paragraph>
            C. Creation of Anonymous Data. Loadsmart may create anonymous data
            records (“Anonymous Data”) from User’s User Content by using
            commercially reasonable efforts to exclude any and all information
            (such as company name) that makes the data identifiable to any
            particular User. Loadsmart may use and disclose Anonymous Data for
            any purpose, including to improve the Loadsmart Service.
          </Paragraph>
          <Paragraph>
            D. Certain Disclosures. Loadsmart may share User’s User Content (i)
            with Loadsmart’s third-party service providers; (ii) with the
            acquiring company if another company acquires User’s company,
            business, or its assets, including through bankruptcy; and (iii) to
            comply with relevant laws, to respond to served subpoenas or
            warrants, to protect or defend Loadsmart or its User’s rights or
            property, and/or to investigate or assist in preventing any
            violation or potential violation of the law or this Agreement.
          </Paragraph>
          <Paragraph>XIII. ACCEPTABLE USE POLICY</Paragraph>

          <Paragraph>
            A. User agrees not to use the Loadsmart Service to collect, upload,
            transmit, display, or distribute any User Content that (i) violates
            any third-party right, including any copyright, trademark, patent,
            trade secret, moral right, privacy right, right of publicity, or any
            other intellectual property or proprietary right; (ii) is unlawful,
            harassing, abusive, tortious, threatening, harmful, invasive of
            another’s privacy, vulgar, defamatory, false, intentionally
            misleading, trade libelous, pornographic, obscene, patently
            offensive (e.g., material that promotes racism, bigotry, hatred, or
            physical harm of any kind against any group or individual) or
            otherwise objectionable material of any kind or nature or which is
            harmful to minors in any way; or (iii) violates any rule, law, or
            regulation or any obligation or restriction imposed by any third
            party.
          </Paragraph>

          <Paragraph>
            B. User agrees not to use the Loadsmart Service to: (i) upload,
            transmit, or distribute any computer viruses, worms, or any software
            intended to damage or alter a computer system or data; (ii) send
            unsolicited or unauthorized advertising, promotional materials, junk
            mail, spam, chain letters, pyramid schemes, or any other form of
            duplicative or unsolicited messages, whether commercial or
            otherwise; (iii) harvest, collect, gather or assemble information or
            data regarding other Users, including e-mail addresses, without
            their consent; (iv) interfere with, disrupt, or create an undue
            burden on servers or networks connected to the Loadsmart Service or
            violate the regulations, policies, or procedures of such networks;
            (v) attempt to gain unauthorized access to the Loadsmart Service or
            other computer systems or networks connected to or used together
            with the Loadsmart Service, through password mining or other means;
            (vi) harass or interfere with another User’s use and enjoyment of
            the Loadsmart Service; or (vii) introduce software or automated
            agents or scripts to the Loadsmart Service so as to produce multiple
            accounts, generate automated searches, requests, and queries, or to
            strip, scrape, or mine data from the Loadsmart Service.
          </Paragraph>

          <Paragraph>
            C. Loadsmart reserves the right (but has no obligation) in its sole
            discretion to review any User Content, investigate, and/or take
            appropriate action against any User (including removing or modifying
            User’s User Content, terminating User’s Account in accordance with
            Section XIII below and/or reporting User to law enforcement
            authorities) if User violates the Acceptable Use Policy or any other
            provision of this Agreement or otherwise creates liability for
            Loadsmart or any other person.
          </Paragraph>

          <Paragraph>
            <b>XIV. TERM AND TERMINATION</b>
          </Paragraph>

          <Paragraph>
            Subject to the provisions of this Section, this Agreement will
            remain in full force and effect while as User you use the Loadsmart
            Service. Loadsmart may at any time in its sole discretion terminate
            this Agreement with User if (i) User has breached any provision of
            this Agreement (or has acted in a manner that objectively shows that
            User does not intend to, or is unable to, comply with this
            Agreement); (ii) any representation or warranty made by the User was
            untrue, inaccurate or misleading at the time this Agreement was
            entered into; (iii) Loadsmart is required to do so by law (for
            example, where the provision of the Loadsmart Service is, or
            becomes, unlawful); (iv) Loadsmart has elected to discontinue the
            Loadsmart Service as described above; or (v) Loadsmart deems, in its
            sole discretion, that User’s performance as a Shipper or Carrier is
            poor and may adversely affect the credibility or effectiveness of
            the Loadsmart Service. Where Loadsmart terminates this Agreement
            with the User on the basis of (i), (ii) or (v), above, Loadsmart
            shall provide the User with details regarding the reasoning behind
            the termination but shall have no obligation to re-instate the
            Agreement should the User provide an explanation for its conduct.
            Upon termination of this Agreement, User’s Account and right to
            access and use the Loadsmart Service will terminate immediately.
            Where a Shipment is in progress at the time of termination, the
            Shipper and Carrier shall complete the Shipment, as applicable, and
            the User who’s Agreement has been terminated shall send any
            documents related to such Shipment to Loadsmart directly, at the
            email provided in the contact information section below and the
            Shipper shall remain responsible for payment to Loadsmart for such
            Shipment and Loadsmart shall remain responsible for payment to the
            Carrier for such Shipment. Loadsmart will have no liability
            whatsoever to User for any termination of this Agreement, including
            for termination of User’s Account or deletion of its User Content.
            Following termination of this Agreement, Sections X(C) – Licences
            and Sections XI – Ownership to XXI - General of this Agreement will
            remain in effect.
          </Paragraph>

          <Paragraph>
            <b>XV. INDEMNITY</b>
          </Paragraph>

          <Paragraph>
            In addition to other indemnities expressly provided in this
            Agreement, each User agrees to defend, indemnify, including for
            costs and attorneys’ fees, and hold Loadsmart and its officers,
            directors, shareholders, employees, contractors, agents, successors
            and assigns (collectively, the “Loadsmart Group”), harmless, from
            and against any claim, action, demand, loss, and expense (including
            costs and reasonable legal fees) (each, a “Claim”), made or suffered
            by any third party due to or arising out of User’s (i) use of the
            Loadsmart Service; (ii) User Content; (iii) interaction with any
            other User; (iv) violation of this Agreement; (v) violation of
            applicable laws or regulations; or (vi) User’s shipment contents (if
            you are a Shipper) or User’s transportation shipment services (if
            you are a Carrier), to the extent such claim or demand does not
            result entirely from the negligence or willful misconduct of
            Loadsmart Group. Loadsmart reserves the right, at User’s expense, to
            assume the exclusive defense and control of any matter for which
            User is required to indemnify Loadsmart Group, and User agrees to
            cooperate with Loadsmart’s defense of these claims. User agrees not
            to settle any matter without the prior written consent of Loadsmart.
            Loadsmart will use reasonable efforts to notify User of any such
            claim, action, or proceeding upon becoming aware of it.
          </Paragraph>

          <Paragraph>
            <b>XVI. DISCLAIMERS AND RELEASE</b>
          </Paragraph>

          <Paragraph>
            LOADSMART MAKES EVERY REASONABLE EFFORT TO KEEP ITS LOADSMART
            PLATFORM UP, BUG-FREE, AND SAFE, BUT ALL USERS USE IT AT THEIR OWN
            RISK. THE LOADSMART SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE,”
            AND LOADSMART (AND ITS SUPPLIERS) EXPRESSLY DISCLAIM ANY WARRANTIES
            AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING
            THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, AND
            NON-INFRINGEMENT. LOADSMART (AND ITS SUPPLIERS) MAKE NO WARRANTY
            THAT THE LOADSMART SERVICE: (A) WILL MEET USER’S REQUIREMENTS OR
            EXPECTATIONS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY,
            SECURE, OR ERROR-FREE BASIS; (C) WILL BE ACCURATE, RELIABLE, FREE OF
            VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE; OR (D)
            RESULT IN ANY REVENUE, PROFITS, OR COST REDUCTION. THE LOADSMART
            SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS
            INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS.
            LOADSMART IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR
            OTHER DAMAGES RESULTING FROM SUCH PROBLEMS.
          </Paragraph>

          <Paragraph>
            LOADSMART USES TECHNOLOGY TO CONNECT SHIPPERS AND CARRIERS, BUT DOES
            NOT AND DOES NOT INTEND TO PROVIDE SHIPPING OR TRANSPORTATION
            SERVICES AS A CARRIER, COURIER OR SHIPPER. IT IS UP TO THE
            THIRD-PARTY CARRIER TO PROVIDE SHIPPING AND TRANSPORTATION SERVICES
            WHICH MAY BE SCHEDULED THROUGH THE USE OF THE LOADSMART SERVICE.
            LOADSMART HAS NO RESPONSIBILITY OR LIABILITY FOR ANY SHIPPING OR
            TRANSPORTATION SERVICES PROVIDED TO SHIPPERS BY SUCH THIRD-PARTY
            CARRIERS. CARRIERS SHALL NOT BE DEEMED TO BE SUBCONTRACTORS OR
            EMPLOYEES OF LOADSMART FOR ANY REASON. CARRIER IS AND SHALL REMAIN
            AT ALL TIMES AN INDEPENDENT CONTRACTOR WITH RESPECT TO LOADSMART AND
            THE LOADSMART SERVICE PROVIDED UNDER THIS AGREEMENT. NOTHING IN THE
            AGREEMENT OR ANY SHIPPING DOCUMENT SHALL BE CONSTRUED TO CREATE A
            LEGAL PARTNERSHIP OR JOINT VENTURE BETWEEN ANY OF THE PARTIES. AS AN
            INDEPENDENT CONTRACTOR, CARRIER ASSUMES FULL RESPONSIBILITY FOR THE
            PAYMENT OF ALL TAXES, INSURANCE, PENSION AND RELATED MATTERS
            APPLICABLE TO ITS EMPLOYEES. EXCEPT AS OTHERWISE PROVIDED HEREIN,
            THIS AGREEMENT IS NONEXCLUSIVE AND SHALL NOT BE INTERPRETED TO LIMIT
            ANY PARTY’S RIGHT TO OBTAIN PRODUCTS OR SERVICES FROM OTHER SOURCES.
          </Paragraph>

          <Paragraph>
            DRIVING WHILE USING CELL OR SMART PHONES IS DANGEROUS AND IS AGAINST
            THE LAW IN MANY JURISDICTIONS. DO NOT USE THE MOBILE APP WHILE
            DRIVING. IF YOU USE THE MOBILE APP WHILE DRIVING, USER DOES SO AT
            ITS OWN RISK.
          </Paragraph>

          <Paragraph>
            LOADSMART DOES NOT ASSESS THE SUITABILITY, LEGALITY, REGULATORY
            COMPLIANCE, QUALITY, OR ABILITY OF ANY CARRIER, SHIPPER, SHIPPED
            ITEMS, AND SHIPPING OR TRANSPORTATION SERVICES SCHEDULED THROUGH THE
            USE OF THE LOADSMART SERVICE, AND LOADSMART MAKES NO WARRANTY,
            EXPRESS OR IMPLIED, REGARDING THE FOREGOING. BY USING THE LOADSMART
            SERVICE, USER OR THE SHIPMENTS MAY BE EXPOSED TO SITUATIONS THAT ARE
            POTENTIALLY DANGEROUS, OFFENSIVE, HARMFUL, UNSAFE, OR OTHERWISE
            OBJECTIONABLE. USERS USE THE LOADSMART SERVICE AT THEIR OWN RISK.
            USERS’ INTERACTIONS WITH OTHER USERS AND THIRD PARTIES ARE SOLELY
            BETWEEN THOSE USERS AND SUCH USER OR THIRD PARTY. USER AGREES THAT
            LOADSMART WILL NOT BE RESPONSIBLE FOR ANY LOSS OR DAMAGE INCURRED AS
            THE RESULT OF ANY SUCH INTERACTIONS. IF THERE IS A DISPUTE BETWEEN
            YOU AS A USER AND ANY OTHER USER OR THIRD PARTY, LOADSMART IS UNDER
            NO OBLIGATION TO BECOME INVOLVED.
          </Paragraph>

          <Paragraph>
            USER HEREBY WAIVES AND RELEASES LOADSMART AND ITS SUPPLIERS,
            OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AGENTS, CONTRACTORS,
            SUCCESSORS AND ASSIGNS (COLLECTIVELY “LOADSMART/SUPPLIER GROUP”)
            FROM, AND HEREBY WAIVES AND RELINQUISHES, EACH AND EVERY PAST,
            PRESENT AND FUTURE DISPUTE, CLAIM, CONTROVERSY, DEMAND, RIGHT,
            OBLIGATION, LIABILITY, ACTION AND CAUSE OF ACTION OF EVERY KIND AND
            NATURE (INCLUDING PERSONAL INJURIES, DEATH, AND PROPERTY DAMAGE),
            LOSS, OR EXPENSE INCLUDING REASONABLE LEGAL FEES,(EACH, A “CLAIM”)
            ARISING FROM (i) USER’S USE OF THE LOADSMART SERVICE; (II) USER
            CONTENT; (III) INTERACTION WITH ANY OTHER USER; (IV) VIOLATION OF
            THIS AGREEMENT; (V) VIOLATION OF APPLICABLE LAWS OR REGULATIONS; OR
            (VI) USER’S SHIPMENT CONTENTS (IF YOU ARE A SHIPPER) OR USER’S
            TRANSPORTATION SHIPMENT SERVICES (IF YOU ARE A CARRIER), OR IN ANY
            WAY RELATED TO OTHER USERS OR THIRD PARTIES, TO THE EXTENT SUCH
            CLAIM DOES NOT RESULT ENTIRELY FROM THE NEGLIGENCE OR WILLFUL
            MISCONDUCT OF LOADSMART/SUPPLIER GROUP. IF YOU AS A USER ARE A
            CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION
            1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL
            RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW
            OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
            RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED
            HIS OR HER SETTLEMENT WITH THE DEBTOR.”
          </Paragraph>

          <Paragraph>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES.
            ACCORDINGLY, THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
          </Paragraph>

          <Paragraph>
            <b>XVII. LIMITATION ON LIABILITY</b>
          </Paragraph>

          <Paragraph>
            IN NO EVENT SHALL LOADSMART/SUPPLIER GROUP BE LIABLE TO USER OR ANY
            THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES ARISING FROM OR
            RELATING TO THIS AGREEMENT OR USER’S USE OF, OR INABILITY TO USE,
            THE LOADSMART SERVICE, EVEN IF LOADSMART HAS BEEN ADVISED IN ADVANCE
            OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED HEREIN, LOADSMART/SUPPLIER GROUP’S LIABILITY TO
            USER FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT OR
            THE LOADSMART SERVICE (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF
            THE FORM OF THE ACTION) WILL AT ALL TIMES BE LIMITED TO THE GREATER
            OF (A) FIFTY DOLLARS ($50) OR (B) THE AGGREGATE OF THE AMOUNTS USER
            HAS PAID LOADSMART IN THE PRIOR 12 MONTHS (IF ANY). THE EXISTENCE OF
            MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.
          </Paragraph>

          <Paragraph>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES. ACCORDINGLY, THE
            ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU AS A USER, AND
            YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM JURISDICTION TO
            JURISDICTION.
          </Paragraph>

          <Paragraph>
            <b>XVIII. THIRD PARTY SITES & ADS</b>
          </Paragraph>

          <Paragraph>
            The Loadsmart Service might contain links to third-party websites,
            services, and advertisements for third parties (collectively, “Third
            Party Sites & Ads”). These Third Party Sites & Ads are not under the
            control of Loadsmart, and Loadsmart is not responsible for any Third
            Party Sites & Ads. Loadsmart provides these Third Party Sites & Ads
            only as a convenience and does not review, approve, monitor,
            endorse, warrant, or make any representations with respect to Third
            Party Sites & Ads. Users use all Third Party Sites & Ads at their
            own risk. When User links to a Third Party Site & Ad, the applicable
            third party’s terms and policies apply, including the third party’s
            privacy and data gathering practices. User should make whatever
            investigation User feel necessary or appropriate before proceeding
            with any transaction in connection with such Third Party Sites &
            Ads.
          </Paragraph>

          <Paragraph>
            <b>XIX. COPYRIGHT POLICY AND PERSONAL INFORMATION PRIVACY</b>
          </Paragraph>

          <Paragraph>A. Copyright Policy</Paragraph>

          <Paragraph>
            Loadsmart respects the intellectual property of others and asks all
            Users of its Loadsmart Service do the same. In connection with the
            Loadsmart Service, Loadsmart has adopted and implemented a policy
            respecting copyright law that provides for the removal of any
            infringing materials and for the termination, in appropriate
            circumstances, of Users that are repeat infringers of intellectual
            property rights, including copyrights. If you as a User believe that
            one of Loadsmart’s Users is, through the use of the Loadsmart
            Service, unlawfully infringing the copyright(s) in a work and wish
            to have the allegedly infringing material removed, the following
            information in the form of a written notification, pursuant to 17
            U.S.C. § 512(c), must be provided to Loadsmart’s designated
            Copyright Agent:
          </Paragraph>

          <Paragraph>(1) your physical or electronic signature;</Paragraph>

          <Paragraph>
            (2) identification of the copyrighted work(s) you claim have been
            infringed;
          </Paragraph>

          <Paragraph>
            (3) identification of the material on Loadsmart’s Service you claim
            is infringing and you request be removed;
          </Paragraph>

          <Paragraph>
            (4) sufficient information to permit Loadsmart to locate such
            material;
          </Paragraph>

          <Paragraph>
            (5) your address, telephone number, and e-mail address;
          </Paragraph>

          <Paragraph>
            (6) a statement that you have a good faith belief the use of the
            objectionable material is not authorized by the copyright owner, its
            agent, or under the law; and
          </Paragraph>

          <Paragraph>
            (7) a statement that the information in the notification is accurate
            and, under penalty of perjury, that you are either the owner of the
            copyright that has allegedly been infringed or you are authorized to
            act on behalf of the copyright owner.
          </Paragraph>

          <Paragraph>B. Personal Information Privacy</Paragraph>

          <Paragraph>
            Loadsmart collects, uses, and discloses information regarding User’s
            use of Loadsmart’s Site and Loadsmart Service and User’s personal
            information in accordance with the Loadsmart Privacy Policy,
            incorporated by reference herein. That Policy is available by
            clicking here. Loadsmart may, and reserves the right to, change the
            Privacy Policy from time to time in its discretion without prior
            notice or liability to Users or any other person. By accepting this
            Agreement, and each time you as a User use the Site, you consent to
            Loadsmart’s collection, use, and disclosure of your personal
            information in accordance with the Privacy Policy as it then reads
            without any further notice or any liability to you or any other
            person.
          </Paragraph>

          <Paragraph>
            Please note that, pursuant to 17 U.S.C. § 512(f), any
            misrepresentation of material fact (falsities) in a written
            notification automatically subjects the complaining party to
            liability for any damages, costs, and attorney’s fees Loadsmart
            incurs in connection with the written notification and allegation of
            copyright infringement.
          </Paragraph>

          <Paragraph>
            <b>XX. APPLE APP STORE ADDITIONAL TERMS AND CONDITIONS</b>
          </Paragraph>

          <Paragraph>
            The following additional terms and conditions apply to you as a User
            if you are using a Mobile App from the Apple App Store. To the
            extent the other terms and conditions of this Agreement are less
            restrictive than, or otherwise conflict with, the terms and
            conditions of this Section XX, the more restrictive or conflicting
            terms and conditions in this Section apply, but solely with respect
            to Mobile Apps from the Apple App Store.
          </Paragraph>

          <Paragraph>
            A. Acknowledgement: Loadsmart and you as a User acknowledge that
            this Agreement is concluded between Loadsmart and you only, and not
            with Apple, and that Loadsmart, not Apple, is solely responsible for
            the Mobile App and the content thereof. To the extent this Agreement
            provides for usage rules for the Mobile App that are less
            restrictive than the Usage Rules set forth for the Mobile App in the
            App Store Terms of Service, or is otherwise in conflict with those
            Terms of Service, the more restrictive or conflicting Apple term
            applies.
          </Paragraph>

          <Paragraph>
            B. Scope of License: The license granted to you as a User for the
            Mobile App is limited to a nontransferable license to use the Mobile
            App on an iOS Product that you own or control and as permitted by
            the Usage Rules set forth in the App Store Terms of Service.
          </Paragraph>

          <Paragraph>
            C. Maintenance and Support: Loadsmart is solely responsible for
            providing any maintenance and support services with respect to the
            Mobile App, as specified in this Agreement (if any), or as required
            under applicable law. Loadsmart and you as User acknowledge that
            Apple has no obligation whatsoever to furnish any maintenance and
            support services with respect to the Mobile App.
          </Paragraph>

          <Paragraph>
            D. Warranty: Loadsmart is solely responsible for any product
            warranties, whether express or implied by law, to the extent not
            effectively disclaimed. In the event of any failure of the Mobile
            App to conform to any applicable warranty, you may notify Apple, and
            Apple will refund the purchase price for the Mobile App to you; and
            to the maximum extent permitted by applicable law, Apple will have
            no other warranty obligation whatsoever with respect to the Mobile
            App, and any other claims, losses, liabilities, damages, costs or
            expenses attributable to any failure to conform to any warranty will
            be Loadsmart’s sole responsibility.
          </Paragraph>

          <Paragraph>
            E. Product Claims: Loadsmart and you as a User acknowledge that
            Loadsmart, not Apple, is responsible for addressing any claims of
            you or any third party relating to the Mobile App or your possession
            and/or use of the Mobile App, including, but not limited to: (i)
            product liability claims; (ii) any claim that the Mobile App fails
            to conform to any applicable legal or regulatory requirement; and
            (iii) claims arising under consumer protection or similar
            legislation. This Agreement does not limit Loadsmart’s liability to
            you beyond what is permitted by applicable law.
          </Paragraph>

          <Paragraph>
            F. Intellectual Property Rights: Loadsmart and you as a User
            acknowledge that, in the event of any third party claim that the
            Mobile App or your possession and use of the Mobile App infringes
            that third party’s intellectual property rights, as between
            Loadsmart and Apple, Loadsmart, not Apple, will be solely
            responsible for the investigation, defense, settlement and discharge
            of any such intellectual property infringement claim.
          </Paragraph>

          <Paragraph>
            G. Legal Compliance: You as User represent and warrant that (i) you
            are not located in a country that is subject to a U.S. Government
            embargo, or that has been designated by the U.S. Government as a
            “terrorist supporting” country; and (ii) you are not listed on any
            U.S. Government list of prohibited or restricted parties.
          </Paragraph>

          <Paragraph>
            H. Developer Name and Address: Loadsmart’s contact information for
            any end-user questions, complaints, or claims with respect to the
            Mobile App is set forth in Section XXI(K).
          </Paragraph>

          <Paragraph>
            I. Third-Party Terms of Agreement: You as User must comply with
            applicable third-party terms of agreement when using the Mobile App.
          </Paragraph>

          <Paragraph>
            J. Third Party Beneficiary: Loadsmart and you as User acknowledge
            and agree that Apple, and Apple’s subsidiaries, are third-party
            beneficiaries of this Agreement and that, upon your acceptance of
            the terms and conditions of this Agreement, Apple will have the
            right (and will be deemed to have accepted the right) to enforce
            this Agreement against you as a third-party beneficiary thereof.
          </Paragraph>

          <Paragraph>
            <b>XXI. GENERAL</b>
          </Paragraph>

          <Paragraph>
            A. Changes to Terms of Service. This Agreement is subject to
            occasional revision by Loadsmart, and if Loadsmart makes any
            material changes, it will attempt to notify you as a User by
            providing you an option to accept the new terms before your next use
            of the Loadsmart Service or by sending you an e-mail to the last
            e-mail address you provided to us (if any) and/or by prominently
            posting notice of the changes on the Loadsmart Service. Any changes
            to this Agreement will be effective upon the earlier of (i) the date
            you accept the new terms or (ii) thirty (30) calendar days following
            Loadsmart’s transmission of an e-mail notice to you (if applicable)
            or (iii) thirty (30) calendar days following Loadsmart’s posting of
            notice of the changes on the Loadsmart Service. These changes will
            be effective immediately for new Users of the Loadsmart Service. As
            a User, you are responsible for providing Loadsmart with your most
            current e-mail address. In the event the last e-mail address you
            provided Loadsmart is not valid, or for any reason is not capable of
            delivering to you the notice described above, Loadsmart’s dispatch
            of the e-mail containing such notice will nonetheless constitute
            effective notice of the changes described in the notice. Your
            continued use of the Loadsmart Service following notice of such
            changes shall indicate your acknowledgement of such changes and
            agreement to be bound by the terms and conditions of such changes.
          </Paragraph>

          <Paragraph>
            B. Dispute Resolution. Except with respect to Canadian Origin
            Shipments, any claim, cause of action, or dispute (claim) that you
            as a User has with Loadsmart arising out of or relating to this
            Agreement must be brought exclusively in a state or federal court
            located in the State of Illinois. The laws of the State of Illinois
            will govern this Agreement, as well as any claim that might arise
            between you and Loadsmart, without regard to conflict of law
            provisions. You agree to submit to the personal jurisdiction of the
            courts located in Chicago, IL for the purpose of litigating all such
            claims. With respect to Canadian Origin Shipments, this Agreement
            shall be construed according to the laws of the Province of Ontario
            and the laws of Canada applicable therein. The courts sitting in the
            City of Toronto shall have exclusive jurisdiction over any dispute
            arising from or relating to this Agreement.
          </Paragraph>

          <Paragraph>
            C. Force Majeure. Any delay in the performance of any duties or
            obligations of any party to this Agreement (except the payment of
            money owed) will not be considered a breach of this Agreement if
            such delay is caused by a labor dispute, shortage of materials,
            fire, earthquake, flood, or any other event beyond the control of
            such party.
          </Paragraph>

          <Paragraph>
            D. Anti-Bribery and Anti-Corruption. The parties warrant that for
            the duration of this Agreement, they will comply with all applicable
            anti-corruption laws. In particular, the parties undertake not to:
            pay, promise to pay or offer to pay, any commission, success fee,
            bribe, pay off or kickback related to the Services that violates any
            anti-corruption laws or enter into any agreement pursuant to which
            any such commission, success fee, bribe, pay off or kickback may, or
            will at any time, be paid; or offer, promise or give any undue
            pecuniary or other advantage, whether directly or indirectly to any
            public official, with the intent of influencing the actions or
            decisions of such official in performance of his/her official
            duties, with the purpose of obtaining or retaining business or other
            improper benefit or advantage. Any breach by either party of this
            provisions will be a material breach of this Agreement and shall
            entitle either party to cancel this Agreement immediately on notice
            to the other party.
          </Paragraph>

          <Paragraph>
            E. Right to Audit. Loadsmart reserves the right to require the User
            to submit to an audit by any auditor of Loadsmart’s choosing, at
            Loadsmartt’s sole expense. The User shall provide access to all of
            its records, which relate directly or indirectly to this Agreement
            at its place of business during regular business hours. User shall
            retain all records pertaining to this Agreement and upon request
            make them available to Loadsmart for three (3) complete calendar
            years following expiration of the Agreement. User agrees to provide
            such assistance as may be necessary to facilitate the review or
            audit by Loadsmart to ensure compliance with applicable accounting
            and financial standards. This provision is hereby considered to be
            included within, and applicable to, any subcontractor agreement
            entered into by User in performance of any work under this
            Agreement. If an audit inspection or examination pursuant to this
            section discloses overpricing or overcharges of any nature by User
            to Loadsmart in excess of five percent (5%) of the total contract
            billings, in addition to making adjustments for the overcharges, the
            reasonable actual cost of Loadsmart’s audit must be reimbursed to
            the Loadsmart by User. Any adjustments or payments which must be
            made as a result of any such audit or inspection of User’s invoices
            or records must be made within a reasonable amount of time, but in
            no event may the time exceed ninety (90) calendar days, from
            presentation of the Loadsmart’s audit findings to User.
          </Paragraph>

          <Paragraph>
            F. Shippers and Carriers will treat Loadsmart staff with dignity and
            respect. Physical abuse, the threat of physical abuse, sexual or
            other harassment, verbal abuse or any other form of intimidation are
            prohibited.
          </Paragraph>

          <Paragraph>
            G. Entire Agreement. This Agreement constitutes the entire agreement
            between you as User and Loadsmart regarding the use of the Loadsmart
            Service. Loadsmart’s failure to exercise or enforce any right or
            provision of this Agreement shall not operate as a waiver of such
            right or provision. The section titles in this Agreement are for
            convenience only and have no legal or contractual effect. The word
            “including” means including without limitation. If any provision of
            this Agreement is, for any reason, held to be invalid or
            unenforceable, the other provisions of this Agreement will be
            unimpaired and the invalid or unenforceable provision will be deemed
            modified so that it is valid and enforceable to the maximum extent
            permitted by law. This Agreement may be executed in counterparts.
          </Paragraph>

          <Paragraph>
            H. Assignment. This Agreement, and your rights and obligations
            herein as User, may not be assigned, subcontracted, delegated, or
            otherwise transferred by you without Loadsmart’s prior written
            consent, and any attempted assignment, subcontract, delegation, or
            transfer in violation of the foregoing will be null and void. The
            terms of this Agreement shall be binding upon assignees.
          </Paragraph>

          <Paragraph>
            I. Press. You as User hereby grant Loadsmart permission to identify
            you as a customer of Loadsmart or user of its Loadsmart Service and
            to reproduce your name and logo on the Site and in any other
            marketing materials during the term of this Agreement.
          </Paragraph>

          <Paragraph>
            J. Conflict. If there is any conflict or inconsistency between the
            terms and conditions set forth in this Agreement and the terms set
            forth in any Bill of Lading, Proof of Delivery, or any other
            shipping form, notation, sticker, tariff, or website, the terms and
            conditions of this Agreement shall control over such terms.
          </Paragraph>

          <Paragraph>
            K. Copyright/Trademark Information. All trademarks, logos, and
            service marks (“Marks”) displayed on the Loadsmart Service are
            Loadsmart’s property or the property of other third parties. You as
            User are not permitted to use these Marks without Loadsmart’s prior
            written consent or the consent of such third party, which may own
            the Marks.
          </Paragraph>

          <Paragraph>
            L. Electronic Communications. The communications between you and
            Loadsmart use electronic means, whether you use the Loadsmart
            Service or send Loadsmart e-mails, or whether Loadsmart posts
            notices on the Loadsmart Service or communicates with you via
            e-mail. For contractual purposes, you as User (i) consent to receive
            communications from Loadsmart in an electronic form; and (ii) agree
            that all terms and conditions, agreements, notices, disclosures, and
            other communications that Loadsmart provides to you as User
            electronically satisfy any legal requirement that such
            communications would satisfy if it were be in a hardcopy writing.
            The foregoing does not affect your non-waivable rights.
          </Paragraph>

          <Paragraph>M. Contact Information:</Paragraph>

          <Paragraph>
            <b>
              Loadsmart, Inc.
              <br />
              Address: {OFFICES.main.address}
              <br />
              {`${OFFICES.main.city}, ${OFFICES.main.state} ${OFFICES.main.zipcode}`}
            </b>
          </Paragraph>
        </Layout>
      </>
      <Footer />
    </>
  );
};

export default UserAgreement;
export { UserAgreement };
