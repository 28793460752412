//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

//components
import {
  Layout,
  Title,
  TitleH2,
  TitleH3,
  TitleH4,
  Paragraph,
  Link,
  BulltetList,
} from '@/components/Typography/Legal';

const SubscriptionAndProfessionalServicesAgreement = () => {
  return (
    <>
      <Navigation />
      <>
        <Layout>
          <Title>Subscription and Professional Services Agreement</Title>
          <Paragraph>
            <i>Last Updated: August 12, 2024</i>
          </Paragraph>
          <Paragraph>
            This Subscription and Professional Services Agreement is effective
            as of the Effective Date and is made by and between Customer and
            Loadsmart and sets forth the terms and conditions governing
            Customer’s access to and use of the Services.
          </Paragraph>
          <Paragraph>
            <TitleH2>1. DEFINITIONS</TitleH2>
            <Paragraph>
              <strong>1.1. “Affiliate”</strong> means with respect to a party,
              any entity which (i) is controlled by, (ii) controls, or (iii) is
              under common control with such party, where the term “control”
              means the ownership, directly or indirectly, of more than fifty
              percent (50%) of the shares entitled to vote for the election of
              directors.
            </Paragraph>
            <Paragraph>
              <strong>1.2. “Agreement” </strong> means this Subscription and
              Professional Services Agreement together with the applicable Order
              Form or Statement of Work executed by Customer.
            </Paragraph>
            <Paragraph>
              <strong>1.3. “Authorized User”</strong> means an employee of
              Customer that has been authorized by Customer to access and use
              the Subscription Services.
            </Paragraph>
            <Paragraph>
              <strong>1.4. “Confidential Information”</strong> means all
              non-public information of a party, including, but not limited to,
              all technical data, trade secrets, know-how, research, product or
              service ideas or plans, software codes and designs, algorithms,
              developments, inventions, patent applications, laboratory
              notebooks, processes, formulas, techniques, mask works,
              engineering designs and drawings, hardware configuration
              information, agreements with third parties, lists of, or
              information relating to, employees and consultants of the
              Disclosing Party (including, but not limited to, the names,
              contact information, jobs, compensation, and expertise of such
              employees and consultants), lists of, or information relating to,
              customers, price lists, pricing methodologies, cost data, market
              share data, marketing plans, licenses, contract information,
              business plans, financial forecasts, historical financial data,
              budgets or other business information, and usernames and passwords
              for the Subscription Services disclosed by the Disclosing Party
              (whether by oral, written, graphic or machine-readable format) to
              the Receiving Party, which Confidential Information is designated
              to be confidential or proprietary or which information would,
              under the circumstances, appear to a reasonable person to be
              confidential or proprietary.
            </Paragraph>
            <Paragraph>
              <strong>1.5. “Customer”</strong> means the legal entity other than
              Loadsmart identified in the applicable Order Form or Statement of
              Work.
            </Paragraph>
            <Paragraph>
              <strong>1.6. “Customer Data”</strong> means any data that is
              provided by Customer or its Authorized Users to Loadsmart during
              the Services, including Personal Data and Personal Information
              about Authorized Users, but specifically excluding any Third Party
              User Data and Usage Data. Customer Data is the Confidential
              Information of Customer.
            </Paragraph>
            <Paragraph>
              <strong>1.7. “Deliverables”</strong> means the completed work
              product specifically identified as a deliverable in the applicable
              Statement of Work or Order Form.
            </Paragraph>
            <Paragraph>
              <strong>1.8. “Documentation”</strong> means the technical
              documentation regarding the Subscription Services provided by
              Loadsmart to Customer.
            </Paragraph>
            <Paragraph>
              <strong>1.9. “Effective Date”</strong> means (a) in the case of
              Subscription Services, the earlier of: (i) Customer’s initial
              access to the applicable Subscription Services in any capacity, or
              (ii) Customer’s execution of the applicable Order Form; or (b) in
              the case of Professional Services, the effective date listed in
              the applicable Statement of Work.
            </Paragraph>
            <Paragraph>
              <strong>1.10. “Excluded Claim”</strong> means liabilities arising
              from: (i) a party’s gross negligence, willful misconduct, or
              fraud; (ii) a party’s violation of applicable law; (iii)
              Customer’s breach of Section 2.2; (iv) Customer’s indemnification
              obligations outlined in Section 10.1; or (v) a party’s breach of
              the confidentiality obligations outlined in Section 7.1.
            </Paragraph>
            <Paragraph>
              <strong>1.11. “Feedback”</strong> ” means any suggestions, ideas,
              concepts, know-how, techniques, comments, or questions containing
              feedback about the Services submitted by Customer or its
              Authorized Users.
            </Paragraph>
            <Paragraph>
              <strong>1.12. “Fees”</strong>” means any fees paid by Customer for
              the Services, as further set forth in the applicable Order Form or
              Statement of Work.
            </Paragraph>
            <Paragraph>
              <strong>1.13. “Intellectual Property”</strong> means any and all
              inventions, trade secrets, know-how, developments, improvements,
              results, data, designs, visual interfaces, graphics, compilations,
              information, computer code (including source code or object code),
              products, software, services and any other intellectual property
              rights recognized in any country or jurisdiction in the world.
            </Paragraph>
            <Paragraph>
              <strong>1.14. “Loadsmart”</strong> means Loadsmart, Inc. and its
              wholly owned subsidiaries and Affiliates.
            </Paragraph>
            <Paragraph>
              <strong>1.15. “Order Form”</strong> means an order for
              Subscription Services in a format approved by Loadsmart, which
              incorporates this Agreement and its terms by reference.
            </Paragraph>
            <Paragraph>
              <strong>1.16. “Professional Services”</strong> means the services
              to be performed by Loadsmart as further described in the
              applicable Statement of Work or Order Form.
            </Paragraph>
            <Paragraph>
              <strong>1.17. “Security Incident”</strong> means the actual
              unauthorized acquisition of Customer Data, Personal Information,
              or Confidential Information of a party which is maintained or
              stored by the other party or the actual or suspected unauthorized
              access to or use of the Subscription Services.
            </Paragraph>
            <Paragraph>
              <strong>1.18. “Services”</strong> means both the Subscription
              Services and the Professional Services, collectively.
            </Paragraph>
            <Paragraph>
              <strong>1.19. “Software”</strong> means the Software-as-a-Service
              platform owned and operated by Loadsmart through which an
              Authorized User may access the Subscription Services.
            </Paragraph>
            <Paragraph>
              <strong>1.20. “Statement of Work”</strong> means a document
              executed by both parties outlining the Professional Services to be
              performed by Loadsmart and the associated Deliverables.
            </Paragraph>
            <Paragraph>
              <strong>1.21. “Subscription Services”</strong> means the online
              and hosted Software subscription provided by Loadsmart. The scope
              of the Subscription Services purchased by the Customer may be
              further described in the Order Form.
            </Paragraph>
            <Paragraph>
              <strong>1.22. “Subscription Term”</strong> means the service start
              date through the service end date as set forth in the applicable
              Order Form.
            </Paragraph>
            <Paragraph>
              <strong>1.23. “Third Party User”</strong> means a user accessing
              the Subscription Services that is not Customer or Loadsmart.
            </Paragraph>
            <Paragraph>
              <strong>1.24. “Usage Data”</strong> means any information derived
              from the Software as a result of Customer’s use of the
              Subscription Services, including, but not limited to, data,
              statistics, trends, patterns, and similar information.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            <TitleH2>2. SUBSCRIPTION SERVICES</TitleH2>
            <Paragraph>
              <TitleH3>2.1. Subscription Services.</TitleH3>
              Loadsmart will provide the Subscription Services in accordance
              with this Agreement for the duration of the Subscription Term.
              Customer may access and use the Subscription Services solely for
              its internal business purposes, subject to the terms of this
              Agreement, and such access and use is expressly limited to the
              Authorized Users of Customer.
            </Paragraph>
            <Paragraph>
              <TitleH3>2.2. Restrictions.</TitleH3>
              Customer shall not attempt to interfere with or disrupt the
              Subscription Services or the Software or attempt to gain access to
              any systems or networks that connect thereto (except as required
              to access and use the Subscription Services). Customer shall not
              allow access to or use of the Subscription Services by anyone
              other than Authorized Users. Customer shall not, and shall ensure
              its Authorized Users do not: (a) copy, modify or distribute any
              portion of the Subscription Services or Software, (b) rent, lease,
              or provide access to the Subscription Services or Software on a
              time-share or service bureau basis; (c) use the Subscription
              Services or Software to provide, or incorporate the Subscription
              Services or Software into, any product or service provided to a
              third party, (d) reverse engineer, decompile, disassemble, or
              otherwise seek to obtain the source code or non-public APIs
              related to the Subscription Services or Software, or (e) transfer
              any of its rights hereunder (except as expressly set forth
              herein).
            </Paragraph>
            <Paragraph>
              <TitleH3>2.3. Acceptable Use.</TitleH3>
              Customer shall use the Subscription Services exclusively for
              authorized and legal purposes, consistent with applicable laws,
              regulations and the rights of others and in accordance with the
              applicable Order Form. Except for Customer Data, Customer may only
              use the data, information, or other content derived from the
              Subscription Services for Customer’s internal business purposes to
              the extent necessary to make use of the Subscription Services.
              Customer shall be fully liable for all acts or omissions of its
              Authorized Users in connection with the Subscription Services as
              if they were performed by Customer.
            </Paragraph>
            <Paragraph>
              <TitleH3>
                2.4. Illegal and Unauthorized Use of Subscription Services.
              </TitleH3>
              Loadsmart may immediately suspend or terminate Customer’s access
              to the Subscription Services, by written notice to Customer, in
              the event that (i) Loadsmart determines on the basis of reasonable
              evidence that the Subscription Services are being used for
              fraudulent or criminal activities, or in violation of any
              applicable law or regulation or the Agreement, or (ii) in the
              event that a governmental, legal or other law enforcement
              authority so requires, or instructs Loadsmart to terminate or
              suspend the Subscription Services to Customer. Customer is and
              shall be solely responsible for any fraudulent use, misuse, or
              illegal activity conducted in or on the Subscription Services by
              its Authorized Users.
            </Paragraph>
            <Paragraph>
              <TitleH3>2.5. Data Maintenance and Backup Procedures.</TitleH3>
              The Subscription Services are not intended to be a data warehouse
              or data back-up solution. In the event of any loss or corruption
              of Customer Data, Loadsmart shall use commercially reasonable
              efforts to restore the lost or corrupted Customer Data from the
              latest backup of such Customer Data maintained by Loadsmart.
              Customer acknowledges that full restoration of Customer Data may
              not be possible under all circumstances and Loadsmart shall have
              no liability to Customer in such case.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            <TitleH2>3. PROFESSIONAL SERVICES</TitleH2>
            <Paragraph>
              <TitleH3>3.1. Statement of Work.</TitleH3>
              From time to time, Customer may request that Loadsmart perform
              certain Professional Services. Each engagement for Professional
              Services will be described in detail in a separate Statement of
              Work executed by both parties, including, but not limited to, Fees
              and expenses, project timelines, personnel and resource
              requirements, and specific Deliverables.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            <TitleH2>4. CUSTOMER OBLIGATIONS</TitleH2>
            <Paragraph>
              <TitleH3>4.1. Cooperation and Assistance.</TitleH3>
              Customer shall at all times: (a) provide Loadsmart with good faith
              cooperation and assistance and access to such information and
              personnel as may be reasonably required by Loadsmart in order to
              provide the Services from time to time; and (b) carry out in a
              timely manner all other Customer responsibilities set forth in
              this Agreement.
            </Paragraph>
            <Paragraph>
              <TitleH3>4.2. Marketing Support.</TitleH3>
              Customer grants to Loadsmart a non-exclusive, non-transferable,
              revocable, limited right to use Customer’s name, trademarks, and
              logos (collectively, the <strong>“Customer Marks”</strong>) solely
              for the purpose of identifying Customer as a recipient of the
              Services and in accordance with any trademark and logo use
              guidelines that Customer provides to Loadsmart.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            <TitleH2>5. FEES; TAXES</TitleH2>
            <Paragraph>
              <TitleH3>5.1. Fees; Late Payment.</TitleH3>
              Customer shall pay the Fees and expenses as set forth in the Order
              Form and Statement of Work, as applicable. In the event Customer’s
              usage of the Subscription Services exceeds the limitations set
              forth in the applicable Order Form, Customer will be responsible
              for any additional amounts billed by Loadsmart as a result. After
              the initial Subscription Term, Loadsmart may increase the Fees for
              the Subscription Services no more than once in a twelve (12) month
              period. Customer is responsible for maintaining complete and
              accurate billing and contact information and notifying Loadsmart
              of any changes to such information. All invoices are due thirty
              (30) days following receipt by Customer. In the event that an
              invoice is not timely paid, Loadsmart shall have the right to
              charge interest on past due amounts at the rate of one and one
              half percent (1.5%) per month, but in no event greater than the
              highest rate of interest allowed by law, calculated from the date
              such amount was due until the date that payment is received by
              Loadsmart. In addition, if any invoice becomes more than thirty
              (30) days overdue, Loadsmart may: (i) for Subscription Services,
              suspend Customer’s access to and use of the Subscription Services
              without liability to Customer, or (ii) for Professional Services,
              cease all work under the applicable Statement of Work. If
              Loadsmart is required to take legal action to collect undisputed
              Fees, Customer shall be obligated to reimburse Loadsmart for the
              reasonable costs of collection, including reasonable attorney’s
              fees. Except as otherwise set forth in Section 9.2 and Section
              10.2, all Fees for Services are non-refundable.
            </Paragraph>
            <Paragraph>
              <TitleH3>5.2. Payments Processing.</TitleH3>
              Customer authorizes Loadsmart to automatically charge Customer’s
              credit card or bank account for all Fees payable in accordance
              with the applicable Order Form or Statement of Work. Customer
              further authorizes Loadsmart to use a third party to process
              payments and consents to the disclosure of Customer’s payment
              information to such third party. Customer will notify Loadsmart
              immediately of any change in Customer’s credit card or bank
              account billing information.
            </Paragraph>
            <Paragraph>
              <TitleH3>5.3. Taxes.</TitleH3>
              All amounts and Fees stated or referred to in this Agreement or
              any Order Form or Statement of Work, are exclusive of taxes,
              duties, levies, tariffs, and other governmental charges
              (including, without limitation, sales, use, GST, VAT, withholding,
              or similar taxes) (collectively, <strong>“Taxes”</strong>). If
              applicable, Loadsmart will charge all Taxes to Customer as a
              separate line item on each invoice issued in connection with the
              Services.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            <TitleH2>6. INTELLECTUAL PROPERTY</TitleH2>
            <Paragraph>
              <TitleH3>6.1. Loadsmart Intellectual Property.</TitleH3>
              Loadsmart shall retain all right, title, and interest to any and
              all Intellectual Property: (i) in the Subscription Services, (ii)
              in the Software, and (iii) developed by Loadsmart prior to
              commencement of the Professional Services, independent of the
              Professional Services, and during the Professional Services,
              provided the applicable Intellectual Property is not a
              Deliverable, and, in each case, all Intellectual Property rights
              therein or relating thereto are and shall remain the exclusive and
              sole property of Loadsmart (collectively{' '}
              <strong>“Loadsmart Intellectual Property”</strong>). Loadsmart
              grants to Customer and its Authorized Users a limited,
              non-exclusive, non-sublicensable, non-transferrable, terminable,
              revocable, license to the Loadsmart Intellectual Property to the
              extent necessary for Customer to make use of the Subscription
              Services or the applicable Deliverable for Customer’s internal
              business purposes in accordance with the applicable Order Form or
              Statement of Work.
            </Paragraph>
            <Paragraph>
              <TitleH3>6.2. Customer Intellectual Property.</TitleH3>
              Customer shall retain all right, title, and interest to any and
              all Intellectual Property: (i) in the Customer Data, (ii) if
              applicable and subject to Customer’s payment of all applicable
              Fees and expenses, in the Deliverables, and (iii) in the Customer
              Marks. By providing Customer Data during the Services, Customer
              grants to Loadsmart the right and license to use, reproduce,
              distribute, modify, and adapt the Customer Data: (x) for the
              purpose of providing the Services to Customer, including the right
              to disclose Customer Data to Loadsmart’s employees, subsidiaries,
              Affiliates, contractors, vendors, and Third Party Users to the
              extent necessary to provide the Services to Customer, and (y) for
              Loadsmart’s internal business purposes (subject to the
              confidentiality obligations outlined in Section 7.1).
            </Paragraph>
            <Paragraph>
              <TitleH3>
                6.3. Aggregate, Usage, and Third Party User Data.
              </TitleH3>
              Notwithstanding anything to the contrary in this Agreement,
              Loadsmart may use de-identified or aggregated Customer Data for
              any business purpose, including, without limitation, industry
              benchmarking, best practices guidance, recommendations, and
              similar reports which may be distributed externally to other
              customers of Loadsmart. In addition, nothing contained within this
              Agreement or the applicable Order Form or Statement of Work will
              restrict or prohibit Loadsmart’s ability to utilize Third Party
              User data or Usage Data for any lawful purpose.
            </Paragraph>
            <Paragraph>
              <TitleH3>6.4. Feedback.</TitleH3>
              Loadsmart shall be free to use, disclose, reproduce, license, or
              otherwise distribute Feedback as it sees fit, without obligation
              or restriction of any kind on account of Intellectual Property
              rights or otherwise; provided, that such Feedback shall not
              contain any specific or identifiable elements unique to Customer
              or its operations. Customer shall have no Intellectual Property
              rights in the Subscription Services as a result of Loadsmart’s
              incorporation of Feedback into the Subscription Services.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            <TitleH2>7. CONFIDENTIALITY; SECURITY; DATA PRIVACY.</TitleH2>
            <Paragraph>
              <TitleH3>7.1. Confidentiality Obligations.</TitleH3> In connection
              with this Agreement, a party may disclose (the{' '}
              <strong>“Disclosing Party”</strong>) certain Confidential
              Information to the other party (the{' '}
              <strong>“Receiving Party”</strong>). During the term of this
              Agreement and for a period of three (3) years after expiration or
              termination of this Agreement, the Receiving Party will not
              disclose the Disclosing Party’s Confidential Information for any
              purposes other than exercising its rights and performing its
              obligations under this Agreement. The Receiving Party shall not
              disclose Confidential Information to any third party except to the
              Receiving Party’s employees, contractors, vendors, and Affiliates
              that have a reasonable need to know such information in connection
              with this Agreement; provided, that any such third parties will be
              legally bound by confidentiality obligations at least as strict as
              those outlined hereunder. The Receiving Party shall ensure that
              the Disclosing Party’s Confidential Information is not disclosed
              or distributed by its employees, contractors, vendors, or
              Affiliates in violation of the terms of this Agreement. The
              Receiving Party will treat all Confidential Information with the
              same degree of care that the Receiving Party utilizes to protect
              its own Confidential Information, but in any event no less than a
              reasonable degree of care.
            </Paragraph>
            <Paragraph>
              The foregoing confidentiality restrictions shall not apply to
              information which the Receiving Party is able to demonstrate by
              documentary evidence: (i) is or becomes available in the public
              domain without breach of this Agreement by Receiving Party; (ii)
              was in the Receiving Party’s possession prior to receipt from the
              Disclosing Party; (iii) is independently developed by the
              Receiving Party without use of or reference to the Confidential
              Information; or (iv) is lawfully received by the Receiving Party
              from a third party having a right of further disclosure.
            </Paragraph>
            <Paragraph>
              Notwithstanding the foregoing, Confidential Information may be
              disclosed as required by any governmental agency or judicial
              process; provided, that before providing such Confidential
              Information the Receiving Party must give (to the extent permitted
              by applicable law) the Disclosing Party sufficient advance notice
              of the disclosure to enable the Disclosing Party to exercise any
              rights it may have to challenge or limit the disclosure of such
              Confidential Information.
            </Paragraph>
            <Paragraph>
              Upon written request by the Disclosing Party, the Receiving Party
              shall: (i) cease using the Confidential Information, (ii) return
              the Confidential Information and all copies, notes or extracts
              thereof to the Disclosing Party within seven (7) business days of
              receipt of request, and (iii) confirm in writing that the
              Receiving Party has complied with the obligations set forth in
              this paragraph. Notwithstanding the foregoing, the Receiving Party
              may retain one (1) copy of the Confidential Information to monitor
              ongoing compliance with this Agreement and a limited number of
              backup copies made in accordance with the Receiving Party’s
              standard record retention policies.
            </Paragraph>

            <Paragraph>
              <TitleH3>7.2. Security Obligations.</TitleH3>
              Each party shall (i) maintain commercially reasonable information
              security practices for its systems used to provide or access the
              Subscription Services, including security procedures and practices
              appropriate to the nature of the information input into or
              residing in the Subscription Services and designed to prevent
              unauthorized access to, or use or disclosure of, any Customer Data
              and Confidential Information or a Security Incident, and (ii)
              comply with all privacy and data security laws and regulations
              applicable to the Subscription Services. Each party shall promptly
              notify the other party of any Security Incident. Where Customer is
              the notifying party, the notice shall be directed to
              Security@Loadsmart.com with a copy to Legal@Loadsmart.com. The
              notifying party will reasonably investigate any such Security
              Incident. For Security Incidents arising out of a party’s gross
              negligence or willful misconduct, that party shall be responsible
              for (i) costs of government or regulatory fines, and (ii) if
              required by applicable law, providing notice and/or credit
              monitoring or identity protection to any data subject impacted by
              the applicable Security Incident and/or to provide notice to any
              governmental entity, costs associated with any such notices or
              identity protection (collectively, “Breach Costs”). Loadsmart
              shall have no responsibility to pay any Breach Costs related to a
              Security Incident caused by the Customer’s or its Authorized
              Users’ negligence, willful misconduct, fraud, or violation of
              applicable law.
            </Paragraph>
            <Paragraph>
              <TitleH3>7.3. Data Privacy.</TitleH3>
              Customer’s use of and access to the Services as well as
              Loadsmart’s handling of Customer Data will be subject to and
              governed by Loadsmart’s privacy policy which can be found at:{' '}
              <Link href="https://loadsmart.com/privacy-policy">
                https://loadsmart.com/privacy-policy
              </Link>
              , and is incorporated herein by reference. Where Customer desires
              Loadsmart to engage in the processing of “Personal Data” (as
              defined in the European Union General Data Protection Regulation
              (“GDPR”)) or “Personal Information” (as defined in the California
              Consumer Privacy Act (“CCPA”)) Loadsmart shall act as the
              “Controller” or “Business” of the Third-Party User Data and as
              “Processor” or “Service Provider” of the Customer Data,
              respectively. Loadsmart uses and shares Personal Data and Personal
              Information as set forth in its privacy policy. Loadsmart does not
              “Sell” Personal Information as such term is defined in the CCPA.
              In the event that Customer elects in an Order Form or Statement of
              Work to transfer Personal Data from the European Economic Area to
              Loadsmart in a third country, the parties shall execute a Data
              Processing Agreement (“DPA”) which shall be incorporated into this
              Agreement by reference. For purposes of this Agreement, the terms
              Controller, Processor, and Personal Data shall have the meanings
              set forth in the GDPR, and the terms Business, Service Provider,
              Personal Information, and Sell shall have the meanings set forth
              in the CCPA.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            <TitleH2>8. WARRANTIES AND DISCLAIMERS</TitleH2>
            <Paragraph>
              <TitleH3>
                8.1. Representations and Warranties of Loadsmart.
              </TitleH3>
              Loadsmart represents and warrants the following with respect to
              the Services:
            </Paragraph>
            <BulltetList>
              <li>
                <Paragraph>
                  <TitleH4>8.1.1.</TitleH4>
                  Loadsmart warrants that the Subscription Services will
                  substantially conform to the functionality set forth in the
                  Documentation. If the Subscription Services do not
                  substantially conform to the functionality set forth in the
                  Documentation, Loadsmart’s sole and exclusive obligation will
                  be to use commercially reasonable efforts to correct the
                  non-conformity or, in Loadsmart’s sole discretion, if such
                  remedy is impracticable Customer may terminate this Agreement
                  in accordance with Section 9.2. The warranty set forth in this
                  Section 8.1.1 will not apply: (i) if Customer makes a claim of
                  non-conformity more than thirty (30) days after the date on
                  which Customer first noticed the non-conformity, (ii) if the
                  non-conformity was caused by misuse, unauthorized
                  modifications or third-party hardware, software or services,
                  or (iii) if the Subscription Services are being offered on a
                  no-charge, trial, or evaluation basis.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <TitleH4>8.1.2.</TitleH4>
                  Loadsmart represents and warrants that the Services will be of
                  a professional quality conforming to generally accepted
                  industry standards and practices.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <TitleH4>8.1.3.</TitleH4>
                  Loadsmart shall at all times comply with all applicable laws,
                  rules and regulations, including data privacy laws, and the
                  terms of this Agreement during Loadsmart’s provision of the
                  Services.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <TitleH4>8.1.4.</TitleH4>
                  If Loadsmart uses or licenses Intellectual Property owned by
                  third parties in the performance or provision of the Services,
                  Loadsmart represents and warrants that it has obtained any
                  such licenses and authorizations necessary for its valid use
                  of such third-party Intellectual Property in connection with
                  the Services.
                </Paragraph>
              </li>
            </BulltetList>
            <Paragraph>
              <TitleH3>
                8.2. Representations and Warranties of Customer.
              </TitleH3>
              Customer represents and warrants the following with respect to its
              use of the Services and provision of the Customer Data:
            </Paragraph>
            <BulltetList>
              <li>
                <Paragraph>
                  <TitleH4>8.2.1.</TitleH4>
                  Customer has the necessary licenses, rights, consents, and
                  permissions to use the Customer Data and to allow its
                  Authorized Users and Loadsmart to use and distribute Customer
                  Data as necessary to provide and make use of the Services.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <TitleH4>8.2.2.</TitleH4>
                  Customer Data, and the use thereof as contemplated herein,
                  does not and will not infringe, violate, or misappropriate any
                  applicable laws or any third-party right, including any
                  copyright, trademark, patent, trade secret, moral right,
                  privacy right, right of publicity, contractual right, or any
                  other Intellectual Property or proprietary right.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <TitleH4>8.2.3.</TitleH4>
                  Customer shall at all times comply with all applicable laws,
                  rules and regulations, including data privacy laws, and the
                  terms of this Agreement during Customer’s use of the Services.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <TitleH4>8.2.4.</TitleH4>
                  Customer shall not engage in any conduct that may cause a
                  virus or other computer code, file, or program to interrupt,
                  destroy or limit the functionality of the Subscription
                  Services and/or any related hardware or technological
                  equipment, or otherwise permit unauthorized access to or use
                  of the Subscription Services.
                </Paragraph>
              </li>
            </BulltetList>

            <Paragraph>
              <TitleH3>8.3. Customer Use and Data.</TitleH3> Loadsmart shall
              have no liability for any claims, losses, or damage caused by
              errors or omissions in any information provided by Customer or its
              Authorized Users to Loadsmart in connection with the Services.
              Except as otherwise set forth herein, Loadsmart expressly
              disclaims any and all liability in connection with Customer Data.
              Loadsmart is under no obligation to edit or control Customer Data,
              including Customer Data that Customer inputs into the Subscription
              Services. Loadsmart may, at any time without prior notice, remove
              any Customer Data that violates this Agreement or applicable law,
              or which violates the rights of a third party or Loadsmart.
            </Paragraph>
            <Paragraph>
              <TitleH3>8.4. General Disclaimer.</TitleH3> OTHER THAN THE
              WARRANTIES IN THIS AGREEMENT THE SERVICES ARE PROVIDED “AS IS” AND
              LOADSMART EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND RELATING
              TO THE SERVICES PROVIDED HEREUNDER, WHETHER EXPRESS OR IMPLIED,
              INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND
              NON-INFRINGEMENT.
            </Paragraph>
            <Paragraph>
              <TitleH3>8.5. AI Disclaimer.</TitleH3> CERTAIN FUNCTIONALITIES AND
              FEATURES IN THE SUBSCRIPTION SERVICES ARE POWERED BY THIRD PARTY
              ARTIFICIAL INTELLIGENCE AND LOADSMART DOES NOT MAKE, AND HEREBY
              DISCLAIMS, ANY AND ALL REPRESENTATIONS OR WARRANTIES, WHETHER
              EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF TITLE,
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY,
              COMPLETENESS, AND NONINFRINGEMENT OF THIRD PARTIES’ RIGHTS WITH
              RESPECT TO ANY INFORMATION, RECOMMENDATIONS, ANSWERS, ANALYSIS OR
              OTHER DATA PROVIDED BY SUCH FUNCTIONALITIES OR FEATURES TO EACH
              AUTHORIZED USER.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            <TitleH2>9. TERM AND TERMINATION</TitleH2>
            <Paragraph>
              <TitleH3>9.1. Term.</TitleH3>
              This Agreement is effective as of the Effective Date and shall
              remain in full force and effect: (a) for each Order Form, until
              the end of the Subscription Term; and (b) for each Statement of
              Work, until the end of the applicable term set forth therein. For
              Subscription Services, the Subscription Term shall automatically
              renew for subsequent twelve (12) month periods following
              completion of the initial Subscription Term, unless either party
              provides the other party written notice of termination at least
              thirty (30) days prior to the expiration of the then-current
              Subscription Term.
            </Paragraph>
            <Paragraph>
              <TitleH3>9.2. Termination for Breach.</TitleH3>. If either party
              commits a material breach of its obligations in this Agreement or
              any Order Form or Statement of Work (including payment
              obligations), the non-defaulting party may give written notice to
              the defaulting party specifying the nature of the default, and if
              such default is not remedied within thirty (30) days from the
              receipt of such notice, then the non-defaulting party shall have
              the right to immediately terminate this Agreement or the Order
              Form or Statement of Work by written notice. If the breach relates
              solely to one Order Form or Statement of Work, then only such
              Order Form or Statement of Work may be terminated in accordance
              with the terms herein and all other Order Forms and Statements of
              Work and this Agreement shall continue in full force and effect.
              In the event that Customer terminates an Order Form in accordance
              with this Section 9.2, Customer shall be entitled to a pro-rated
              refund of any prepaid Fees for the remainder of the Subscription
              Term as of the effective date of termination.
            </Paragraph>
            <Paragraph>
              <TitleH3>9.3. Termination for Insolvency.</TitleH3>
              Notwithstanding anything to the contrary herein, either party may
              terminate this Agreement immediately by providing written notice
              to the other party in the event the other party becomes insolvent,
              makes an assignment for the benefit of creditors, ceases to do
              business, or if any bankruptcy, reorganization, arrangement,
              insolvency, liquidation proceeding, or other proceeding under any
              bankruptcy or other law for the relief of debtors is instituted by
              or against such party and is not dismissed within sixty (60) days
              thereafter.
            </Paragraph>
            <Paragraph>
              <TitleH3>
                9.4. Rights and Obligations Upon Expiration or Termination.
              </TitleH3>
              Upon expiration or termination of this Agreement, (i) Customer’s
              and its Authorized Users’ right to access and use the Subscription
              Services shall immediately terminate, (ii) Customer and its
              Authorized Users shall immediately cease all use of the
              Subscription Services, (iii) Customer shall delete or return, and
              make no further use of any materials, Loadsmart Confidential
              Information, Documentation, Loadsmart Intellectual Property,
              usernames, passwords, or other items (and all copies thereof)
              obtained in connection with the Services; and (iv) Customer will
              have no further access to any Customer Data input into the
              Subscription Services.
            </Paragraph>
            <Paragraph>
              <TitleH3>9.5. Survival.</TitleH3>
              Upon termination, any provision which, by its nature or express
              terms should survive, will survive, including, but not limited to,
              Sections 5, 6, 7, 10, 11, 12.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            <TitleH2>10. INDEMNIFICATION</TitleH2>
            <Paragraph>
              <TitleH3>10.1. Indemnification by Customer.</TitleH3>
              Customer shall indemnify, defend, and hold harmless Loadsmart, its
              Affiliates, and subsidiaries, and its and their officers,
              directors and employees, from and against any liabilities, losses,
              damages and expenses, including court costs and reasonable
              attorneys’ fees, associated with any claim by a third party to the
              extent and proportion arising from: (i) Customer’s or its
              Authorized User’s use of, or any person granted access by a
              Customer to use, the Services in a manner that violates applicable
              laws, the terms of this Agreement, or the terms of the DPA (if
              applicable), (ii) alleging that any Customer Data, violates a
              third party’s privacy rights, Intellectual Property rights, or
              other rights, or (iii) Customer’s material breach of this
              Agreement. Customer’s obligations under this Section 10.1 are
              contingent upon Loadsmart providing Customer with prompt written
              notice of such claim.
            </Paragraph>
            <Paragraph>
              <TitleH3>10.2. Indemnification by Loadsmart.</TitleH3>. Loadsmart
              shall indemnify, defend, and hold harmless Customer, its officers,
              directors, and employees, from and against any liabilities,
              losses, damages and expenses, including court costs and reasonable
              attorneys’ fees, associated with any third party’s claim to the
              extent and proportion arising from: (i) the infringement or
              misappropriation of any Intellectual Property rights of a third
              party by Customer’s authorized use of a Deliverable or the
              Subscription Services, (ii) Loadsmart’s violation of applicable
              law, or (iii) Loadsmart’s material breach of this Agreement.
              Loadsmart’s obligations under this Section 10.2 are contingent
              upon: (a) Customer providing Loadsmart with prompt written notice
              of such claim; (b) Customer providing reasonable cooperation to
              Loadsmart, at Loadsmart’s expense, in the defense and settlement
              of such claim, (c) Loadsmart having sole authority to defend or
              settle such claim. With respect to claims described in subsection
              (i) herein, in the event that Loadsmart’s ability to provide the
              Subscription Services or Customer’s use of a Deliverable is
              enjoined or in Loadsmart’s reasonable opinion is likely to be
              enjoined, Loadsmart may obtain the right to continue providing the
              Subscription Services or to allow Customer’s use of the applicable
              Deliverable, replace or modify the Subscription Services or the
              applicable Deliverable so that they become non-infringing, or, if
              such remedies are not commercially practicable, terminate this
              Agreement without liability to Customer and provide a pro-rated
              refund of any Fees prepaid and unused upon such termination. The
              foregoing states the entire obligation of Loadsmart with respect
              to any alleged or actual infringement or misappropriation of
              Intellectual Property rights by the Subscription Services or a
              Deliverable. Loadsmart shall have no liability or obligation under
              this Section 10.2 for any infringement claims caused in whole or
              in part by: (x) any instruction, information, design, or other
              materials furnished by Customer to Loadsmart as part of the
              Services, (y) modification of the Subscription Services or a
              Deliverable by any party other than Loadsmart without Loadsmart’s
              express written consent, or (z) the combination, operation, or use
              of the Subscription Services or a Deliverable with other
              product(s), data or services where the Subscription Services or
              the applicable Deliverable would not by itself be infringing.
              Notwithstanding anything to the contrary hereunder, Loadsmart
              shall have no obligation or liability under this Section 10.2 for
              any claims arising from the negligence, willful misconduct, or
              fraud of Customer.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            <TitleH2>11. LIMITATION OF LIABILITY</TitleH2>
            <Paragraph>
              <TitleH3>11.1. Damages Waiver.</TitleH3>
              EXCEPT IN THE EVENT OF AN EXCLUDED CLAIM, IN NO EVENT SHALL EITHER
              PARTY BE LIABLE TO THE OTHER PARTY FOR ANY INDIRECT, INCIDENTAL,
              SPECIAL, EXEMPLARY, RELIANCE, PUNITIVE OR CONSEQUENTIAL DAMAGES,
              INCLUDING LOSS OF INCOME, DATA, PROFITS, REVENUE OR BUSINESS
              INTERRUPTION, OR COST OF SUBSTITUTE SERVICES, OR OTHER ECONOMIC
              LOSS, WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES, AND WHETHER ANY CLAIM FOR RECOVERY IS
              BASED ON THEORIES OF CONTRACT, WARRANTY, TORT (INCLUDING
              NEGLIGENCE AND STRICT LIABILITY) OR OTHERWISE AND EACH PARTY
              HEREBY RELEASES AND WAIVES ANY CLAIMS AGAINST THE OTHER PARTY
              REGARDING SUCH LIABILITY AND DAMAGES.
            </Paragraph>
            <Paragraph>
              <TitleH3>11.2. Liability Cap.</TitleH3>
              To the fullest extent permitted by law, in no event shall
              Loadsmart’s aggregate liability to Customer in connection with
              this Agreement for any and all claims, losses, costs, or damages
              of any nature whatsoever, including reasonable attorney’s fees,
              exceed the total Fees paid by Customer under the applicable Order
              Form or Statement of Work in the twelve (12) month period
              preceding the incident giving rise to the applicable claim or
              action, regardless of the form or theory of the claim or action.
            </Paragraph>
          </Paragraph>
          <Paragraph>
            <TitleH2>12. GENERAL</TitleH2>
            <Paragraph>
              <TitleH3>12.1. Governing Law; Venue.</TitleH3>
              This Agreement and all matters arising out of or relating to this
              Agreement shall be governed by the laws of the state of Illinois,
              without regard to its conflict of laws provisions. The exclusive
              jurisdiction for any claim or controversy arising out of or
              relating to this Agreement shall be in the state and federal
              courts located in Cook County, Illinois, and each party hereto
              irrevocably and unconditionally waives the defense of an
              inconvenient forum.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.2. Amendment; Waiver.</TitleH3>
              Except as otherwise provided herein, this Agreement may only be
              amended by a written instrument executed by a duly authorized
              representative of each party. No waiver of any provision hereof
              shall be effective unless made in writing and signed by the
              waiving party. The failure of any party to require the performance
              of any term or obligation of this Agreement, or the waiver by any
              party of any breach of this Agreement, shall not prevent any
              subsequent enforcement of such term or obligation or be deemed a
              waiver of any subsequent breach.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.3. Modifications.</TitleH3>
              Notwithstanding anything to the contrary in Section 12.2, from
              time to time, Loadsmart may modify this Agreement with respect to
              the Subscription Services and, unless otherwise specified by
              Loadsmart, the modifications will become effective upon the
              earlier of: (i) Customer’s access to or use of the Subscription
              Services subsequent to any modifications; (ii) Customer’s
              acceptance of the modified terms via click-through, or (iii) via
              email to the address designated in the applicable Order Form. For
              Order Forms effective prior to any modifications, Customer may
              terminate the applicable Order Form within thirty (30) days from
              the modifications becoming effective and receive, as its sole
              remedy, a refund of any prepaid Fees for the remainder
              Subscription Term.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.4. Subcontracting.</TitleH3>
              Nothing in this Agreement shall prevent Loadsmart from utilizing
              the services of any subcontractor as it deems appropriate to
              perform its obligations under this Agreement; provided, however,
              that Loadsmart shall require its subcontractors to comply with all
              applicable terms and conditions of this Agreement in providing
              such services and Loadsmart shall remain primarily liable to
              Customer for the performance of such subcontractor.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.5. Notices.</TitleH3>
              Any notices provided by Loadsmart under this Agreement will be
              given to the address set forth in the applicable Order Form or
              Statement of Work. Notices and all other communications provided
              for herein shall be deemed to have been received: (i) when
              actually received, if delivered personally or by courier, or (ii)
              five (5) days following mailing if sent via certified mail,
              postage prepaid, return receipt requested. Notices provided to
              Loadsmart shall include a copy via email to{' '}
              <Link href="mailto:legal@loadsmart.com">Legal@Loadsmart.com</Link>
              .
            </Paragraph>
            <Paragraph>
              <TitleH3>12.6. Severability.</TitleH3>
              If any provision of this Agreement is held to be invalid or
              unenforceable, the remaining provisions shall remain in full force
              and effect.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.7. Force Majeure.</TitleH3>
              Neither party shall be liable hereunder by reason of any failure
              or delay in the performance of its obligations hereunder (except
              for the payment of Fees) on account of events beyond the
              reasonable control of such party, which may include without
              limitation denial-of-service attacks, cyberattacks, strikes
              (except by its own employees), shortages, riots, insurrection,
              fires, flood, pandemic, epidemics, storm, explosions, acts of God,
              war, terrorism, governmental action, labor conditions,
              earthquakes, failure or diminishment of power, telecommunications
              or data networks, and materials shortages (each a{' '}
              <strong>“Force Majeure Event”</strong>). Upon the occurrence of a
              Force Majeure Event, the non-performing party will be excused from
              any further performance of its obligations affected by the Force
              Majeure Event for so long as the Force Majeure Event continues and
              provided such party uses commercially reasonable efforts to resume
              performance.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.8. Compliance with Laws.</TitleH3>
              Each party agrees to comply with all applicable laws, including
              U.S. export laws, and regulations with respect to its activities
              hereunder.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.9. Relationship Between the Parties.</TitleH3>
              Nothing in this Agreement shall be construed to create a
              partnership, joint venture or agency relationship between the
              parties. Neither party will have the power to bind the other or to
              incur obligations on the other’s behalf without such other party’s
              prior written consent.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.10. Assignment/Successors.</TitleH3>
              Neither party may assign or transfer an Order Form, a Statement of
              Work, or this Agreement, in whole or in part, without the other
              party’s prior written consent. Notwithstanding the foregoing,
              Loadsmart may freely assign or transfer this Agreement to any of
              its Affiliates or to any purchaser of all or substantially all of
              its assets or in connection with any merger or consolidation. Any
              attempted assignment or transfer in violation of this Section will
              be null and void.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.11. Entire Agreement.</TitleH3>
              This Agreement, together with the applicable Order Form or
              Statement of Work, constitutes the complete and exclusive
              agreement between the parties concerning its subject matter and
              supersedes all prior or contemporaneous agreements or
              understandings, written or oral, concerning the subject matter of
              this Agreement. This Agreement may be executed in counterparts,
              each of which will be deemed an original and all of which together
              will be considered one and the same agreement. No provision of any
              purchase order or other similar business form employed by Customer
              will supersede the terms and conditions of this Agreement, and any
              such document relating to this Agreement will be for
              administrative purposes only and will have no legal effect with
              respect to the Services.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.12. Equitable Relief.</TitleH3>
              Each party acknowledges that a breach by the other party of any of
              the confidentiality rights set forth in Section 7.1 or
              Intellectual Property rights set forth in Section 6 of this
              Agreement may cause the non-breaching party irreparable damage,
              for which the award of damages would not be adequate compensation.
              Consequently, the non-breaching party may seek the entry of an
              injunction enjoining any breach or threatened breach of those
              provisions, in addition to any other relief to which the
              non-breaching party may be entitled at law or in equity.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.13. No Third-Party Beneficiaries.</TitleH3>
              This Agreement is for the sole benefit of the signatories and is
              not intended to benefit any third party. Only the parties hereto
              may enforce this Agreement.
            </Paragraph>
            <Paragraph>
              <TitleH3>12.14. Order of Precedence.</TitleH3>
              In the event of a conflict between the terms of an Order Form or
              Statement of Work and this Agreement, the Order Form or Statement
              of Work, as applicable, shall take precedence.
            </Paragraph>
          </Paragraph>
        </Layout>
      </>
      <Footer />
    </>
  );
};

export default SubscriptionAndProfessionalServicesAgreement;
export { SubscriptionAndProfessionalServicesAgreement };
