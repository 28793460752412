//components
import {
  MirandaBrandButton,
  MirandaHollowSecondaryButton,
} from '@/components/Button';

//containers
import { Title, Description } from '@/containers/Hero/Hero.styles';

// context containers
import { ctaStyle, FlexRowWrap, HeroBackground } from './HeroSection.styles';

const responsiveBackground = {
  sm: `url(/images/port-drayage/small_hero.png)`,
  md: `url(/images/port-drayage/medium_hero.png)`,
  lg: `url(/images/port-drayage/large_hero.png)`,
  xxlg: `url(/images/port-drayage/xxlarge_hero.png)`,
};

export const HeroSection = () => {
  return (
    <HeroBackground image={responsiveBackground}>
      <Title>
        Port Drayage Freight Services <br />
        <strong>Instantly Quote & Book</strong>
      </Title>
      <Description>
        Price and tender port drayage shipments in seconds at no cost on our
        free, online platform. Improve visibility with real time tracking and
        full integration with port operators, truckers, and warehouses.
      </Description>
      <FlexRowWrap>
        <MirandaBrandButton
          as="a"
          href={`${process.env.SHIPPERGUIDE_URL}/signup?utm_source=loadsmart&utm_medium=port-drayage-page&utm_content=hero-banner&utm_campaign=`}
          target="_blank"
          rel="noopener noreferrer"
          css={[ctaStyle]}
        >
          Start Quoting in Minutes
        </MirandaBrandButton>

        <MirandaHollowSecondaryButton
          as="a"
          href="#hubspot-form"
          css={[ctaStyle]}
        >
          Request a demo
        </MirandaHollowSecondaryButton>
      </FlexRowWrap>
    </HeroBackground>
  );
};
