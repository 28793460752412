import {
  IconInstantPricing,
  IconTenderAcceptance,
  IconService,
  IconSmartTracking,
  IconSmartScheduling,
  IconTMSReady,
} from '@loadsmart/icons';
import { Container } from '@/components/Grid/Container';
import {
  Feature,
  FeatureSection as Section,
  FeatureGroup,
  FeatureIcon,
  FeatureTitle,
  FeatureDescription,
  responsiveIconStyle,
} from '@/components/Feature';

export const FeatureSection = () => (
  <Section>
    <Container>
      <FeatureGroup>
        <Feature>
          <FeatureIcon>
            <IconInstantPricing role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>INSTANT PRICING {'&'} BOOKING</FeatureTitle>
          <FeatureDescription>
            Get one invoice for your entire move, minimizing unnecessary
            accessorial costs.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconTenderAcceptance role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>100% TENDER ACCEPTANCE</FeatureTitle>
          <FeatureDescription>
            We stand behind our instant rates with guaranteed 100% tender
            acceptance.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconService role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>EXCEPTIONAL SERVICE</FeatureTitle>
          <FeatureDescription>
            Our operations team backs up your every move, keeping you in the
            loop every step of the way.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconSmartTracking role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>SMART TRACKING</FeatureTitle>
          <FeatureDescription>
            Track the real-time location of your shipments from pickup through
            delivery.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconSmartScheduling role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>SMART SCHEDULING</FeatureTitle>
          <FeatureDescription>
            Improve on-time service with technology that automatically selects
            the best appointment time.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconTMSReady role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>TMS READY</FeatureTitle>
          <FeatureDescription>
            Price, book, and track shipments from wherever you manage freight.
          </FeatureDescription>
        </Feature>
      </FeatureGroup>
    </Container>
  </Section>
);
