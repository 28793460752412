import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

import { Feature, FeatureGroup } from '@/components/Feature/Layout';

export const SubTitle = styled.h2`
  display: block;
  margin: 0;
  padding-top: 5px;

  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  ${screen.md} {
    display: inline-block;
    padding-top: 5px;

    font-size: 15px;
    line-height: 18px;
    text-align: left;
  }

  ${screen.lg} {
    margin: 10px 0 15px;

    font-size: 20px;
    line-height: 23px;
  }

  ${screen.xxlg} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const SubSubTitle = styled(SubTitle)`
  display: block;
  width: 100%;
  margin: auto auto 20px;

  font-size: 16px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    margin: auto auto 30px;

    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 42px;
  }
`;

export const StyledFeatureGroup = styled(FeatureGroup)`
  padding: 5px 0 0;

  ${screen.md} {
    margin: 0 -22px;
  }

  ${screen.lg} {
    margin: 0 -35px;
  }

  ${screen.xxlg} {
    margin: 0 -39px;
  }
`;

export const StyledFeature = styled(Feature)`
  width: 100%;
  margin: 10px auto 10px;

  ${screen.md} {
    width: 30%;
    margin: 10px auto 20px;
  }

  ${screen.lg} {
    margin: 10px auto 25px;
  }

  ${screen.xxlg} {
    margin: 10px auto 35px;
  }
`;

export const ResponsiveImage = styled.picture`
  display: inline-flex;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 5px 0;

  text-align: center;

  img,
  source {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }
`;
