import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container, Section } from '@/components/Grid';
import { Title } from '@/components/Grid/Content/';
import { Text } from '@/components/Grid/Content/';
import { SectionPanel } from '@/components/Grid/Panel';
import { Button } from '@/components/Button';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

const SectionStyled = styled(Section)`
  padding: 0 0 20px;
  margin: 0 auto;

  ${screen.md} {
    padding: 0 0 40px;
  }

  ${screen.lg} {
    padding: 0 0 60px;
  }

  ${screen.xxlg} {
    padding: 0 0 80px;
  }
`;

const SectionPanelStyled = styled.div`
  margin: 20px auto;
  display: flex;
  flex-direction: column-reverse;

  ${screen.md} {
    display: grid;
    grid-template-columns: 50% auto;

    margin: 40px auto;
  }

  ${screen.lg} {
    margin: 60px auto;
  }

  ${screen.xxlg} {
    margin: 80px auto;
  }
`;

const TextStyled = styled(Text)`
  font-weight: 400;

  a {
    color: rgb(${getToken('color-success-60')});
    text-decoration: underline;
    font-weight: bold;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  ${screen.md} {
    a {
      min-width: 80%;
    }
  }

  ${screen.lg} {
    a {
      min-width: unset;
    }
  }
`;

const ImageWrapper = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  align-self: center;

  img {
    margin: auto;
    max-width: 100%;
    height: auto;
  }
`;

const BenefitList = styled.ul`
  list-style: none;
  padding: 0 0;
  margin: 0 40px 20px auto;
`;

const BenefitItemText = styled.li`
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  margin: 8px 0;
  font-weight: 400;

  a {
    font-weight: normal;
    font-size: inherit;
  }

  ${screen.md} {
    text-align: left;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
    text-align: left;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
  }
`;

export const ExternalVideoSection = ({ content }) => (
  <>
    <SectionStyled>
      <Container>{content.title && <Title>{content.title}</Title>}</Container>
      <Container>
        {content.introduction && (
          <TextStyled as="div">{content.introduction}</TextStyled>
        )}
        <SectionPanelStyled>
          <ContentWrapper>
            {content.benefits && (
              <BenefitList>
                {content.benefits.map((item, key) => {
                  return (
                    <BenefitItemText key={`content-benefitsvid-${key}`}>
                      &bull; {item.text}
                    </BenefitItemText>
                  );
                })}
              </BenefitList>
            )}
            <Button
              as="a"
              href={content.cta.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {content.cta.title}
            </Button>
          </ContentWrapper>
          <ImageWrapper>{content.media}</ImageWrapper>
        </SectionPanelStyled>
      </Container>
    </SectionStyled>
  </>
);
