import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SegmentNavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background-color: rgba(${getToken('color-background-tertiary')});

  margin-top: -410px;
  padding: 20px 0 60px;

  ${screen.md} {
    margin-top: -160px;
    padding: 50px 0;
  }
  ${screen.lg} {
    margin-top: -230px;
    padding: 70px 0;
  }
  ${screen.xxlg} {
    margin-top: -292px;
    padding: 70px 0;
  }
`;

export const NavList = styled.nav`
  display: grid;
  gap: 16px;
  grid-template-areas:
    'b'
    'a'
    'c';

  ${screen.md} {
    grid-template-areas: 'a b c';
    grid-template-columns: repeat(3, 1fr);
    gap: 11px;
  }

  ${screen.lg} {
    gap: 16px;
  }

  ${screen.xxlg} {
    gap: 20px;
  }
`;

export const Label = styled.div`
  padding: 6px 10px 9px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  text-align: center;

  border-radius: 0 0 10px 10px;

  ${screen.md} {
    padding: 13px 10px;

    line-height: 26px;
  }

  ${screen.lg} {
    padding: 20px 10px;

    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    padding: 25px 10px;

    font-size: 30px;
    line-height: 42px;
  }
`;

export const ItemMenu = styled.a`
  display: flex;
  flex-direction: column;
  background: #283241;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

  color: rgb(${getToken('color-text-inverted')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};

  transition: all 0.15s ease-in-out;

  &:hover,
  &:focus {
    background: rgb(${getToken('color-background-primary')});
    color: rgb(${getToken('color-text-primary')});
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    transform: translateY(-6px);

    transition: all 0.25s ease-in-out;
  }

  &:focus {
    outline: none;
  }

  &:nth-child(1) {
    grid-area: a;
  }

  &:nth-child(2) {
    grid-area: b;
  }

  &:nth-child(3) {
    grid-area: c;
  }
`;

export const Image = styled.picture`
  img,
  source {
    max-width: 100%;
    height: auto;

    border-radius: 5px 5px 0 0;
  }

  ${screen.md} {
    img,
    source {
      border-radius: 10px 10px 0 0;
    }
  }
`;
