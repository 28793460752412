import styled from 'styled-components';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
//helpers
import { screen } from '@/helpers/screen';
import { CardLayout } from './Layout';

import ManhattanLogo from '@/public/images/logos/logo-manhattan.svg';

const ManhattanWrapper = styled(FlexRowFullCentered)`
  width: 122px;
  height: 24px;

  ${screen.md} {
    width: 132px;
    height: 26px;
  }

  ${screen.lg} {
    width: 170px;
    height: 34px;
  }

  ${screen.xxlg} {
    width: 225px;
    height: 45px;
  }
`;

export const ManhattanCard = (props) => (
  <CardLayout {...props}>
    <ManhattanWrapper>
      <ManhattanLogo />
    </ManhattanWrapper>
  </CardLayout>
);
