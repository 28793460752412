/* eslint-disable @next/next/no-img-element */
//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

// context containers
import {
  Hero,
  Form,
  FeaturedItems,
  SideFeatures,
  ImageSection,
  ModalBoxes,
} from './containers/';

// containers
import { CarouselSlider } from '@/containers-m/CarouselSlider';

//styles
import { PageWrapper } from './ExpeditedShipping.styles';

//assets
import Acceleration from '@/public/images/icons/loadsmart-collection-2/icon-Acceleration.svg';
import DeliveryVan from '@/public/images/icons/loadsmart-collection-2/icon-DeliveryVan.svg';
import Scale from '@/public/images/icons/loadsmart-collection-2/icon-Scale.svg';
import Project from '@/public/images/icons/loadsmart-collection-2/icon-Project.svg';
import Technology from '@/public/images/icons/loadsmart-collection-2/icon-Technology.svg';
import Help from '@/public/images/icons/loadsmart-collection-2/icon-Help.svg';
//
import Airplane from '@/public/images/icons/loadsmart-collection-2/icon-Airplane.svg';
import Tech from '@/public/images/icons/loadsmart-collection-2/icon-Tech.svg';
import Sale from '@/public/images/icons/loadsmart-collection-2/icon-Sale.svg';
import Government from '@/public/images/icons/loadsmart-collection-2/icon-Government.svg';
import Building from '@/public/images/icons/loadsmart-collection-2/icon-Building.svg';
import Gold from '@/public/images/icons/loadsmart-collection-2/icon-Gold.svg';

const ExpeditedShipping = () => {
  const fatured_items = {
    title: 'Core Offerings',
    description:
      'Experience unparalleled expedited shipping with our tailored solutions for urgent logistics needs. From specialized move types and diverse equipment options to strategic capacity strength and advanced technology, we ensure swift and secure delivery. Backed by a dedicated 24/7 domestic operations team, trust us to deliver excellence every time.',
    items: [
      {
        icon: <Acceleration width="64" height="64" />,
        title: 'Move Types',
        description:
          'Healthcare, Automotive,  Government, Trade Shows, Concerts, Retail Rollouts, Seasonal Industries, and Residential Urban/Rural',
      },
      {
        icon: <DeliveryVan width="64" height="64" />,
        title: 'Equipment Options',
        description:
          'Sprinter Van, Straight Truck, Team Driver Service on all FTL, and Premium Cargo Coverage',
      },
      {
        icon: <Scale width="64" height="64" />,
        title: 'Capacity Strength',
        description:
          'Event Drivers in all Major US Metro’s, Drivers at Air Gateways (ORD, DFW, JFK, ATL, LAX, MIA, CMH), and JIT Drivers in MI, IL, TX, IN, TN, AL, SC, & Southern California',
      },
      {
        icon: <Project width="64" height="64" />,
        title: 'Tailored Solutions',
        description:
          'Customized solutions designed specifically for each shipment, ensuring maximum efficiency and accuracy',
      },
      {
        icon: <Technology width="64" height="64" />,
        title: 'Advanced Tech',
        description: 'State-of-the art system integration and GPS tracking',
      },
      {
        icon: <Help width="64" height="64" />,
        title: 'Domestic Ops Team',
        description: 'Dedicated support teams available 24/7/365',
      },
    ],
  };

  const fatured_side = {
    title: 'Let us handle your niche expediting needs!',
    description:
      'Loadsmart’s team has experience handling expedited shipments in a wide range of industries and situations. ',
    cta: {
      url: '/',
      label: 'Scale Your Fleet Now',
      external: false,
    },
    items: [
      {
        icon: <Airplane width="32" height="32" />,
        title: 'Freight Forwarders / Airlines',
        description:
          'Manages the transportation of goods globally – often handling international logistics, customs clearance, and coordinating various carriers. Specialize in moving goods between countries and work with multiple carriers and transportation modes.',
      },
      {
        icon: <Tech width="32" height="32" />,
        title: 'Just-In-Time Industrial',
        description:
          'Servicing industrial production where goods are manufactured or delivered precisely when they’re needed, minimizing excess inventory. It aims to reduce waste and costs by only producing what’s immediately necessary.',
      },
      {
        icon: <Sale width="32" height="32" />,
        title: 'Consumer Packaged Goods (CPG)',
        description:
          'These are everyday items ready for sale to consumers. It includes products like food, beverages, toiletries, and other goods usually found on store shelves, packaged and labeled for individual purchase.',
      },
      {
        icon: <Government width="32" height="32" />,
        title: 'Government and Healthcare',
        description:
          'This encompasses public services provided by governments and healthcare institutions. It involves various activities aimed at governing a region or country, as well as services related to medical treatment, prevention, and overall well-being of individuals.',
      },
      {
        icon: <Building width="32" height="32" />,
        title: 'Jobsite & Event Logistics',
        description:
          'Refers to the transportation services that involve picking up and delivering goods, equipment, or materials to and from specific job sites or events. This could include transporting items and freight for multiple industries/verticals.',
      },
      {
        icon: <Gold width="32" height="32" />,
        title: 'High-Value Offering for Additional Cargo',
        description:
          'Enhanced insurance offering provided for cargo or goods being transported that are of significant value. This coverage extends beyond standard insurance policies and can be needed by multiple industries/verticals.',
      },
    ],
  };

  const benefits_slides = [
    {
      title: 'KPI Driven',
      description: '98.7% on-time delivery performance + .02% chance of claim',
    },
    {
      title: 'Delivery & Visibility Assurance',
      description:
        'GPS tracking throughout transit with proof of delivery paperwork provided upon delivery',
    },
    {
      title: 'Experienced Drivers',
      description:
        'Ability to navigate shared airport and freight facilities and no-dock deliveries with paramount attention to detail',
    },
    {
      title: 'Diverse Experience',
      description:
        'From SMB to Enterprise shippers with ad-hoc requirements, niche logistics companies and high-visibility requirements',
    },
    {
      title: 'Driver Professionalism',
      description:
        'Experienced, punctual drivers who proactively communicate directly with Jobsite/Event Teams',
    },
    {
      title: 'Large Reach, Local Expertise',
      description:
        'Wide range of capabilities with a local touch, ensuring expedited solutions anywhere, anytime',
    },
    {
      title: 'Unparalleled Speed for Options',
      description:
        'An exclusive range of expedited pricing for immediate, tailored solutions',
    },
    {
      title: 'Proactive Problem Solving',
      description:
        'Swiftly rectify mishaps like missed pickups, ensuring seamless operations even in emergencies',
    },
    {
      title: 'Compliance & Industry Mastery',
      description:
        'Deep expertise navigating industry complexities, ensuring seamless compliance with regulations',
    },
  ];

  const image_section = {
    title: 'Is Expedited Shipping Worth It?',
    description:
      'Expedited shipping offers more than just speed; it ensures guaranteed transit times, great visibility, and helps to protect your relationships with customers. It’s an ideal solution when you have a time-sensitive shipment, need to ship something valuable, or when you can’t be held up due to customs delays. No matter whether you have a strict deadline to hit SLAs, need to recover left-behind freight in a hurry, or want to minimize the potential risk of damages by removing touches from the supply chain, expedited shipping can help.',
    image: {
      path: '/images/expedited-shipping/expedited-shipping-chart.svg',
      alt: 'Shipment Modals',
    },
    footer: (
      <>
        *EUV LTL = Exclusive Use Vehicle Less than Truckload (ex. Sprinter Van,
        Straight Truck) <br /> *Green Filled Boxes = Where most HV moves are
        serviced
      </>
    ),
  };

  return (
    <PageWrapper>
      <Navigation />
      <>
        <Hero />
        <FeaturedItems content={fatured_items} />
        <ModalBoxes />
        <SideFeatures content={fatured_side} />
        <CarouselSlider
          content={benefits_slides}
          title={'Benefits that help you move more'}
          arrows={false}
        />
        <ImageSection content={image_section} />
        <Form />
      </>
      <Footer />
    </PageWrapper>
  );
};

export default ExpeditedShipping;
export { ExpeditedShipping };
