import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SignupWrapper = styled.section`
  background: rgb(${getToken('color-background-primary')});
  display: flex;
  width: 100%;
  color: rgb(${getToken('color-primary-100')});
  padding: 60px 0;

  & > div {
    display: grid;
    justify-content: space-between;
  }

  ${screen.lg} {
    padding: 80px 0;
    min-height: 568px;

    & > div {
      grid-template-columns: 55% auto;
    }
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const TitleWrapper = styled.div`
  margin: auto 0 70px;
  text-align: center !important;

  h2 {
    text-align: center !important;

    br {
      display: none;
    }
  }

  ${screen.lg} {
    margin: auto 0;

    h2 {
      text-align: left !important;

      br {
        display: block;
      }
    }
  }
`;

export const CTAMobile = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 50px;

  ${screen.lg} {
    display: none;
  }
`;

export const CTAs = styled.div`
  display: none;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 50px;

  ${screen.lg} {
    display: flex;
    margin-top: 0px;
    gap: 24px;
    flex-direction: row;
  }
`;

export const IconFill = styled.div`
  svg,
  path {
    fill: rgb(${getToken('color-brand')});
  }
`;

export const Columns = styled.div`
  display: grid;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;

  article * {
    text-align: center;
    justify-content: center;
  }

  ${screen.md} {
    grid-template-columns: 1fr 1fr;

    article * {
      text-align: left;
      justify-content: start;
    }
  }
`;
