import styled from 'styled-components';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
//helpers
import { screen } from '@/helpers/screen';
import { CardLayout } from './Layout';

import OracleLogo from '@/public/images/logos/logo-oracle.svg';

const OracleWrapper = styled(FlexRowFullCentered)`
  width: 73px;
  height: 9px;

  ${screen.md} {
    width: 84px;
    height: 12px;
  }

  ${screen.lg} {
    width: 133px;
    height: 17px;
  }

  ${screen.xxlg} {
    width: 150px;
    height: 21px;
  }
`;

export const OracleCard = (props) => (
  <CardLayout {...props}>
    <OracleWrapper>
      <OracleLogo />
    </OracleWrapper>
  </CardLayout>
);
