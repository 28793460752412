import styled from 'styled-components';

//components
import { Container } from 'components/Grid';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-color: rgb(${getToken('color-background-tertiary')});

  padding: 20px 0;
`;

export const SessionTitle = styled.h1`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 38px;
  line-height: 47.5px;
  margin: 24px auto;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction column;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin: 0 0 32px;
  position: relative;

  ${screen.md} {
    margin: 50px 0 ;
    min-height: 200px;
  }
`;
export const ContentItem = styled.article`
  display: flex;
  marging: 0 auto;
  flex-direction: column;

  ${screen.md} {
    flex-direction: row;
  }

  &.collapsed {
    header {
      background: rgb(${getToken('color-background-highlight')});
      color: rgb(${getToken('color-text-primary')});

      &::after {
        content: '-';
        top: 8px;
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }

      ${screen.md} {
        transition: all 0.2s ease-in-out;

        &::after {
          content: '\\203A';
          top: -9px;
        }
      }
    }

    p {
      display: block;
      background: rgb(${getToken('color-background-highlight')});
      transition: all 0.2s ease-in-out;

      ${screen.md} {
        right: 0;
        opacity: 1;
        pointer-events: default;
      }
    }
  }
`;

export const CollapseTrigger = styled.header`
  width: 100%;
  margin: ${getToken('spacing-1')} auto;
  padding: ${getToken('spacing-2')};
  padding-right: 24px;
  border-radius: ${getToken('border-medium')};
  position: relative;
  color: rgb(${getToken('color-text-secondary')});
  cursor: pointer;

  &::after {
    display: inline-block;
    color: inherit;
    content: '+';
    position: absolute;
    top: 11px;
    right: 6px;
    font-size: 24px;
  }

  ${screen.md} {
    margin: ${getToken('spacing-1')} 0;
    max-width: 40%;

    &::after {
      display: inline-block;
      color: #fcfcff;
      content: '\\203A';
      position: absolute;
      top: -9px;
      right: 6px;
      font-size: 48px;
      color: inherit;
      opacity: 0.5;
    }
  }

  &:hover {
    &::after {
      opacity: 0.9;
      transition: all 0.2s ease-in-out;
    }
  }
`;

export const Title = styled.h2`
  color: inherit;
  font-family: ${getToken('platform-font-title')};
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  padding: 0;
  margin: 0;

  ${screen.md} {
    text-align: left;
  }
`;

export const Paragraph = styled.p`
  color: rgb(${getToken('color-text-secondary')});
  font-family: ${getToken('platform-font-title')};
  font-size: 16px;
  line-height: 24px;
  margin: 12px 0;
  text-align: center;
  padding: ${getToken('spacing-4')};
  border-radius: ${getToken('border-thick')};
  display: none;

  ${screen.md} {
    display: block;
    text-align: left;
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    right: -5px;
    opacity: 0;
    pointer-events: none;
  }
`;

export const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  ${screen.lg} {
    display: grid;
    grid-template-columns: 36% auto;
    align-items: center;
  }
`;
