// components
import { HubspotForm } from '@/containers-m/HubspotForm';
import { HubspotFormDescription } from '@/containers-m/HubspotForm/HubspotForm.styles';
import { managedTransOptions } from '@/vendor/HubspotFormIntegration';

// styles
import {
  FormBackground,
  ContainerForm,
  ContentHolder,
  FormHolder,
  Title,
  SubTitle,
} from './Form.styles';

export function Form() {
  return (
    <>
      <FormBackground id="hubspot-form">
        <ContainerForm>
          <ContentHolder>
            <Title>
              Turn your logistics network from cost center to secret weapon
            </Title>
            <SubTitle>
              Unlock the potential of your supply chain. Get started with an
              assessment and benchmark of your network.
            </SubTitle>
          </ContentHolder>
          <FormHolder>
            <HubspotForm {...managedTransOptions} disclaimer={true}>
              <HubspotFormDescription>
                To begin, tell us a bit about yourself.
              </HubspotFormDescription>
            </HubspotForm>
          </FormHolder>
        </ContainerForm>
      </FormBackground>
    </>
  );
}
