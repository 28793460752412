/* eslint-disable @next/next/no-img-element */
import React from 'react';

// components
import { Container } from 'components/Grid';

//containers
import { MidBanner } from '../MidBanner';
import { BannerCTA } from '../../BannerCTA';

//styles
import {
  ListWrapper,
  ListHeader,
  StyledTitle1,
  ListGrid,
  ListItem,
  StyledContainer,
  Media,
  Content,
  StyledTitle2,
  Paragraph2,
  LinkItem,
  IconThinArrow,
} from './List.styles';

const features_finance = [
  {
    title: <>Automated Invoicing</>,
    anchor: 'invoicing',
    copy: 'With all your documentation in one place, Carrier TMS PRO is the logical location to create and submit invoices. Sync invoices to Quickbooks to simplify bookkeeping.',
    image: `/images/carrier-tms/features/screenshots/fa-01_Invoicing.png`,
  },
  {
    title: <>Driver Statements</>,
    anchor: 'driver-statements',
    copy: 'Create and customize driver statements based on mileage, time, or percentage of load price.',
    image: `/images/carrier-tms/features/screenshots/fa-02_Driver-Statements.png`,
  },
  {
    title: <>Quickbooks</>,
    anchor: 'quickbooks',
    copy: 'Sync invoice information and data from Carrier TMS PRO to Quickbooks at the click of a button, saving the need to spend hours copying and pasting from one system to another. ',
    image: `/images/carrier-tms/features/screenshots/fa-03_Quickbooks.png`,
  },
  {
    title: <>Factoring</>,
    anchor: 'factoring',
    copy: 'Easily click-to-send invoices to RTS Financial, or use our own 2.5% factoring for same-day payments.',
    image: `/images/carrier-tms/features/screenshots/fa-04_Factoring.png`,
  },
  {
    title: <>1099 Truck Driver Reports</>,
    anchor: '1099-reports',
    copy: '1099 reports used to be a once-a-year hassle for your contract-based drivers, that is now an easy task thanks to integrations and reporting capabilities within Carrier TMS PRO',
    image: `/images/carrier-tms/features/screenshots/fa-05_Reporting.png`,
  },
  {
    title: <>IFTA Reports</>,
    anchor: 'ifta-reports',
    copy: 'Fuel Card integrations with ELD integrations make IFTA reporting a breeze.',
    image: `/images/carrier-tms/features/screenshots/fa-06_IFTA-Reports.png`,
  },
];

const back_office_management = [
  {
    title: <>Document & Safety Management</>,
    anchor: 'document-&-safety-management',
    copy: 'Stay compliant with simplified document management including expiration dates and alerts.',
    image: `/images/carrier-tms/features/screenshots/ma-01_Document-&-Safety-Management.png`,
  },
  {
    title: <>Multiple MC Management</>,
    anchor: 'multiple-mc-management',
    copy: 'Easily manage separate companies all in one place, under one login.',
    image: `/images/carrier-tms/features/screenshots/ma-02_Multiple-MC-Management.png`,
  },
  {
    title: <>Reporting</>,
    anchor: 'reporting',
    copy: 'Analyze market rates, fleet expenses, profitability, aging reports and other actionable insights that help you improve efficiencies and profitability.',
    image: `/images/carrier-tms/features/screenshots/ma-03_Reporting.png`,
  },
];

const seamless_integrations = [
  {
    title: <>ELDs</>,
    anchor: 'elds',
    copy: 'Compatible with over 25 top ELDs for real-time tracking, HOS, and IFTA reporting have never been easier.',
    image: `/images/carrier-tms/features/screenshots/in-01_ELDs.png`,
  },
  {
    title: <>Quickbooks</>,
    anchor: 'quickbooks',
    copy: 'Sync invoice information and data from Carrier TMS PRO to Quickbooks at the click of a button, saving the need to spend hours copying and pasting from one system to another.',
    image: `/images/carrier-tms/features/screenshots/fa-03_Quickbooks.png`,
  },
  {
    title: <>Load Board</>,
    anchor: 'load-board',
    copy: 'Carrier TMS provides a consolidated load board with integrations with your subscriptions to DAT, Loadsmart, and others. With the ‘book it now’ functionality, dispatchers can quickly search, book and dispatch a truck load within a few clicks.',
    image: `/images/carrier-tms/features/screenshots/di-01_Load-Board.png`,
  },
  {
    title: <>Fuel Cards</>,
    anchor: 'fuel-cards',
    copy: 'Import fuel expenses from driver’s fuel cards  for easy IFTA  and driver expense reports.',
    image: `/images/carrier-tms/features/screenshots/in-02_Fuel-Cards.png`,
  },
  {
    title: <>Shipper EDI</>,
    anchor: 'shipper-edi',
    copy: 'Connect EDI to your top customers to receive and accept tenders while avoiding data entry and expensive integration fees.',
    image: `/images/carrier-tms/features/screenshots/in-03_Shipper-EDI.png`,
  },
];

const featuredContent = [
  {
    header: {
      title: <>Finance &amp; Accounting</>,
      anchor: 'finance-accounting',
    },
    items: features_finance,
  },
  {
    header: {
      title: <>Back-Office Management</>,
      anchor: 'management',
    },
    items: back_office_management,
  },
  {
    header: {
      title: <>Seamless Integrations</>,
      anchor: 'integrations',
    },
    items: seamless_integrations,
  },
];

export default function List() {
  return (
    <>
      {featuredContent.map((item, key) => {
        const keyDiv = `list-${key}`;
        return (
          <div key={keyDiv}>
            <ListWrapper>
              <ListHeader id={item.header.anchor}>
                <Container>
                  <StyledTitle1>
                    <IconThinArrow className="icon-arrow" width="31" />
                    <span>{item.header.title}</span>
                  </StyledTitle1>
                </Container>
              </ListHeader>

              <ListGrid>
                {item.items.map((feat, keyf) => {
                  const KeyListItem = `feature-${key}-${keyf}`;
                  return (
                    <ListItem
                      key={KeyListItem}
                      id={`${item.header.anchor}-${feat.anchor}`}
                    >
                      <StyledContainer>
                        <Media className="media">
                          <img
                            src={feat.image}
                            alt={`Screenshot for ` + feat.anchor}
                            width=""
                            height=""
                          />
                        </Media>

                        <Content>
                          <StyledTitle2>
                            <span>{feat.title}</span>
                            <a href="#scroll-top">[back &#x2191;]</a>
                          </StyledTitle2>
                          <Paragraph2>{feat.copy}</Paragraph2>
                          <Paragraph2>
                            <LinkItem href="#hubspot-form">
                              SCHEDULE A DEMO &gt;
                            </LinkItem>
                          </Paragraph2>
                        </Content>
                      </StyledContainer>
                    </ListItem>
                  );
                })}
              </ListGrid>
            </ListWrapper>
            {key === 0 ? <MidBanner /> : ''}
            {key === 1 ? <BannerCTA variant="features" /> : ''}
          </div>
        );
      })}
    </>
  );
}

export { List };
