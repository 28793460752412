import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

import { Link as BaseLink } from '@/components/Grid/Content';
import { SectionPanel } from '@/components/Grid/Panel';

export const SectionStyled = styled(SectionPanel)`
  margin-top: 0;
  ${screen.md} {
    margin-top: 0;
  }
  ${screen.lg} {
    margin-top: 0;
  }
  ${screen.xxlg} {
    margin-top: 0;
  }
`;

export const Link = styled(BaseLink)`
  text-align: center;
  display: inline-block;
  width: fit-content;
  margin: auto;

  ${screen.md} {
    text-align: left;
    margin: 0;
  }
`;

export const Column = styled.div`
  display: block;

  p:nth-child(3) {
    margin-top: 0;
  }
`;

export const ResponsiveImage = styled.picture`
  display: inline-flex;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  margin-top: 5px;

  text-align: center;

  img,
  source {
    width: 100%;
    height: auto;
  }

  ${screen.md} {
    text-align: left;
  }
`;
