import styled from 'styled-components';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

import {
  HeroSection,
  IntroductionSection,
  YourDataInsightsSection,
  ModeOptSection,
} from './containers';

import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';

import { FreightDataReportSection } from '@/containers/FreightDataReportSection';

import { dataInsightsOptions } from '@/vendor/HubspotFormIntegration';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

const DataInsights = () => {
  return (
    <>
      <PageWrapper>
        <Navigation />
        <>
          <HeroSection />
          <IntroductionSection />
          <FreightDataReportSection />
          <YourDataInsightsSection />
          <ModeOptSection />
          <HubspotForm {...dataInsightsOptions}>
            <HubspotFormTitle>
              Talk to a Transportation Specialist
            </HubspotFormTitle>
            <HubspotFormDescription>
              Our team of industry experts and technicians are here to answer
              your questions.
            </HubspotFormDescription>
          </HubspotForm>
        </>
        <Footer />
      </PageWrapper>
    </>
  );
};

export default DataInsights;
export { DataInsights };
