import styled from 'styled-components';

//
import { screen } from '@/helpers/screen';
import conditional from '@/helpers/conditional';

//
import { Wrapper as BaseWrapper } from '@/components/Grid/Content';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SidePanelsWrapper = styled.section`
  padding: 40px 0 80px;

  text-align: center;
`;

export const SectionTitle = styled.h1`
  margin: 20px auto;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  br {
    display: none;
  }

  ${screen.md} {
    margin: 32px auto;
    padding: 0;

    font-size: 28px;
    line-height: 40px;

    br {
      display: block;
    }
  }
  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin: 40px auto 24px;

    font-size: 48px;
    line-height: 72px;

    br {
      display: none;
    }
  }
`;

export const SectionIntro = styled.p`
  margin: 20px auto 14px;

  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    margin: 32px auto 0px;

    padding: 0 70px;

    font-size: 16px;
    line-height: 24px;

    br {
      display: none;
    }
  }
  ${screen.lg} {
    margin: 32px auto 20px;

    padding: 0 20px;

    font-size: 21px;
    line-height: 34px;
  }

  ${screen.xxlg} {
    margin: 24px auto 40px;

    font-size: 26px;
    line-height: 38px;
  }
`;

export const LogoWrapper = styled.div`
  margin: 0 0 22px;

  svg {
    width: auto;
    max-width: 100%;
    max-height: 46px;
  }

  ${screen.md} {
    margin: 0 0 28px;
  }

  ${screen.lg} {
    margin: 0 0 32px;

    svg {
      max-height: 66px;
    }
  }

  ${screen.xxlg} {
    margin: 0 0 40px;

    svg {
      max-height: auto;
    }
  }
`;

export const Title = styled.h2`
  margin-bottom: 10px;

  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 25px;
  line-height: 36px;

  white-space: pre-wrap;
  text-align: center;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
    text-align: left;
  }
  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    font-size: 40px;
    line-height: 56px;
    white-space: normal;
  }
`;

export const Subtitle = styled.h3`
  margin: 0;
  padding: 0;

  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-neutral-60')});
  font-weight: ${getToken('font-weight-bold')};

  font-size: 16px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    line-height: 26px;
    white-space: pre-wrap;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 28px;
    line-height: 44px;
  }
`;

export const Content = styled.div`
  margin: 16px 0;

  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};

  font-size: 12px;
  line-height: 22px;

  ul {
    li {
      font-weight: 700;

      list-style: disc inside;
    }
  }

  ${screen.md} {
    margin: 22px 0;

    padding-right: ${conditional({
      0: (props) => props.scale !== 'scaleImage',
      '6px': (props) => props.scale === 'scaleImage',
    })};
  }

  ${screen.lg} {
    margin: 36px 0;

    padding-right: 0px;

    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    margin: 42px 0;

    font-size: 20px;
    line-height: 38px;
  }
`;

export const Link = styled.a`
  display: inline-flex;
  width: fit-content;
  margin: auto;

  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-brand')});
  font-weight: ${getToken('font-weight-medium')};
  font-size: 16px;
  line-height: 22px;
  text-decoration: underline;

  img,
  source {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
  }

  ${screen.md} {
    margin: 0;

    font-size: 15px;
    line-height: 24px;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 28px;
  }

  ${screen.xxlg} {
    font-size: 24px;
    line-height: 32px;
  }

  &:hover {
    color: ${({ theme }) => theme.color.primaryCodeString};
  }
`;

export const Picture = styled.picture`
  display: grid;
  box-sizing: border-box;

  ${screen.md} {
    padding: ${conditional({
      0: (props) => props.direction !== 'reverse',
      '0 20px 0 0': (props) => props.direction === 'reverse',
    })};
  }

  img,
  source {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;

    ${screen.md} {
      transform: ${conditional({
        'scale(1);': (props) => props.scale !== 'scaleImage',
        'scale(1.08);': (props) => props.scale === 'scaleImage',
      })};
    }

    ${screen.lg} {
      transform: scale(1);
    }
  }
`;

export const SectionPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  ${screen.md} {
    flex-direction: ${conditional({
      'row-reverse': (props) => props.direction !== 'reverse',
      row: (props) => props.direction === 'reverse',
    })};
    padding: 25px 0 25px;
  }

  ${screen.lg} {
    padding: 30px 0 30px;
  }

  ${screen.xxlg} {
    padding: 50px 0 50px;
  }
`;

export const Wrapper = styled(BaseWrapper)`
  justify-content: center;
  padding: 0;

  ${screen.md} {
    align-items: flex-start;
    width: 343px;

    padding: ${conditional({
      '0 14px 0 0': (props) => props.direction !== 'reverse',
      '0 0 0 14px': (props) => props.direction === 'reverse',
    })};

    text-align: left;
  }

  ${screen.lg} {
    width: auto;

    width: ${conditional({
      '100%': (props) => props.scale !== 'scaleImage',
      '475px !important': (props) => props.scale === 'scaleImage',
    })};
  }

  ${screen.xxlg} {
    width: 100% !important;
    padding: ${conditional({
      0: (props) => props.direction !== 'reverse',
      '0 0 0 22px': (props) => props.direction === 'reverse',
    })};
  }
`;

export const FlexRow = styled.div`
  display: flex;
  gap: 36px;
  align-items: center;

  width: 100%;

  margin-top: 40px;
`;
