//vendor
import ReactPlayer from 'react-player/lazy';

//components
import { Container } from '@/components/Grid/Container';
import {
  BackgroundSection,
  SectionTitle,
  Title,
  Content,
  VideoWrapper,
  SectionPanel,
  CustomContainer,
  ReversedWrapper,
  Wrapper,
} from './VideosSection.styles';

export const VideosSection = () => (
  <BackgroundSection>
    <Container>
      <SectionTitle>What does it mean to be one of us?</SectionTitle>
      <div>
        <CustomContainer>
          <SectionPanel>
            <VideoWrapper>
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/embed/M2yaGJh3Q-U?rel=0"
                controls
                width="100%"
                height="100%"
              />
            </VideoWrapper>
            <ReversedWrapper>
              <Title>
                A Decade of Logistics Innovation: Celebrating 10 Years of
                Loadsmart!
              </Title>
              <Content>
                We are celebrating a decade of moving more with less! From our
                humble beginnings as a small startup in NYC, to now boasting
                over $250M FUM, Loadsmart has paved new roads for the logistics
                industry with revolutionary services and software solutions.
              </Content>
            </ReversedWrapper>
          </SectionPanel>
        </CustomContainer>
        <CustomContainer>
          <SectionPanel direction="reverse">
            <VideoWrapper>
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/embed/PW6EYRSjcpA?rel=0"
                controls
                width="100%"
                height="100%"
              />
            </VideoWrapper>
            <Wrapper>
              <Title>What does it mean to be future ready?</Title>
              <Content>
                Our annual Hackathon allows Loadies from across the globe to
                come together to partner and solve tomorrow’s supply chain
                problems, brought to us by our customers and leaders. Hear what
                folks at Loadsmart’s 8th Hackathon had to say when asked what
                ’future-ready’ means to them!
              </Content>
            </Wrapper>
          </SectionPanel>
        </CustomContainer>
        <CustomContainer>
          <SectionPanel>
            <VideoWrapper>
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/embed/TxpeJjwSzMM?rel=0"
                controls
                width="100%"
                height="100%"
              />
            </VideoWrapper>
            <ReversedWrapper>
              <Title>Renewed Chicago Office opening day!</Title>
              <Content>
                We launched our Loadsmart Headquarters in West Jackson, Chicago
                in 2021. Hear what our founders, Ricardo Salgado and Felipe
                Capella had to say to our wonderful Loadies and get a glimpse of
                the awesome office.
              </Content>
            </ReversedWrapper>
          </SectionPanel>
        </CustomContainer>
      </div>
    </Container>
  </BackgroundSection>
);
