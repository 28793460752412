import styled from 'styled-components';

//helpers
import { screen, hideOnMedium, hideOnMobile } from '@/helpers/screen';

//components
import { Section } from '@/components/Grid/Section';
import { Container } from '@/components/Grid/Container';
import { Panel } from '@/components/Grid/Panel';
import {
  ReversedCenteredWrapper,
  Text,
  TitleSection,
} from '@/components/Grid/Content';
import { Button } from '@/components/Button';

//styles
const ImgWrapper = styled.div`
  display: flex;
  width: 280px;
  margin: auto;

  ${screen.md} {
    width: 530px;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const FullButton = styled(Button)`
  width: 100%;
  display: inline-flex;
`;

export const IntegrationAPISection = () => (
  <Section>
    <Container>
      <Panel reverse mobileReverse>
        <ImgWrapper>
          <Img
            src={`/images/integrations/common/code_mockup.png`}
            alt="Direct access to API"
            loading="lazy"
            width="530"
            height="524"
          />
        </ImgWrapper>
        <ReversedCenteredWrapper>
          <TitleSection>Direct API Access</TitleSection>
          <Text>
            Loadsmart offers developer resources for transportation providers
            and shippers to integrate Loadsmart’s APIs directly into their TMS
            solutions. <br />
            <br />
            Contact us for documentation, code samples, and other tools for easy
            integration.
          </Text>
          <Button as="a" href="/contact/" css={hideOnMobile}>
            CONTACT US
          </Button>
        </ReversedCenteredWrapper>
      </Panel>
      <FullButton as="a" href="/contact/" css={hideOnMedium}>
        CONTACT US
      </FullButton>
    </Container>
  </Section>
);
