import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';

// styles
import {
  FeaturesCarouselWrapper,
  Title,
  SlidesRotator,
  SlidesContainer,
  SlidesSection,
  StyledButton,
} from './TestimonialCarousel.styles';

// slides
import { Slide } from './Slide';

const features = [
  {
    active: false,
    title:
      '“Carrier TMS is the best thing I have ever used. Thank you so much for all the help along the way! IT HAS BEEN FANTASTIC!!!”',
    content: (
      <>
        <strong>Trailer Move Logistics</strong>
      </>
    ),
  },
  {
    active: true,
    title:
      '“Carrier TMS has helped me meet deadlines and OTP/OTD and I appreciate the fact that Carrier TMS staff is so willing to work with new carriers!”',
    content: (
      <>
        <strong>CDL 1000 INC</strong>
      </>
    ),
  },
  {
    active: false,
    title:
      '“Carrier TMS helps us see the broader view, make better decisions and helps us do the right thing for everybody.”',
    content: (
      <>
        <strong>Rollzi</strong>
      </>
    ),
  },
  {
    active: false,
    title:
      '“Carrier TMS is a one-stop-shop for everything you need to streamline your trucking business. Stop copy and pasting and doing things manually. You’re wasting time. Let Carrier TMS help.”',
    content: (
      <>
        <strong>Trucking Wins</strong>
      </>
    ),
  },
];

let timeout = null;
const interval = 6000;

export function TestimonialCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);

  const clearTimer = () => {
    window.clearInterval(timeout);
  };

  const changeSlide = useCallback(() => {
    setActiveIndex((activeIndex) => (activeIndex + 1) % features.length);
  }, []);

  const testAfter = (val) => {
    if (val === activeIndex + 1) {
      return true;
    }

    if (0 === val && activeIndex + 1 === features.length) {
      return true;
    }

    return false;
  };

  const testBefore = (val) => {
    if (val === activeIndex - 1) {
      return true;
    }

    if (0 === activeIndex && val + 1 === features.length) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    timeout = window.setInterval(() => {
      changeSlide();
    }, interval);

    return () => {
      clearTimer();
    };
  }, [changeSlide]);

  return (
    <FeaturesCarouselWrapper>
      <Title>
        Carriers all over the
        <br /> country love us.
      </Title>

      <SlidesSection>
        <SlidesContainer>
          {features.map((item, key) => {
            const indexkey = `slide-${key}`;
            const isvisible = key === activeIndex;
            const isAfter = testAfter(key);
            const isBefore = testBefore(key);

            return (
              <SlidesRotator
                key={indexkey}
                length={features.length}
                activeIndex={activeIndex}
                className={classnames({
                  active: isvisible,
                  inactive: !isvisible,
                  after: isAfter,
                  before: isBefore,
                })}
              >
                <Slide property={item} />
              </SlidesRotator>
            );
          })}
        </SlidesContainer>
      </SlidesSection>

      <StyledButton as="a" href="https://carrier.loadsmart.com/#/signup">
        Start for free today!
      </StyledButton>
    </FeaturesCarouselWrapper>
  );
}
