//icons
import {
  IconInstantPricing,
  IconTenderAcceptance,
  IconArtificialIntelligence,
  IconSmartTracking,
  IconInvoice,
} from '@loadsmart/icons';
import { IconPackageTracking, IconVisibility } from '@loadsmart/icons';
import {
  IconFastPayment,
  IconImproveEfficiency,
  IconFasterService,
} from '@loadsmart/icons';

//componets
import { WideImageOnMobile } from '@/components/Grid/Content';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { tmsIntegrationOptions } from '@/vendor/HubspotFormIntegration';

// context containers
import {
  HeroSection,
  FeatureSection,
  BackgroundImageSection,
  IntegrationAPISection,
  ContentSection,
} from './containers/index';

//styles
import { PageWrapper } from './Integrations.styles';

//content
const heroContent = {
  page: 'Oracle',
  logo: {
    src: `/images/logos/logo-oracle.svg`,
    alt: 'Oracle',
  },
  title: `Access Loadsmart Directly From Your Oracle TMS`,
  description: `Loadsmart’s OTM integration makes it easy to add instant market rates, guaranteed tender acceptance, and real-time tracking directly to your Oracle Transportation Management System.`,
  images: {
    sm: `/images/integrations/oracle/small_hero.png`,
    md: `/images/integrations/oracle/medium_hero.png`,
    lg: `/images/integrations/oracle/large_hero.png`,
    xxlg: `/images/integrations/oracle/xxlarge_hero.png`,
  },
};

const featuresContent = {
  title: 'Capabilities',
  features: [
    {
      title: 'INSTANT RATES',
      icon: <IconInstantPricing />,
    },
    {
      title: '100% TENDER ACCEPTANCE',
      icon: <IconTenderAcceptance />,
    },
    {
      title: 'AI-POWERED SOURCING',
      icon: <IconArtificialIntelligence />,
    },
    {
      title: 'NEAR REAL-TIME TRACKING',
      icon: <IconSmartTracking />,
    },
    {
      title: 'EDI INVOICING',
      icon: <IconInvoice />,
    },
  ],
};

const benefitsContent = {
  text: `Loadsmart’s partnerships with leading ELD providers such as KeepTruckin, combined with smart apps and other technologies like geofencing, means we always know the real-time location of your shipments and can proactively mitigate service issues before they occur.`,
  benefits: [
    {
      icon: <IconPackageTracking width={66} height={66} />,
      copy: 'View real-time tracking updates directly from within OTM',
    },
    {
      icon: <IconVisibility width={66} height={66} />,
      copy: 'Proactively identify and mitigate service issues before they occur',
    },
  ],
};

const copyContent = {
  title: 'Supercharge Your Routing Guide',
  introduction: `Loadsmart’s integration with Oracle fully automates the quoting and booking of truckload shipments. We do this by inserting real-time rates directly into your routing guide, enabling you to take advantage of market conditions when current prices are below the static options provided by other carriers. Loadsmart accepts 100% of tenders instantly — so whenever our rate is best, the load can be immediately covered.`,
  media: (
    <WideImageOnMobile
      loading="lazy"
      src={`/images/integrations/oracle/tms-landing-macbook.png`}
      alt="Oracle System"
    />
  ),
  benefits: [
    {
      icon: <IconFastPayment width={66} height={66} />,
      text: 'Save when the market rate is below your static contracted options',
    },
    {
      icon: <IconImproveEfficiency width={66} height={66} />,
      text: 'Improve service by lowering the number of shipments sent to the spot market',
    },
    {
      icon: <IconFasterService width={66} height={66} />,
      text: 'Reduce time spent securing coverage for routing guide freight',
    },
  ],
};
function Oracle() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <HeroSection content={heroContent} />
        <FeatureSection content={featuresContent} showHeader={true} />
        <ContentSection content={copyContent} background="dark" />
        <BackgroundImageSection content={benefitsContent} />
        <IntegrationAPISection />
        <HubspotForm {...tmsIntegrationOptions}>
          <HubspotFormTitle>Ready to learn more?</HubspotFormTitle>
          <HubspotFormDescription>
            Fill out the form below to and a member of the Loadsmart team will
            be in touch.
          </HubspotFormDescription>
        </HubspotForm>
      </>
      <Footer />
    </PageWrapper>
  );
}

export default Oracle;
export { Oracle };
