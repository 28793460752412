//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

//context containers
import {
  Hero,
  LeadershipVideo,
  BackgroundContent,
  PhotoList,
  SideVideos,
} from './containers/index';

//styles
import { PageWrapper } from './Leadership.styles';

function Leadership() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <Hero />
        <LeadershipVideo />
        <BackgroundContent />
        <PhotoList />
        <SideVideos />
      </>
      <Footer />
    </PageWrapper>
  );
}

export default Leadership;
export { Leadership };
