import styled from 'styled-components';

// helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const TableWrapper = styled.div`
  padding: 0;
  border: 1px solid rgb(${getToken('color-neutral-100')});

  table {
    width: 100%;
    border: none;
  }

  th,
  td {
    padding: 8px 4px;
    border: none;
    font-size: 11px;
    line-height: 18px;
    border: 0.5px solid rgb(${getToken('color-neutral-100')});
    font-weight: 500;

    ${screen.md} {
      font-size: 14px;
      line-height: 22px;
    }

    ${screen.lg} {
      font-size: 17px;
      line-height: 25px;
    }

    ${screen.xxlg} {
      font-size: 20px;
      line-height: 28px;
    }
  }

  th {
    border-bottom: 1px solid rgb(${getToken('color-neutral-100')});
    font-weight: 700;
  }
`;

export default function Table({ content }) {
  return (
    <TableWrapper>
      <table cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            {content.header.map((item, key) => {
              const keyitem = item + '-' + key;
              return <th key={keyitem}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {content.body.map((item, key) => {
            const keyitem = item + '-' + key;
            return (
              <tr key={keyitem}>
                {item.map((subitem, subkey) => {
                  const subkeyitem = subitem + '-' + subkey;
                  return <td key={subkeyitem}>{subitem}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableWrapper>
  );
}

export { Table };
