import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { InvertedSection } from '@/components/Grid';
import { Title } from '@/components/Grid/Content';
import { Feature, FeatureDescription } from '@/components/Feature';

// context containers
import { BackgroundImages } from '@/containers/BackgroundImages/';

const responsiveBackground = {
  sm: `url(/images/mode-optimization/small_background.jpg);`,
  md: `url(/images/mode-optimization/medium_background.jpg);`,
  lg: `url(/images/mode-optimization/large_background.jpg);`,
  xxlg: `url(/images/mode-optimization/xxlarge_background.jpg);`,
};

export const BackgroundImageSection = styled(InvertedSection)`
  ${BackgroundImages(responsiveBackground)};

  color: white;
`;

export const SpacedTitle = styled(Title)`
  margin-bottom: 38px;

  ${screen.md} {
    margin-bottom: 57px;
  }

  ${screen.lg} {
    margin-bottom: 47px;
  }

  ${screen.xxlg} {
    margin-bottom: 96px;
  }
`;

export const SpacedDescription = styled(FeatureDescription)`
  margin: 16px 0;

  ${screen.md} {
    margin: 36px 0;
  }

  ${screen.lg} {
    max-width: 309px;
  }

  ${screen.xxlg} {
    max-width: 100%;
    margin: 42px 0;
  }
`;

export const SpacedFeature = styled(Feature)`
  margin: 20px 0;
`;
