import styled, { keyframes } from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const CustomersLogosWrapper = styled.aside`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 88px;
  overflow: hidden;
  background-color: rgba(${getToken('color-background-inverted')});

  &::before,
  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 250px;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  &::before {
    left: 0;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(${getToken('color-background-inverted')})
    );
  }
  &::after {
    right: 0;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(${getToken('color-background-inverted')})
    );
  }

  ${screen.lg} {
    height: 124px;
  }
`;

const logosScroll = keyframes`
    0%{
      transform: translateX(0);
    }
    100%{
      transform: translateX(-100%);
    }
`;

export const LogosSlider = styled.div`
  width: 100%;
  overflow: hidden;
  display: inline-flex;
`;

export const LogosList = styled.div`
  display: inline-flex;
  justify-content: space-between;

  animation: ${(props) => props.items}s ${logosScroll} infinite linear;
`;

export const Logo = styled.div`
  box-sizing: border-box;
  height: 35px;
  width: 120px;
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${screen.md} {
    margin: 16px 32px;
    height: 40px;
    width: 140px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
  }
`;
