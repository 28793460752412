import { useRouter } from 'next/router';

//helpers
import { sleep } from '@/helpers/sleep';

//containers
import { Title, Description } from '@/containers/Hero/Hero.styles';

//components
import { Button } from '@/components/Button';

import {
  HeroBackground,
  FlexRowWrap,
  ctaStyle,
  smallFont,
} from './HeroSection.styles';

const responsiveBackground = {
  sm: `url(/images/multimodal/small_hero.png)`,
  md: `url(/images/multimodal/medium_hero.png)`,
  lg: `url(/images/multimodal/large_hero.png)`,
  xxlg: `url(/images/multimodal/xxlarge_hero.png)`,
};

export const HeroSection = () => {
  const router = useRouter();

  const handleFormJump = async (event) => {
    event.preventDefault();
    await sleep(300);
    router.push('/multimodal#hubspot-form');
  };

  return (
    <HeroBackground image={responsiveBackground}>
      <Title>Multimodal Services</Title>
      <Description>
        Loadsmart’s Multimodal Services offering bridges port drayage,
        transload, intermodal, and over-the-road truckload FTL and LTL shipping
        using advanced technology. Get seamless transparency across the entire
        lifecycle of your shipments — from the port, to the transload facility,
        over-the-road trucks, and, ultimately, to your final destinations.
      </Description>
      <FlexRowWrap>
        <Button as="a" onClick={handleFormJump} css={[ctaStyle, smallFont]}>
          Request a demo
        </Button>
      </FlexRowWrap>
    </HeroBackground>
  );
};
