import { screen } from '@/helpers/screen';
import { css } from 'styled-components';

const ignoreSmall = (keys) => keys !== 'sm';

export const BackgroundImages = (props) => css`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${props.sm && `background-image: ${props.sm}`};

  ${Object.keys(props)
    .filter(ignoreSmall)
    .reduce((acc, key) => {
      return (acc += `${screen[key]} {
            background-image: ${props[key]};
        }`);
    }, '')};
`;
