import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const FeaturedListWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 0 5px;

  ${screen.md} {
    flex-direction: row;
  }
`;

export const FeaturedListItem = styled.article`
  flex: 1;
  margin: 12px 0;

  & > div,
  & > div > div {
    height: 100%;
  }

  ${screen.md} {
    margin: 10px;

    &:nth-child(1) {
      margin-left: -5px;
    }

    &:nth-last-child(1) {
      margin-right: -5px;
    }
  }

  p {
    font-size: 12px;
    line-height: 18px;

    ${screen.lg} {
      font-size: 16px;
      line-height: 30px;
    }

    ${screen.xxlg} {
      font-size: 20px;
      line-height: 38px;
    }
  }
`;

export const IconWrapper = styled.div`
  min-height: unset;
  margin: 20px auto 0;
  text-align: center;

  ${screen.lg} {
    min-height: 60px;
  }

  svg,
  path {
    fill: rgb(${getToken('color-brand')});
    width: 48px;
    height: 48px;

    ${screen.lg} {
      width: 66px;
      height: 66px;
    }
  }
`;
