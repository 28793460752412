import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const ColumnFeaturesWrapper = styled.section`
  display: flex;
  flex-direction: column;

  width: 100%;
  background: rgb(${getToken('color-background-primary')});
  padding: 20px 0 40px;

  ${screen.md} {
    padding: 40px 0 60px;
  }
  ${screen.lg} {
    padding: 80px 0 100px;
  }
  ${screen.xxlg} {
    padding: 80px 0 100px;
  }
`;

export const TitleWrapper = styled.div`
  margin: 0 0 32px;

  ${screen.lg} {
    margin-bottom: 40px;
  }

  ${screen.xxlg} {
    margin-bottom: 80px;
  }

  br {
    display: none;

    ${screen.md} {
      display: block;
    }
  }

  h2 {
    font-family: ${getToken('font-family-title')};
    font-weight: ${getToken('font-weight-bold')};
    color: rgb(${getToken('color-primary-60')});

    font-size: 26px;
    line-height: 39px;
    margin: 0 0 10px;

    ${screen.lg} {
      font-size: 38px;
      line-height: 47.5px;
      margin: 0 0 24px;
    }

    ${screen.xxlg} {
      font-size: 48px;
      line-height: 60px;
    }
  }

  p {
    font-family: ${getToken('font-family-default')};
    font-weight: ${getToken('font-weight-bold')};
    color: rgb(${getToken('color-text-tertiary')});
    margin: 0;
    font-size: 16px;
    line-height: 24px;

    ${screen.lg} {
      font-size: 20px;
      line-height: 30px;
    }

    ${screen.xxlg} {
      font-size: 26px;
      line-height: 39px;
    }
  }
`;

export const Columns = styled.div`
  display: grid;
  gap: 20px;
  align-items: start;
  justify-content: flex-start;
  margin: auto;

  ${screen.md} {
    grid-template-columns: repeat(2, 1fr);
    max-width: 734px;
  }

  ${screen.lg} {
    max-width: 734px;
  }

  ${screen.xxlg} {
    grid-template-columns: repeat(4, 1fr);
    max-width: unset;
  }
`;

export const CTAWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 18px;

  a {
    min-width: 100%;
  }

  ${screen.md} {
    grid-template-columns: repeat(2, auto);

    a {
      min-width: unset;
    }
  }
`;
