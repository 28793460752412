/* eslint-disable @next/next/no-img-element */
// styles
import {
  CustomersLogosWrapper,
  LogosSlider,
  LogosList,
  Logo,
} from './CustomersLogos.styles';

const logos_list = [
  {
    img: '/images/logos/light-bg/Alcoa.png',
    alt: 'Alcoa',
  },
  {
    img: '/images/logos/light-bg/Amazon.png',
    alt: 'Amazon',
  },
  {
    img: '/images/logos/light-bg/AthleticBrewing.png',
    alt: 'AthleticBrewing',
  },
  {
    img: '/images/logos/light-bg/BackyardProductions.png',
    alt: 'BackyardProductions',
  },
  {
    img: '/images/logos/light-bg/Cabot.png',
    alt: 'Cabot',
  },
  {
    img: '/images/logos/light-bg/Halliburton.png',
    alt: 'Halliburton',
  },
  {
    img: '/images/logos/light-bg/Olympicsteel.png',
    alt: 'Olympicsteel',
  },
  {
    img: '/images/logos/light-bg/ScottsMiracle.png',
    alt: 'ScottsMiracle',
  },
  {
    img: '/images/logos/light-bg/SteeleSolutions.png',
    alt: 'SteeleSolutions',
  },
];

export function CustomersLogos() {
  const sliderspeed = logos_list.length * 4;

  return (
    <CustomersLogosWrapper>
      <LogosSlider className="logoslider">
        <LogosList className="logoslider-list" items={sliderspeed}>
          {logos_list.map((item, key) => {
            return (
              <Logo key={key + '-' + item.alt.trim()}>
                <img src={item.img} alt={item.alt} />
              </Logo>
            );
          })}
        </LogosList>
        <LogosList
          className="logoslider-list"
          items={sliderspeed}
          role="presentation"
        >
          {logos_list.map((item, key) => {
            return (
              <Logo key={key + '-' + item.alt.trim()}>
                <img src={item.img} alt={item.alt} />
              </Logo>
            );
          })}
        </LogosList>
        <LogosList
          className="logoslider-list"
          items={sliderspeed}
          role="presentation"
        >
          {logos_list.map((item, key) => {
            return (
              <Logo key={key + '-' + item.alt.trim()}>
                <img src={item.img} alt={item.alt} />
              </Logo>
            );
          })}
        </LogosList>
      </LogosSlider>
    </CustomersLogosWrapper>
  );
}
