// libraries
import {
  IconAutomateInvoicing,
  IconPackageTracking,
  IconCreateValue,
  IconContractDeal,
} from '@loadsmart/icons';

// components
import { TitleRotator } from '../TitleRotator';

// styles
import {
  FeaturedWrapper,
  FeaturedContainer,
  Title,
  RotatorWrapper,
  ContainerList,
  FeaturedList,
  FeaturedItem,
  Subtitle,
  Content,
} from './Featured.styles';

const wordsArray = ['sustainably', 'transparently', 'instantly', 'reliably'];

export function Featured() {
  return (
    <FeaturedWrapper>
      <FeaturedContainer>
        <Title>
          <span>
            Move more
            <br /> freight
          </span>
          <RotatorWrapper>
            <TitleRotator wordsArray={wordsArray} />
          </RotatorWrapper>
          <span>
            <br />
            with less
          </span>
        </Title>
        <ContainerList>
          <FeaturedList>
            <FeaturedItem>
              <IconAutomateInvoicing width="50" />
              <Subtitle>Instant Freight Pricing</Subtitle>
              <Content>
                Automate freight quoting and booking with real-time, instantly
                bookable rates. Only Loadsmart offers API rates with safeguards
                to protect from above market rates.
              </Content>
            </FeaturedItem>
            <FeaturedItem>
              <IconPackageTracking width="50" />
              <Subtitle>Trusted Capacity</Subtitle>
              <Content>
                A vast and vetted carrier network with the safest, most reliable
                carriers. From owner-operators to dedicated fleets, Loadsmart
                offers reliable multimodal capacity and matches the best carrier
                to shipping and equipment needs.
              </Content>
            </FeaturedItem>
          </FeaturedList>
          <FeaturedList>
            <FeaturedItem>
              <IconCreateValue width="50" />
              <Subtitle>Best in Class Service</Subtitle>
              <Content>
                We service and support your supply chains through best-in-class
                logistics professionals and dedicated teams that are obsessed
                with finding you the optimal mix of service, quality and price.
              </Content>
            </FeaturedItem>
            <FeaturedItem>
              <IconContractDeal width="50" />
              <Subtitle>Reliable Contracts</Subtitle>
              <Content>
                100% primary tender acceptance and complete price transparency.
                Avoid the spot market with guaranteed contractual acceptance
                regardless of market conditions.
              </Content>
            </FeaturedItem>
          </FeaturedList>
        </ContainerList>
      </FeaturedContainer>
    </FeaturedWrapper>
  );
}
