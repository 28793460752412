import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const FloatHeroBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background: rgb(${getToken('color-background-tertiary')});
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: calc(100% - 56px);
  max-width: 367px;
  margin-top: -250px;
  padding: 19px 22px;

  background-color: rgb(${getToken('color-text-inverted')});

  ${screen.md} {
    align-items: flex-start;
    max-width: 720px;
    margin-top: -179px;
    padding: 24px 24px 33px;
  }

  ${screen.lg} {
    max-width: 1040px;
    margin-top: -279px;
    padding: 36px;
  }

  ${screen.xxlg} {
    width: calc(100% - 460px);
    max-width: 1460px;
    margin-top: -330px;
    padding: 55px 50px;

    br {
      display: block;
    }
  }
`;

export const Title = styled.h1`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 32px;
  line-height: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  text-align: center;

  br {
    display: none;
  }

  ${screen.md} {
    align-items: flex-start;

    font-size: 34px;
    line-height: 40px;
    text-align: left;

    br {
      display: block;
    }
  }

  ${screen.lg} {
    font-size: 48px;
    line-height: 54px;
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72px;
  }
`;

export const Subtitle = styled.p`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};
  font-size: 14px;
  line-height: 22px;

  margin: 16px 0;

  text-align: center;

  ${screen.md} {
    margin: 21px 0;

    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }

  ${screen.lg} {
    margin: 24px 0;

    font-size: 23px;
    line-height: 34px;
  }

  ${screen.xxlg} {
    margin: 32px 0 41px;

    font-size: 28px;
    line-height: 38px;
  }
`;

export const Button = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 10px 44px;

  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-bold')};

  font-size: 12px;
  line-height: 16px;
  text-transform: none;

  background: rgb(${getToken('color-primary-60')});
  border-radius: 4px;

  transition: all 0.1s ease-in-out;

  ${screen.md} {
    margin: 0px;
    padding: 16px;

    font-size: 16px;
    line-height: 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.primaryStrong};
    border-color: ${({ theme }) => theme.color.primaryStrong};

    transition: all 0.2s ease-in-out;
  }
`;
