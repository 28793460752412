//components
import { Title4, Paragraph2 } from 'components/Text';

//styles
import {
  DefinitionListWrapper,
  DefinitionTitle,
  DefinitionDescription,
  DefinitionBlock,
} from './DefinitionList.styles';

export default function DefinitionList({ content }) {
  return (
    <DefinitionListWrapper>
      {content &&
        content.map((item, index) => {
          return (
            <DefinitionBlock key={`dd-${index}`}>
              <DefinitionTitle>
                <Title4 as="h3">{item.title}</Title4>
              </DefinitionTitle>
              <DefinitionDescription>
                <Paragraph2> {item.description}</Paragraph2>
              </DefinitionDescription>
            </DefinitionBlock>
          );
        })}
    </DefinitionListWrapper>
  );
}

export { DefinitionList };
