// styles
import {
  FloatTeamHeroBoxWrapper,
  Container,
  Title,
  Subtitle,
} from './FloatTeamHeroBox.styles';

export function FloatTeamHeroBox() {
  return (
    <FloatTeamHeroBoxWrapper>
      <Container>
        <Title>
          Not just technology, but industry experts and great people behind it
          all.
          <span> Meet our team: the nerds of logistics.</span>
        </Title>
        <Subtitle>
          Your dedicated account manager is your logistics veteran and data
          expert. They are the trusted advisors to 30% of the top fortune 100
          shippers in North America. They are ready to geek out on the logistics
          of your most complex shipments to achieve your unique service
          requirements and efficiency goals.
        </Subtitle>
      </Container>
    </FloatTeamHeroBoxWrapper>
  );
}
