//styles
import {
  ContentArrowWrapper,
  ContentArrowIcon,
  ContentArrowTitle,
  ContentArrowPeriod,
  ContentArrowDescription,
  ArrowHeader,
} from './ContentArrow.styles';

export function ContentArrow({ content }) {
  return (
    content && (
      <ContentArrowWrapper>
        <ArrowHeader>
          {content.icon && <ContentArrowIcon>{content.icon}</ContentArrowIcon>}
          {content.title && (
            <ContentArrowTitle>{content.title}</ContentArrowTitle>
          )}
          {content.period && (
            <ContentArrowPeriod>{content.period}</ContentArrowPeriod>
          )}
        </ArrowHeader>
        {content.description && (
          <ContentArrowDescription>
            {content.description}
          </ContentArrowDescription>
        )}
      </ContentArrowWrapper>
    )
  );
}
