import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';
import { LogoTitle } from './Content';

import PythonLogo from '@/public/images/logos/logo-python.svg';

const PythonWrapper = styled(FlexRowFullCentered)`
  width: 26px;
  height: 27px;

  ${screen.md} {
    width: 27px;
    height: 29px;
  }

  ${screen.lg} {
    width: 44px;
    height: 46px;
  }

  ${screen.xxlg} {
    width: 50px;
    height: 52px;
  }
`;

export const PythonCard = (props) => (
  <CardLayout {...props}>
    <PythonWrapper>
      <PythonLogo />
    </PythonWrapper>
    <LogoTitle>Python</LogoTitle>
  </CardLayout>
);
