import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const FeaturedSection = styled.section`
  background: rgb(${getToken('color-background-secondary')});
  padding: 50px 0;

  ${screen.md} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const HeaderWrapper = styled.header`
  text-wrap: balance;
  margin: 0 0 40px;
`;

export const FeatureGroup = styled.div`
  display: grid;
  flex-wrap: wrap;

  ${screen.md} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Featured = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;

  ${screen.lg} {
    max-width: 90%;
  }
`;

export const FeaturedTitle = styled.h3`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  margin: 10px 0;
  font-size: 20px;
  line-height: 30px;
  text-wrap: balance;
  text-align: center;

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const FeaturedDescription = styled.p`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-primary-60')});
  text-align: center;
  margin: 0;
  font-size: 12px;
  line-height: 16.8px;
  text-wrap: balance;

  ${screen.xxlg} {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const FeaturedIcon = styled.div``;
