/* eslint-disable @next/next/no-img-element */
import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import {
  ReversedCenteredWrapper,
  Text,
  Link as BaseLink,
  SubTitle,
} from '@/components/Grid/Content';
import { SectionPanel } from '@/components/Grid/Panel';
import { FlexRowFullCentered } from '@/components/Grid/Flex';

//context components
const SectionStyled = styled(SectionPanel)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 570px;
  margin: 0;
  padding: 210px 0 50px;

  background: linear-gradient(
      180deg,
      #ffffff 55.18%,
      rgba(255, 255, 255, 0) 110.96%
    ),
    linear-gradient(114.92deg, #84f11e -33.79%, #46e575 -8.04%, #00d7d7 117.74%);
  mix-blend-mode: normal;

  ${screen.md} {
    height: 390px;
    margin: 0;
    padding: 0 0 20px;
    overflow-x: hidden;

    background: linear-gradient(
        180deg,
        #ffffff 27.18%,
        rgba(255, 255, 255, 0) 119.96%
      ),
      linear-gradient(
        114.92deg,
        #84f11e -33.79%,
        #46e575 -8.04%,
        #00d7d7 117.74%
      );
  }

  ${screen.lg} {
    height: 510px;
    margin: 0;
  }

  ${screen.xxlg} {
    height: 700px;
    margin: 0;
  }
`;

const TitleAsImage = styled.picture`
  display: inline-block;
  width: 196px;
  height: 40px;

  img {
    max-width: 100%;
    height: auto;
  }

  ${screen.lg} {
    width: 291px;
    height: 59px;
  }
  ${screen.xxlg} {
    width: 459px;
    height: 94px;
  }
`;

const SubTitleStyled = styled(SubTitle)`
  margin: 6px 0 5px;

  ${screen.md} {
    margin: 20px 0 0px;
  }

  ${screen.lg} {
    margin: 40px 0 20px;
  }

  ${screen.xxlg} {
    margin: 60px 0 40px;
  }
`;

const Column = styled.div`
  display: block;

  padding: 0 20px 0 30px;
`;

const Link = styled(BaseLink)`
  ${FlexRowFullCentered};

  ${screen.md} {
    justify-content: start;
  }
`;

const ResponsiveImage = styled.picture`
  position: relative;

  display: inlne-flex;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  margin-top: -165px;

  text-align: center;

  img,
  source {
    position: relative;

    width: 100%;
    max-width: 370px;
    height: auto;
    margin: auto;
  }

  ${screen.md} {
    margin-top: -10px;

    text-align: left;

    img,
    source {
      position: absolute;
      right: 0;

      width: 110%;
      max-width: unset;
    }
  }
`;

export const RFPGuideSection = () => (
  <>
    <SectionStyled>
      <ResponsiveImage>
        <source
          srcSet={`/images/smb-shipping/RPF-Guide-dashboard.png`}
          media="(min-width: 768px)"
        />
        <img
          src={`/images/smb-shipping/RPF-Guide-dashboard-small.png`}
          alt="RFP Guide Screen"
          width="759"
          height="495"
        />
      </ResponsiveImage>
      <ReversedCenteredWrapper>
        <Column>
          <TitleAsImage>
            <img
              src={`/images/smb-shipping/RFP-GUIDE-logo.svg`}
              alt="RFP Guide"
              width="459"
              height="93"
            />
          </TitleAsImage>
          <SubTitleStyled>
            Streamline your freight procurement process
          </SubTitleStyled>
          <Text>
            Price 1000 lanes in 1 minute via our a cloud-based application that
            allows shippers to match their contracted lanes with the best
            available carrier in their network, optimizing for both price and
            service.
          </Text>
          <Link href="https://rfpguide.com/?utm_campaign=rfp-guide-program&utm_source=smbshipping-loadsmart&utm_medium=cta-link">
            Learn more
          </Link>
        </Column>
      </ReversedCenteredWrapper>
    </SectionStyled>
  </>
);
