/* eslint-disable @next/next/no-img-element */
//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import { SideCTA, WideCalculator } from '/containers-m/';

// context containers
import {
  Hero,
  Logos,
  VideoHero,
  ImageCTA,
  Testimonial,
  ColumnFeatures,
  Newsroom,
  Form,
  FeaturedIcons,
} from './containers/';

//styles
import { PageWrapper } from './ManagedTransportation.styles';

const ManagedTransportation = () => {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <Hero />
        <VideoHero />
        <Logos />
        <WideCalculator />
        <SideCTA />
        <ImageCTA />
        <FeaturedIcons />
        <Testimonial />
        <ColumnFeatures />
        <Newsroom />
        <Form />
      </>
      <Footer />
    </PageWrapper>
  );
};

export default ManagedTransportation;
export { ManagedTransportation };
