import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const MissionBannerWrapper = styled.aside`
  width: 100%;
  min-height: 797px;
  background: rgb(${getToken('color-background-inverted')})
    url(/images/about/mission-loadsmart-sm.jpg) top center no-repeat;
  background-size: cover;
  box-sizing: content-box;

  ${screen.md} {
    min-height: 440px;
    background: rgb(${getToken('color-background-inverted')})
      url(/images/about/mission-loadsmart-md.jpg) center no-repeat;
    background-size: cover;
  }

  ${screen.lg} {
    min-height: 700px;
    background: rgb(${getToken('color-background-inverted')})
      url(/images/about/mission-loadsmart-lg.jpg) center no-repeat;
    background-size: cover;
  }

  ${screen.xxlg} {
    background: rgb(${getToken('color-background-inverted')})
      url(/images/about/mission-loadsmart-xxlg.jpg) center no-repeat;
    background-size: cover;
  }
`;

export const MissionBannerContainer = styled(Container)`
  display: grid;
  align-items: center;
  height: 100%;
  padding-top: 120%;

  ${screen.md} {
    padding-top: 0;
  }
`;

export const MissionBannerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto 0 40px;
  text-align: center;

  ${screen.md} {
    margin: auto 0;
    text-align: left;
    width: 327px;
  }

  ${screen.lg} {
    width: 670px;
  }
`;

export const StyledTitle = styled.h2`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 20px;
  line-height: 30px;
  margin: 0;

  ${screen.lg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const StyledDescription = styled.p`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-brand')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 26px;
  line-height: 39px;
  margin: 8px 0;

  ${screen.lg} {
    margin: 16px 0;
    font-size: 48px;
    line-height: 60px;
  }
`;
