import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { carrierOptions } from '@/vendor/HubspotFormIntegration';

export const HubspotFormSection = () => (
  <HubspotForm {...carrierOptions} theme="light">
    <HubspotFormTitle>
      Gain access to freight technology, fleet management services, and
      instantly bookable shipments
    </HubspotFormTitle>
    <HubspotFormDescription>
      Our team of industry experts and technicians are here to answer your
      questions.
    </HubspotFormDescription>
  </HubspotForm>
);
