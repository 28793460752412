/* eslint-disable @next/next/no-img-element */
//vendor
import ReactPlayer from 'react-player/lazy';

// components

import { MirandaSecondaryButton } from '@/components/Button/';

//logos
import { LogoOpendock } from './Logos';

// styles
import {
  SidePanelsWrapper,
  SectionTitle,
  SectionPanel,
  EvenSection,
  CustomContainer,
  Title,
  Content,
  Link,
  MobileHide,
  Picture,
  Wrapper,
  ReversedWrapper,
  VideoWrapper,
  LogoWrapper,
} from './SidePanels.styles';

export function SidePanels() {
  return (
    <SidePanelsWrapper>
      <CustomContainer>
        <SectionPanel>
          <VideoWrapper>
            <ReactPlayer
              className="react-player"
              url="//www.youtube.com/watch?v=K-YQJNejwDY?rel=0"
              controls
              width="100%"
              height="100%"
            />
          </VideoWrapper>
          <ReversedWrapper>
            <LogoWrapper>
              <LogoOpendock />
            </LogoWrapper>
            <Title>Dock Scheduling Software</Title>

            <Content>
              The leading dock appointment scheduling software focused on
              solving the problems of trucks waiting in the yard by allowing
              carriers and brokers to book dock appointments with warehouses
              online. Manage appointment availability, track workflow, and
              provide online scheduling to carriers in one place.
            </Content>
            <MobileHide>
              <MirandaSecondaryButton
                as="a"
                href={`${process.env.OPENDOCK_URL}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More About Opendock
              </MirandaSecondaryButton>
            </MobileHide>
            <Link
              href={`${process.env.OPENDOCK_URL}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More About Opendock
            </Link>
          </ReversedWrapper>
        </SectionPanel>
      </CustomContainer>
      <EvenSection>
        <CustomContainer>
          <SectionPanel direction="reverse">
            <Picture className="gif" direction="reverse">
              <source
                srcSet={`/images/home/features/navtrac.gif`}
                media="(min-width: 1920px)"
              />
              <source
                srcSet={`/images/home/features/navtrac.gif`}
                media="(min-width: 1024px)"
              />
              <source
                srcSet={`/images/home/features/navtrac.gif`}
                media="(min-width: 768px)"
              />
              <img
                src={`/images/home/features/navtrac.gif`}
                alt="Screenshot Shipper Guide"
                width="748"
                height="500"
              />
            </Picture>
            <Wrapper>
              <Title>Yard and asset visibility</Title>

              <Content>
                NavTrac’s gate and yard visibility tools increase your
                facility’s efficiency by eliminating manual gate check-ins and
                yard checks. Through cameras installed at your site, you can
                automatically track containers, tractors, and chassis, enabling
                real-time inventory reporting and damage detection. The platform
                also offers searchable dashboards, configurable notifications,
                and comprehensive analytics, enhancing the visibility and
                security of your facility, while also increasing accountability
                with your customers.
              </Content>

              <MobileHide>
                <MirandaSecondaryButton
                  as="a"
                  href="https://www.navtrac.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More
                </MirandaSecondaryButton>
              </MobileHide>
              <Link
                href="https://www.navtrac.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </Link>
            </Wrapper>
          </SectionPanel>
        </CustomContainer>
      </EvenSection>
      <CustomContainer>
        <SectionTitle>Logistics services with industry experts</SectionTitle>

        <SectionPanel>
          <Picture>
            <source
              srcSet={`/images/home/features/managed-transportation-xxlarge@2x.jpg`}
              media="(min-width: 1920px)"
            />
            <source
              srcSet={`/images/home/features/managed-transportation-large@2x.jpg`}
              media="(min-width: 1024px)"
            />
            <source
              srcSet={`/images/home/features/managed-transportation-medium@2x.jpg`}
              media="(min-width: 768px)"
            />
            <img
              src={`/images/home/features/managed-transportation-small@2x.jpg`}
              alt="Managed Transportation"
              width="677"
              height="520"
            />
          </Picture>
          <ReversedWrapper>
            <Title>Freight Brokerage</Title>
            <Content>
              Price, book, ship, and track with guaranteed capacity and
              dedicated service. Move FTL, LTL, PTL, Drayage, and Intermodal
              freight. Book instantly or secure long term contracts.
            </Content>
            <MobileHide>
              <MirandaSecondaryButton as="a" href="/shipper/">
                Learn More
              </MirandaSecondaryButton>
            </MobileHide>
            <Link href="/shipper/">Learn More</Link>
          </ReversedWrapper>
        </SectionPanel>
      </CustomContainer>
      <EvenSection>
        <CustomContainer>
          <SectionPanel direction="reverse">
            <Picture direction="reverse">
              <source
                srcSet={`/images/home/features/carrier-truck-xxlg@2x.jpg`}
                media="(min-width: 1920px)"
              />
              <source
                srcSet={`/images/home/features/carrier-truck-lg@2x.jpg`}
                media="(min-width: 1024px)"
              />
              <source
                srcSet={`/images/home/features/carrier-truck-md@2x.jpg`}
                media="(min-width: 768px)"
              />
              <img
                src={`/images/home/features/carrier-truck-sm@2x.jpg`}
                alt="Truck driver"
                width="677"
                height="520"
              />
            </Picture>
            <Wrapper>
              <Title>Carrier TMS</Title>
              <Content>
                Carrier TMS drives profitability through an intuitive turnkey
                truck management system that connects carriers to an agnostic
                marketplace of loads, services, factoring, and integrations.
              </Content>
              <MobileHide>
                <MirandaSecondaryButton
                  as="a"
                  href="/carrier/tms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Optimize your Fleet
                </MirandaSecondaryButton>
              </MobileHide>
              <Link
                href="/carrier/tms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Optimize your Fleet
              </Link>
            </Wrapper>
          </SectionPanel>
        </CustomContainer>
      </EvenSection>
      <CustomContainer>
        <SectionPanel>
          <Picture>
            <source
              srcSet={`/images/home/features/consulting-loadsmart-xxlg@2x.jpg`}
              media="(min-width: 1920px)"
            />
            <source
              srcSet={`/images/home/features/consulting-loadsmart-lg@2x.jpg`}
              media="(min-width: 1024px)"
            />
            <source
              srcSet={`/images/home/features/consulting-loadsmart-md@2x.jpg`}
              media="(min-width: 768px)"
            />
            <img
              src={`/images/home/features/consulting-loadsmart-sm@2x.jpg`}
              alt="Logistics consultor"
              width="677"
              height="520"
            />
          </Picture>
          <ReversedWrapper>
            <Title>Private Fleet Services</Title>
            <Content>
              Loadsmart’s Fleet Optimizer makes it easy for dedicated and
              private fleets to match unused capacity with available demand.
              Access millions of available loads that are customized to your
              business and won’t compromise your primary hauls.
            </Content>
            <MobileHide>
              <MirandaSecondaryButton
                as="a"
                href="https://lp.loadsmart.com/loadsmart-fleet-optimization"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </MirandaSecondaryButton>
            </MobileHide>
            <Link
              href="https://lp.loadsmart.com/loadsmart-fleet-optimization"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </Link>
          </ReversedWrapper>
        </SectionPanel>
      </CustomContainer>
    </SidePanelsWrapper>
  );
}
