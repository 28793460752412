/* eslint-disable @next/next/no-img-element */
import PropTypes from 'prop-types';
import classnames from 'classnames';

// styles
import {
  SlideWrapper,
  Title,
  SlidePanel,
  Content,
  Link,
  SystemImage,
} from './Slide.styles';

export function Slide({ property }) {
  return (
    <SlideWrapper
      className={classnames({
        active: property.active,
        inactive: !property.active,
      })}
    >
      <SlidePanel>
        <Title>{property.subtitle}</Title>
        <Content>{property.content}</Content>
        <Link
          href={property.link.url}
          color={property.color}
          target={property.external ? `_blank` : `_self`}
          rel={property.external ? `noreferrer noopener` : ``}
        >
          {property.link.label}
        </Link>
      </SlidePanel>
      {property?.image && (
        <SystemImage>
          <img
            src={`${property.image.src}`}
            alt={property.image.alt}
            width="786"
            height=""
          />
        </SystemImage>
      )}
      {property?.media && <SystemImage>{property.media}</SystemImage>}
    </SlideWrapper>
  );
}

Slide.propTypes = {
  property: PropTypes.shape({
    active: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.element,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    link: PropTypes.shape({
      external: PropTypes.bool,
      url: PropTypes.string,
      label: PropTypes.string,
    }),
    image: PropTypes.oneOfType([
      PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
      PropTypes.bool,
    ]),
    media: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  }).isRequired,
};
