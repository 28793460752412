import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const ShipWithUsWrapper = styled.section`
  padding: 80px 0;

  text-align: center;

  background-color: rgb(${getToken('color-background-primary')});

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const Title = styled.h2`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  margin: 0px;
  margin-bottom: 48px;

  font-size: 38px;
  line-height: 47px;
  text-align: center;

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

  ${screen.md} {
    flex-direction: row;
  }
`;
