import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';
import { LogoTitle } from './Content';

import GoogleSheetsLogo from '@/public/images/logos/logo-google_sheets.svg';

const GoogleSheetsWrapper = styled(FlexRowFullCentered)`
  width: 18px;
  height: 25px;

  ${screen.md} {
    width: 21px;
    height: 28px;
  }

  ${screen.lg} {
    width: 32px;
    height: 45px;
  }

  ${screen.xxlg} {
    width: 34px;
    height: 47px;
  }
`;

export const GoogleSheetsCard = (props) => (
  <CardLayout {...props}>
    <GoogleSheetsWrapper>
      <GoogleSheetsLogo />
    </GoogleSheetsWrapper>
    <LogoTitle>Google Sheets</LogoTitle>
  </CardLayout>
);
