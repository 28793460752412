import React from 'react';
// import Head from 'next/head';
import styled from 'styled-components';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

// sections
import {
  Hero,
  VideoHero,
  Carousel,
  BannerCTA,
  FeaturesNavBanner,
  TestimonialCarousel,
  Partners,
  TeamHero,
  FloatTeamHeroBox,
  HubspotFormSection,
  IntroductionText,
} from './containers';
import { MobileAppSection } from '@/containers/MobileAppSection';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;

  background-color: rgba(${getToken('color-background-tertiary')});
`;

const mobileApp = {
  title: 'The fastest and easiest way to find loads to keep your trucks full.',
  subtitle:
    'Find a load, post an empty truck, communicate with drivers, instantly upload photos and documents, and view all upcoming shipments.',
  variant: true,
};

const CarrierTMS = () => {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <Hero />
        <VideoHero />
        <IntroductionText />
        <Carousel />
        <MobileAppSection content={mobileApp} />
        <FeaturesNavBanner variant="external" />
        <Partners />
        <TestimonialCarousel />
        <TeamHero />
        <FloatTeamHeroBox />
        <HubspotFormSection />
      </>
      <Footer />
    </PageWrapper>
  );
};

export default CarrierTMS;
export { CarrierTMS };
