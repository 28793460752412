// components
import { HubspotForm } from '@/containers-m/HubspotForm';
import { HubspotFormDescription } from '@/containers-m/HubspotForm/HubspotForm.styles';
import { expeditedShippingOptions } from '@/vendor/HubspotFormIntegration';

// styles
import {
  FormBackground,
  ContainerForm,
  ContentHolder,
  FormHolder,
  Title,
  SubTitle,
} from './Form.styles';

export function Form() {
  return (
    <>
      <FormBackground id="hubspot-form">
        <ContainerForm>
          <ContentHolder>
            <Title>Accelerate Your Shipment to Success</Title>
            <SubTitle>
              Elevate your logistics with expedited shipping solutions designed
              for efficiency and speed. Take the first step toward optimized
              delivery by getting into contact with one of our experts.
            </SubTitle>
          </ContentHolder>
          <FormHolder>
            <HubspotForm {...expeditedShippingOptions}>
              <HubspotFormDescription>
                To begin, tell us a bit about yourself.
              </HubspotFormDescription>
            </HubspotForm>
          </FormHolder>
        </ContainerForm>
      </FormBackground>
    </>
  );
}
