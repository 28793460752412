import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { SectionPanel } from '@/components/Grid/Panel';
import {
  Wrapper as ContentWrapper,
  WideImageOnMobile,
  Title,
  Description,
  Text,
  CenteredWrapper,
} from '@/components/Grid/Content/';
import { FlexColumnFullCentered } from '@/components/Grid/Flex';

//assets
import SmartTrackingLogo from '@/public/images/logos/logo-smart_tracking.svg';
import SmartSchedulingLogo from '@/public/images/logos/logo-smart_scheduling.svg';

const LogoWrapper = styled.div`
  height: 46px;
  margin-bottom: 18px;

  color: #6c8694;

  ${screen.md} {
    height: 52px;
  }

  ${screen.lg} {
    height: 72px;
  }

  ${screen.xxlg} {
    height: 84px;
    margin-bottom: 37px;
  }
`;

const ResponsiveSmartTrackingLogo = styled(SmartTrackingLogo)`
  width: auto;
  height: 100%;
`;

const ResponsiveSmartSchedulingLogo = styled(SmartSchedulingLogo)`
  width: auto;
  height: 100%;
`;

const FloatResponsiveImage = styled.img`
  width: auto;
  max-height: 200px;

  ${screen.md} {
    align-self: flex-start;
    width: 459px;
    max-height: initial;
  }

  ${screen.lg} {
    width: 621px;
  }

  ${screen.xxlg} {
    width: 864px;
  }
`;

const LeftContentWrapper = styled(ContentWrapper)`
  padding-left: 0 !important;
`;

const ImageWrapper = styled(FlexColumnFullCentered)`
  ${screen.lg} {
    justify-content: flex-start;
  }
`;

export const PanelsSection = () => (
  <>
    <SectionPanel>
      <ImageWrapper>
        <WideImageOnMobile
          src={`/images/ftl/half_section-equipments.png`}
          alt="Loadsmart supports Dry, Reefer, and Flatbed"
        />
      </ImageWrapper>
      <CenteredWrapper>
        <Title>Dry, Reefer, and Flatbed</Title>
        <Description>
          Access the trucks you need, when you need them
        </Description>
        <Text>
          Ship commodities with unique dimensions and requirements. Loadsmart’s
          instant booking and guaranteed tender acceptance extend to dry,
          reefer, and flatbed equipment.
        </Text>
      </CenteredWrapper>
    </SectionPanel>

    <SectionPanel reverse>
      <ImageWrapper>
        <FloatResponsiveImage
          src={`/images/ftl/half_section-track.png`}
          alt="Loadsmart tracking"
        />
      </ImageWrapper>

      <LeftContentWrapper>
        <LogoWrapper>
          <ResponsiveSmartTrackingLogo />
        </LogoWrapper>
        <Description>
          Track the real-time location of your shipments from pickup through
          delivery
        </Description>
        <Text>
          Easily monitor the real-time location of your shipments using our
          web-based platform Transit or integrate your TMS with Loadsmart Link
          and track loads from your native system. A variety of technologies,
          including smart apps and GPS triangulation, as well as partnerships
          with leading ELD providers, ensure you always know the location of
          your shipments and can avoid potential service disruptions before they
          happen.
        </Text>
      </LeftContentWrapper>
    </SectionPanel>

    <SectionPanel>
      <ImageWrapper>
        <WideImageOnMobile
          src={`/images/ftl/half_section-improve.png`}
          alt="Loadsmart improve"
        />
      </ImageWrapper>
      <ContentWrapper>
        <LogoWrapper>
          <ResponsiveSmartSchedulingLogo />
        </LogoWrapper>
        <Description>
          Improve on-time service by automatically selecting and confirming the
          best available appointment time
        </Description>
        <Text>
          After a shipment has been tendered, our Smart Scheduling technology
          automatically selects the best available appointment by analyzing
          estimated transit time and other factors. That appointment can then be
          confirmed either instantly via integration with the facility’s dock
          management system or via an automated email flow — removing the need
          for phone and email tag. Should a driver’s ETA change, appointments
          can be rebooked instantly.
        </Text>
      </ContentWrapper>
    </SectionPanel>
  </>
);
