import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const FeaturedSection = styled.section`
  background: rgb(${getToken('color-background-primary')});
  padding: 50px 0;

  ${screen.md} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const HeaderWrapper = styled.header`
  text-wrap: balance;
  margin: 0 0 40px;
`;

export const ImageWrapper = styled.div`
  text-align: center;

  img {
    width: 100%;
    height: auto;
    margin: auto;

    ${screen.md} {
      max-width: 670px;
    }
    ${screen.lg} {
      max-width: 962px;
    }
    ${screen.xxlg} {
      max-width: 1286px;
    }
  }
`;

export const Footer = styled.div`
  margin: 40px 0;

  p {
    font-size: 12px;
    line-height: 18px;
    text-align: left;

    ${screen.lg} {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;
