import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container } from '@/components/Grid/Container';

export const Layout = styled(Container)`
  padding: 100px 0 40px;

  ${screen.md} {
    padding: 200px 0 80px;
  }
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  align-items: center;
  color: #000000;
  margin-bottom: 40px;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    margin-bottom: 59px;
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin-bottom: 91px;
    font-size: 48px;
    line-height: 72px;
  }
`;

export const TitleH2 = styled.h2`
  margin: 40px 0;

  font-weight: 500;
  font-size: 22px;
  line-height: 24px;

  ${screen.md} {
    margin: 40px 0;

    font-size: 28px;
    line-height: 32px;
  }

  ${screen.lg} {
    margin: 40px 0;

    font-size: 38px;
    line-height: 42px;
  }

  ${screen.xxlg} {
    margin: 40px 0;

    font-size: 42px;
    line-height: 48px;
  }
`;

export const TitleH3 = styled.h3`
  margin: 30px 0;

  font-size: 18px;
  line-height: 22px;
  text-decoration: underline;

  ${screen.md} {
    margin: 30px 0;

    font-size: 22px;
    line-height: 32px;
  }

  ${screen.lg} {
    margin: 30px 0;

    font-size: 28px;
    line-height: 32px;
  }

  ${screen.xxlg} {
    margin: 30px 0;

    font-size: 32px;
    line-height: 48px;
  }
`;

export const TitleH4 = styled.h4`
  margin: 20px 0;

  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;

  ${screen.md} {
    margin: 20px 0;

    font-size: 18px;
    line-height: 22px;
  }

  ${screen.lg} {
    margin: 20px 0;

    font-size: 22px;
    line-height: 38px;
  }

  ${screen.xxlg} {
    margin: 20px 0;

    font-size: 28px;
    line-height: 32px;
  }
`;

export const Link = styled.a`
  color: inherit;
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
`;

export const BulltetList = styled.ul`
  padding-left: 20px;

  list-style: none;

  li {
    margin: 10px 0;
  }
`;

export const Paragraph = styled.p`
  margin: 20px 0;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;

  ${screen.md} {
    font-size: 14px;
    line-height: 22px;
  }

  ${screen.lg} {
    font-size: 17px;
    line-height: 25px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 28px;
  }
`;
