import PropTypes from 'prop-types';
import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container, Section } from '@/components/Grid';
import { Wrapper, Text } from '@/components/Grid/Content/';
import { SectionPanel } from '@/components/Grid/Panel';
import { Title } from '@/components/Grid/Content/';
import { Button } from '@/components/Button';

const SectionStyled = styled(Section)`
  background-color: ${(props) =>
    props.background === 'dark' ? 'rgb(211, 217, 217)' : 'transparent'};
  padding: 80px;
  margin: 0 auto;
`;

const SectionPanelStyled = styled(SectionPanel)`
  ${screen.md} {
    & > div > div {
      flex-direction: ${(props) =>
        props.direction !== 'reverse' ? 'row' : 'row-reverse'};

      * {
        margin-left: 0;
      }
    }
  }
`;

const SubTitle = styled.h2`
  margin-bottom: 15px;
  padding-top: 25px;

  font-size: 16px;
  line-height: 22px;

  ${screen.md} {
    padding-top: 0;

    line-height: 26px;
  }

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 42px;
  }
`;

const BenefitItemText = styled.span`
  display: inline-block;
  max-width: calc(100% - 70px);
  vertical-align: top;

  ${screen.md} {
    padding-top: 10px;
    padding-left: 15px;
  }

  ${screen.lg} {
    max-width: calc(100% - 95px);
    padding-left: 25px;
  }
`;

const BenefitList = styled.ul`
  padding: 10px;
`;

const BenefitItem = styled.li`
  display: block;
  margin-bottom: 15px;

  font-size: 12px;
  line-height: 22px;
  text-align: center;

  a {
    font-weight: normal;
    font-size: inherit;
  }

  ${screen.md} {
    text-align: left;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
    text-align: left;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
  }

  svg {
    display: block;

    max-width: 48px;
    max-height: 48px;
    margin: 0 auto;

    color: #6c8694;

    ${screen.md} {
      display: inline-block;
      margin: auto;

      vertical-align: middle;
    }

    ${screen.lg} {
      max-width: unset;
      max-height: unset;
    }
  }
`;

const StyledButton = styled(Button)`
  ${screen.md} {
    margin-left: 75px;
  }
`;

const ImageWrapper = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  align-self: center;

  img,
  div {
    margin: auto;
  }
`;

export const ContentSection = ({ content, background, direction, cta }) => (
  <>
    <SectionStyled background={background}>
      <Container>
        {content?.title && <Title>{content.title}</Title>}
        {content?.introduction && <Text>{content.introduction}</Text>}
      </Container>

      <SectionPanelStyled direction={direction}>
        {content?.media && <ImageWrapper>{content.media}</ImageWrapper>}
        <div>
          <Wrapper>
            <SubTitle>Benefits</SubTitle>
            <BenefitList>
              {content?.benefits &&
                content.benefits.map((item, key) => {
                  return (
                    <BenefitItem key={`content-benefits-${key}`}>
                      {item.icon}
                      <BenefitItemText>{item.text}</BenefitItemText>
                    </BenefitItem>
                  );
                })}
            </BenefitList>
            {cta ? (
              <StyledButton
                as="a"
                href={cta.url}
                target={cta.external ? `_blank` : `_self`}
                rel={cta.external ? `noreferrer noopener` : ``}
              >
                {cta.title}
              </StyledButton>
            ) : (
              <StyledButton as="a" href="#hubspot-form">
                LEARN MORE
              </StyledButton>
            )}
          </Wrapper>
        </div>
      </SectionPanelStyled>
    </SectionStyled>
  </>
);

ContentSection.defaultProps = {
  background: 'light',
  direction: 'default',
  cta: null,
};

ContentSection.propTypes = {
  background: PropTypes.string,
  direction: PropTypes.string,
  cta: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  content: PropTypes.shape({
    title: PropTypes.string,
    introduction: PropTypes.string,
    media: PropTypes.element,
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.element,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      })
    ),
  }).isRequired,
};
