/* eslint-disable @next/next/no-img-element */
import React from 'react';

//styles
import {
  MidBannerWrapper,
  ImageWrapper,
  StyledButton,
  ContentWrapper,
  StyledTitle,
} from './MidBanner.styles';

export default function MidBanner() {
  return (
    <>
      <MidBannerWrapper>
        <ImageWrapper>
          <source
            srcSet={`/images/carrier-tms/features/midbanner/mid-banner-img.png`}
            media="(min-width: 1920px)"
          />
          <source
            srcSet={`/images/carrier-tms/features/midbanner/mid-banner-img-lg.png`}
            media="(min-width: 1024px)"
          />
          <source
            srcSet={`/images/carrier-tms/features/midbanner/mid-banner-img-md.png`}
            media="(min-width: 768px)"
          />
          <img
            src={`/images/carrier-tms/features/midbanner/mid-banner-img.png`}
            alt="Truck driver image"
            width="700"
            height="555"
          />
        </ImageWrapper>
        <ContentWrapper>
          <StyledTitle>
            Easily manage day-to-day operations with the cloud-based truck
            management system you need
          </StyledTitle>
          <StyledButton as="a" href="#hubspot-form">
            Start for free
          </StyledButton>
        </ContentWrapper>
      </MidBannerWrapper>
    </>
  );
}

export { MidBanner };
