import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const DefinitionListWrapper = styled.dl`
  padding: 0;
  margin: 0;

  * {
    text-align: left;
  }
`;

export const DefinitionBlock = styled.dl`
  position: relative;
  margin: 36px 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(${getToken('color-primary-60')});
    border-radius: 3px;

    width: 4px;
    height: 100%;

    ${screen.lg} {
      width: 6px;
    }
  }

  ${screen.xxlg} {
    margin: 48px 0;
  }
`;

export const DefinitionTitle = styled.dt`
  padding: 0.1px 10px 0 25px;
`;
export const DefinitionDescription = styled.dd`
  padding: 0 10px 5px 25px;
  margin: 0;

  p {
    font-size: 14px;
    line-height: 21px;

    ${screen.xxlg} {
      font-size: 20px;
      line-height: 36px;
    }
  }
`;
