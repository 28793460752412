import styled from 'styled-components';

import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SlideWrapper = styled.div`
  position: relative;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  min-width: 263px;
  margin-inline: 5px;

  ${screen.md} {
    margin-inline: 8px;

    min-width: 634px;
  }

  ${screen.lg} {
    margin-inline: 40px;

    min-width: 904px;
  }

  ${screen.xxlg} {
    min-width: 1353px;
  }
`;

export const SlidePanel = styled.article`
  width: 100%;

  text-align: center;
`;

export const Content = styled.div`
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};
  margin: 16px 0 0;
  font-size: 12px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    margin: 28px 0 0;
  }

  ${screen.lg} {
    margin: 36px 0 0;

    font-size: 16px;
    line-height: 30px;
  }
  ${screen.xxlg} {
    width: 100%;
    margin: 42px 0 0;

    font-size: 26px;
    line-height: 38px;
  }
`;

export const Title = styled.h2`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 16px;
  line-height: 22px;
  margin: 0px;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
  }
  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }
  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;
