import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import Lottie from 'react-lottie';

//assets
import animationData from '@/datafiles/lottie/link/animation.json';

// icons
import {
  IconPackageTracking,
  IconStockOptions,
  IconTenderAcceptance,
  IconImproveEfficiency,
  IconOpportunity,
  IconTMSReady,
  IconTailoredLoads,
  IconArrowsExpandSide,
} from '@loadsmart/icons';

// components
import { Container } from '@/components/Grid';

// styles
import {
  FeaturesCarouselWrapper,
  Title,
  LogosNavigation,
  LogoItem,
  BulletNavigation,
  BulletItem,
  SlidesList,
  SlideContainer,
  NavCarousel,
  NavCarouselTrail,
  NextArrow,
  PrevArrow,
} from './FeaturesCarousel.styles';

// slides
import { Slide } from './Slide';

const features = [
  {
    active: true,
    icon: <IconPackageTracking width="66" />,
    title: 'Instant Capacity',
    subtitle: <>Guaranteed freight capacity with one click</>,
    content: (
      <>
        <p>
          Move a load in any market condition. Get an instantly bookable quote
          backed by 100% capacity guarantee.
        </p>
        <ul>
          <li>No price changes or load givebacks</li>
          <li>Available for FTL, LTL, and Intermodal</li>
          <li>Easy to access online or in your TMS</li>
        </ul>
      </>
    ),
    link: {
      external: false,
      url: `${process.env.SHIPPERGUIDE_URL}/signup`,
      label: `Get a quote online now`,
    },
    image: {
      src: `/images/shipper/carousel/item-01.jpg`,
      alt: 'Item 01',
    },
    media: false,
  },
  {
    active: false,
    icon: <IconOpportunity width="66" />,
    title: 'Best Service and Price',
    subtitle: <>Logistics experts at your fingertips</>,
    content: (
      <>
        <p>
          We service and support your supply chains through best-in-class
          logistics professionals and dedicated teams that are obsessed with
          finding you the optimal mix of service, quality and price.
        </p>
        <ul>
          <li>24/7 account support</li>
          <li>Operational management from scheduling to delivery</li>
          <li>Real-time market insights, customized to your business</li>
        </ul>
      </>
    ),
    link: {
      external: false,
      url: '#hubspot-form',
      label: `Talk to a specialist`,
    },
    image: {
      src: `/images/shipper/carousel/item-02.jpg`,
      alt: 'Item 02',
    },
    media: false,
  },
  {
    active: false,
    icon: <IconTenderAcceptance width="66" />,
    title: 'Carrier Network',
    subtitle: <>World-class Carrier Network you can trust</>,
    content: (
      <>
        <p>
          Expand your carrier network with instant access to Loadsmart’s vetted
          and trusted carriers.
        </p>
        <ul>
          <li>46k Carriers | 750k+ Trucks | National Footprint</li>
          <li>Customized carrier sales support </li>
          <li>100% visibility and real-time location tracking </li>
        </ul>
      </>
    ),
    link: {
      external: false,
      url: '#hubspot-form',
      label: `Talk to a specialist`,
    },
    image: {
      src: `/images/shipper/carousel/item-03.jpg`,
      alt: 'Item 03',
    },
    media: false,
  },
  {
    active: false,
    icon: <IconImproveEfficiency width="66" />,
    title: 'Operational Efficiency',
    subtitle: <>Simplify your supply chain operations</>,
    content: (
      <>
        <p>
          Say goodbye to emails, phone calls, and spreadsheets in your supply
          chain. Get modern day tools and service for freight procurement and
          dock scheduling.
        </p>
        <ul>
          <li>Easy to use, no training required </li>
          <li>Easy to integrate, no IT required </li>
          <li>Maximize ROI, reduce operational costs</li>
        </ul>
      </>
    ),
    link: {
      external: false,
      url: '#hubspot-form',
      label: `Learn more about our supply chain software`,
    },
    image: {
      src: `/images/shipper/carousel/item-04.jpg`,
      alt: 'Item 04',
    },
    media: false,
  },
  {
    active: false,
    icon: <IconTailoredLoads width="66" />,
    title: 'Centralized Freight Process',
    subtitle: <>Freight management made simple </>,
    content: (
      <>
        <p>
          Stop losing important data and time with decentralized and manual
          processes. With one platform and dedicated provider, it’s easy to
          control spend, manage risk and improve efficiency.
        </p>
        <ul>
          <li>Your logistics operations - all in one place.</li>
          <li>
            Single, centralized, platform providing full network visibility.
          </li>
          <li>
            Managed transportation with a distinct service that optimizes and
            executes your networks.
          </li>
        </ul>
      </>
    ),
    link: {
      external: false,
      url: '/shipper/shipperguide/',
      label: `Learn more`,
    },
    image: {
      src: `/images/shipper/carousel/item-07.jpg`,
      alt: 'Item 07',
    },
    media: false,
  },
  {
    active: false,
    icon: <IconTMSReady width="66" />,
    title: 'Data Driven Transportation Planning',
    subtitle: <>Real-time freight data</>,
    content: (
      <>
        <p>
          Relying on static data and historical rates can leave you exposed to
          the market. Make data-informed freight decisions with access to
          real-time insights.
        </p>
        <ul>
          <li>Select carriers based on real-time performance</li>
          <li>Benchmark your rates with accurate data </li>
          <li>Optimize your network with customized insights</li>
        </ul>
      </>
    ),
    link: {
      external: false,
      url: '#hubspot-form',
      label: `Learn more`,
    },
    image: {
      src: `/images/shipper/carousel/item-06.jpg`,
      alt: 'Item 06',
    },
    media: false,
  },
  {
    active: false,
    icon: <IconStockOptions width="66" />,
    title: 'Dynamic Pricing',
    subtitle: <>Guaranteed service and automated pricing</>,
    content: (
      <>
        <p>
          Automate how spot and contracted freight is priced, booked, and
          shipped within your TMS. Shippers using Dynamic Pricing can simplify
          rate discovery, add guaranteed waterfall protection, and customize how
          tender decisions are made.
        </p>
        <ul>
          <li>100% tender acceptance, no tender rejection.</li>
          <li>Use real-time rates to benchmark your contracted rates.</li>
          <li>Auto-tender with customized price controls.</li>
        </ul>
      </>
    ),
    link: {
      external: false,
      url: 'https://loadsmart.com/link/',
      label: `Learn more about dynamic solutions`,
    },
    image: false,
    media: (
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
        }}
      />
    ),
  },
];

let timeout = null;
const interval = 10000;

export function FeaturesCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);

  const clearTimer = () => {
    window.clearInterval(timeout);
  };

  const nextSlide = useCallback(() => {
    return (activeIndex) => (activeIndex + 1) % features.length;
  }, []);

  const prevSlide = useCallback(() => {
    return (activeIndex) =>
      (activeIndex - 1 + features.length) % features.length;
  }, []);

  const updateActive = useCallback(
    (current) => {
      if (current !== activeIndex) {
        clearTimer();
        setActiveIndex(current);
        timeout = window.setInterval(() => {
          setActiveIndex(nextSlide());
        }, interval);
      }
      return false;
    },
    [activeIndex, nextSlide]
  );

  useEffect(() => {
    timeout = window.setInterval(() => {
      setActiveIndex(nextSlide());
    }, interval);
    return () => {
      clearTimer();
    };
  }, [nextSlide]);

  return (
    <FeaturesCarouselWrapper>
      <Container>
        <Title>Discover the benefits of digital shipping services</Title>
        <NavCarousel>
          <PrevArrow onClick={() => updateActive(prevSlide())}>
            <IconArrowsExpandSide width={32} />
          </PrevArrow>
          <NavCarouselTrail>
            <LogosNavigation
              items={features.length}
              style={
                activeIndex > 2
                  ? { marginLeft: (activeIndex - 2) * -77 + 'px' }
                  : {}
              }
            >
              {features.map((item, key) => {
                const indexkey = `logo-${key}`;
                const isvisible = key === activeIndex;
                return (
                  <LogoItem
                    key={indexkey}
                    className={classnames({
                      active: isvisible,
                      inactive: !isvisible,
                    })}
                    onClick={() => {
                      updateActive(key);
                    }}
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </LogoItem>
                );
              })}
            </LogosNavigation>
          </NavCarouselTrail>
          <NextArrow onClick={() => updateActive(nextSlide())}>
            <IconArrowsExpandSide width={32} />
          </NextArrow>
        </NavCarousel>

        {/* slider content */}
        <SlidesList>
          {features.map((item, key) => {
            const indexkey = `slide-${key}`;
            const isvisible = key === activeIndex;
            return (
              <SlideContainer
                key={indexkey}
                className={classnames({
                  active: isvisible,
                  inactive: !isvisible,
                })}
              >
                <Slide property={item} />
              </SlideContainer>
            );
          })}
        </SlidesList>
        {/* navigation: bullets */}
        <BulletNavigation>
          {features.map((item, key) => {
            const indexkey = `bullet-${key}`;
            const isvisible = key === activeIndex;
            return (
              <BulletItem
                key={indexkey}
                className={classnames({
                  active: isvisible,
                  inactive: !isvisible,
                })}
                onClick={() => {
                  updateActive(key);
                }}
                aria-label={`${key} slide`}
              >
                <span aria-hidden="true">{key}</span>
              </BulletItem>
            );
          })}
        </BulletNavigation>
      </Container>
    </FeaturesCarouselWrapper>
  );
}
