import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import {
  Title,
  Text,
  Link as BaseLink,
  ResponsiveImage,
} from '@/components/Grid/Content';
import { Container } from '@/components/Grid/Container';
import { Section } from '@/components/Grid/Section';
import { FlexRowFullCentered, FlexRowWrap } from '@/components/Grid/Flex';

import { DarkSlider, Slide, SliderImage } from '@/containers/Slider';

// logos
import BlueYonderLogo from '@/public/images/logos/logo-blue_yonder.svg';
import E2OpenLogo from '@/public/images/logos/logo-e2open.svg';
import MercuryGateLogo from '@/public/images/logos/logo-mercury_gate.svg';
import OracleLogo from '@/public/images/logos/logo-oracle.svg';

export const Link = styled(BaseLink)`
  ${FlexRowFullCentered};
  ${screen.md} {
    justify-content: start;
  }
`;

export const CarrousselLink = styled(BaseLink)`
  margin-top: 12px;
`;

export const ImageWrapper = styled.a`
  box-sizing: border-box;
  width: 100%;
  padding: 30px 53px;

  color: #010d2c;
  text-align: center;

  & > .resized {
    width: 85%;
  }

  ${screen.md} {
    width: 25%;
    padding: 30px 17px;

    text-align: left;
  }

  ${screen.lg} {
    width: 25%;
    padding: 30px 25px;
  }

  ${screen.xxlg} {
    width: 25%;
    padding: 30px 35px;
  }
`;

const TMSLogoWrapper = styled(FlexRowWrap)`
  align-items: center;
  justify-content: center;

  margin: 40px 0;

  ${screen.md} {
    margin: 10px 0;
  }

  ${screen.lg} {
    margin: 26px 0;
  }

  ${screen.xxlg} {
    margin: 46px 0;
  }
`;

export const TMSSection = () => (
  <section>
    <Section as="div">
      <Container>
        <Title>Get more from your Transportation Management System </Title>
        <Text>
          Periodically optimizing routing guides using only static, contracted
          rates without market correlation is a common yet outdated approach.
          Avoid high tender rejections, service delays, and the spot market when
          capacity is tight and avoid paying premiums when the market is soft.
        </Text>
        <Text>
          Move freight at a fair price from right within the system you live in
          every day with a dynamic, real-time, instantly bookable price.
        </Text>
      </Container>
    </Section>

    <Section as="div">
      <Container>
        <Link href="/link/">Learn more about TMS Integrations</Link>

        <TMSLogoWrapper>
          <ImageWrapper href="/integrations/blue-yonder/">
            <BlueYonderLogo />
          </ImageWrapper>
          <ImageWrapper href="/integrations/e2open/">
            <E2OpenLogo />
          </ImageWrapper>
          <ImageWrapper href="/integrations/mercury-gate/">
            <MercuryGateLogo />
          </ImageWrapper>
          <ImageWrapper href="/integrations/oracle/">
            <OracleLogo className="resized" />
          </ImageWrapper>
        </TMSLogoWrapper>

        <DarkSlider slidesPerPage={2} slidesPerPageOnMobile={1}>
          <Slide>
            <SliderImage>
              <ResponsiveImage
                src={`/images/enterprise-shipping/slides/tms_section-oracle.png`}
                alt="Oracle partner"
                width="620"
                height="324"
              />
            </SliderImage>
            <Text>
              In a volatile freight market, shippers see tender rejections
              higher than 25%. Gain an edge in your TMS and prepare...
            </Text>
            <CarrousselLink
              href="https://success.loadsmart.com/oracle-tms-on-demand-webinar?utm_source=masterpage&utm_medium=carrouselwebinar&utm_campaign=2021-enterprise-shipper-program&utm_content=oracle"
              target="_blank"
            >
              Watch Now
            </CarrousselLink>
          </Slide>
          <Slide>
            <SliderImage>
              <ResponsiveImage
                src={`/images/enterprise-shipping/slides/tms_section-mercury_gate.png`}
                alt="Mercury Gate partner"
                width="620"
                height="324"
              />
            </SliderImage>
            <Text>
              When one capacity crunch ends, another is on the horizon.
              Thankfully, it’s never been easier to move with the market...
            </Text>
            <CarrousselLink
              href="https://success.loadsmart.com/mercurygate-on-demand-webinar-capacity-crunch?utm_source=masterpage&utm_medium=carrouselwebinar&utm_campaign=2021-enterprise-shipper-program&utm_content=mercurygate"
              target="_blank"
            >
              Watch Now
            </CarrousselLink>
          </Slide>
          <Slide>
            <SliderImage>
              <ResponsiveImage
                src={`/images/enterprise-shipping/slides/tms_section-blue_yonder.png`}
                alt="Blue Yonder partner"
                width="620"
                height="324"
              />
            </SliderImage>
            <Text>
              Transportation is becoming increasingly efficient as shippers
              embrace digital transformation...
            </Text>
            <CarrousselLink
              href="https://success.loadsmart.com/blueyonder-tms-on-demand-webinar?utm_source=masterpage&utm_medium=carrouselwebinar&utm_campaign=2021-enterprise-shipper-program&utm_content=blueyonder"
              target="_blank"
            >
              Watch Now
            </CarrousselLink>
          </Slide>
          <Slide>
            <SliderImage>
              <ResponsiveImage
                src={`/images/enterprise-shipping/slides/tms_section-blujay.png`}
                alt="Blujay partner"
                width="620"
                height="324"
              />
            </SliderImage>
            <Text>
              Learn how {"Loadsmart's"} integration with BluJay delivers greater
              transportation efficiency and visibility.
            </Text>
            <CarrousselLink
              href="https://success.loadsmart.com/blujay-on-demand-webinar?utm_source=masterpage&utm_medium=carrouselwebinar&utm_campaign=2021-enterprise-shipper-program&utm_content=blujay"
              target="_blank"
            >
              Watch Now
            </CarrousselLink>
          </Slide>
        </DarkSlider>
      </Container>
    </Section>
  </section>
);
