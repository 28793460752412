import PropTypes from 'prop-types';

//components
import { MirandaGradientButton } from 'components/Button';

//styles
import {
  HeroWrapper,
  ContainerStyled,
  ChildrenWrapper,
  Logo,
  Title,
  Subtitle,
} from './Hero.styles';

export default function Hero({ children, content, gradient }) {
  return (
    <HeroWrapper>
      <ContainerStyled>
        {content.logo && <Logo>{content.logo}</Logo>}
        <Title>{content.title}</Title>
        {content.subtitle && <Subtitle as="p">{content.subtitle}</Subtitle>}
        <MirandaGradientButton
          as="a"
          href={content.button.url}
          className="noborder"
        >
          {content.button.text}
        </MirandaGradientButton>
      </ContainerStyled>
      {children && (
        <ChildrenWrapper gradient={gradient}>{children}</ChildrenWrapper>
      )}
    </HeroWrapper>
  );
}

Hero.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    logo: PropTypes.node,
    image: PropTypes.shape({
      small: PropTypes.string,
      medium: PropTypes.string,
      large: PropTypes.string,
      xxlarge: PropTypes.string,
    }),
    button: PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.node.isRequired,
      variant: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

Hero.defaultProps = {
  content: {
    subtitle: null,
    logo: null,
    image: null,
    gradient: null,
  },
};

export { Hero };
