import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';

export const ContainerStyled = styled(Container)`
  padding: 0;
  position: relative;

  @media (max-width: calc( ${(props) =>
      props.theme.breakpoint.medium} - 1px )) {
    width: 100%;
    max-width: calc(100% - 40px);
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  position: relative;
  align-content: center;
  justify-content: center;
  width: 75%;
  height: 100%;
  margin: auto;
`;

export const VideoContainer = styled.div`
  display: block;
  padding: 20px;
  width: 100%;
  position: relative;
  padding-top: 48.45%; /* Player ratio: 100 / (1280 / 720) */
  background: ${(props) => props.theme.color.neutral.darkest};
  box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);
  margin: 24px auto -40px;

  ${screen.md} {
    margin: 40px auto -130px;
  }
  ${screen.lg} {
    margin: 50px auto -200px;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
