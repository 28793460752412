import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { FeatureTitle, FeatureDescription } from '@/components/Feature/Content';
import { Feature, FeatureGroup } from '@/components/Feature';

export const StyledFeatureGroup = styled(FeatureGroup)`
  ${screen.md} {
    margin: 0 -7px;
  }

  ${screen.lg} {
    margin: 0 -8px;
  }

  ${screen.xxlg} {
    margin: 0 -10px;
  }
`;

export const StyledFeature = styled(Feature)`
  width: 255px;
  margin: 13px 0;

  ${screen.md} {
    width: 158px;
    margin: 0 7px;
  }

  ${screen.lg} {
    width: 230px;
    margin: 0 8px;
  }

  ${screen.xxlg} {
    width: 325px;
    margin: 0 10px;
  }
`;

export const PreventBreak = styled.span`
  white-space: nowrap;
`;

export const StyledTitle = styled(FeatureTitle)`
  ${screen.md} {
    max-width: 100px;
  }

  ${screen.lg} {
    max-width: 100%;
  }
`;

export const StyledFeatureDescription = styled(FeatureDescription)`
  ${screen.md} {
    max-width: 152px;
  }

  ${screen.lg} {
    max-width: 180px;
  }

  ${screen.xxlg} {
    max-width: 100%;
  }
`;
