import styled from 'styled-components';

import { screen } from '@/helpers/screen';

// components
import { Container } from 'components/Grid';

export const SlidePanel = styled(Container)`
  width: 100%;

  text-align: center;
`;

export const SystemImage = styled.figure`
  width: 100%;
  height: auto;
  margin: 0;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
    margin: auto;

    ${screen.md} {
      max-width: 85%;
    }

    ${screen.lg} {
      max-width: 90%;
    }
  }
`;
