import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const HeroWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 549px;

  text-align: center;

  background-color: rgb(${getToken('color-background-inverted')});
  background-image: url(/${(props) => props.bg.small});
  background-position: top center;
  background-size: cover;

  position: relative;

  ${screen.md} {
    min-height: 432px;

    background-image: url(/${(props) => props.bg.medium});
  }

  ${screen.lg} {
    min-height: 555px;

    background-image: url(/${(props) => props.bg.large});
  }

  ${screen.xxlg} {
    background-image: url(/${(props) => props.bg.xxlarge});
  }
`;

export const Title = styled.h1`
  color: rgb(${getToken('color-text-inverted')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 24px auto;

  font-size: 26px;
  line-height: 39px;

  ${screen.md} {
    font-size: 38px;
    line-height: 47.5px;
    width: 550px;
  }

  ${screen.lg} {
    font-size: 48px;
    line-height: 60px;
    width: 840px;
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72.5px;
  }
`;

export const ButtonWrapper = styled.div``;
