/* eslint-disable @next/next/no-img-element */
import React from 'react';

//components
import { Container } from '@/components/Grid';
import { Title, Text } from '@/components/Grid/Content';
import { Panel } from '@/components/Grid/Panel';

//context components
import {
  GreySection,
  Column,
  Link,
  ChartWrapper,
  SubTitleStyled,
  ResponsiveImage,
} from './FreightDataReportSection.styles';

export const FreightDataReportSection = () => (
  <GreySection>
    <Container>
      <Panel>
        <Column>
          <Title>
            Freight Data <br />
            Insights Report
          </Title>
          <Text>
            Shippers operate most efficiently when they’re able to learn from
            not just their own data and insights — but from each other.
          </Text>
          <Text>
            Loadsmart’s Freight Data Insights Report analyzes millions of quotes
            and hundreds of thousands of shipments to deliver industry-specific
            insights. Take a look back to look ahead.
          </Text>
          <Link
            href="https://lp.loadsmart.com/en/data-driven-optimization-reports-for-shippers?utm_source=loadsmart&utm_medium=hyperlink&utm_content=freight-data-insights-section&utm_campaign="
            target="_blank"
          >
            Download the Report
          </Link>
        </Column>
        <ChartWrapper>
          <SubTitleStyled>
            Average lead time <br />
            from quote to pick-up
          </SubTitleStyled>
          <ResponsiveImage>
            <source
              srcSet={`/images/charts/freight-data-xl.svg`}
              media="(min-width: 1920px)"
            />
            <source
              srcSet={`/images/charts/freight-data-l.svg`}
              media="(min-width: 1024px)"
            />
            <source
              srcSet={`/images/charts/freight-data-m.svg`}
              media="(min-width: 768px)"
            />
            <img
              src={`/images/charts/freight-data-s.svg`}
              alt="Retail – 7.2 Days, Agriculture – 1.7 Days, Average – 6.3 Days"
              width="636"
              height="403"
            />
          </ResponsiveImage>
        </ChartWrapper>
      </Panel>
    </Container>
  </GreySection>
);
