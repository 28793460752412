import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

export const TitleWrapper = styled.div`
  display: grid;
  text-align: center;
  justify-content: center;
  margin: 40px auto;
`;

export const FeatureGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Feature = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 16px 28px;
  margin: 0 auto;

  ${screen.md} {
    max-width: 640px;
    width: 50%;
  }
`;
