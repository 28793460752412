import styled from 'styled-components';
import { screen } from '@/helpers/screen';

export const FeaturesWrapper = styled.section`
  padding: 50px 20px;

  ${screen.md} {
    padding: 50px 20px 70px;
  }

  ${screen.lg} {
    padding: 50px 20px 30px;
  }

  ${screen.xxlg} {
    padding: 50px 20px 70px;
  }
`;
