//containers
import { HeaderSection, FeaturedList } from 'containers/';

//styles
import { FeaturesWrapper } from './Features.styles';

import {
  IconImproveEfficiency,
  IconDeal,
  IconGameChangers,
} from '@loadsmart/icons';

export default function Features() {
  const headerSectionContent = {
    title: (
      <>
        Move Flatbed freight with less hassle,
        <br /> lower costs, and better service 
      </>
    ),
    description:
      'The Home Depot and Loadsmart have partnered together to build Flatbed Messenger, offering shippers seamless access to capacity from top performing Flatbed carriers - all at a discounted rate.',
  };

  const featuredListContent = [
    {
      icon: <IconDeal />,
      title: 'Secure Cost Savings',
      description:
        'Loadsmart makes empty backhauls from The Home Depot’s dedicated and private carriers easily accessible, giving shippers the chance to book at up to 40% below market rates.',
    },
    {
      icon: <IconImproveEfficiency />,
      title: 'Guaranteed Service Performance',
      description:
        'Shippers using Flatbed Messenger experience 94%+ on time pick up and delivery.',
    },
    {
      icon: <IconGameChangers />,
      title: 'Extend Your Team',
      description:
        'Every shipper using Flatbed Messenger is assigned a dedicated account manager to assist in booking, tracking and managing their shipments.',
    },
  ];

  return (
    <FeaturesWrapper>
      <HeaderSection content={headerSectionContent} width="962px" />
      <FeaturedList content={featuredListContent} />
    </FeaturesWrapper>
  );
}

export { Features };
