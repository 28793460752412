import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';

import MercuryGateLogo from '@/public/images/logos/logo-mercury_gate.svg';

const MercuryGateWrapper = styled(FlexRowFullCentered)`
  width: 104px;
  height: 28px;

  ${screen.md} {
    width: 126px;
    height: 34px;
  }

  ${screen.lg} {
    width: 180px;
    height: 49px;
  }

  ${screen.xxlg} {
    width: 210px;
    height: 57px;
  }
`;

export const MercuryGateCard = (props) => (
  <CardLayout {...props}>
    <MercuryGateWrapper>
      <MercuryGateLogo />
    </MercuryGateWrapper>
  </CardLayout>
);
