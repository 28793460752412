import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';
import { Title1 } from '@/components/Text';

export const ItemsBoxWrapper = styled.section`
  margin: 10px auto 30px;

  ${screen.md} {
    margin: 40px auto 100px;
  }
`;

export const ContentWrapper = styled(Container)`
  display: grid;
  grid-gap: 20px;
  grid-column-gap: 20px;
  grid-template-areas:
    'a'
    'b'
    'd';
  grid-template-columns: 100%;

  padding: 0 5px 20px;

  ${screen.md} {
    grid-column-gap: 30px;
    grid-template-columns: 50% auto;
    grid-template-areas:
      'a d'
      'a d'
      'a d'
      'b d'
      'b d';
  }

  img {
    width: 100%;
    height: auto;
    box-shadow: 0px 0.4rem 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  & > div {
    display: flex;
    height: 100%;

    & > div,
    & > div > div {
      height: 100%;
    }
  }
`;

export const GridOne = styled.div`
  grid-area: a;
`;
export const ContentWrapperOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
  text-align: left;

  h2,
  p {
    text-align: left;
    max-width: 420px;
  }

  a {
    margin: 20px 0 0;
  }

  ${screen.xxlg} {
    padding: 40px 0;
  }
`;

export const LogoWrapper = styled.div`
  height: 70px;
  width: 120px;
  margin: 20px 0;

  svg,
  img {
    max-width: 100%;
    height: auto;
  }

  ${screen.lg} {
    height: 94px;
    width: 163px;
  }
  ${screen.xxlg} {
    height: 125.5px;
    width: 217.2px;
  }
`;

export const CustomTitle = styled(Title1)`
  font-size: 38px;
  line-height: 48px;

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const GridTwo = styled.div`
  grid-area: b;
`;

export const GridThree = styled.div`
  grid-area: c;
`;

export const GridFour = styled.div`
  grid-area: d;
`;

export const ContentWrapperFour = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 5px;
  text-align: left;

  ${screen.md} {
    padding: 5px 15px;
  }

  ${screen.lg} {
    padding: 0 25px;
  }
  ${screen.xxlg} {
    padding: 5px 65px;
  }
`;

export const Picture = styled.picture`
  display: grid;
  box-sizing: border-box;
  width: 100%;

  img,
  source {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
  }
`;
