import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//container
import { Container } from '@/components/Grid/Container';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const Section = styled.section`
  background-color: rgb(${getToken('color-background-secondary')});
`;

export const QuoteContainer = styled(Container)`
  background-color: rgb(${getToken('color-background-secondary')});
  padding: 80px 0;

  ${screen.md} {
    padding: 50px 0;
  }

  ${screen.lg} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const Quote = styled.p`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin: 0;
  margin-bottom: 30px;

  ${screen.md} {
    font-size: 26px;
    line-height: 39px;
  }

  ${screen.lg} {
    font-size: 25px;
  }

  ${screen.xxlg} {
    font-size: 26px;
    margin-bottom: 50px;
  }
`;

export const QuoteSource = styled.p`
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-primary')});
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  margin: 0;

  ${screen.md} {
    br {
      display: none;
    }
  }
`;
