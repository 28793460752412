/* eslint-disable @next/next/no-img-element */
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';
import { sleep } from '@/helpers/sleep';

//components
import { Button } from '@/components/Button';

//containers
import { Hero } from '@/containers/Hero';
import { Title, Description } from '@/containers/Hero/Hero.styles';

const LogoWrapper = styled.div`
  margin-bottom: 28px;
  width: 290px;
  height: 40px;
  display: flex;
  justify-content: center;

  img,
  source,
  svg {
    max-width: 100%;
    height: auto;
  }

  ${screen.md} {
    width: 337px;
    height: 50px;
    margin-bottom: 36px;
    justify-content: unset;
  }

  ${screen.lg} {
    margin-bottom: 48px;
  }

  ${screen.xxlg} {
    margin-bottom: 65px;
  }
`;

const HeroCover = styled(Hero)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${(props) =>
    props.images?.sm && `background-image: url(${props.images?.sm});`}

  ${screen.md} {
    ${(props) =>
      props.images?.md && `background-image: url(${props.images?.md});`}
  }

  ${screen.lg} {
    ${(props) =>
      props.images?.lg && `background-image: url(${props.images?.lg});`}
  }

  ${screen.xxlg} {
    ${(props) =>
      props.images?.xxlg && `background-image: url(${props.images?.xxlg});`}
  }
`;

const StyledTitle = styled(Title)`
  sup {
    top: 0;

    font-weight: 200;
    font-size: 100%;
  }
`;

const WideButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  display: inline-block;

  ${screen.md} {
    max-width: 240px;
  }

  ${screen.lg} {
    max-width: 300px;
  }
`;

export const HeroSection = ({ content }) => {
  const router = useRouter();
  const handleFormJump = async (event) => {
    event.preventDefault();
    // analytics.track(` Page ${content.page}/ Request Demo`);
    await sleep(300);
    router.push('#hubspot-form');
  };

  return (
    <HeroCover images={content.images}>
      {content?.logo?.src && (
        <LogoWrapper>
          <img loading="lazy" src={content.logo.src} alt={content.logo.alt} />
        </LogoWrapper>
      )}
      {content?.title && <StyledTitle>{content.title}</StyledTitle>}
      {content?.description && <Description>{content.description}</Description>}
      <br />
      <WideButton as="a" onClick={handleFormJump}>
        Request a demo
      </WideButton>
    </HeroCover>
  );
};

HeroSection.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.string,
    logo: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
    images: PropTypes.shape({
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
      xxlg: PropTypes.string,
    }),
  }).isRequired,
};
