import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';
import { Title, Description } from '@/containers/Hero/Hero.styles';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const HeroWrapper = styled.header`
  width: 100%;
  min-height: 521px;
  background: rgb(${getToken('color-background-secondary-inverted')})
    url(/images/expedited-shipping/hero/expedited-shipping-hero-sm.jpg) center
    no-repeat;
  background-size: cover;
  display: grid;

  ${screen.md} {
    background-image: url(/images/expedited-shipping/hero/expedited-shipping-hero-md.jpg);
  }

  ${screen.lg} {
    background-image: url(/images/expedited-shipping/hero/expedited-shipping-hero-lg.jpg);
  }

  ${screen.xxlg} {
    background-image: url(/images/expedited-shipping/hero/expedited-shipping-hero-xxlg.jpg);
    min-height: 665px;
  }
`;

export const HeroContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin auto;
  
  ${screen.md} {
    align-items: flex-start;
  }

 
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;

  ${screen.md} {
    align-items: flex-start;
    max-width: 330px;
  }

  ${screen.lg} {
    max-width: 565px;
  }

  ${screen.xxlg} {
    max-width: 690px;
  }
`;

export const StyledTitle = styled(Title)`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-bold')};
  margin: 24px 0 0;
  font-size: 38px;
  line-height: 47.5px;
  text-wrap: balance;
  text-align: center;

  span {
    color: rgb(${getToken('color-brand')});
  }

  ${screen.md} {
    text-align: left;
  }

  ${screen.lg} {
    font-size: 48px;
    line-height: 60px;
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72.5px;
  }
`;

export const StyledDescription = styled(Description)`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-regular')};
  margin: 24px 0;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-wrap: balance;

  ${screen.md} {
    margin: 16px 0;
    max-width: 640px;
    text-align: left;
  }

  ${screen.lg} {
    margin: 24px 0;
    max-width: 800px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 36px;
    max-width: 820px;
    margin: 24px 0 48px;
  }
`;
