// components
import { Container } from 'components/Grid';

// styles
import {
  IntroductionTextWrapper,
  Title,
  Content,
} from './IntroductionText.styles';

export function IntroductionText() {
  return (
    <IntroductionTextWrapper>
      <Container>
        <Title>Flexible and easy-to-use logistics solutions</Title>
        <Content>
          Loadsmart’s managed transportation, transportation management systems,
          dock scheduling, digital brokerage, and AI applications are here to
          support your business.
        </Content>
      </Container>
    </IntroductionTextWrapper>
  );
}
