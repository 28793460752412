import styled, { css } from 'styled-components';
import Lottie from 'react-lottie';

//helpers
import { screen } from '@/helpers/screen';

//components
import {
  CenteredWrapper,
  ReversedCenteredWrapper,
  WideImageOnMobile,
  MobileOnlyContainer,
  Title,
  Description,
  Text,
} from '@/components/Grid/Content';
import { SectionPanel } from '@/components/Grid/Panel';
import { Container } from '@/components/Grid';

import { Section } from '@/components/Grid/Section';
import { FlexColumnFullCentered, FlexColumn } from '@/components/Grid/Flex';

import { FeatureIcon, responsiveIconStyle } from '@/components/Feature';

//assets
import {
  IconLowerCosts,
  IconFasterService,
  IconDeliveryLoad,
} from '@loadsmart/icons';
import animationData from '@/datafiles/lottie/link/animation.json';

const lottieDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};

const CustomText = styled(Text)`
  margin: 5px 0 20px !important;
`;

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${screen.md} {
    flex-direction: row;
  }
`;

const marginRightOnMedium = css`
  ${screen.md} {
    margin-right: 15px;
  }
`;

const CustomContentWrapper = styled(FlexColumnFullCentered)`
  height: 100%;

  text-align: center;

  ${screen.md} {
    text-align: left;
  }
`;

export const SidePanelsSection = () => (
  <>
    <Section>
      <Container>
        <Title>Dynamic Freight Pricing</Title>
        <Text>
          The problem with traditional routing guides is that when your primary
          carrier rejects a load, you’re forced to rely on backup rates that are
          typically only adjusted once or twice a year, resulting in
          overpayment. Valuable time is also lost when each backup carrier takes
          hours to respond.
        </Text>
        <Text>
          Improve cost savings, secure coverage faster, and avoid
          same-day/next-day markets with real-time market rates inserted
          directly into spot or your routing guide and instantly book shipments
          with fair, favorable pricing.
        </Text>
      </Container>
    </Section>

    <SectionPanel>
      <FlexColumnFullCentered>
        <Lottie options={lottieDefaultOptions} />
      </FlexColumnFullCentered>
      <MobileOnlyContainer>
        <CustomContentWrapper>
          <FeatureWrapper>
            <FeatureIcon css={marginRightOnMedium}>
              <IconLowerCosts role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <FlexColumn>
              <Description>REDUCE COSTS</Description>
              <CustomText>
                Save when our instant market rates are lower than your static
                backup options.
              </CustomText>
            </FlexColumn>
          </FeatureWrapper>
          <FeatureWrapper>
            <FeatureIcon css={marginRightOnMedium}>
              <IconFasterService role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <FlexColumn>
              <Description>SAVE TIME</Description>
              <CustomText>
                Loads tendered to Loadsmart are accepted instantly, helping you
                avoid pricey same-day/next-day markets.
              </CustomText>
            </FlexColumn>
          </FeatureWrapper>
          <FeatureWrapper>
            <FeatureIcon css={marginRightOnMedium}>
              <IconDeliveryLoad role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <FlexColumn>
              <Description>BREATHE EASY</Description>
              <CustomText>
                Our carriers are held to the strictest safety and reliability
                standards, delivering an average of 95% on-time.
              </CustomText>
            </FlexColumn>
          </FeatureWrapper>
        </CustomContentWrapper>
      </MobileOnlyContainer>
    </SectionPanel>

    <SectionPanel reverse>
      <WideImageOnMobile
        src={`/images/link/half_section-enabling.jpg`}
        alt="Direct Api Access"
      />
      <ReversedCenteredWrapper>
        <Title>Enabling the Integration is Easy, Fast and Free</Title>
        <Text>
          Our team has enabled hundreds of pre-built TMS integrations and will
          set up instant pricing in your system quickly, easily, and at no cost.
        </Text>
      </ReversedCenteredWrapper>
    </SectionPanel>

    <SectionPanel>
      <WideImageOnMobile
        src={`/images/link/half_section-rates.jpg`}
        alt="Direct Api Access"
      />
      <CenteredWrapper>
        <Title>Dynamic Back-Up Rates</Title>
        <Text>
          Upgrade freight routing from static and unreliable to dynamic and
          efficient. Avoid above-market auctions and overpriced contract rates
          while maintaining control.
        </Text>
      </CenteredWrapper>
    </SectionPanel>
  </>
);
