/* eslint-disable @next/next/no-img-element */
//components
import { Paragraph1 } from '@/components/Text';
import {
  ShadowBox,
  OpaqueBox,
  DefinitionList,
  MirandaSecondaryInvertedButton,
} from 'components/';

//styles
import {
  ItemsBoxWrapper,
  ContentWrapper,
  ContentWrapperOne,
  LogoWrapper,
  CustomTitle,
  ContentWrapperFour,
  GridOne,
  GridTwo,
  GridFour,
  Picture,
} from './ItemsBox.styles';

import FBMLogo from '@/public/images/logos/logo_FlatbedMessengerTM_vertical.svg';

export default function ItemsBox() {
  const definitionsContent = [
    {
      title: 'Flexible Capacity',
      description:
        'Get coverage matched to your network - how and where you need it, no hassle. Shippers can get spot or contract rates directly online, through their TMS or by emailing their dedicated rep.',
    },
    {
      title: 'Premium service, discounted rates',
      description:
        'Access premium flatbed capacity at below market rates.  Flatbed Messenger taps into unused and idle capacity from top performing dedicated carriers that service Fortune 500 shippers at below spot market rates.',
    },
    {
      title: 'Dedicated Account Management',
      description:
        'World-class service from flatbed logistics experts. Every shipper gets a dedicated account manager to provide 1:1 support and ensure carriers meet and exceed service requirements.',
    },
    {
      title: 'Sustainable, Profitable Shipments',
      description:
        'Move more flatbed loads with less emissions and costs.  By tapping into excess capacity, loads moved through Flatbed messenger reduce empty miles and carbon emissions.',
    },
  ];

  return (
    <ItemsBoxWrapper>
      <ContentWrapper>
        <GridOne>
          <OpaqueBox>
            <ContentWrapperOne>
              <LogoWrapper>
                <FBMLogo width="218" height="127" />
              </LogoWrapper>

              <CustomTitle as="h2">
                Move Flatbed freight with ease. Start booking loads today.
              </CustomTitle>
              <Paragraph1>
                Work smarter, not harder, with Flatbed Messenger.
              </Paragraph1>
              <MirandaSecondaryInvertedButton
                as="a"
                variant="neutral"
                href={`${process.env.SHIPPERGUIDE_URL}/fbm-signup`}
              >
                Access Flatbed Messenger
              </MirandaSecondaryInvertedButton>
            </ContentWrapperOne>
          </OpaqueBox>
        </GridOne>
        <GridTwo>
          <Picture>
            <source
              srcSet="/images/flatbed-messenger/Flatbed-Truck-xxlg.png"
              media="(min-width: 1920px)"
            />
            <source
              srcSet="/images/flatbed-messenger/Flatbed-Truck-lg.png"
              media="(min-width: 1024px)"
            />
            <source
              srcSet="/images/flatbed-messenger/Flatbed-Truck-sm.png"
              media="(min-width: 768px)"
            />
            <img
              src="/images/flatbed-messenger/Flatbed-Truck-sm.png"
              alt="teste"
              width="670"
              height="485"
            />
          </Picture>
        </GridTwo>
        <GridFour>
          <ShadowBox>
            <ContentWrapperFour>
              <DefinitionList content={definitionsContent} />
            </ContentWrapperFour>
          </ShadowBox>
        </GridFour>
      </ContentWrapper>
    </ItemsBoxWrapper>
  );
}

export { ItemsBox };
