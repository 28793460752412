//styles
import { ShadowBoxWrapper, InnerBox } from './ShadowBox.styles';

export default function ShadowBox({ children }) {
  return (
    <ShadowBoxWrapper>
      <InnerBox>{children}</InnerBox>
    </ShadowBoxWrapper>
  );
}

export { ShadowBox };
