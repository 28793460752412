import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { InvertedSection } from '@/components/Grid/Section';
import { SubTitle } from '@/components/Grid/Content/';
import { Container } from '@/components/Grid/Container';

//containers
import { Slider } from '@/containers/Slider';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const BackgroundSection = styled(InvertedSection)`
  background: rgb(${getToken('color-background-secondary')});

  padding: 50px 0;

  ${screen.md} {
    padding: 50px 0 80px;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const CustomContainer = styled(Container)`
  max-width: calc(100vw - 20px);
  margin: 0;

  @media (min-width: ${(props) => props.theme.breakpoint.small}) {
    margin: auto;
    max-width: calc(${(props) => props.theme.breakpoint.small} - 6rem);
  }

  ${screen.md} {
    max-width: calc(${(props) => props.theme.breakpoint.medium} - 6rem);
  }

  ${screen.lg} {
    max-width: calc(${(props) => props.theme.breakpoint.big} - 4.4rem);
  }

  @media (min-width: ${(props) => props.theme.breakpoint.biggest}) {
    max-width: calc(1440px - 6rem);
  }
`;

export const TestimonialSlider = styled(Slider)`
  margin-top: 20px;

  button {
    width: 35px;
    & > svg {
      display: flex;
      color: rgb(${getToken('color-background-inverted')});
      height: 45px;
    }
  }

  ${screen.md} {
    margin-top: 30px;
  }
`;

export const TestimonialItem = styled.div`
  max-width: 100%;
`;

export const Testimonial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 35px;
  text-align: center;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 20px;
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};

  ${screen.md} {
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 30px;
  }

  ${screen.lg} {
    margin-bottom: 40px;
  }

  ${screen.xxlg} {
    margin-bottom: 50px;
  }
`;

export const CenterSubTitle = styled(SubTitle)`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  text-align: center;
  font-size: 26px;
  line-height: 39px;
`;
