//styles
import { OpaqueBoxWrapper, InnerBox } from './OpaqueBox.styles';

export default function OpaqueBox({ children }) {
    return (
        <OpaqueBoxWrapper>
            <InnerBox>{children}</InnerBox>
        </OpaqueBoxWrapper>
    );
}

export { OpaqueBox };
