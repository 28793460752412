import PropTypes from 'prop-types';

import { Title1 } from 'components/Text';

//styles
import {
  HeaderSectionWrapper,
  HeaderSectionContainer,
  HeaderSectionContent,
  HeaderSubtitle,
} from './HeaderSection.styles';

export default function HeaderSection({ content, width }) {
  return (
    <HeaderSectionWrapper>
      <HeaderSectionContainer>
        <HeaderSectionContent width={width}>
          {content.title && <Title1>{content.title}</Title1>}
          {content.description && (
            <HeaderSubtitle>{content.description}</HeaderSubtitle>
          )}
        </HeaderSectionContent>
      </HeaderSectionContainer>
    </HeaderSectionWrapper>
  );
}

HeaderSection.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.node.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

HeaderSection.defaultProps = {
  content: {
    description: null,
  },
};

export { HeaderSection };
