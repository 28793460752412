/* eslint-disable @next/next/no-img-element */
//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

// context containers
import {
  Hero,
  FAQ,
  Features,
  ItemsBox,
  VideoHero,
  Signup,
} from './containers/';

//styles
import { PageWrapper, Picture } from './Flatbed.styles';

import FBMLogo from '@/public/images/logos/logo_FlatbedMessengerTM_horizontal.svg';
import { TwoColumnsCTA } from '@/containers-m/TwoColumnsCTA';

const heroContent = {
  title:
    'Get access to premium capacity from top performing carriers with Flatbed Messenger',
  subtitle: 'Quote and book flatbed loads in a few clicks.',
  logo: <FBMLogo width="273" height="65" />,
  button: {
    url: `${process.env.SHIPPERGUIDE_URL}/fbm-login`,
    text: <span>Access Flatbed Messenger</span>,
    variant: 'primary',
  },
};

const ctaContent = {
  title: 'Looking to haul flatbed loads? ',
  subtitle:
    'Start reducing your empty miles and filling your flatbed trucks with quality loads.  ',
  ctas: [
    {
      url: `${process.env.SHIPPERGUIDE_URL}/fbm-signup`,
      text: `Sign Up & Start Hauling`,
      external: true,
      variant: 'primary',
    },
    {
      url: `https://lp.loadsmart.com/hubfs/5041527/FlatbedMessenger_Overview.pdf`,
      text: `Download the One-Pager`,
      external: true,
      variant: 'secondary',
    },
  ],
};

const Flatbed = () => {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <Hero content={heroContent} gradient>
          <VideoHero />
        </Hero>
        <Features />
        <Picture>
          <img
            src="/images/flatbed-messenger/Flatbed-Messenger-Process-Graphic.svg"
            alt="Flatbed Messenger Proccess"
            width="1302"
            height="287"
          />
        </Picture>
        <Signup />
        <FAQ />
        <ItemsBox />
        <TwoColumnsCTA content={ctaContent} />
      </>
      <Footer />
    </PageWrapper>
  );
};

export default Flatbed;
export { Flatbed };
