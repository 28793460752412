import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//containers
import { Feature, FeatureGroup, FeatureTitle } from '@/components/Feature/';

//components
import { Button } from '@/components/Button';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SolutionsWrapper = styled.section`
  padding: 58px 0;

  text-align: center;

  background: #202931;

  ${screen.md} {
    padding: 34px 0;
  }

  ${screen.lg} {
    padding: 64px 0 63px;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 316px;
  margin: 0 auto;

  ${screen.md} {
    max-width: 674px;
  }

  ${screen.lg} {
    max-width: 970px;
  }

  ${screen.xxlg} {
    max-width: 1380px;
  }
`;

export const Title = styled.h1`
  margin: 0 auto 10px;

  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-brand')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 28px;
  line-height: 40px;
  text-align: center;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin-bottom: 8px;

    font-size: 40px;
    line-height: 53px;
  }
`;

export const SubTitle = styled.p`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-bold')};
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-align: center;

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 28px;
    line-height: 44px;
  }
`;

export const Text = styled.p`
  margin: 16px 0;
  margin: 28px 0px 25px;

  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-regular')};
  font-size: 12px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    margin: 28px 20px 25px;
  }

  ${screen.lg} {
    margin: 36px 7px 50px;

    font-size: 18px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    margin: 42px 100px;

    font-size: 22px;
    line-height: 38px;
  }
`;

export const StyledButton = styled(Button)`
  width: 260px;
  margin: 0;

  padding: 14px 21px;

  font-size: 14px;
  line-height: 18px;

  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-bold')};

  ${screen.md} {
    width: 247px;
    padding: 5px 15px;
  }

  ${screen.lg} {
    width: 315px;

    padding: 10px 49px;

    font-size: 14px;
    line-height: 18px;
  }

  ${screen.xxlg} {
    width: 436px;

    padding: 14px 54px;

    font-size: 26px;
    line-height: 38px;
    text-transform: none;
  }
`;

export const StyledFeatureGroup = styled(FeatureGroup)`
  ${screen.md} {
    margin: 0 -22px;
  }

  ${screen.lg} {
    margin: 0 -35px;
  }

  ${screen.xxlg} {
    margin: 0 -39px;
  }
`;

export const StyledFeature = styled(Feature)`
  width: 255px;
  margin: 13px 0;

  ${screen.md} {
    width: 196px;
    margin: 23px 21px;
  }

  ${screen.lg} {
    width: 258px;
    margin: 16px 35px;
  }

  ${screen.xxlg} {
    width: 325px;
    margin: 0px 10px;

    svg {
      width: 66px;
      height: 66px;

      color: #202931;
    }
  }
`;

export const StyledFeatureTitle = styled(FeatureTitle)`
  color: #202931;
  font-weight: 700;
  font-size: 40px;
  line-height: 53px;

  ${screen.xxlg} {
    margin: 12px 0px;

    font-size: 21px;
    line-height: 28px;
  }
`;
