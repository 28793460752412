import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//componets
import { Feature, FeatureGroup, FeatureTitle } from '@/components/Feature/';
import { FeatureDescription } from '@/components/Feature';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SolutionsWrapper = styled.section`
  padding: 22px 0 27px;

  text-align: center;

  background: rgb(${getToken('color-background-tertiary')});

  ${screen.md} {
    padding: 50px 0;
  }

  ${screen.lg} {
    padding: 70px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 316px;
  margin: 0 auto;

  ${screen.md} {
    max-width: 674px;
  }

  ${screen.lg} {
    max-width: 970px;
  }

  ${screen.xxlg} {
    max-width: 1380px;
  }
`;

export const Title = styled.h1`
  margin: 0 15px 30px;

  font-family: ${getToken('font-family-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  br {
    display: none;
  }

  ${screen.md} {
    margin: 0 75px 36px;

    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    margin: 0 -10px 50px;

    font-size: 38px;
    line-height: 50px;

    br {
      display: block;
    }
  }

  ${screen.xxlg} {
    margin-bottom: 70px;

    font-size: 48px;
    line-height: 72px;
  }
`;

export const StyledFeatureGroup = styled(FeatureGroup)`
  ${screen.md} {
    margin: 0 -22px;
  }

  ${screen.lg} {
    margin: 0 -10px;
  }

  ${screen.xxlg} {
    margin: 0 -39px;
  }
`;

export const StyledFeature = styled(Feature)`
  width: 218px;
  margin: 20px 0;

  svg {
    width: 48px;
    height: 48px;

    color: rgb(${getToken('color-text-primary')});
  }

  ${screen.md} {
    width: 158px;
    margin: 0px 7px;
  }

  ${screen.lg} {
    width: 230px;
    margin: 0px 8px;

    svg {
      width: 66px;
      height: 66px;
    }
  }

  ${screen.xxlg} {
    width: 325px;
    margin: 0px 10px;
  }
`;

export const StyledFeatureTitle = styled(FeatureTitle)`
  margin: 10px 0px;

  font-family: ${getToken('font-family-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 14px;
  line-height: 18px;

  ${screen.md} {
    font-size: 14px;
    line-height: 18px;
  }

  ${screen.lg} {
    font-size: 17px;
    line-height: 22px;
  }

  ${screen.xxlg} {
    margin: 12px 0px;

    font-size: 21px;
    line-height: 28px;
  }
`;

export const StyledFeatureDescription = styled(FeatureDescription)`
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};
  font-size: 12px;
  line-height: 18px;

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
  }
`;
