import React from 'react';
import PropTypes from 'prop-types';

// styles
import {
  BannerCTAWrapper,
  Title,
  Text,
  StyledButton,
  StyledContainer,
} from './BannerCTA.styles';

export function BannerCTA({ variant }) {
  return (
    <BannerCTAWrapper>
      <StyledContainer>
        {variant === 'features' ? (
          <>
            <Title>The truck management system for growing fleets.</Title>
            <Text>
              Carry your carrier into the digital age with our Truck Management
              System
            </Text>
            <StyledButton as="a" href="#hubspot-form">
              Request a Demo
            </StyledButton>
          </>
        ) : (
          <>
            <Title>
              Simplify and automate everyday tasks for your fleet so you can
              focus on profits.
            </Title>
            <Text>
              Access tools, resources, and integrations to organize internal
              operations.
            </Text>
            <StyledButton as="a" href="#hubspot-form">
              Request a Demo
            </StyledButton>
          </>
        )}
      </StyledContainer>
    </BannerCTAWrapper>
  );
}

BannerCTA.propTypes = {
  variant: PropTypes.string,
};

BannerCTA.defaultProps = {
  variant: null,
};
