//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

import { Layout, Title, Paragraph } from '@/components/Typography/Legal';

const PrivacyPolicySimpleQuote = () => {
  return (
    <>
      <Navigation />
      <>
        <Layout>
          <Title>Loadsmart Simple Quote Privacy Policy</Title>

          <Paragraph>
            PRIVACY POLICY STATEMENT OF LOADSMART, INC.’s SIMPLE QUOTE PRODUCT
          </Paragraph>
          <Paragraph>
            <strong>Scope and Application of Policy.</strong> This statement of
            our privacy policy (“Privacy Policy”) describes how Loadsmart, Inc.
            (“Loadsmart,” “we,” or “us”) collects and uses personal,
            non-personal and tracking and mobile data information from users
            (“Users” or “you”) of its Microsoft Excel add-in, Simple Quote
            (“Product”), and the platform of services Loadsmart offers and
            provides through the Product (“Services”).The Product permits direct
            access to our Loadsmart website (the “Website”).
          </Paragraph>
          <Paragraph>
            <strong>Terms and Conditions.</strong> By assessing or using our
            Website through the Product, you agree to be bound by the most
            current version of the Loadsmart User Agreement (“Agreement”). That
            Agreement sets forth the terms and conditions for using the Website
            and our platform of Services and incorporates by reference this
            Privacy Policy. Please read that Agreement carefully. If you do not
            agree to be boundby the terms and conditions set forth and
            incorporated by reference in the Agreement or are not authorized to
            agree to and be bound by the Agreement, you may not use the Website.
          </Paragraph>
          <Paragraph>
            <strong>Your Consent.</strong> By using, and each time you use, the
            Product, the Website and its related sites, applications, tools, or
            our platform of Services, you expressly consent to the collection,
            use, processing, disclosure, and retention of your information as
            described in and in accordance with this Privacy Policy. You also
            accept and agree tobe bound by the Privacy Policy.
          </Paragraph>
          <Paragraph>
            <strong>The Information We Collect and Its Use.</strong> When you
            access this The Product, youmay provide Loadsmart three types of
            information, personal, non-personal and Tracking and Mobile Data
            (together the “Covered Information”).
          </Paragraph>
          <Paragraph>
            <strong>Personal.</strong> “Personal information” is individually
            identifiable information about you, not otherwise available to the
            public, such as your name, address, phone number, and email address,
            that you willingly provide us in order to use or pay for our
            Platform or Services. You may provide us personal information when
            you use our Product, visit the Website, register as a User, use
            certain features of our Website platform such as submitting or
            posting information, making or accepting bookings, and paying for
            Services you use, and when you request or use one of our other
            Services.
          </Paragraph>
          <Paragraph>
            We collect personal information from Users only when they
            voluntarily provide it to us. We collect and process such data in
            order to provide Users access to Product and our Website, tools, and
            applications and to our Services. We also do so in order to enhance
            our Product and Website and our ability to provide efficient
            Services customized to meet your needs and those of other Users. We
            use personal information to facilitate providing general accounting,
            billing, and administrative services to our Users, to monitor your
            use of our Services, such as the pages you view and things you click
            on or search for, and to expedite resolving User complaints and
            disputes, among other purposes permitted by law.
          </Paragraph>
          <Paragraph>
            If you elect to provide us personal information, you consent to its
            transfer and storage on our servers. If you decline to provide us
            personal information, you may be prevented from participating in and
            using some or all of our Services. We do not knowingly contact or
            collect personal information from children under age 13. If you
            believe we have inadvertently collected such information, please
            contact us, and wewill promptly obtain parental consent or remove
            the information.
          </Paragraph>
          <Paragraph>
            <strong>Non-Personal.</strong> You also may provide us non-personal
            identification information when you use our Product or access our
            Website. You may, for example, provide us your browser’s name and
            its characteristics and enable us to identify your Internet service
            provider, your operating system and IP address, your method of
            connectivityto Product or the Website, and other similar
            information.
          </Paragraph>
          <Paragraph>
            <strong>Tracking and Mobile Data.</strong> In order to perform its
            Services, when you use certain features of Loadsmart’s Service
            platform, or input information directly into the Product, we may
            receive, collect, store, and process different types of information
            about your location and that of your assets (e.g. your trucks or
            your in-transit goods)
          </Paragraph>
          <Paragraph>
            <strong>Storing and Tracking.</strong> As explained above, we may
            track your IP address and the location of your assets that you
            provide to us. You understand and agree that we and/or our agents or
            service providers may monitor your activities and locations as
            described above, including your use of the Product, the Website and
            its Services. Specifically, you consent to Loadsmart collecting and
            using the Covered Informationabout you and your assets to (i)
            administer the Services and the Product, (ii) electronically locate
            you or, if applicable, your assets in conjunction with providing you
            and your customers Services, (iii) provide information to
            third-party service providers, wireless carriers, or other persons
            we engage for the purpose of providingServices and the Product, or
            in response to a subpoena or other legal process, and (iv) use and
            share your information and statistics in conjunction with providing
            you Services and the Product. In addition, you consent to Loadsmart
            using Covered Information about you and your assets, including
            contact information and the types of Services that you use, to (i)
            enforce the terms of the Loadsmart User Agreement, (ii) prevent
            fraud, (iii) respond to regulatory and legal requirements and
            governmentinquiries, or in litigation or dispute resolution, (iv)
            share information with Loadsmart affiliates in conjunction with
            Loadsmart Services, (v) provide you with promotional offers and
            marketing materials, (vi) administer your account, including for
            credit and collections purposes, and (vii) disclose such information
            to your bank or to a third-party collection agency. We may retain
            your data indefinitely.
          </Paragraph>
          <Paragraph>
            YOU ACKNOWLEDGE AND AGREE YOU ARE RESPONSIBLE FOR OBTAINING WHATEVER
            CONSENTS AND WAIVERS FROM THIRD PARTIES MAY BE NECESSARY IN ORDER
            FOR LOADSMART TO COLLECT, USE, AND DISCLOSE SUCH INFORMATION.
          </Paragraph>
          <Paragraph>
            <strong>
              Sharing Covered Information with Loadsmart’s Trusted Partners and
              Vendors.
            </strong>{' '}
            Loadsmart may share your Covered Information with its trusted
            partners in order to provide you with the Product and Services and
            relevant advertising and offers. We also may allow advertising
            companies to collect data through our Services.
          </Paragraph>
          <Paragraph>
            Loadsmart may share your personal information with other companies
            it engages, including its vendors and contractors, in order to
            provide you improved Services anda better Product. Their use of this
            information is limited to these purposes and subject to agreements
            requiring them to keep the information confidential. Loadsmart’s
            vendors provide assurances they take and will take reasonable steps
            tosafeguard the data they hold on your behalf, although data
            security cannot be guaranteed.
          </Paragraph>
          <Paragraph>
            Analytical companies we use or engage may access or allow us to
            access aggregated data to help Loadsmart better understand how its
            Services and the Product are used, thus enabling Loadsmart to expand
            and enhance both. These companies use these data solely on our
            behalf. They do not share data except in aggregated form.
          </Paragraph>
          <Paragraph>
            <strong>
              Sharing Covered Information with User’s Clients, Partners,
              Contractors and Related Third Parties.
            </strong>
            Loadsmart may share your Covered Information with certain User’s
            commercial partners (such as warehouses, pick-up and delivery
            locations, motor carriers, shippers, among others) to improve the
            User’s experience or to enhance the quality and scope of its
            Services (e.g. allowing shippers to track their shipments; notifying
            consignees of arriving loads; allowing shipper and carrier Users to
            share their Covered Information with third parties, etc.) Loadsmart
            has no control over when and with whom Carriers, Shippers and their
            agents share their Covered Information, and, therefore, Loadsmart
            expressly disclaims any liability to you or any third party.
          </Paragraph>
          <Paragraph>
            <strong>
              Steps to Secure Covered Information: Our Disclaimer.
            </strong>{' '}
            We take reasonable steps to protect your personal information
            against unauthorized access or disclosure; however, you understand
            that privacy cannot be guaranteed on our system, or other systems
            such as those used in order to provide our Services and the Product,
            because no security or encryption method can be guaranteed to
            protect information from all hackers or to be devoid of all human
            action or error. Accordingly, we expressly disclaim liability to you
            or any third party for any claims, loss, damages, or costs resulting
            from a lack of privacy.
          </Paragraph>
          <Paragraph>
            <strong>Special-Situation Disclosures.</strong> To operate the
            Service and the Product, we also may make identifiable and anonymous
            information available to unrelated third parties in these limited
            circumstances: (1) with your express consent, (2) when we have a
            good faith belief it is required by law, (3) when we have a good
            faith belief it is necessary to protect our rights or property, or
            (4) subject to appropriate confidentiality protections, to any
            successor or purchaser in a merger, acquisition, liquidation,
            dissolution, or sale of assets. Your consent will not be required
            for disclosure in these cases, but Loadsmart will attempt to notify
            you, to the extent permitted by law to do so.
          </Paragraph>
          <Paragraph>
            <strong>Changes To This Privacy Policy.</strong> Loadsmart last
            updated this Privacy Policy on June 27, 2019. We may change our
            Privacy Policy from time to time in our discretion. When we make any
            material changes, we will place a prominent notice on our Website or
            application and will revise the updated date at the bottom of this
            page. If the change materially affects registered Users, we will
            undertake to send you notice by email, push notification, or text.
            We also urge all Users to check these pages frequently and the
            current revision date for any changes. You acknowledge and agree you
            are responsible for reviewing this Privacy Policy periodically and
            becoming aware of modifications.
          </Paragraph>
          <Paragraph>
            Our Loadsmart User Agreement May Include Other Important
            Information. Please read it carefully before accessing the Website.
          </Paragraph>
        </Layout>
      </>
      <Footer />
    </>
  );
};

export default PrivacyPolicySimpleQuote;
export { PrivacyPolicySimpleQuote };
