import styled from 'styled-components';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//helpers
import { screen } from '@/helpers/screen';

export const FeaturesWrapper = styled.section`
  padding: 0rem 2rem 3.9rem;
  background: rgb(${getToken('color-background-primary')});

  div {
    padding: 0px;
  }

  ${screen.md} {
    padding: 0rem 2rem 3.6rem;
  }

  ${screen.lg} {
    padding: 0rem 2rem 3rem;
  }

  ${screen.xxlg} {
    padding: 0rem 2rem 7rem;
  }
`;

export const FeaturedListWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center
  gap: 32px;

  ul {
    margin: 0;
  }

  p {
      line-height: 21px;
    }

  h3 {
    font-size: 20px;
    line-height: 30px;
  }

  ${screen.md} {
    grid-template-columns: repeat(2, 284px);
    gap: 32px;
  }

  ${screen.lg} {
    grid-template-columns: repeat(2, 313px);
    gap:32px 64px;
  }

  ${screen.xxlg} {
    grid-template-columns: repeat(3, 313px);
  }
`;

export const HeaderSection = styled.header`
  max-width: 100%;
  width: auto;
  margin: auto auto 50px;
  text-align: center;

  ${screen.md} {
    width: 506px;
  }

  ${screen.lg} {
    width: 925px;
    margin: auto auto 80px;
  }

  ${screen.xxlg} {
    width: 1362px;
  }
`;

export const Title = styled.h2`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  font-size: 20px;
  line-height: 30px;

  margin: 0 auto;

  ${screen.md} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const Description = styled.p`
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};

  font-size: 12px;
  line-height: 16.8px;

  ${screen.md} {
    font-size: 14px;
    line-height: 21px;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 36px;
  }
`;
