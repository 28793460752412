import styled from 'styled-components';

import {
  IconLowerCosts,
  IconFasterService,
  IconImproveEfficiency,
} from '@loadsmart/icons';

//helpers
import { screen } from '@/helpers/screen';

import { Title, Text } from '@/components/Grid/Content';
import { Container } from '@/components/Grid/Container';
import { Section } from '@/components/Grid/Section';

import {
  Feature,
  FeatureGroup,
  FeatureTitle,
  FeatureDescription,
  FeatureIcon,
  responsiveIconStyle,
} from '@/components/Feature';

export const StyledFeatureGroup = styled(FeatureGroup)`
  ${screen.md} {
    margin: 0 -22px;
  }

  ${screen.lg} {
    margin: 0 -35px;
  }

  ${screen.xxlg} {
    margin: 0 -39px;
  }
`;

export const StyledFeature = styled(Feature)`
  width: 255px;
  margin: 13px 0;

  ${screen.md} {
    width: 196px;
    margin: 23px 21px;
  }

  ${screen.lg} {
    width: 258px;
    margin: 16px 35px;
  }

  ${screen.xxlg} {
    width: 325px;
    margin: 55px 39px;
  }
`;

export const IntroductionSection = () => (
  <Section>
    <Container>
      <Title>Driving savings and simplicity for Enterprise shippers</Title>
      <Text>
        The larger your company, the more challenging your logistics
        organization—and the more challenging it becomes to make foundational
        changes to drive growth and weather market disruption. You need
        practical, scalable tools and services to drive savings without adding
        additional complexity.
      </Text>
      <Text>
        We’re here to help you meet your goals, big or small. Whether you need
        reliable overland capacity, expert assistance managing your operations,
        are looking for easy-to-use technology your teams will actually use, or
        want to get ahead of your competition with the latest in AI-enabled
        logistics intelligence, we’ve got you covered.
      </Text>
      <StyledFeatureGroup>
        <StyledFeature>
          <FeatureIcon>
            <IconImproveEfficiency role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Improve Service</FeatureTitle>
          <FeatureDescription>
            Meet or exceed on-time performance benchmarks with unparalleled
            precision
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconFasterService role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Save Time</FeatureTitle>
          <FeatureDescription>
            Quote and book thousands of shipments in seconds within the tools
            you use everyday
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconLowerCosts role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Lower Costs</FeatureTitle>
          <FeatureDescription>
            Save when the market rate is below your static contracted options
          </FeatureDescription>
        </StyledFeature>
      </StyledFeatureGroup>
    </Container>
  </Section>
);
