//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

// context containers
import {
  Hero,
  FloatHeroBox,
  SolutionsSection,
  SidePanels,
  TransparentRatesSection,
  TransformYourFleetSection,
  TeamHero,
  FloatTeamHeroBox,
  HubspotFormSection,
} from './containers/index';

//styles
import { PageWrapper } from './Carrier.styles';
import { MobileAppSection } from '@/containers/MobileAppSection';

const mobileApp = {
  title:
    'Loadsmart Mobile App. An easy, on-the-go way to keep your trucks and wallets full.',
  subtitle:
    'Find a load, post an empty truck, communicate with drivers, instantly upload photos and documents, and view all upcoming shipments.',
};

function Carrier() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <Hero />
        <FloatHeroBox />
        <SolutionsSection />
        <SidePanels />
        <MobileAppSection content={mobileApp} />
        <TransparentRatesSection />
        <TransformYourFleetSection />
        <TeamHero />
        <FloatTeamHeroBox />
        <HubspotFormSection />
      </>
      <Footer />
    </PageWrapper>
  );
}

export default Carrier;
export { Carrier };
