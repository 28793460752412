import styled, { css } from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//containers
import { Hero } from '@/containers/Hero';

export const smallFont = css`
  font-size: 12px;
  line-height: 14px;

  ${screen.lg} {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const ctaStyle = css`
  width: 100%;
  margin: 12px 0;

  white-space: nowrap;
  text-transform: uppercase;

  ${screen.md} {
    width: auto;
    margin-right: 15px;
  }
`;

export const FlexRowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const HeroBackground = styled(Hero)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${(props) => props.image?.sm && `background-image: ${props.image?.sm}`};

  ${screen.md} {
    ${(props) => props.image?.md && `background-image: ${props.image?.md}`};
    background-position: center top !important;
  }

  ${screen.lg} {
    ${(props) => props.image?.lg && `background-image: ${props.image?.lg}`};
  }

  ${screen.xxlg} {
    ${(props) => props.image?.xxlg && `background-image: ${props.image?.xxlg}`};
  }
`;
