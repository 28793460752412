//containers
import { CollapsedtList } from 'containers/';
//components
import { Title1 } from 'components/Text';

//data
import { faqContent } from '@/datafiles/faq/flatbed';

//styles
import { FAQWrapper, FAQHeader } from './FAQ.styles';

export default function FAQ() {
  const headerSectionContent = {
    title: 'Frequently Asked Questions',
  };

  return (
    <FAQWrapper>
      <FAQHeader>
        <Title1>{headerSectionContent.title}</Title1>
      </FAQHeader>
      <CollapsedtList content={faqContent} />
    </FAQWrapper>
  );
}

export { FAQ };
