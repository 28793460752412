//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

//components
import {
  Layout,
  Title,
  TitleH2,
  TitleH3,
  TitleH4,
  Paragraph,
  Link,
  BulltetList,
} from '@/components/Typography/Legal';

const ManagedTransportationMasterServicesAgreement = () => {
  return (
    <>
      <Navigation />
      <>
        <Layout>
          <Title>Managed Transportation Master Services Agreement</Title>
          <Paragraph>
            <i>Last Updated: 01/06/23</i>
          </Paragraph>
          <Paragraph>
            This Managed Transportation Master Services Agreement (“MSA”),
            effective as of the SOW Effective Date (as defined in the applicable
            SOW), is made between Customer (as defined in the applicable SOW)
            and Loadsmart, Inc. (“Loadsmart”); (each of Customer and Loadsmart,
            a “Party,” or in the aggregate, the “Parties”).
          </Paragraph>
          <Paragraph>
            <strong>WHEREAS</strong>, Customer is a distributor of certain
            products (“Product”) and in connection therewith requires
            transportation and logistics services for purposes of coordinating
            transportation for the Products;
          </Paragraph>
          <Paragraph>
            <strong>WHEREAS</strong>, Loadsmart is authorized by the Federal
            Motor Carrier Safety Administration (“FMCSA”) to operate as a
            Registered Property Broker pursuant to license issued in Number
            MC-872918 and arranges transportation and logistics services using
            various third-party providers;
          </Paragraph>
          <Paragraph>
            <strong>WHEREAS</strong>, Loadsmart agrees to provide to Customer,
            and Customer agrees to purchase from Loadsmart, transportation
            logistics services as set forth in this MSA and each statement of
            work entered into pursuant hereto (the “Services”);
          </Paragraph>
          <Paragraph>
            <strong>WHEREAS</strong>, it is contemplated that the Services will
            include Loadsmart tendering loads on behalf of Customer to motor
            carriers selected by Customer pursuant to a Customer provided
            routing guide or as otherwise directed by Customer (“Customer
            Contracted Carriers”) and motor carriers selected and contracted by
            Loadsmart (“Loadsmart Contracted Carriers”);
          </Paragraph>
          <Paragraph>
            <strong>NOW THEREFORE</strong>, in consideration of the above
            recitals and the various terms and conditions contained herein and
            other good and valuable consideration the receipt and sufficient of
            which are hereby acknowledged, the parties agree as follows:
          </Paragraph>
          <Paragraph>
            <TitleH2>1. Structure of the Agreement.</TitleH2> Loadsmart shall
            provide to Customer the Services specified in one or more statements
            of work (“SOW”). Each is an “Incorporated Document.” This MSA,
            together with all Incorporated Documents, makes up the “Agreement”
            between the Parties. In the event of a conflict between the terms of
            this MSA and the terms of any Incorporated Document, the documents
            shall prevail in the following order, (i) the SOW, then (ii) this
            MSA shall control.
          </Paragraph>
          <Paragraph>
            <TitleH2>2. Term and Termination:</TitleH2>
          </Paragraph>
          <Paragraph>
            <TitleH3>2.1. Term.</TitleH3>
            The term of this MSA shall begin on the initial SOW Effective Date
            and shall remain in effect for as long as there is an effective SOW
            in connection with this MSA. For the avoidance of doubt, this MSA
            will automatically terminate if there is are no effective SOWs in
            place.
          </Paragraph>
          <Paragraph>
            <TitleH3>2.2. Post-Termination Obligations.</TitleH3>
            Within ten (10) days of expiration or termination of this MSA for
            any reason, Customer may request return or destruction of all data
            provided by Customer to Loadsmart for use in connection with
            providing the Services. Loadsmart shall return all such data to
            Customer in a format reasonably agreed by the parties or destroy
            such data, as directed by Customer, within thirty (30) days of such
            request.
          </Paragraph>
          <Paragraph>
            <TitleH3>2.3. Termination Assistance.</TitleH3>
            Upon expiration or termination of this MSA for any reason other than
            termination by Loadsmart due to Customer’s breach, failure to make
            payments when due, insolvency or bankruptcy, upon Customer’s
            request, Loadsmart will continue to provide the Services to Customer
            for up to ninety (90) days during decommissioning or transition of
            the Services to Customer or Customer’s agent. Customer shall pay
            Loadsmart for such Services in accordance with the terms of this
            MSA. If Customer requests transition assistance services in addition
            to the Services described in this MSA, the Parties shall negotiate
            in good faith the scope of such additional services and the
            applicable fees, and Loadsmart shall only be required to provide
            such additional transition assistance services to the extent the
            parties agree in writing with respect thereto. Notwithstanding
            anything to the contrary herein, Customer shall pay to Loadsmart all
            outstanding Fees within ten (10) days after the expiration or
            termination of this MSA.
          </Paragraph>
          <Paragraph>
            <TitleH3>2.4. Survival.</TitleH3>
            Notwithstanding anything to the contrary, the following terms and
            conditions of this MSA shall survive termination or expiration
            thereof: Sections 2.3 (Post-Termination Obligations), 2.4
            (Termination Assistance), 2.5 (Survival), 7 (Confidentiality), 8
            (Indemnification), 9 (Warranties and Disclaimers), 10 (Limitation of
            Liability), 11 (Insurance), 12 (Loadsmart Intellectual Property), 13
            (Choice of Law and Venue), and 15 (Miscellaneous).
          </Paragraph>
          <Paragraph>
            <TitleH2>3. Services and Responsibilities:</TitleH2>
          </Paragraph>
          <Paragraph>
            <TitleH3>3.1. Loadsmart’s Services and Responsibilities.</TitleH3>
            Loadsmart shall provide the Services to Customer in compliance with
            all applicable Federal, state and local laws and regulations.
          </Paragraph>
          <Paragraph>
            <TitleH4>3.1.1. </TitleH4>
            In performing the Services, Loadsmart will only tender Product loads
            hereunder to carriers included in the Customer provided routing
            guide or as otherwise directed by Customer, except to the extent
            tendered to Loadsmart to arrange transportation pursuant to
            Loadsmart’s User Agreement (
            <Link href="https://loadsmart.com/user-agreement/">
              https://loadsmart.com/user-agreement/
            </Link>
            ) incorporated herein by reference.
          </Paragraph>
          <Paragraph>
            <TitleH3>3.2. Customer’s Responsibilities.</TitleH3>
          </Paragraph>
          <Paragraph>
            <TitleH4>3.2.1. </TitleH4>
            As between Customer and Loadsmart, and except to the extent
            otherwise agreed in writing by the Parties, Customer is responsible
            for its own Product procurement, warehousing, order fulfillment,
            customer service, distributors and supplier management. Customer
            represents and warrants that all of the Product will comply with all
            applicable Federal, State and local laws and/or regulations relating
            to the manufacture, importation, adulteration, contamination,
            packaging or labeling of such product.
          </Paragraph>
          <Paragraph>
            <TitleH4>3.2.2. </TitleH4>
            Customer shall retain sole responsibility for (a) vetting Products
            for compliance to federal, state or local laws (including common
            law), ordinances, statutes, rules, regulations, (b) executing
            written transportation agreements with Customer Contracted Carriers;
            and (c) paying all of Customer’s third party providers, including,
            without limitation, Customer Contracted Carriers, unless expressly
            set forth an applicable SOW.
          </Paragraph>
          <Paragraph>
            <TitleH2>
              4. Business Reviews, Changes in Operating Parameters or
              Conditions:
            </TitleH2>
          </Paragraph>
          <Paragraph>
            <TitleH3>4.1. Business Reviews.</TitleH3>
            The Parties’ duly authorized representatives (as designated by each
            Party) shall meet to review the Service performance and discuss
            Operating Parameters (as defined in Section 4.2) and any other
            issues between the two Parties relating to this Agreement (“Business
            Reviews”). Such Business Reviews shall be held, at a minimum, once
            each calendar quarter. Subject to the foregoing, the Business
            Reviews will be scheduled at mutually convenient times for the
            Parties. Based on the Business Reviews, Loadsmart will identify,
            evaluate, and propose improvement opportunities, including without
            limitation, new or revised logistics strategies that support and
            facilitate the achievement of Customer’s overall business
            objectives.
          </Paragraph>
          <Paragraph>
            <TitleH3>
              4.2. Changes in Operating Parameters or Conditions.
            </TitleH3>
          </Paragraph>
          <Paragraph>
            <TitleH4>4.2.1.</TitleH4>
            Customer acknowledges and agrees that Loadsmart calculates the Fees
            (as defined herein) based on and in reliance upon certain key
            assumptions. Such assumptions shall be set forth in the applicable
            Incorporated Documents as “Operating Parameters.” In the event of a
            change in any Operating Parameters or a “Changed Condition” (as
            defined below), Loadsmart may propose an adjustment to the Fees (as
            defined herein) to respond to the change by providing Customer with
            thirty (30) days prior written notice thereof, setting forth the
            basis for such adjustment, which notice shall be given prior to the
            implementation of the adjustment. If the Parties are unable to agree
            upon the adjustment within thirty (30) days of Loadsmart’s notice of
            the adjustment, Loadsmart may terminate this MSA upon ninety (90)
            days’ written notice. For the avoidance of doubt, both Parties agree
            to continue their obligations under the Agreement during the ninety
            (90) day notice period prior to any such termination.
          </Paragraph>
          <Paragraph>
            <TitleH4>4.2.2.</TitleH4>
            “Changed Condition” means the enactment or dissemination of any new
            law or regulation or the imposition of a new material condition on
            the issuance or renewal of any permit, license or approval after the
            Effective Date hereof that: (a) materially increases Loadsmart’s
            operating costs to perform the Services or otherwise materially
            adversely affects the ability of Loadsmart to perform the Services,
            or (b) materially decreases the Fees (as defined herein) to which
            Loadsmart would otherwise be entitled under an Incorporated
            Document.
          </Paragraph>
          <Paragraph>
            <TitleH2>5. Fees, Charges, Expenses and Taxes.</TitleH2>
          </Paragraph>
          <Paragraph>
            <TitleH3>5.1. Fees.</TitleH3>
            Loadsmart shall be paid for the Services contemplated by this
            Agreement as further defined in the applicable SOW. Customer shall
            pay to Loadsmart all fees, charges and expenses (“Fees”) as invoiced
            within thirty (30) days of Customer’s receipt of the invoice with no
            right of set-off. All Fees will be billed and paid in U. S. dollars.
            Payment shall be made as specified in the invoice.
          </Paragraph>
          <Paragraph>
            <TitleH3>5.2. Late Payments.</TitleH3>
            If Customer fails to make a payment of the Fees when due, Customer
            shall pay to Loadsmart a late payment charge at the rate of one and
            one-half percent (1.5%) per month or, if lower, the highest rate
            allowed by applicable law. The late payment charge shall be
            calculated from the date that the unpaid Fees become due and shall
            be compounded annually for the period during which any such Fees
            remain unpaid.
          </Paragraph>
          <Paragraph>
            <TitleH3>5.3. Taxes.</TitleH3>
            Customer agrees to pay, indemnify and hold Loadsmart and its
            affiliates harmless from and against all sales, use, personal
            property, gross receipts, excise, franchise and business taxes
            (including any penalties, fines or interest thereon) imposed by any
            federal, state or local government or taxing authority with respect
            to the Services performed by Loadsmart under this Agreement.
            Notwithstanding the foregoing, Loadsmart shall be solely responsible
            for all income taxes arising from its operations however and by
            whomever imposed.
          </Paragraph>
          <Paragraph>
            <TitleH2>6. Title to Product.</TitleH2>
            Title to Product shall remain at all times with Customer and shall
            not pass to Loadsmart under any circumstances unless otherwise
            specifically provided in this Agreement.
          </Paragraph>
          <Paragraph>
            <TitleH2>7. Confidentiality.</TitleH2>
            Each party agrees that all business, technical, financial and other
            information that it obtains from the other is the confidential
            property of the disclosing party (“Confidential Information” of the
            disclosing party). Except as expressly and unambiguously allowed
            herein, the receiving party will hold in confidence and not use or
            disclose any Confidential Information of the disclosing party and
            shall similarly bind its employees in writing. Upon termination of
            this Agreement or upon request of the disclosing party, the
            receiving party will return to the disclosing party or destroy (and
            certify such destruction) all Confidential Information of such
            disclosing party, all documents and media containing such
            Confidential Information and any and all copies or extracts thereof.
            The receiving party shall not be obligated under this Section with
            respect to information the receiving party can document: (a) is or
            has become readily publicly available without restriction through no
            fault of the receiving party or its employees or agents; or (b) is
            received without restriction from a third party lawfully in
            possession of such information and lawfully empowered to disclose
            such information; or (c) was rightfully in the possession of the
            receiving party without restriction prior to its disclosure by the
            other party; or (d) was independently developed by employees or
            consultants of the receiving party without access to such
            Confidential Information; or (e) is required to be disclosed by law
            or order of court of competent jurisdiction.
          </Paragraph>
          <Paragraph>
            <TitleH2>8. Indemnification.</TitleH2>
          </Paragraph>
          <Paragraph>
            <TitleH3>8.1. Loadsmart Indemnification. </TitleH3>
          </Paragraph>
          <Paragraph>
            <TitleH4>8.1.1.</TitleH4>
            Loadsmart shall indemnify, defend and hold Customer and its
            affiliates, and their directors, employees, officers, and agents
            harmless from and against any and all fines, penalties or
            assessments by any government agency and any and all third party
            liabilities, claims, suits, demands, actions, fines, damages,
            losses, costs and expenses (including reasonable attorneys’ fees)
            (collectively, “Claims”) arising from or to the extent caused by (i)
            any alleged infringement of the copyrights, patent or other
            intellectual property rights of a third party by any transportation
            management system provided by Loadsmart to Customer as part of the
            Services (the “Loadsmart Platform”) or (ii) the violation by
            Loadsmart of any applicable law or regulation.
          </Paragraph>
          <Paragraph>
            <TitleH4>8.1.2.</TitleH4>
            In the event of a claim of infringement or misappropriation with
            respect to the Loadsmart Platform, Loadsmart shall at no cost to
            Customer either (i) modify or replace the Loadsmart Platform so that
            it no longer infringes or misappropriates, or (ii) obtain a license
            for Customer’s continued use of the Loadsmart Platform in accordance
            with this Agreement. If neither option (i) nor (ii) are available on
            commercially reasonable terms, Loadsmart may terminate the Agreement
            upon thirty (30) days’ written notice and refund to Customer any
            prepaid Fees covering the remainder of the term after the effective
            date of termination. The foregoing in this Section 9.1 shall be
            Loadsmart’s sole obligation and Customer’s exclusive remedy with
            respect to any alleged infringement by Loadsmart of any third
            party’s intellectual property rights.
          </Paragraph>
          <Paragraph>
            <TitleH3>8.2. Customer Indemnification.</TitleH3>
            Customer agrees to indemnify, defend, and hold Loadsmart and its
            affiliates, and their directors, employees, officers, and agents
            harmless from and against any Claims, arising out of or to the
            extent caused by (i) the packaging, distribution, marketing, use or
            sale of the Products or Customer’s instructions regarding such
            Products, (ii) the use by Loadsmart of any third party providers
            selected by Customer to provide services to Customer, including,
            without limitation, Customer Contracted Carriers, warehouseman,
            forwarders and customs brokers, or (iii) the violation by Customer
            of any applicable law or regulation.
          </Paragraph>
          <Paragraph>
            <TitleH3>8.3. Indemnification Process.</TitleH3> Each Party (the
            “Indemnitee”) shall provide the other Party (the “Indemnitor”) with
            prompt written notice of any claim for which indemnification is
            sought hereunder, provided that that failure to provide such prompt
            notice shall excuse an Indemnitor’s obligation hereunder only to the
            extent that the Indemnitor is prejudiced by the delay. The
            Indemnitee shall: (i) give the Indemnitor sole control of the
            defense and settlement of the claim (provided that the Indemnitor
            may not settle any claim unless it unconditionally releases the
            Indemnitee of all liability), and (ii) provide the Indemnitor all
            reasonable assistance, at the Indemnitor’s expense.
          </Paragraph>
          <Paragraph>
            <TitleH2>9. Warranties and Disclaimer.</TitleH2>
          </Paragraph>
          <Paragraph>
            <TitleH3>9.1. </TitleH3>
            Each Party represents and warrants to the other that (a) it has the
            full power and authority to enter into the Agreement and perform all
            obligations and conditions to be performed by it under the Agreement
            without any restriction by any other agreement or otherwise; (b) the
            execution, delivery and performance of the Agreement have been duly
            authorized by all necessary corporate action of such party; and (c)
            the Agreement constitutes the legal, valid and binding obligation of
            such party.
          </Paragraph>
          <Paragraph>
            <TitleH3>9.2.</TitleH3>
            Except as expressly set forth in this Agreement, the Services and
            the Loadsmart Platform are provided “as is” and Loadsmart makes no
            representations or warranties. Loadsmart expressly disclaims any
            implied warranties or conditions of any kind, including, without
            limitation, any warranty or condition of quality, performance,
            merchantability, fitness for a particular purpose or
            noninfringement. Except as specifically set forth in this Agreement,
            Loadsmart does not warrant, guarantee, or make any representations
            regarding the use, or the results of the use, of the Services, the
            Loadsmart Platform or any other materials in terms of correctness,
            accuracy, reliability, or otherwise.
          </Paragraph>
          <Paragraph>
            <TitleH2>10. Limitation of Liability.</TitleH2>
          </Paragraph>
          <Paragraph>
            <TitleH3>
              10.1. Liability Limitations for Loss or Damage to Product.
            </TitleH3>
            Except as specifically provided in an applicable SOW, (i) Loadsmart
            shall have no liability for any loss of, or damage to, or delay of
            the Products of Customer while in the custody of Customer’s third
            party providers, including, without limitation, Customer Contracted
            Carriers, (ii) any insurance or other protection of Customer’s
            Product shall be procured by Customer and at Customer’s expense and
            (iii) all claims in connection with acts of a third party provider
            shall be filed and brought against the third party by Customer.
            Loadsmart shall reasonably cooperate with Customer in the filing of
            such claims with third party providers; provided that in no event
            shall Loadsmart have any responsibility to pay Customer any amounts
            which a third party provider fails or refuses to pay with respect to
            any claim. Loadsmart’s aggregate liability to Customer for breach of
            this Agreement shall not exceed the amount of fees paid by Customer
            to Loadsmart under this Agreement during the previous twelve (12)
            month period under the SOW that applies to the Services for which
            the breach relates.
          </Paragraph>
          <Paragraph>
            <TitleH3>10.2. Exclusion.</TitleH3>
            IN NO EVENT SHALL LOADSMART BE LIABLE FOR LOSS OR DAMAGE TO/AND OR
            DELAY IN THE DELIVERY OF A SHIPMENT CAUSED BY IT OR ITS AGENTS
            NEGLIGENCE IN AN AMOUNT IN EXCESS OF USD $100,000 PER OCCURRENCE,
            AND THE MAXIMUM AGGREGATE LIABILITY OF EACH PARTY ARISING OUT OF OR
            IN CONNECTION WITH THIS AGREEMENT SHALL NOT EXCEED USD $2,000,000.
            EACH PARTY HERETO WAIVES WITH THE EXCEPTION OF RELIEF MANDATED BY
            STATUTE, ANY CLAIM TO PUNITIVE, EXEMPLARY, MULTIPLIED, INDIRECT,
            CONSEQUENTIAL OR LOST PROFITS/REVENUES DAMAGES.
          </Paragraph>
          <Paragraph>
            <TitleH2>11. Insurance. </TitleH2>
            During the performance of Services, the Parties shall maintain the
            following minimum insurance coverage. The Parties shall provide a
            certificate of insurance evidencing such coverage at the start of
            work under the Agreement and from time to time, upon request by the
            other Party, and the Parties shall take adequate steps to ensure
            that the other Party shall be named as an additional insured on the
            Comprehensive General Liability Insurance certificate.
          </Paragraph>
          <Paragraph>
            <BulltetList>
              <li>
                a) Workers’ Compensation insurance in the amount not less than
                statutory limits.{' '}
              </li>

              <li>
                b) Employer’s Liability insurance in the amount of not less than
                $1,000,000.
              </li>

              <li>
                c) Comprehensive General Liability insurance covering the Party
                and its agents and employees (including contractual and product
                liability coverage) for bodily injury and property damage in the
                minimum combined single limit amount of $1,000,000 per
                occurrence, $2,000,000 aggregate, or in such greater amounts as
                may be required by federal or state authorities.
              </li>
            </BulltetList>
          </Paragraph>
          <Paragraph>
            <TitleH2>12. Loadsmart Intellectual Property. </TitleH2>
            All of Loadsmart’s work product under this Agreement and all
            concepts, inventions, ideas, patent rights, know-how, data,
            trademarks, copyrights and other intellectual property rights which
            are related to, arise out of, or are employed or developed in
            connection with all Services performed pursuant to the Agreement,
            including without limitation, the Loadsmart Platform, are and shall
            remain the exclusive property of, and all ownership rights therein
            do and shall remain with Loadsmart. Loadsmart shall retain sole and
            exclusive ownership of all right, title and interest to and in its
            proprietary information, templates, processes, methodologies,
            inventions, patents, know-how and standard operating manuals and
            information contained therein, software and other intellectual
            property owned by it as of the Effective Date or thereafter and all
            derivative works thereof developed or used by Loadsmart to provide
            the Services. Customer acknowledges and agrees that Customer’s
            rights to use any such Loadsmart intellectual property are
            non-exclusive and limited to receiving the Services during the
            applicable term.
          </Paragraph>
          <Paragraph>
            <TitleH2>13. Choice Of Law And Venue.</TitleH2>
            All questions concerning the construction, interpretation, validity
            and enforceability of this Agreement shall be governed by and
            construed and enforced in accordance with the laws of the State of
            Illinois, without giving effect to any choice or conflict of law
            provision or rule that would cause the laws of any other
            jurisdiction to apply. Any legal action arising under or pursuant to
            this Agreement shall be brought and maintained only in the state and
            federal courts located in Cook County, Illinois.
          </Paragraph>
          <Paragraph>
            <TitleH2>14. Dispute Resolution.</TitleH2>
            The parties recognize that although they are neither partners nor
            joint ventures, they are engaged in what is intended to be a
            long-term mutually beneficial commercial relationship. In an effort
            to further this relationship, the parties, therefore, agree that
            they shall use commercially reasonable efforts to resolve,
            informally and at the appropriate levels of their respective
            management, any disagreements or disputes that may arise under this
            Agreement at the earliest occasion in an effort to prevent
            manageable issues from becoming crises. All such agreements reached
            by management shall be reduced to writing and signed by duly
            authorized representatives of the parties.
          </Paragraph>
          <Paragraph>
            <TitleH2>15. Miscellaneous.</TitleH2>
          </Paragraph>
          <Paragraph>
            <TitleH3>15.1. Independent Contractor.</TitleH3>
            It is understood and agreed that the relationship between Customer
            and Loadsmart is solely that of independent contractor and not as an
            agent, joint venturer, owner-operator or employee and that no
            employer/employee relationship exists, or is intended. Neither Party
            shall hold itself out as an agent of or in a joint venture with the
            other Party, and neither Party shall have any authority to act on
            behalf of the other Party.
          </Paragraph>
          <Paragraph>
            <TitleH3>15.2. Entire Agreement.</TitleH3>
            The Agreement constitutes the entire agreement between the Parties
            with respect to the subject matter of the Agreement and supersedes
            and replaces all prior communications, agreements and understandings
            of the Parties with respect to the subject matter hereof. The
            Parties may renegotiate and amend the terms and conditions of the
            Agreement, but no amendment to the Agreement shall be binding on
            either Party unless it is reduced to writing and signed by the
            authorized representatives of both Parties.
          </Paragraph>
          <Paragraph>
            <TitleH3>15.3. Third Party Beneficiaries.</TitleH3> Except as
            otherwise expressly provided herein, the execution and delivery of
            this MSA shall not be deemed to confer any rights upon, nor obligate
            any of the parties hereto, to any person or entity other than the
            Parties hereto or other than as expressly provided herein.
          </Paragraph>
          <Paragraph>
            <TitleH3>15.4. Successors and Assigns.</TitleH3> This Agreement
            shall be binding upon the successors and assigns of parties hereto.
            Except in connection with the sale or other disposition of all or a
            substantial part of its assets and business, neither party shall
            assign this Agreement or any part thereto, without the prior written
            consent of the other party, which shall not be unreasonably
            withheld. Notwithstanding the foregoing, either Party may assign
            this Agreement to wholly owned subsidiaries or affiliates.
          </Paragraph>
          <Paragraph>
            <TitleH3>15.5. Headings.</TitleH3> Any headings of the sections or
            subsection of the Agreement are solely for the convenience of the
            Parties hereto, are not a Party of the Agreement and shall not be
            used for the interpretation or determination of the validity of the
            Agreement or any provision hereof.
          </Paragraph>
          <Paragraph>
            <TitleH3>15.6. Invalid Provisions.</TitleH3> If any provisions of
            the Agreement are held to be invalid, the remainder of the Agreement
            shall remain in full force and effect with the offensive term or
            condition being stricken to the extent necessary to comply with
            conflicting law.
          </Paragraph>
          <Paragraph>
            <TitleH3>15.7. Waiver.</TitleH3> No provisions of the Agreement
            shall be waived by any Party hereto, unless such waiver is written
            and signed by the authorized representative of the Party against
            whom such waiver is to be enforced.
          </Paragraph>
          <Paragraph>
            <TitleH3>15.8. Force Majeure. </TitleH3> Neither Party shall be held
            responsible for any delay or failure in performance of any part of
            the Agreement to the extent such delay or failure is due to causes
            beyond its control and without its fault or negligence, including
            but not limited to, acts of God, acts of the public enemy, fire,
            explosion, flood, war, riots, strike, lockouts, pandemics, epidemics
            cyberattacks, acts or request of any governmental authority or any
            cause beyond its control, whether or not similar to the foregoing.
          </Paragraph>
          <Paragraph>
            <TitleH3>15.9. Notices.</TitleH3> All written notices required by or
            related to the Agreement shall be in writing and sent to the Parties
            either by email or at the addresses set forth in the applicable SOW,
            or such other address as a Party may identify to the other Party in
            writing. Any such notice shall be deemed given when received and
            shall be sent by (1) registered or certified mail, return receipt
            requested, posted prepaid, or (2) overnight courier service, or (3)
            personal delivery (4) read receipt on an email.
          </Paragraph>
        </Layout>
      </>
      <Footer />
    </>
  );
};

export default ManagedTransportationMasterServicesAgreement;
export { ManagedTransportationMasterServicesAgreement };
