//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { tmsIntegrationOptions } from '@/vendor/HubspotFormIntegration';

// context containers
import {
  HeroSection,
  FeatureSection,
  SidePanelsSection,
  TMSIntegrationsSection,
} from './containers/index';

//styles
import { PageWrapper } from './Link.styles';

function Link() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <HeroSection />
        <FeatureSection />
        <TMSIntegrationsSection />
        <SidePanelsSection />
        <HubspotForm {...tmsIntegrationOptions}>
          <HubspotFormTitle>
            Talk to a Transportation Specialist
          </HubspotFormTitle>
          <HubspotFormDescription>
            Our team of industry experts and technicians are here to answer your
            questions.
          </HubspotFormDescription>
        </HubspotForm>
      </>
      <Footer />
    </PageWrapper>
  );
}

export default Link;
export { Link };
