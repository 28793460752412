import styled from 'styled-components';

import { screen } from '@/helpers/screen';

//components
import { Button } from '@/components/Button';

export const MidBannerWrapper = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #202931;

  box-sizing: content-box;

  ${screen.md} {
    justify-content: flex-start;
  }
`;

export const ImageWrapper = styled.picture`
  display: none;

  img {
    max-width: 100%;
    height: auto;

    border: 3px solid;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-image: linear-gradient(to bottom, #00d7d7 23.29%, #84f11e 88.12%);
    border-image-slice: 1;
    box-sizing: content-box;
  }

  ${screen.md} {
    display: flex;
    width: 214px;
    margin: auto 0;
  }
  ${screen.lg} {
    width: 398px;

    img {
      border: 8px solid;
      border-top: 0;
      border-bottom: 0;
      border-left: 0;
      border-image: linear-gradient(to bottom, #00d7d7 23.29%, #84f11e 88.12%);
      border-image-slice: 1;
    }
  }
  ${screen.xxlg} {
    min-width: 700px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 315px;
  padding: 40px 0;
  box-sizing: content-box;

  ${screen.md} {
    max-width: 489px;
    padding: 0;
    padding-left: 16px;
  }
  ${screen.lg} {
    width: calc(100% - 503px);
    max-width: 693px;
    padding-left: 54px;
  }
  ${screen.xxlg} {
    max-width: 886px;
  }
`;

export const StyledTitle = styled.h1`
  padding-bottom: 20px;

  color: #fcfcff;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  margin: 0%;

  border-bottom: 2px solid #fcfcff;

  ${screen.md} {
    padding-right: 44px;

    font-size: 24px;
    line-height: 33px;
    text-align: start;
  }
  ${screen.lg} {
    padding-right: 0px;
    padding-bottom: 36px;

    font-size: 38px;
    line-height: 50px;
  }
  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;

export const StyledButton = styled(Button)`
  min-width: 259px;
  margin: 20px auto 0;
  padding: 1px 47px;

  color: #202931;

  font-size: 20px;
  line-height: 38px;
  text-transform: none;

  ${screen.md} {
    margin: 20px 0 0;

    font-size: 20px;
    line-height: 38px;
  }

  ${screen.lg} {
    min-width: 310px;
    margin: 36px 0 0;
    padding: 11px 49px;

    font-size: 26px;
    line-height: 38px;
  }
`;
