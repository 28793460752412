// styles
import { useEffect, useState } from 'react';
import {
  HeroWrapper,
  HeroContainer,
  GhostButtonStyled,
  ButtonStyled,
  FlexRow,
  LoginLink,
  CarrierTMSLogo,
  StyledTitle,
  StyledDescription,
} from './Hero.styles';

const arr = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source'];

function getUrlVars(url) {
  const vars = {};
  if (url.search) {
    url.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      if (arr.includes(key)) {
        vars[key] = value;
      }
    });
  }

  return vars;
}

export function Hero() {
  const [utmValues, setUtmValues] = useState(
    'utm_source=salescollateral&utm_medium=hyperlink&utm_campaign=carrier-sign-up-campaign-carrier-carriertms-q3-24'
  );

  useEffect(() => {
    const queryHref = window.location;
    const findUtms = getUrlVars(queryHref);
    if (
      findUtms.hasOwnProperty('utm_source') &&
      findUtms.hasOwnProperty('utm_medium') &&
      findUtms.hasOwnProperty('utm_campaign')
    ) {
      if (
        findUtms.utm_source === 'capterra' &&
        findUtms.utm_medium === 'profile-page-cta' &&
        findUtms.utm_campaign === 'gartner-ppc-profile-carrier-carriertms-q4-24'
      ) {
        setUtmValues(
          'utm_source=capterra&utm_medium=profile-page-cta&utm_campaign=gartner-ppc-profile-carrier-carriertms-q4-24'
        );
      }
    }
  }, []);

  return (
    <HeroWrapper>
      <HeroContainer>
        <CarrierTMSLogo />
        <StyledTitle>
          Optimize dispatch. Reduce overhead costs.
          <br />
          Scale trucking fleets.
          <br /> All in one TMS.
        </StyledTitle>
        <StyledDescription>
          A truck management software that has all your carrier operations in
          one place,
          <br /> making it easier to manage all areas within your business.
        </StyledDescription>
        <FlexRow>
          <>
            <ButtonStyled
              as="a"
              href={
                `https://app.carriertms.io/login?state=checkAccount&` +
                utmValues
              }
            >
              Sign Up for Free
            </ButtonStyled>
            <GhostButtonStyled as="a" href="#hubspot-form">
              Request a Demo
            </GhostButtonStyled>
          </>
          <LoginLink>
            Already have an account?{' '}
            <a
              href={`https://app.carriertms.io/login?` + utmValues}
              target="_blank"
              rel="noreferrer"
            >
              Login here.
            </a>
          </LoginLink>
        </FlexRow>
      </HeroContainer>
    </HeroWrapper>
  );
}
