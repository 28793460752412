import styled from 'styled-components';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';

export const VideoHeroWrapper = styled.section`
  position: relative;
  background: rgb(${getToken('color-background-tertiary')});
  padding-bottom: 60px;

  ${screen.lg} {
    padding-bottom: 80px;
  }

  ${screen.xxlg} {
    padding-bottom: 100px;
  }
`;

export const ContainerStyled = styled(Container)`
  padding: 0;
  width: 100%;
  max-width: calc(100% - 38px);
  margin-top: -140px;

  ${screen.md} {
    margin-top: -240px;
    width: calc(100% - 168px);
    max-width: 605px;
  }

  ${screen.lg} {
    width: calc(100% - 266px);
    max-width: 835px;
    margin-top: -180px;
  }

  ${screen.xxlg} {
    width: calc(100% - 803px);
    max-width: 1100px;
    margin-top: -200px;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 20px;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  background: rgb(${getToken('color-background-secondary-inverted')});

  .react-player {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
`;
