//components
import { Container } from '@/components/Grid/Container';

import {
  SectionWrapper,
  Title,
  ListContainer,
  AwardsWrapper,
  Year,
  Text,
} from './Awards.styles';

//datafiles
import { AWARDS } from '@/datafiles/awards/list';

export const Awards = () => (
  <SectionWrapper>
    <Container>
      <Title>Awards and Recognition</Title>
    </Container>

    <Container>
      <ListContainer>
        {AWARDS.map((item, key) => {
          const index = `board-${key}`;
          return (
            <AwardsWrapper key={index}>
              <Year>{item.year}</Year>
              <Text>
                <ul>
                  {item.awards.map((award, key) => {
                    const index = `board-${item.year}-${key}`;
                    return (
                      <li key={index}>
                        {award.link ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={award.link}
                          >
                            {award.name}
                          </a>
                        ) : (
                          <span key={index}>{award.name}</span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </Text>
            </AwardsWrapper>
          );
        })}
      </ListContainer>
    </Container>
  </SectionWrapper>
);
