import React from 'react';

//components
import { Container } from '@/components/Grid';
import { Title } from '@/components/Grid/Content';
import { FlexColumnFullCentered } from '@/components/Grid/Flex';
import { GhostButton } from '@/components/Button';
import { FeatureGroup } from '@/components/Feature';

// context containers
import {
  BackgroundImageSection,
  SpacedTitle,
  SpacedFeature,
  SpacedDescription,
} from './AvailableModesSection.styles';

export const AvailableModesSection = () => (
  <BackgroundImageSection>
    <Container>
      <FlexColumnFullCentered>
        <SpacedTitle>Available Modes</SpacedTitle>
        <FeatureGroup>
          <SpacedFeature>
            <Title>FTL</Title>
            <SpacedDescription>
              Price and book dry, reefer, and flatbed instantly with access to
              24/7 capacity
            </SpacedDescription>
          </SpacedFeature>
          <SpacedFeature>
            <Title>LTL</Title>
            <SpacedDescription>
              Price and book PTL, volume LTL, and LTL instantly with access to
              24/7 capacity
            </SpacedDescription>
          </SpacedFeature>
          <SpacedFeature>
            <Title>RAIL</Title>
            <SpacedDescription>
              Price and book Rail, ship high volume freight, reduce fuel costs
              and emissions
            </SpacedDescription>
          </SpacedFeature>
        </FeatureGroup>
        <GhostButton as="a" href={`${process.env.SHIPPERGUIDE_URL}/signup`}>
          get a quote
        </GhostButton>
      </FlexColumnFullCentered>
    </Container>
  </BackgroundImageSection>
);
