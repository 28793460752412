/* eslint-disable @next/next/no-img-element */
// styles
import {
  SolutionsWrapper,
  Title,
  Text,
  Picture,
  Link,
  FlexRow,
  Container,
  Content,
} from './MobileAppSection.styles';

export function MobileAppSection({ content }) {
  return (
    <SolutionsWrapper>
      <Container>
        <Picture>
          <source
            srcSet="/images/carrier/mobileApp/mobileApp-xxlarge.png"
            media="(min-width: 1920px)"
          />
          <source
            srcSet="/images/carrier/mobileApp/mobileApp-xxlarge.png"
            media="(min-width: 1024px)"
          />
          <source
            srcSet="/images/carrier/mobileApp/mobileApp-xxlarge.png"
            media="(min-width: 768px)"
          />
          <img
            src="/images/carrier/mobileApp/mobileApp-xxlarge.png"
            alt="Mobile Apps screen shots"
            width="677"
            height="520"
          />
        </Picture>
        <Content className={content.variant ? 'variant' : ''}>
          <Title className={content.variant ? 'variant' : ''}>
            {content.title}
          </Title>
          <Text className={content.variant ? 'variant' : ''}>
            {content.subtitle}
          </Text>
          <FlexRow>
            <Link href="https://apps.apple.com/br/app/loadsmart-loads/id1256783942">
              <img
                src="/images/carrier/BadgeAppleSmall.png"
                alt="Download on the App Store"
                width="197"
                height="66"
              />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.loadsmart.liveloads&hl=en&gl=US&pli=1">
              <img
                src="/images/carrier/BadgeGoogleSmall.png"
                alt="Get it on google play"
                width="221"
                height="66"
              />
            </Link>
          </FlexRow>
        </Content>
      </Container>
    </SolutionsWrapper>
  );
}
