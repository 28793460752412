import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';
import conditional from '@/helpers/conditional';

//components
import { Container } from 'components/Grid';
import { MirandaButton } from '@/components/Button';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const FeaturesNavBannerWrapper = styled.aside`
  display: flex;
  min-height: 365px;
  overflow: hidden;

  background: ${conditional({
    '#ffffff': (props) => props.variant === 'external',
    '#202931': (props) => props.variant !== 'external',
  })};

  ${screen.md} {
    min-height: 679px;
  }

  ${screen.lg} {
    min-height: 815px;
  }

  ${screen.xxlg} {
    min-height: 920px;
  }
`;

export const StyledContainer = styled(Container)`
  position: relative;

  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;

  padding: ${conditional({
    '40px 0 0': (props) => props.variant !== 'external',
    '0 0 0': (props) => props.variant === 'external',
  })};

  ${screen.md} {
    grid-template-columns: 1fr 303px;
    padding: 0;

    & > div,
    & > nav {
      margin: auto 0;
    }
  }
  ${screen.lg} {
    grid-template-columns: 1fr 373px;
  }
  ${screen.xxlg} {
    grid-template-columns: 1fr 480px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 314px;
  margin: auto;

  ${screen.md} {
    margin: 0;
    max-width: ${conditional({
      '346px': (props) => props.variant !== 'external',
      '307px': (props) => props.variant === 'external',
    })};
  }
  ${screen.lg} {
    max-width: ${conditional({
      '558px': (props) => props.variant !== 'external',
      '459px': (props) => props.variant === 'external',
    })};
  }
  ${screen.xxlg} {
    max-width: 715px;
  }
`;

export const Title = styled.h2`
  font-family: ${getToken('platform-font-title')};
  display: block;
  width: 100%;
  margin: 0 auto;

  color: ${conditional({
    '#FCFCFF': (props) => props.variant !== 'external',
    '#5C6A76': (props) => props.variant === 'external',
  })};
  font-weight: 800;
  font-size: 26px;
  line-height: 36px;
  text-align: center;

  strong {
    color: ${conditional({
      '#14D64D': (props) => props.variant !== 'external',
      '#202931': (props) => props.variant === 'external',
    })};
  }

  ${screen.md} {
    margin: 0;
    text-align: left;
    font-size: ${conditional({
      '43px': (props) => props.variant !== 'external',
      '26px': (props) => props.variant === 'external',
    })};
    line-height: ${conditional({
      '60px': (props) => props.variant !== 'external',
      '39px': (props) => props.variant === 'external',
    })};
  }

  ${screen.lg} {
    font-size: ${conditional({
      '70px': (props) => props.variant !== 'external',
      '38px': (props) => props.variant === 'external',
    })};
    line-height: ${conditional({
      '91px': (props) => props.variant !== 'external',
      '47px': (props) => props.variant === 'external',
    })};
  }

  ${screen.xxlg} {
    font-size: ${conditional({
      '80px': (props) => props.variant !== 'external',
      '58px': (props) => props.variant === 'external',
    })};
    line-height: ${conditional({
      '105px': (props) => props.variant !== 'external',
      '72px': (props) => props.variant === 'external',
    })};
  }
`;

export const SmallText = styled.p`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  margin: 0 0 16px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  ${screen.md} {
    text-align: start;
  }

  ${screen.lg} {
    margin: 0 0 32px;
    font-size: 26px;
    line-height: 39px;
  }
`;

export const Text = styled.p`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  margin-top: 16px;
  margin-bottom: 0px;

  color: ${conditional({
    '#FCFCFF': (props) => props.variant !== 'external',
    '#202931': (props) => props.variant === 'external',
  })};
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    margin-top: 20px;
    text-align: left;
    font-size: ${conditional({
      '18px': (props) => props.variant !== 'external',
      '14px': (props) => props.variant === 'external',
    })};
    line-height: ${conditional({
      '30px': (props) => props.variant !== 'external',
      '21px': (props) => props.variant === 'external',
    })};
  }

  ${screen.lg} {
    max-width: 508px;

    font-size: 20px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    max-width: 100%;
    margin-top: 32px;
    font-size: ${conditional({
      '26px': (props) => props.variant !== 'external',
      '20px': (props) => props.variant === 'external',
    })};
    line-height: ${conditional({
      '38px': (props) => props.variant !== 'external',
      '36px': (props) => props.variant === 'external',
    })};
  }
`;

export const StyledButton = styled(MirandaButton)`
  display: inline-grid;
  width: 252px;
  margin: 0 auto;
  margin-top: 16px;
  padding: 8px;

  color: rgb(${getToken('color-text-inverted')});

  font-size: 12px;

  ${screen.md} {
    width: 219px;
    margin: 0px;
    margin-top: 16px;
  }
  ${screen.lg} {
    width: 296px;
    font-size: 16px;
    line-height: 16px;
    margin-top: 32px;
    padding: 16px;
  }
`;

export const FeaturesNavBannerNav = styled.nav`
  display: none;
  height: 100%;
  padding: 12px 22px;

  background: ${conditional({
    '#ffffff': (props) => props.variant === 'external',
    '#202931': (props) => props.variant !== 'external',
  })};

  ${screen.md} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${screen.lg} {
    padding: 16px 28px;
  }
  ${screen.xxlg} {
    padding: 20px 40px;
  }
`;

export const FeaturesNavBannerNavWrapper = styled.div`
  button {
    border-bottom: 1px solid #5c6a76;
  }

  &:nth-last-child(1) {
    button {
      border-bottom: none;

      &.opened {
        border-bottom: 1px solid #5c6a76;
      }
    }
  }
`;

export const FeaturesNavBannerNavTitle = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 0;

  color: ${conditional({
    '#FCFCFF': (props) => props.variant !== 'external',
    '#202931': (props) => props.variant === 'external',
  })};
  font-weight: 600;

  font-size: 16px;
  line-height: 32px;

  background: transparent;
  border: none;
  cursor: pointer;

  ${screen.lg} {
    padding: 8px 0;

    font-size: 18px;
    line-height: 38px;
  }

  ${screen.xxlg} {
    padding: 10px 0;

    font-size: 22px;
    line-height: 45px;
  }

  &::after {
    display: inline-block;

    color: #14d64d;

    content: '+';
  }

  &.opened {
    &::after {
      content: '-';
    }
  }
`;
export const FeaturesNavBannerNavGroup = styled.div`
  flex-direction: column;
  height: 0px;
  overflow: hidden;

  transition: all 0.2s ease-in-out;

  &.opened {
    height: auto;

    ${screen.md} {
      height: calc(50px * ${(props) => props.items});
    }

    ${screen.lg} {
      height: calc(58px * ${(props) => props.items});
    }

    ${screen.xxlg} {
      height: calc(66px * ${(props) => props.items});
    }
  }
`;

export const FeaturesNavBannerNavItem = styled.a`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 0 6px 24px;

  color: ${conditional({
    '#FCFCFF': (props) => props.variant !== 'external',
    '#202931': (props) => props.variant === 'external',
  })};
  font-weight: 400;
  font-size: 12px;
  line-height: 36px;

  border-bottom: 1px solid #5c6a76;

  transition: all 0.1s ease-in-out;

  &::after {
    display: inline-block;

    color: ${conditional({
      '#FCFCFF': (props) => props.variant !== 'external',
      '#C1CED9': (props) => props.variant === 'external',
    })};

    transition: all 0.1s ease-in-out;

    content: ${conditional({
      "'\\2193'": (props) => props.variant !== 'external',
      "'\\2192'": (props) => props.variant === 'external',
    })};
  }

  &::before {
    position: absolute;
    top: 5px;
    left: 10px;

    content: '\\2022';
  }

  &:hover {
    color: #14d64d;

    &::after {
      color: inherit;
    }
  }

  ${screen.lg} {
    padding: 8px 0 8px 36px;

    font-size: 15px;
    line-height: 40px;

    &::before {
      top: 9px;
      left: 20px;
    }
  }

  ${screen.xxlg} {
    padding: 10px 0 10px 42px;

    font-size: 19px;
    line-height: 42px;

    &::before {
      top: 9px;
      left: 20px;
    }
  }
`;

//

export const MobileNav = styled.div`
  display: flex;

  background: ${conditional({
    '#202931': (props) => props.variant !== 'external',
    '#ffffff': (props) => props.variant === 'external',
  })};

  ${screen.md} {
    display: none;
  }
`;

export const MobileNavWrapper = styled.nav`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 374px;
  margin: auto;
`;

export const MobileNavTitle = styled.a`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100px;

  color: ${conditional({
    '#33333d': (props) => props.variant === 'external',
    '#ffffff': (props) => props.variant !== 'external',
  })};

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  background: ${conditional({
    '#3D4B55': (props) => props.variant !== 'external',
    '#CADBDD': (props) => props.variant === 'external',
  })};

  &:nth-child(even) {
    background: ${conditional({
      '#202931': (props) => props.variant !== 'external',
      '#E6EDF2': (props) => props.variant === 'external',
    })};
  }

  &::after {
    display: inline-block;

    color: #14d64d;

    transition: all 0.1s ease-in-out;

    content: '\\2193';
  }
`;
