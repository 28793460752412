import styled from 'styled-components';

import { screen } from '@/helpers/screen';

import { FlexColumn } from '@/components/Grid/Flex';

export const SliderImage = styled.div`
  margin-bottom: 12px;

  ${screen.lg} {
    margin-bottom: 20px;
  }

  ${screen.xxlg} {
    margin-bottom: 30px;
  }
`;

export const SliderTitle = styled.div`
  margin-bottom: 0px;
  text-align: left;

  ${screen.lg} {
    margin-bottom: 8px;
  }
  ${screen.xxlg} {
    margin-bottom: 10px;
  }
`;

export const Slide = styled(FlexColumn)`
  padding: 0 35px;

  & > * {
    text-align: left;
  }
`;
