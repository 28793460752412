import { useRouter } from 'next/router';

//helpers
import { sleep } from '@/helpers/sleep';

//components
import { BaseButton } from '@/components/Button';

//containers
import { Title, Description } from '@/containers/Hero/Hero.styles';

// context containers
import {
  smallFont,
  ctaStyle,
  FlexRowWrap,
  HeroBackground,
} from './HeroSection.styles';

const responsiveBackground = {
  sm: `url(/images/data-insights/hero/small-hero.png);`,
  md: `url(/images/data-insights/hero/medium-hero.png);`,
  lg: `url(/images/data-insights/hero/large-hero.png);`,
  xxlg: `url(/images/data-insights/hero/xxlarge-hero.png);`,
};

export const HeroSection = () => {
  const router = useRouter();
  const handleFormJump = async (event) => {
    event.preventDefault();
    // analytics.track(' Page / Request Demo');
    await sleep(300);
    router.push('#hubspot-form');
  };

  return (
    <HeroBackground image={responsiveBackground}>
      <Title>Data Insights</Title>
      <Description>
        Actionable insights into your operations to optimize shipments and move
        beyond benchmarks
      </Description>
      <FlexRowWrap>
        <BaseButton as="a" onClick={handleFormJump} css={[ctaStyle, smallFont]}>
          Talk to a Transportation Specialist
        </BaseButton>
      </FlexRowWrap>
    </HeroBackground>
  );
};
