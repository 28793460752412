import styled from 'styled-components';

//components
import { Container } from '@/components/Grid';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SideFeaturesWrapper = styled.section`
  display: flex;
  width: 100%;
  background: rgb(${getToken('color-background-inverted')});
  padding: 40px 0;

  ${screen.md} {
    padding: 60px 0;
  }
  ${screen.lg} {
    padding: 80px 0;
  }
  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const CustomContainer = styled(Container)`
  display: grid;
  align-items: center;
  justify-content: center;

  ${screen.md} {
    gap: 42px;
  }

  ${screen.lg} {
    grid-template-columns: 1fr 615px;
  }

  ${screen.xxlg} {
    gap: 85px;
    grid-template-columns: 1fr 900px;
  }
`;

export const TitleHolder = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  ${screen.md} {
    margin-bottom: auto;
  }

  ${screen.lg} {
    margin: auto;
    align-items: flex-start;
  }
`;

export const Title = styled.h1`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-brand')});
  font-weight: ${getToken('font-weight-bold')};
  text-align: center;
  font-size: 38px;
  line-height: 47.5px;

  margin: 0;

  ${screen.md} {
    font-size: 48px;
    line-height: 60px;
  }

  ${screen.lg} {
    text-align: left;
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72.5px;
  }
`;

export const Paragraph = styled.p`
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-text-inverted')});
  font-size: 12px;
  line-height: 16.8px;
  text-align: center;

  margin: 24px auto;

  ${screen.md} {
    font-size: 14px;
    line-height: 21px;
  }

  ${screen.lg} {
    margin: 36px auto;
    text-align: left;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 36px;
  }
`;

export const Columns = styled.div`
  display: grid;
  justify-content: flex-start;
  padding-top: 16px;

  ${screen.md} {
    padding-top: unset;
    gap: 32px;
    grid-template-columns: 1fr 1fr;
  }

  * {
    color: rgb(${getToken('color-text-inverted')});
  }
`;
