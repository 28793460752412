import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';

import TaiLogo from '@/public/images/logos/logo-tai.svg';

const TaiWrapper = styled(FlexRowFullCentered)`
  width: 52px;
  height: 28px;

  ${screen.md} {
    width: 57px;
    height: 31px;
  }

  ${screen.lg} {
    width: 87px;
    height: 47px;
  }

  ${screen.xxlg} {
    width: 105px;
    height: 57px;
  }
`;

export const TaiCard = (props) => (
  <CardLayout {...props}>
    <TaiWrapper>
      <TaiLogo />
    </TaiWrapper>
  </CardLayout>
);
