// styles
import { TeamHeroWrapper } from './TeamHero.styles';

export function TeamHero() {
  return (
    <TeamHeroWrapper
      bg={{
        xxlarge: '/images/shipper/teamhero/loadsmart-team-hero-xxlarge@2x.jpg',
        large: '/images/shipper/teamhero/loadsmart-team-hero-xxlarge@2x.jpg',
        medium: '/images/shipper/teamhero/loadsmart-team-hero-xxlarge@2x.jpg',
        small: '/images/shipper/teamhero/loadsmart-team-hero-sm@2x.jpg',
      }}
    />
  );
}
