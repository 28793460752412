import styled from 'styled-components';

import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

export const Picture = styled.picture`
  width: 100%;
  display: none;
  box-sizing: border-box;
  margin: 80px auto 40px;
  font-family: ${getToken('platform-font-title')};

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
  }

  ${screen.md} {
    display: inline-grid;
    max-width: 670px;
  }

  ${screen.lg} {
    max-width: 974px;
  }

  ${screen.xxlg} {
    min-width: 1302px;
  }
`;
