//styles
import {
  FeaturesWrapper,
  FeaturedListWrapper,
  HeaderSection,
  Title,
  Description,
} from './OfferList.styles';

//containers
import { ContentPill } from '@/components/ContentPill';
import { Container } from '@/components/Grid';

//featured list assets
import Coding from '@/public/images/about/collection-loadsmart/Coding.svg';
import ContainerIcon from '@/public/images/about/collection-loadsmart/Container.svg';
import Customize from '@/public/images/about/collection-loadsmart/Customize.svg';
import Delivery from '@/public/images/about/collection-loadsmart/Delivery.svg';
import TechnicalSupport from '@/public/images/about/collection-loadsmart/TechnicalSupport.svg';
import Warehouse from '@/public/images/about/collection-loadsmart/Warehouse.svg';

export default function OfferList() {
  const featuredListContent = [
    {
      icon: <TechnicalSupport width="64" height="64" />,
      title: 'Managed Transportation & Consultancy Services',
      description:
        'Let us manage your transportation needs with a dedicated team of specialists and the latest technology so that you can focus on your core business. Implement our network optimization models for up to 20% in annual freight cost savings.',
      link: {
        url: '/shipper/managed-transportation/',
        label: 'Learn More',
      },
    },
    {
      icon: <Coding width="64" height="64" />,
      title: 'Freight Management Software',
      description:
        'Use our cost-effective, intuitive planning, procurement and execution platform to manage RFPs, handle spot procurement, set tender guidelines, and track freight with your carriers. The platform reduces annual freight costs by providing insights on price forecasts, real-time and historical pricing comparisons, and lane data.',
      link: {
        url: '/shipper/shipperguide/',
        label: 'Learn More',
      },
    },
    {
      icon: <ContainerIcon width="64" height="64" />,
      title: 'Freight Brokerage',
      description: (
        <>
          Our brokerage handles FTL, LTL, PTL, Drayage, and Intermodal freight,
          ensuring quality service. Features include:
          <ul>
            <li>Instant pricing</li>
            <li>Spot API Ratings into your TMS</li>
            <li>Dynamic Contracts that adjust to the market</li>
            <li>Booking and Tracking platform</li>
            <li>24/7 support of freight specialists</li>
          </ul>
        </>
      ),
      link: {
        url: '/shipper/',
        label: 'Learn More',
      },
    },
    {
      icon: <Warehouse width="64" height="64" />,
      title: 'Dock & Yard Solutions',
      description:
        'Opendock, trusted by over 3,000 warehouses, is the top dock scheduling software offering carrier scheduling, workflow tracking, and data-driven carrier performance insights. Enhance yard and asset visibility with our computer vision software and hardware that auto-reads plates, spots damages, and tracks trailers, streamlining check-ins and inventory management.',
      link: {
        url: '/warehouse/',
        label: 'Learn More',
      },
    },
    {
      icon: <Delivery width="64" height="64" />,
      title: 'Truck Management Software (CarrierTMS)',
      description:
        'Our Carrier TMS drives profitability for carriers and private fleets through an intuitive turnkey truck management system that connects carriers to an agnostic marketplace of loads, services, factoring, and integrations.',
      link: {
        url: '/carrier/tms/',
        label: 'Learn More',
      },
    },
    {
      icon: <Customize width="64" height="64" />,
      title: 'Tailor Made Logistics Solutions',
      description:
        'Need a partner for your specific supply chain needs? Loadsmart has more than 600 logistics experts offering you best in class services and top notch technology to help your business with flexible and tailored solutions. Give us a call and we can discuss building a custom solution for you.',
      link: {
        url: '/contact/',
        label: 'Learn More',
      },
    },
  ];

  return (
    <FeaturesWrapper>
      <Container>
        <HeaderSection>
          <Title>What we offer</Title>
          <Description>
            Loadsmart is transforming the logistics landscape by leveraging
            cutting-edge technology and industry expertise to deliver optimized,
            reliable, and transparent freight services and solutions. From
            digital freight brokerage and consultancy to software tools, we
            empower shippers, carriers, and warehouses to move more with
            less—increasing efficiency, reducing costs, and enhancing service
            quality.
          </Description>
        </HeaderSection>
        <FeaturedListWrapper>
          {featuredListContent.length > 0 &&
            featuredListContent.map((item, key) => {
              return <ContentPill key={`itemlist-` + key} content={item} />;
            })}
        </FeaturedListWrapper>
      </Container>
    </FeaturesWrapper>
  );
}

export { OfferList };
