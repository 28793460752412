import styled from 'styled-components';
import { screen } from '@/helpers/screen';
import { FlexRowWrap } from '@/components/Grid/Flex';
import { Link as BaseLink } from '@/components/Grid/Content';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 316px;
  margin: 0 auto;

  ${screen.md} {
    max-width: 674px;
  }

  ${screen.lg} {
    max-width: 968px;
  }

  ${screen.xxlg} {
    max-width: 1375px;
  }
`;

export const TMSWrapper = styled.section`
  padding: 40px 0 43px;

  ${screen.md} {
    padding: 50px 0;
  }

  ${screen.lg} {
    padding: 52px 0 53px;
  }

  ${screen.xxlg} {
    padding: 100px 0 100px;
  }
`;

export const Link = styled(BaseLink)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  color: ${({ theme }) => theme.color.neutral.darkest};

  font-weight: 700;
  font-size: 16px;
  line-height: 21px;

  ${screen.md} {
    justify-content: start;
    margin-top: 28px;

    font-size: 15px;
    line-height: 19px;
  }

  ${screen.lg} {
    margin-top: 36px;

    font-size: 20px;
    line-height: 26px;
  }

  ${screen.xxlg} {
    margin-top: 42px;

    font-size: 24px;
    line-height: 32px;
  }
`;

export const ImageWrapper = styled.a`
  box-sizing: border-box;
  width: 100%;
  max-width: 168px;
  margin: 0 auto;
  display: inline-grid;
  align-items: center;
  color: #010d2c;
  text-align: center;

  & > .resized {
    width: 85%;
  }

  ${screen.md} {
    max-width: 100%;
    margin: 0;

    &:first-child {
      text-align: start;
    }

    &:last-child {
      text-align: end;
    }
  }

  ${screen.lg} {
    height: 42px;
  }

  ${screen.xxlg} {
    height: 59px;
  }
`;

export const TMSLogoWrapper = styled(FlexRowWrap)`
  display: grid;
  gap: 33px;
  margin-top: 30px;

  ${screen.md} {
    grid-template-columns: repeat(5, 1fr);
    margin-top: 46px;
  }

  ${screen.lg} {
    gap: 48px;
    margin-top: 42px;
  }

  ${screen.xxlg} {
    gap: 60px;
    margin-top: 100px;
  }
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;

export const Subtitle = styled.p`
  ${({ theme }) => theme.font};
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.neutral.darkest};
  font-weight: 700;

  text-align: center;

  ${screen.md} {
    margin-top: 4px;
    margin-top: 10px;

    line-height: 26px;

    text-align: left;
  }

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    margin-top: 8px;

    font-size: 30px;
    line-height: 44px;
  }
`;

export const Content = styled.p`
  margin-top: 16px;

  font-weight: 500;
  font-size: 12px;
  line-height: 22px;

  text-align: center;

  ${screen.md} {
    margin-top: 28px;

    text-align: left;
  }

  ${screen.lg} {
    margin-top: 36px;

    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    margin-top: 42px;

    font-size: 20px;
    line-height: 38px;
  }
`;
