//components
import { Container } from '@/components/Grid/Container';

//styles
import { HeroWrapper } from './Hero.styles';

export const Hero = ({ children, image, ...other }) => (
  <HeroWrapper {...other}>
    <Container>{children}</Container>
  </HeroWrapper>
);
