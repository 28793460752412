import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';

import OneNetworkLogo from '@/public/images/logos/logo-one_network.svg';

const OneNetworkWrapper = styled(FlexRowFullCentered)`
  width: 70px;
  height: 26px;

  ${screen.md} {
    width: 84px;
    height: 30px;
  }

  ${screen.lg} {
    width: 134px;
    height: 48px;
  }

  ${screen.xxlg} {
    width: 142px;
    height: 51px;
  }
`;

export const OneNetworkCard = (props) => (
  <CardLayout {...props}>
    <OneNetworkWrapper>
      <OneNetworkLogo />
    </OneNetworkWrapper>
  </CardLayout>
);
