import styled, { css } from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container, InvertedSection } from '@/components/Grid';

const responsiveBackground = {
  sm: `url(/images/integrations/common/navsection/small_hero.jpg)`,
  md: `url(/images/integrations/common/navsection/medium_hero.jpg)`,
  lg: `url(/images/integrations/common/navsection/large_hero.jpg)`,
  xxlg: `url(/images/integrations/common/navsection/xxlarge_hero.jpg)`,
};

const BackgroundImageSection = styled(InvertedSection)`
  background-color: #ccc;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${(props) => props.image?.sm && `background-image: ${props.image?.sm}`};

  ${screen.md} {
    ${(props) => props.image?.md && `background-image: ${props.image?.md}`};
  }

  ${screen.lg} {
    ${(props) => props.image?.lg && `background-image: ${props.image?.lg}`};
  }

  ${screen.xxlg} {
    ${(props) => props.image?.xxlg && `background-image: ${props.image?.xxlg}`};
  }
  background-position: left;

  color: #ffffff;
  text-align: center;

  ${screen.md} {
    text-align: left;
  }
`;

export const WrapSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
`;

export const WrapSectionLayoutFix = styled(WrapSection)`
  margin: 0 -6px;

  ${screen.md} {
    margin: 0 -4px;
  }

  ${screen.lg} {
    margin: 0 -7px;
  }

  ${screen.xxlg} {
    margin: 0 -10px;
  }
`;

export const marginFix = css`
  margin: 6px;

  ${screen.md} {
    margin: 4px;
  }

  ${screen.lg} {
    margin: 7px;
  }

  ${screen.xxlg} {
    margin: 10px;
  }
`;

export const LinkSectionLayout = (props) => (
  <BackgroundImageSection image={responsiveBackground}>
    <Container {...props} />
  </BackgroundImageSection>
);
