//components
import { Container } from 'components/Grid';
import { FlexColumnFullCentered } from '@/components/Grid/Flex';

//context components
import {
  BackgroundSection,
  SimpleTextTitleStyled,
  TestimonialSlider,
  Testimonial,
  TestimonialAuthor,
  IconWrapper,
  CargowillLogo,
} from './TestimonialSection.styles';

export const TestimonialSection = () => (
  <>
    <BackgroundSection>
      <Container>
        <SimpleTextTitleStyled>
          A Track Record of Turning Challenges into Testimonials
        </SimpleTextTitleStyled>
        <TestimonialSlider>
          <FlexColumnFullCentered>
            <Testimonial>
              “I was initially attracted to Loadsmart because of their
              consistent and competitive prices. However, my account manager is
              a big part of why they have my continued business. The few times
              I’ve had issues with my loads, Conrad has resolved them and
              ensured that the delivery was still successful. Loadsmart has
              continuously been reliable and shown that my business is valued.”
            </Testimonial>
            <TestimonialAuthor>
              <IconWrapper>
                <CargowillLogo />
              </IconWrapper>
              <span>Jimmy Chou, President</span>
            </TestimonialAuthor>
          </FlexColumnFullCentered>
        </TestimonialSlider>
      </Container>
    </BackgroundSection>
  </>
);
