import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';

import UrouteLogo from '@/public/images/logos/logo-uroute.svg';

const UrouteCardWrapper = styled(FlexRowFullCentered)`
  width: 75px;
  height: 23px;

  ${screen.lg} {
    width: 110px;
    height: 33px;
  }

  ${screen.xxlg} {
    width: 150px;
    height: 45px;
  }
`;

export const UrouteCard = (props) => (
  <CardLayout {...props}>
    <UrouteCardWrapper>
      <UrouteLogo />
    </UrouteCardWrapper>
  </CardLayout>
);
