import styled from 'styled-components';
import { screen } from '@/helpers/screen';

import { Title3, Paragraph1 } from 'components/Text';

import { IconArrowsExpand } from '@loadsmart/icons';

export const CollapsedtListWrapper = styled.ul`
  list-style: none;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const CollapsedtListItem = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px auto;
  text-align: left;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;
  padding: 18px;
  overflow: hidden;

  ${screen.md} {
    padding: 27px 17px 27px 52px;
  }

  ${screen.xxlg} {
    padding: 35px 22px 35px 75px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
    background: ${(props) => props.theme.color.neutral.lighter};
    z-index: 2;

    width: 2.5px;

    ${screen.md} {
      width: 7.1px;
    }

    ${screen.xxlg} {
      width: 10px;
    }
  }

  &::after {
    background: linear-gradient(180deg, #46e575 0%, #00d7d7 100%);
    opacity: 0;
    transition: all 0.25s ease-in-out;
    z-index: 3;
  }

  &.collapsed {
    &::after {
      opacity: 1;
    }
  }
`;

export const CollapseTrigger = styled.button`
  position: relative;
  cursor: pointer;
  border: none;
  background: transparent;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  padding: 0;
  width: 100%;
`;

export const Header = styled(Title3)`
  text-align: left;
  margin: 0;
  padding: 0 0;
`;

export const Icon = styled(IconArrowsExpand)`
  width: 23px;
  height: 11px;
  transform: rotate(180deg);

  &.collapsed {
    transform: rotate(0);
  }

  ${screen.md} {
    width: 21px;
    height: 13px;
  }

  ${screen.xxlg} {
    width: 29.63px;
    height: 14.57px;
  }
`;

export const Description = styled(Paragraph1)`
  text-align: left !important;
  overflow: hidden;
  display: ${(props) => (props.collapsed ? `block` : `none`)};
  padding-right: 34px;

  ${screen.md} {
    padding-right: 70px;
  }

  a {
    text-decoration: underline;
  }

  ul {
    list-style-type: none;
    padding-left: 30px;

    li {
      margin-bottom: 2px;
      position: relative;
      color: inherit;

      &::before {
        content: '.';
        color: inherit;
        font-weight: bold;
        float: left;
        width: 14px;
        display: flex;
        top: -5px;
        left: -22px;
        position: absolute;
      }
    }
  }
`;
