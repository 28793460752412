import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

export const Title = styled.h2`
  margin-top: 100px;

  font-size: 16px;
  line-height: 26px;
  text-align: center;

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 42px;
  }
`;

export const ListContainer = styled.ul`
  display: grid;
  gap: 16px;
  margin: 50px auto 100px;
  padding: 0px;
  list-style: none;

  ${screen.md} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const PhotoWrapper = styled.li`
  margin: 0;
  padding: 0;

  text-align: center;

  ${screen.md} {
    /* Dealing with single orphan */
    &:last-child:nth-child(3n - 2) {
      grid-column-end: 3;
    }
  }
`;

export const Picture = styled.img`
  max-width: 100%;
  height: auto;
  margin: 30px auto;

  vertical-align: middle;
`;

export const Name = styled.h3`
  font-size: 16px;
  line-height: 22px;

  ${screen.md} {
    font-size: 16px;
    line-height: 20px;
  }
  ${screen.lg} {
    font-size: 21px;
    line-height: 26px;
  }
  ${screen.xxlg} {
    font-size: 26px;
    line-height: 36px;
  }
`;

export const Text = styled.p`
  margin: 11px 0;

  font-weight: 300;
  font-size: 12px;
  line-height: 22px;

  ${screen.md} {
    font-size: 12px;
    line-height: 20px;
  }
  ${screen.lg} {
    font-size: 16px;
    line-height: 26px;
  }
  ${screen.xxlg} {
    margin: 18px 0;

    font-size: 20px;
    line-height: 32px;
  }
`;

export const Link = styled(Text)`
  color: #000;
  font-weight: 700;
  text-decoration: underline;
`;
