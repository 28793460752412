import PropTypes from 'prop-types';
import styled from 'styled-components';

//assets
import { IconStar } from '@loadsmart/icons';

//componets
import { FlexRow, FlexColumnFullCentered } from '@/components/Grid/Flex';
import { FeatureTitle } from '@/components/Feature';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

const GlassdoorTitle = styled(FeatureTitle)`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
`;

const GreenStar = styled(IconStar)`
  color: rgb(${getToken('color-brand')});
`;

const GlassdoorReview = ({ stars = 0 }) => (
  <FlexColumnFullCentered>
    <GlassdoorTitle>GLASSDOOR REVIEW</GlassdoorTitle>
    <FlexRow>
      {[...Array(stars)].map((start, index) => (
        <GreenStar key={`start-${index}`} width={20} height={20} />
      ))}
    </FlexRow>
  </FlexColumnFullCentered>
);

GlassdoorReview.defaultProps = {
  stars: 0,
};

GlassdoorReview.propTypes = {
  stars: PropTypes.number,
};

export { GlassdoorReview };
