import React from 'react';

//components
import {
  CenteredWrapper,
  ReversedCenteredWrapper,
  WideImageOnMobile,
  Title,
  Text,
} from '@/components/Grid/Content';
import { SectionPanel } from '@/components/Grid/Panel';

export const PanelSection = () => (
  <>
    <SectionPanel reverse>
      <WideImageOnMobile
        src={`/images/mode-optimization/half_section-move_more.jpg`}
        alt="Move more with less cost"
      />
      <ReversedCenteredWrapper>
        <Title>Move more with less cost</Title>
        <Text>
          Maximize your cost savings and increase flexibility. Receive multiple
          instantly bookable mode options for a single shipment and reduce costs
          by 20-50%.
        </Text>
      </ReversedCenteredWrapper>
    </SectionPanel>

    <SectionPanel>
      <WideImageOnMobile
        src={`/images/mode-optimization/half_section-automated_mode.jpg`}
        alt="Automated mode selection"
      />
      <CenteredWrapper>
        <Title>Automated mode selection</Title>
        <Text>
          Loadsmart can implement business rules aligned to your unique
          requirements to allow the AI system to automatically select your
          “best” mode, eliminating the need for manual decision making.
        </Text>
      </CenteredWrapper>
    </SectionPanel>

    <SectionPanel reverse>
      <WideImageOnMobile
        src={`/images/mode-optimization/half_section-mode_optimization.jpg`}
        alt="Mode optimization consultations"
      />
      <ReversedCenteredWrapper>
        <Title>Mode optimization consultations</Title>
        <Text>
          Take advantage of a free mode optimization consultation. Share your
          shipping objectives and receive insights on multiple mode options for
          your freight.
          <br />
          <br />
          Additionally, our account managers run on-going historical and
          predictive data analysis to share insights that inform your future
          decisions.
        </Text>
      </ReversedCenteredWrapper>
    </SectionPanel>
  </>
);
