import styled from 'styled-components';
import { screen } from '@/helpers/screen';
import { Container } from 'components/Grid';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const OutlinedBoxWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px 2px !important;
  background: linear-gradient(180deg, #ffffff 0%, #46e575 70%, #00d7d7 100%);

  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  ${screen.lg} {
    padding: 0 60px 20px;
  }
`;

export const InnerBox = styled.div`
  position: relative;
  background: rgb(${getToken('color-background-primary')});
  mix-blend-mode: normal;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  padding: 16px;
  border: solid 2px transparent;
  border-top-width: 0;

  ${screen.lg} {
    padding: 32px;
  }
`;
