import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//components
import {
  Feature,
  FeatureTitle,
  FeatureDescription,
  FeatureGroup,
} from '@/components/Feature';
import { Title } from '@/components/Grid';

export const GridSection = styled.section`
  background: rgb(${getToken('color-background-secondary')});
  padding: 40px 0;

  ${screen.md} {
    padding: 60px 0;
  }

  ${screen.lg} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const StyledFeatureGroup = styled(FeatureGroup)`
  flex-direction: column;

  ${screen.lg} {
    flex-direction: row;
  }
`;

export const TitleWrapper = styled.div`
  display: grid;
  text-align: center;
  justify-content: center;
  margin: 0 auto 30px;

  ${screen.lg} {
    margin: 0 auto 50px;
  }
  ${screen.lg} {
    margin: 0 auto 64px;
  }
  ${screen.xxlg} {
    margin: 0 auto 100px;
  }
`;

export const TitleSection = styled(Title)`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 38px;
  line-height: 47.5px;
`;

export const Principle = styled(Feature)`
  box-sizing: border-box;
  margin: 0 auto 24px;
  width: 100% !important;
  justify-content: space-between;

  ${screen.lg} {
    padding: 0 9px;
    margin: 0 auto;
    width: 33% !important;
  }

  ${screen.xxlg} {
    padding: 0 10px;
  }
`;

export const StyledFeatureTitle = styled(FeatureTitle)`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-medium')};
  font-size: 26px;
  line-height: 39px;
  margin: 0 0 24px;
  text-transform: none;

  ${screen.md} {
    text-align: left !important;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 47.5px;
    margin: 0 0 36px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
    margin: 0 0 40px;
  }
`;

export const StyledFeatureDescription = styled(FeatureDescription)`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  text-align: start;
  font-size: 14px;
  line-height: 21px;

  margin: 0 auto 24px;

  ${screen.lg} {
    font-size: 20px;
    line-height: 36px;
  }
`;

export const ImageWrapper = styled.div`
  align-self: flex-end;
  justify-self: flex-end;

  source,
  img {
    max-width: 100%;
    width: 312px;
    height: auto;

    ${screen.md} {
      width: 770px;
      height: auto;
    }

    ${screen.lg} {
      width: 312px;
      height: 345px;
    }

    ${screen.xxlg} {
      width: 440px;
      height: 345px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  margin: 30px auto 0;

  ${screen.md} {
    margin: 50px auto 0;
  }
  ${screen.lg} {
    margin: 64px auto 0;
  }
  ${screen.xxlg} {
    margin: 100px auto 0;
  }
`;
