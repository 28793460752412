import { useRouter } from 'next/router';

//helpers
import { sleep } from '@/helpers/sleep';

//containers
import { Title, Description } from '@/containers/Hero/Hero.styles';

//components
import { BaseButton } from '@/components/Button';

// context containers
import {
  smallFont,
  ctaStyle,
  FlexRowWrap,
  HeroBackground,
} from './HeroSection.styles';

const responsiveBackground = {
  sm: `url(/images/smb-shipping/hero/hero-image-small@2x.jpg);`,
  md: `url(/images/smb-shipping/hero/hero-image-medium.jpg);`,
  lg: `url(/images/smb-shipping/hero/hero-image-large.jpg);`,
  xxlg: `url(/images/smb-shipping/hero/hero-image-xxlarge.jpg);`,
};

export const HeroSection = () => {
  const router = useRouter();

  const handleFormJump = async (event) => {
    event.preventDefault();
    await sleep(300);
    router.push('/smb-shipping/#hubspot-form');
  };

  return (
    <HeroBackground image={responsiveBackground}>
      <Title>Small Business Solutions forged from enterprise technology</Title>
      <Description>
        Leverage the same tools and operating power as the Fortune 100 to
        instantly quote and book spot loads and price and award contract
        business
      </Description>
      <FlexRowWrap>
        <BaseButton as="a" onClick={handleFormJump} css={[ctaStyle, smallFont]}>
          Talk to a Transportation Specialist
        </BaseButton>
      </FlexRowWrap>
    </HeroBackground>
  );
};
