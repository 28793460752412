//components
import { Container, Text } from '@/components/Grid';
import { Section } from '@/components/Grid/Section';
import { TitleSection } from '@/components/Grid/Content';

export const IntroductionSection = () => (
  <Section>
    <Container>
      <TitleSection>Small Business, Big Logistics Challenges</TitleSection>
      <Text>
        As an SMB Shipper, you’re used to dealing with headaches and challenges,
        but many can be controlled with the right resources. That’s easier said
        than done: you neither have the resources on hand nor the capacity to
        find them. Loadsmart provides dedicated account managers to assist in
        these areas. We guarantee our shippers 100% tender acceptance, mode
        optimization, and worry free shipping with Loadsmart taking full
        responsibility for all loads from point A to B.
      </Text>
    </Container>
  </Section>
);
