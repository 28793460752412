import PropTypes from 'prop-types';
import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container, InvertedSection } from '@/components/Grid';
import { Button } from '@/components/Button';

const Layout = styled(InvertedSection)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 40px !important;

  text-align: center;

  &.background {
    padding: 40px 0 !important;
    color: #ffffff;
    background-color: #d3d9d9;
    background-image: url(/images/integrations/common/fullscreen_section-small_background.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  ${screen.md} {
    padding: 0 0 60px !important;

    text-align: left;

    &.background {
      padding: 60px 0 !important;
      background-image: url(/images/integrations/common/fullscreen_section-medium_background.png);
    }
  }

  ${screen.lg} {
    padding: 0 0 100px !important;

    &.background {
      padding: 100px 0 !important;
      background-image: url(/images/integrations/common/fullscreen_section-large_background.png);
    }
  }

  ${screen.xxlg} {
    &.background {
      background-image: url(/images/integrations/common/fullscreen_section-xxlarge_background.png);
    }
  }
`;

const Title = styled.h1`
  margin-bottom: 16px;

  font-size: 25px;
  line-height: 36px;

  ${screen.md} {
    margin-bottom: 20px;

    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    margin-bottom: 30px;

    font-size: 38px;
    line-height: 55px;
  }

  ${screen.xxlg} {
    margin-bottom: 28px;

    font-size: 48px;
    line-height: 72px;
  }
`;

const SubTitle = styled.h2`
  margin-top: 15px;
  margin-bottom: 25px;

  font-size: 16px;
  line-height: 22px;

  ${screen.md} {
    line-height: 26px;
  }

  ${screen.lg} {
    margin-bottom: 35px;

    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 42px;
  }
`;

const ListBenefits = styled.ul`
  padding: 0;
  margin: 0;
`;

const BenefitItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  font-size: 12px;
  line-height: 22px;

  ${screen.md} {
    flex-direction: row;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
  }

  svg {
    max-width: 48px;
    max-height: 48px;
    margin-top: -10px;

    color: #29d454;
    vertical-align: middle;

    ${screen.md} {
      margin-right: 20px;
    }

    ${screen.lg} {
      max-width: unset;
      max-height: unset;
    }
  }
`;

const Text = styled.p`
  font-size: 12px;
  line-height: 22px;

  ${screen.md} {
    max-width: 498px;

    font-size: 12px;
    line-height: 22px;
  }

  ${screen.lg} {
    max-width: 639px;

    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    max-width: 821px;

    font-size: 20px;
    line-height: 38px;
  }
`;

export const BackgroundImageSection = ({ content }) => (
  <Layout className={content.nobackground ? 'nobackground' : 'background'}>
    <Container>
      {content.title ? (
        <Title>{content.title}</Title>
      ) : (
        <Title>Stay ‘in the know’ with real-time tracking</Title>
      )}
      <Text>
        {content?.text && content.text}
        <br />
        <br />
      </Text>
      {content?.benefits && (
        <>
          <SubTitle>Benefits</SubTitle>
          <ListBenefits>
            {content?.benefits.map((item, key) => {
              return (
                <BenefitItem key={`benefits-list-${key}`}>
                  {item.icon}
                  {item.copy}
                </BenefitItem>
              );
            })}
          </ListBenefits>
        </>
      )}
      {content?.cta && (
        <>
          <br />
          <br />
          <SubTitle>
            See what other shippers say about Loadsmart’s reliable service
          </SubTitle>
          <Button as="a" href={content.cta.url}>
            {content.cta.title}
          </Button>
        </>
      )}
    </Container>
  </Layout>
);

BackgroundImageSection.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string,
    benefits: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.element,
          copy: PropTypes.string,
        })
      ),
      PropTypes.bool,
    ]),
  }).isRequired,
};
