// components
import { Container } from 'components/Grid';

// styles
import { HeroWrapper, Title, ButtonWrapper } from './Hero.styles';
import { MirandaHollowButton } from '@/components/Button';

export function Hero() {
  return (
    <HeroWrapper
      bg={{
        xxlarge: 'images/home/hero/home-banner-xxlg.jpg',
        large: 'images/home/hero/home-banner-lg.jpg',
        medium: 'images/home/hero/home-banner-md.jpg',
        small: 'images/home/hero/home-banner-sm.jpg',
      }}
    >
      <Container>
        <Title>
          Logistics solutions that lower costs, increase efficiency, and
          digitize transportation.
        </Title>
        <ButtonWrapper>
          <MirandaHollowButton as="a" href="/#contact-form">
            Get in touch
          </MirandaHollowButton>
        </ButtonWrapper>
      </Container>
    </HeroWrapper>
  );
}
