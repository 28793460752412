import React, { useEffect, useState } from 'react';

// services
import { XMLParser } from 'fast-xml-parser';

// styles
import {
  NewsroomWrapper,
  Container,
  Title,
  Content,
  BlogItem,
  BlogTitle,
  BlogExcerpt,
  Link,
  LinkButton,
} from './Newsroom.styles';

// loader
import { Loader } from './Loader';

export function Newsroom() {
  const [feed, setFeed] = useState(null);
  const url = 'https://blog.loadsmart.com/rss.xml';
  const parser = new XMLParser();
  const POSTS_SHOWN = 3;
  const EXCERPT_LENGHT = 330;
  const TAGS_INCLUDED = ['Blog'];

  // Create the XHR object.
  function createCORSRequest(method, urlq) {
    var xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      // XHR for Chrome/Firefox/Opera/Safari.
      xhr.open(method, urlq, true);
    } else if (typeof XDomainRequest != 'undefined') {
      // XDomainRequest for IE.
      xhr = new XDomainRequest();
      xhr.open(method, urlq);
    } else {
      // CORS not supported.
      xhr = null;
    }
    return xhr;
  }

  // Helper method to parse the content from the response.
  function mountFeedArray(rssPosts) {
    var listPosts = [];

    rssPosts.rss.channel.item.map((post, key) => {
      if (
        post.hasOwnProperty('category') &&
        post.category.find((element) => TAGS_INCLUDED.includes(element))
      ) {
        var imagePath = post.description
          .match('<img src="(.*)?>')[1]
          .split('"')[0];

        var blogExcerpt = new DOMParser()
          .parseFromString(post.description, 'text/html')
          .body.textContent.substring(0, EXCERPT_LENGHT);
        blogExcerpt = blogExcerpt.substring(0, blogExcerpt.lastIndexOf(' '));

        if (listPosts.length < POSTS_SHOWN) {
          listPosts.push({
            title: post.title,
            url: post.link,
            release: post.pubDate.substring(0, 16),
            description: blogExcerpt + '…',
            image: imagePath,
          });
        }
      }
    });
    return listPosts;
  }

  // Make the actual CORS request.
  function makeCorsRequest() {
    // This is a sample server that supports CORS.
    var xhr = createCORSRequest('GET', url);
    if (!xhr) {
      console.error('CORS not supported');
      return;
    }

    // Response handlers.
    xhr.onload = function () {
      var text = xhr.responseText;
      var rssfeed = mountFeedArray(parser.parse(text));
      setFeed(rssfeed);
    };

    xhr.onerror = function () {
      console.error('Woops, there was an error making the RSS request.');
    };

    xhr.send();
  }

  useEffect(() => {
    makeCorsRequest();
  }, []);

  return (
    <NewsroomWrapper>
      <Container>
        <Title>Newsroom </Title>
        {feed && (
          <Content>
            {feed.map((item, key) => {
              return (
                <BlogItem key={key}>
                  <BlogTitle>{item.title}</BlogTitle>
                  <BlogExcerpt>{item.description}</BlogExcerpt>
                  <Link href={item.url}>Read more</Link>
                </BlogItem>
              );
            })}
          </Content>
        )}

        {!feed && <Loader />}

        <LinkButton href="https://blog.loadsmart.com/" target="_blank">
          Read more articles in our blog
        </LinkButton>
      </Container>
    </NewsroomWrapper>
  );
}
