// styles
import {
  NewsroomWrapper,
  Container,
  Title,
  Content,
  BlogItem,
  BlogTitle,
  BlogExcerpt,
  Link,
  LinkButton,
} from './Newsroom.styles';

const feed = [
  {
    title:
      'Learn with Loadsmart: Leveraging Managed Transportation to Build a Resilient Network Strategy',
    description:
      'At Loadsmart, our strongest asset is our people. Just like the supply chain, it takes a lot of people with different expertise across many areas of logistics, transportation, and technology to keep our business running and delivering value to our carrier and shipper customers in existing and new ways. ',
    url: 'https://blog.loadsmart.com/2022/06/01/learn-with-loadsmart-leveraging-managed-transportation-to-build-a-resilient-network-strategy',
  },
  {
    title: 'Finding Technology & Services that Meet Customers Where They Are',
    description:
      'During FreightWaves’ Future of Freight event, my colleague, Art Cady, Director of Digital Sales, and I demonstrated Loadsmart’s tool for centralized capacity planning, procurement, execution and data insights while also introducing the audience to our newly established Managed Transportation Services capabilities.',
    url: 'https://blog.loadsmart.com/2022/11/09/finding-technology-services-that-meet-customers-where-they-are',
  },
  {
    title:
      'Three Takeaways from the Freight Brokerage Automation Panel at 3PL Value Creation Summit',
    description:
      'Digital Freight Matching & Freight Brokerage Automation Panel, Wednesday, October 12, 2022. Last week I had the opportunity to participate on an esteemed panel of industry leaders to speak at the 3PL Value Creation Summit hosted by Armstrong & Associates, Inc. in our HQ home of Chicago. It was a conversation focused on how digitalization...',
    url: 'https://blog.loadsmart.com/2022/11/09/finding-technology-services-that-meet-customers-where-they-are',
  },
];

export function Newsroom() {
  return (
    <NewsroomWrapper>
      <Container>
        <Title>
          Learn more about our <span>Managed Transportation</span>
          <br /> benefits in our Resource Center
        </Title>
        {feed && (
          <Content>
            {feed.map((item, key) => {
              return (
                <BlogItem key={key}>
                  <BlogTitle>{item.title}</BlogTitle>
                  <BlogExcerpt>{item.description}</BlogExcerpt>
                  <Link href={item.url}>Read more</Link>
                </BlogItem>
              );
            })}
          </Content>
        )}

        <LinkButton href="https://blog.loadsmart.com/" target="_blank">
          Read more articles in our blog
        </LinkButton>
      </Container>
    </NewsroomWrapper>
  );
}
