// styles
import {
  FloatHeroBoxWrapper,
  Container,
  Title,
  Subtitle,
  Content,
  Button,
} from './FloatHeroBox.styles';

export function FloatHeroBox() {
  return (
    <FloatHeroBoxWrapper>
      <Container>
        <Title>
          Instantly price, book and ship freight <br /> with guaranteed capacity
        </Title>
        <Subtitle>
          Global leaders trust Loadsmart to service and support their supply
          chains with exceptional service, best-in-class logistics
          professionals, a partnership approach and easy to use shipping
          software.
        </Subtitle>
        <Content>
          <Button href={`${process.env.SHIPPERGUIDE_URL}/signup`}>
            Get a Quote Now
          </Button>
        </Content>
      </Container>
    </FloatHeroBoxWrapper>
  );
}
