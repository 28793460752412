import { useState } from 'react';
import styled from 'styled-components';

import { screen } from '@/helpers/screen';
import { Arrow, Arrows } from './Arrows';

const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const SlideGroup = styled.div`
  display: flex;
  height: 100%;
  transition: transform 0.25s;
  ${({ listSize, slidesPerPage, currentPosition, slidesPerPageOnMobile }) => `
        width: ${(100 * listSize) / slidesPerPageOnMobile}%;
        transform: translateX(${-1 * currentPosition * (100 / listSize)}%);

        ${screen.md} {
            width: ${(100 * listSize) / slidesPerPage}%;
        }
    `};

  & > * {
    flex-basis: 0;
    flex-grow: 1;
  }
`;

export const Slider = ({
  slidesPerPage = 1,
  slidesPerPageOnMobile = 1,
  selected = 0,
  children,
  ...other
}) => {
  const [index, setIndex] = useState(selected);
  const slideCount = children.length;

  const goToSlide = (newIndex) => {
    if (newIndex < 0) newIndex = 0;
    if (newIndex >= slideCount) newIndex = children.length - 1;
    setIndex(newIndex);
  };

  return (
    <Wrapper {...other}>
      <Arrows
        index={index}
        showLeftArrow={index > 0}
        showRightArrow={index < slideCount - slidesPerPage}
        showRightArrowMobile={index < slideCount - slidesPerPageOnMobile}
        onLeftArrowClick={() => goToSlide(index - 1)}
        onRightArrowClick={() => goToSlide(index + 1)}
      />

      <InnerContainer>
        <SlideGroup
          listSize={children.length}
          slidesPerPageOnMobile={slidesPerPageOnMobile}
          currentPosition={index}
          slidesPerPage={slidesPerPage}
        >
          {children}
        </SlideGroup>
      </InnerContainer>
    </Wrapper>
  );
};

export const DarkSlider = styled(Slider)`
  ${Arrow} {
    color: #d3d9d9;
  }
`;

export * from './Content';
