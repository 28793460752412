//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '/containers-m/';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { BannerStrip } from '@/containers-m/BannerStrip';

// context containers
import {
  Hero,
  FloatHeroBox,
  IntroductionText,
  FeaturesCarousel,
  SquaresMenu,
  TMSIntegration,
  SidePanels,
  TeamHero,
  FloatTeamHeroBox,
} from './containers/index';

//styles
import { PageWrapper } from './Shipper.styles';

//setup info
import { tmsIntegrationOptions } from '@/vendor/HubspotFormIntegration';

const bannerContent = (
  <p>
    Unlock the full potential of your freight procurement RFP.{' '}
    <a
      href="https://lp.loadsmart.com/whitepaper-volatile-lanes?utm_source=loadsmart&utm_medium=banner&utm_campaign=reliable-contracts-es-brokerage-q3-23&utm_content=shipper-page"
      target="_blank"
      rel="noopener noreferrer"
    >
      Download eBook!
    </a>
  </p>
);

function Shipper() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <BannerStrip content={bannerContent} />
        <Hero />
        <FloatHeroBox />
        <IntroductionText />
        <FeaturesCarousel />
        <SquaresMenu />
        <TMSIntegration />
        <SidePanels />
        <TeamHero />
        <FloatTeamHeroBox />
        <HubspotForm {...tmsIntegrationOptions}>
          <HubspotFormTitle>
            Talk to a Transportation Specialist
          </HubspotFormTitle>
          <HubspotFormDescription>
            Our team of industry experts and technicians are here to answer your
            questions.
          </HubspotFormDescription>
        </HubspotForm>
      </>
      <Footer />
    </PageWrapper>
  );
}

export default Shipper;
export { Shipper };
