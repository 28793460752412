//vendor
import ReactPlayer from 'react-player/lazy';

// components
import { Container } from 'components/Grid';
import {
  MirandaSecondaryButton,
  MirandaHollowSecondaryButton,
} from '@/components/Button/BaseButton';

// styles
import {
  LifeLoadVideoWrapper,
  Title,
  VideoWrapper,
  CTAWrapper,
} from './LifeLoadVideo.styles';

export function LifeLoadVideo() {
  return (
    <LifeLoadVideoWrapper>
      <Container>
        <Title>Reinventing the future of freight operations</Title>
        <VideoWrapper>
          <ReactPlayer
            className="react-player"
            url="//www.youtube.com/watch?v=TPaNcx_NowY?rel=0"
            controls
            width="100%"
            height="100%"
          />
        </VideoWrapper>
      </Container>
      <CTAWrapper>
        <MirandaSecondaryButton as="a" href="/shipper/#hubspot-form">
          Request a Demo
        </MirandaSecondaryButton>
        <MirandaHollowSecondaryButton
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href="https://lp.loadsmart.com/case-studies"
        >
          Explore our Case Studies
        </MirandaHollowSecondaryButton>
      </CTAWrapper>
    </LifeLoadVideoWrapper>
  );
}
