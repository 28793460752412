import styled from 'styled-components';
import { screen } from '@/helpers/screen';
import { Container } from 'components/Grid';
import { Paragraph1 } from 'components/Text';

export const HeaderSectionWrapper = styled.header``;

export const HeaderSectionContainer = styled(Container)``;

export const HeaderSectionContent = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  margin: auto;
  max-width: 450px;
  width: 100%;
  background: ${(props) => props.theme.color.neutral.lightest};

  ${screen.md} {
    padding: 60px 0;
    max-width: 682px;
  }

  ${screen.lg} {
    padding: 40px 0;
    max-width: 740px;
  }

  ${screen.xxlg} {
    padding: 40px 0;
    max-width: ${(props) => (props.width ? props.width : `1150px`)};
  }
`;

export const HeaderSubtitle = styled(Paragraph1)`
  font-size: 14px;
  line-height: 21px;

  ${screen.md} {
    font-size: 20px;
    line-height: 36px;
  }
`;
