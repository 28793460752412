import styled from 'styled-components';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

import {
  HeroSection,
  IntroSection,
  LessCO2Section,
  PanelSection,
  AvailableModesSection,
} from './containers/index';

import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { modeOptimizationOptions } from '@/vendor/HubspotFormIntegration';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

const ModeOptimization = () => {
  return (
    <>
      <PageWrapper>
        <Navigation />
        <>
          <HeroSection />
          <IntroSection />
          <LessCO2Section />
          <PanelSection />
          <AvailableModesSection />
          <HubspotForm {...modeOptimizationOptions}>
            <HubspotFormTitle>
              Talk to a Transportation Specialist
            </HubspotFormTitle>
            <HubspotFormDescription>
              Our team of industry experts and technicians are here to answer
              your questions.
            </HubspotFormDescription>
          </HubspotForm>
        </>
        <Footer />
      </PageWrapper>
    </>
  );
};

export default ModeOptimization;
export { ModeOptimization };
