import styled from 'styled-components';

import { screen } from '@/helpers/screen';

//components
import { BaseButton } from '@/components/Button';
import { Container } from 'components/Grid';

export const BannerCTAWrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 0;

  text-align: center;

  background-color: #202931;

  ${screen.lg} {
    padding: 100px 0;
  }
`;

export const StyledContainer = styled(Container)`
  max-width: 328px;

  ${screen.md} {
    max-width: 724px;
  }

  ${screen.lg} {
    max-width: 980px;
  }

  ${screen.xxlg} {
    max-width: 1380px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 10px;
  margin-top: 0px;
  color: #14d64d;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin-bottom: 8px;

    font-size: 48px;
    line-height: 72px;
  }
`;

export const Text = styled.p`
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin: 0px;

  ${screen.md} {
    line-height: 26px;
  }

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 28px;
    line-height: 44px;
  }
`;

export const StyledButton = styled(BaseButton)`
  display: inline-block;
  min-width: 199px;
  margin: 40px auto 0;
  padding: 9px 50px;

  color: #202931;

  font-size: 12px;
  line-height: 16px;
  text-transform: none;

  ${screen.md} {
    line-height: 16px;
  }

  ${screen.lg} {
    min-width: 310px;
    margin: 64px auto 0;
    padding: 11px 49px;

    font-size: 26px;
    line-height: 38px;
  }
`;
