import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

export const MainContent = styled.main`
  text-align: center;
`;

export const OpaqueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-color: rgb(${getToken('color-background-tertiary')});

  padding: 50px 0;

  ${screen.lg} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 40px 0 0;
  text-align: center;

  ${screen.md} {
    padding: 70px 0 0;
  }

  ${screen.xxlg} {
    padding: 100px 0 0;
  }
`;
