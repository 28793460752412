import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';

export const FAQWrapper = styled(Container)`
  padding: 40px 0 20px;

  ${screen.md} {
    padding: 80px 0 20px;
  }

  ${screen.lg} {
    padding: 80px 0 50px;
  }

  ${screen.xxlg} {
    padding: 80px 0 80px;
  }
`;

export const FAQHeader = styled(Container)`
  padding: 20px 0;

  ${screen.md} {
    max-width: 600px;
  }

  ${screen.lg} {
    max-width: 840px;
  }

  ${screen.xxlg} {
    max-width: 1120px;
  }
`;
