//containers
import { GlassdoorReview } from '@/containers/GlassdoorReview';
import {
  BackgroundSection,
  CustomContainer,
  CenterSubTitle,
  TestimonialSlider,
  Testimonial,
  TestimonialItem,
} from './CarouselSection.styles';

export const CarouselSection = () => {
  const testmonial_list = [
    {
      text: `Amazing culture, leadership really listens to you. It’s clear the impact you have in the company`,
      rating: 5,
    },
    {
      text: `I feel I’ve joined an innovative and cutting edge company that’s
              changing the face of logistics as we know it. Hackathon was one of
              the coolest things I’ve ever been part of`,
      rating: 5,
    },
    {
      text: `Of course perks as snacks, breakfast and dinner every Wednesday
              are a nice bonus. The opportunity to work with a company growing
              and the ability to work closely with those who are in charge are
              the real bonus`,
      rating: 5,
    },
    {
      text: `While you don’t have to be friends with people you work with, at
              Loadsmart, it is hard not to be`,
      rating: 5,
    },
    {
      text: `Awesome people who work hard and genuinely believe in the company
              and its mission. Lots of opportunities for professional growth if
              you show interest and drive`,
      rating: 5,
    },
    {
      text: `Everyone knows how to enjoy the flexibility of a startup allied
              with responsibility and efficiency to do their job`,
      rating: 5,
    },
  ];

  return (
    <BackgroundSection>
      <CustomContainer>
        <CenterSubTitle>Employee reviews</CenterSubTitle>

        <TestimonialSlider>
          {testmonial_list &&
            testmonial_list.map((item, key) => {
              return (
                <TestimonialItem key={`testimon-hr-` + key}>
                  <Testimonial>“{item.text}”</Testimonial>
                  <GlassdoorReview stars={item.rating} />
                </TestimonialItem>
              );
            })}
        </TestimonialSlider>
      </CustomContainer>
    </BackgroundSection>
  );
};
