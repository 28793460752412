//components
import { ContentPill } from '@/components/ContentPill';

//styles
import {
  SideFeaturesWrapper,
  TitleHolder,
  Title,
  Paragraph,
  Columns,
  CustomContainer,
} from './SideFeatures.styles';
import { MirandaSecondaryInvertedButton } from '@/components/Button';

export function SideFeatures({ content }) {
  return (
    <>
      <SideFeaturesWrapper>
        <CustomContainer>
          <TitleHolder>
            <Title>Let us handle your niche expediting needs!</Title>
            <Paragraph>
              Loadsmart’s team has experience handling expedited shipments in a
              wide range of industries and situations.
            </Paragraph>
            <MirandaSecondaryInvertedButton as="a" href="#hubspot-form">
              Scale Your Fleet Now
            </MirandaSecondaryInvertedButton>
          </TitleHolder>

          <Columns>
            {content.items.length > 0 &&
              content.items.map((item, key) => {
                return (
                  <ContentPill
                    key={item.title.trim() + key}
                    content={{
                      icon: item.icon,
                      title: item.title,
                      description: <>{item.description}</>,
                    }}
                  />
                );
              })}
          </Columns>
        </CustomContainer>
      </SideFeaturesWrapper>
    </>
  );
}
