import ReactPlayer from 'react-player/lazy';

//styles
import {
  ContainerStyled,
  VideoContainer,
  VideoWrapper,
} from './VideoHero.styles';

export default function VideoHero() {
  return (
    <>
      <ContainerStyled>
        <VideoWrapper>
          <VideoContainer>
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=BpDKi0CLly0"
              controls
              width="100%"
              height="100%"
            />
          </VideoContainer>
        </VideoWrapper>
      </ContainerStyled>
    </>
  );
}

export { VideoHero };
