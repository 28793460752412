import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//components
import { Container } from '@/components/Grid';

export const FormBackground = styled.div`
  padding: 60px 0;
  background-color: rgb(${getToken('color-background-inverted')});

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const ContainerForm = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  height: 100%;
  gap: 4px;

  ${screen.md} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  ${screen.lg} {
    gap: 60px;
  }

  ${screen.xxlg} {
    gap: 145px;
  }
`;

export const FormHolder = styled.div`
  width: 100%;

  section {
    padding: 20px;

    & > div {
      width: 100%;
    }
  }

  ${screen.md} {
    width: 330px;
  }
  ${screen.lg} {
    width: 382px;
  }
  ${screen.xxlg} {
    width: 597px;
  }

  form {
    ${screen.lg} {
      padding: 8px;
    }

    div input {
      min-width: unset !important;
      max-width: unset !important;
      width: 100% !important;
    }
  }
`;

export const ContentHolder = styled.div`
  max-width: 100%;

  ${screen.md} {
    width: 308px;
  }

  ${screen.lg} {
    width: 526px;
  }

  ${screen.xxlg} {
    width: 618px;
  }
`;

export const Title = styled.div`
  color: rgb(${getToken('color-brand')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 28px;
  line-height: 43.7px;
  text-align: center;

  strong {
    color: rgb(${getToken('color-brand')});
  }

  ${screen.md} {
    text-align: start;
    font-size: 26px;
    line-height: 39px;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 47.5px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const SubTitle = styled.p`
  color: rgb(${getToken('color-text-inverted')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  text-align: center;
  font-size: 14px;
  line-height: 22.4px;

  ${screen.md} {
    text-align: start;
    font-size: 16px;
    line-height: 24px;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 39px;

    margin: 24px 0 0px;
  }
`;
