import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

// components
import { Container as BaseContainer } from 'components/Grid';

export const NewsroomWrapper = styled.section`
  padding: 50px 0;

  ${screen.lg} {
    padding: 70px 0;
  }
  ${screen.xxlg} {
    padding: 80px 0;
  }
`;

export const Title = styled.h2`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  margin: 0;
  margin-bottom: 26px;
  font-size: 28px;
  line-height: 40px;
  text-align: center;

  ${screen.md} {
    margin-bottom: 60px;
  }
  ${screen.lg} {
    margin-bottom: 70px;
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin-bottom: 59px;

    font-size: 48px;
    line-height: 72px;
  }
`;

export const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Content = styled.div`
  display: grid;
  gap: 20px;
  justify-content: center;

  ${screen.md} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const BlogItem = styled.article`
  display: flex;
  flex-direction: column;
  padding: 30px;

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.3);
`;

export const BlogTitle = styled.div`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 16px;
  line-height: 22px;

  ${screen.md} {
    line-height: 20px;
  }
  ${screen.lg} {
    font-size: 21px;
    line-height: 26px;
  }
  ${screen.xxlg} {
    font-size: 26px;
    line-height: 32px;
  }
`;

export const BlogExcerpt = styled.div`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('font-family-default')};
  margin: 20px 0;

  font-size: 12px;
  line-height: 22px;

  ${screen.md} {
    line-height: 20px;
  }
  ${screen.lg} {
    font-size: 16px;
    line-height: 26px;
  }
  ${screen.xxlg} {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const Link = styled.a`
  align-self: flex-end;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-primary-60')});
  font-size: 13px;
  line-height: 15px;
  text-decoration: underline;

  &:hover {
    color: rgb(${getToken('color-primary-100')});
  }

  ${screen.md} {
    font-size: 12px;
    line-height: 14px;
  }
  ${screen.lg} {
    font-size: 16px;
    line-height: 19px;
  }
  ${screen.xxlg} {
    font-size: 20px;
    line-height: 23px;
  }
`;

export const LinkButton = styled(Link)`
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  display: inline-flex;
  margin: 26px auto 0px;

  color: inherit;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    color: rgb(${getToken('color-primary-60')});
  }

  ${screen.md} {
    margin: 50px auto 0px;
    font-size: 15px;
    line-height: 18px;
  }
  ${screen.lg} {
    margin: 70px auto 0px;
    font-size: 20px;
    line-height: 23px;
  }
  ${screen.xxlg} {
    margin: 80px auto 0px;
    font-size: 24px;
    line-height: 28px;
  }
`;

export const ErrorMessage = styled.p`
  color: #787b7d;
  text-align: center;
`;
