import styled from 'styled-components';

//components
import { MirandaBrandButton } from '@/components/Button';

import { Title2, Paragraph1 } from '@/components/Text';
import { Container } from '@/components/Grid';
import { Navigation } from '@/containers-m/Navigation';
import { Footer } from '@/containers-m/Footer';

const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

const ContainerStyled = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  padding: 40px 0;

  text-align: center;
  align-items: center;
  justify-content: center;
`;

const DefaultErrorSection = () => (
  <PageWrapper>
    <Navigation />
    <>
      <ContainerStyled>
        <Title2>404 – Page not found</Title2>
        <Paragraph1>
          Oops... This is embarassing, but something went wrong with the page.
        </Paragraph1>

        <MirandaBrandButton as="a" href="/">
          Return to the homepage
        </MirandaBrandButton>
      </ContainerStyled>
    </>
    <Footer />
  </PageWrapper>
);

export default DefaultErrorSection;
export { DefaultErrorSection };
