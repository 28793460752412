import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container } from '@/components/Grid';
import { InvertedSection } from '@/components/Grid/Section';
import { Wrapper as BaseWrapper } from '@/components/Grid/Content';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const BackgroundSection = styled(InvertedSection)`
  background-color: rgb(${getToken('color-background-primary')});
  padding: 50px 0 0;

  ${screen.md} {
    padding: 80px 0 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 38px;
  line-height: 50px;
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  text-align: center;
`;

export const Title = styled.h3`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  margin: 0;
  font-size: 26px;
  line-height: 39px;

  ${screen.md} {
    text-align: left;
  }
  ${screen.lg} {
    font-size: 38px;
    line-height: 47.5px;
  }
`;

export const Content = styled.p`
  margin: 16px 0;
  font-family: ${getToken('platform-font-default')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};
  font-size: 14px;
  line-height: 21px;

  ${screen.md} {
    margin: 22px 0;
  }

  ${screen.lg} {
    margin: 36px 0;
  }

  ${screen.xxlg} {
    margin: 42px 0;
    font-size: 20px;
    line-height: 36px;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 180px;
  position: relative;
  box-shadow: 0px 16px 32px rgba(35, 23, 5, 0.26);

  ${screen.md} {
    margin-right: 20px;
    align-self: center;
    height: 225px;
    min-width: 397px;
  }
  ${screen.lg} {
    height: 305px;
    min-width: 536px;
  }

  ${screen.xxlg} {
    align-self: unset;
    height: 510px;
    min-width: 900px;
  }
`;

export const SectionPanel = styled.div`
  display: flex;
  flex-direction: column;
  alig-items: center;
  justify-content: center;
  padding: 20px 0;

  ${screen.md} {
    flex-direction: ${(props) =>
      props.direction === 'reverse' ? 'row-reverse' : 'row'};
    padding: 50px 0;
  }
`;

export const CustomContainer = styled(Container)`
  padding: 20px 0;
`;

export const Wrapper = styled(BaseWrapper)`
  justify-content: center;
  padding: 0;

  ${screen.md} {
    padding-right: 14px;
  }

  ${screen.xxlg} {
    padding-right: 28px;
  }
`;

export const ReversedWrapper = styled(Wrapper)`
  padding: 0;

  ${screen.md} {
    padding-left: 0;
  }
  ${screen.lg} {
    padding-left: 14px;
  }
  ${screen.xxlg} {
    padding-left: 28px;
  }
`;
