import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//assets
import {
  IconCalculatedRisks,
  IconTenderAcceptance,
  IconVisibility,
  IconOurPeople,
  IconCosts,
  IconSmartScheduling,
} from '@loadsmart/icons';

//containers
import { BackgroundImages } from '@/containers/BackgroundImages';

//components
import { InvertedSection } from '@/components/Grid/Section';
import { Container } from '@/components/Grid/Container';
import { Title } from '@/components/Grid/Content';
import { FlexColumnCentered } from '@/components/Grid/Flex';
import {
  FeatureDescription,
  HighlightedIcon as FeatureIcon,
  FeatureTitle,
  responsiveIconStyle,
  Feature,
  FeatureGroup,
} from '@/components/Feature';

const responsiveBackground = {
  sm: `url(/images/enterprise-shipping/features/preference_section-small_background.png);`,
  md: `url(/images/enterprise-shipping/features/preference_section-medium_background.png);`,
  lg: `url(/images/enterprise-shipping/features/preference_section-large_background.png);`,
  xxlg: `url(/images/enterprise-shipping/features/preference_section-xxlarge_background.png);`,
};

const BackgroundSection = styled(InvertedSection)`
  ${BackgroundImages(responsiveBackground)} color: #ffffff;
  background-color: #999999;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 56px;

  ${screen.md} {
    margin-bottom: 46px;
  }

  ${screen.lg} {
    margin-bottom: 56px;
  }

  ${screen.xxlg} {
    margin-bottom: 104px;
  }
`;

export const PreferenceSection = () => (
  <BackgroundSection>
    <Container>
      <FlexColumnCentered>
        <StyledTitle>Why Enterprises Prefer Loadsmart</StyledTitle>
        <FeatureGroup>
          <Feature>
            <FeatureIcon>
              <IconCalculatedRisks role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <FeatureTitle>PEACE OF MIND</FeatureTitle>
            <FeatureDescription>
              We take full operational ownership and payment management for
              every load.
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureIcon>
              <IconTenderAcceptance role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <FeatureTitle>FLEXIBLE CAPACITY</FeatureTitle>
            <FeatureDescription>
              Greater capacity, faster coverage. Our carriers are vast and
              vetted.
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureIcon>
              <IconVisibility role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <FeatureTitle>MAXIMUM VISIBILITY</FeatureTitle>
            <FeatureDescription>
              Full transparency across the entire lifecycle — from the port to
              the final destination.
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureIcon>
              <IconOurPeople role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <FeatureTitle>DEDICATED ACCOUNT MANAGEMENT</FeatureTitle>
            <FeatureDescription>
              Your own industry expert and operations team moving your freight
              on time.
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureIcon>
              <IconCosts role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <FeatureTitle>TMS READY</FeatureTitle>
            <FeatureDescription>
              Pre-built, no-cost TMS integrations that are fast and easy to
              enable.
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureIcon>
              <IconSmartScheduling role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <FeatureTitle>GUARANTEED TENDER ACCEPTANCE</FeatureTitle>
            <FeatureDescription>
              100% tender acceptance. No price changes and no returned loads.
            </FeatureDescription>
          </Feature>
        </FeatureGroup>
      </FlexColumnCentered>
    </Container>
  </BackgroundSection>
);
