import React from 'react';
// import Head from 'next/head';
import styled from 'styled-components';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

// sections
import {
  FeaturesNavBanner,
  TestimonialCarousel,
  Partners,
  TeamHero,
  FloatTeamHeroBox,
  HubspotFormSection,
  List,
} from './containers';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

const FeaturesTMS = () => {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <FeaturesNavBanner variant="internal" />
        <List />
        <Partners />
        <TestimonialCarousel />
        <TeamHero />
        <FloatTeamHeroBox />
        <HubspotFormSection />
      </>
      <Footer />
    </PageWrapper>
  );
};

export default FeaturesTMS;
export { FeaturesTMS };
