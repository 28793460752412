/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable @next/next/no-img-element */
import React from 'react';

//components
import { Container, Section } from '@/components/Grid';
import { Title, Text } from '@/components/Grid/Content';
import {
  FeatureDescription,
  FeatureIcon,
  FeatureTitle,
  responsiveIconStyle,
} from '@/components/Feature/Content';

//icons
import {
  IconFasterService,
  IconCosts,
  IconDevelopmentPlans,
  IconImproveEfficiency,
  IconReporting,
  IconContainerLift,
} from '@loadsmart/icons';

//context components
import {
  SubTitle,
  ResponsiveImage,
  SubSubTitle,
  StyledFeatureGroup,
  StyledFeature,
} from './YourDataInsightsSection.styles';

export const YourDataInsightsSection = () => (
  <Section>
    <Container>
      <Title>Your Custom Data Insights Dashboard</Title>
      <SubTitle as="span">Provided by Loadsmart</SubTitle>
      <Text>
        <a href="/shipper/">Shippers</a> are moving beyond benchmarks with
        real-time insights to operational efficiency. Know when you are paying
        too much detention, analyze costs enhanced by multiple data points, and
        eliminate top offenders to drive long-term efficiency. With enhanced
        data insights into your unique shipping environment, you can stop
        putting out fires and gain control of freight shipping.
      </Text>

      <ResponsiveImage>
        <source
          srcSet={`/images/data-insights/screens/data-insights-screens-xxlarge.png`}
          media="(min-width: 1920px)"
        />
        <source
          srcSet={`/images/data-insights/screens/data-insights-screens-large.png`}
          media="(min-width: 1024px)"
        />
        <source
          srcSet={`/images/data-insights/screens/data-insights-screens-medium.png`}
          media="(min-width: 768px)"
        />
        <img
          src={`/images/data-insights/screens/data-insights-screens-small.png`}
          alt="Data Insights"
          width="1360"
          height="814"
        />
      </ResponsiveImage>

      <SubSubTitle as="h3">
        Data, What Is It Good For? Absolutely Nothing – Unless It’s Customized
      </SubSubTitle>

      <StyledFeatureGroup>
        <StyledFeature>
          <FeatureIcon>
            <IconFasterService role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Lead time</FeatureTitle>
          <FeatureDescription>
            Target lead times for your operations that avoid accessorials like
            detention.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconCosts role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Estimated additional costs</FeatureTitle>
          <FeatureDescription>
            Estimated additional costs that result from shorter lead times.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconDevelopmentPlans role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Top Offenders</FeatureTitle>
          <FeatureDescription>
            Identify top offenders driving short lead time by vendor, customer,
            and warehouse.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconImproveEfficiency role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Mode Optimization</FeatureTitle>
          <FeatureDescription>
            Utilize metrics to identify savings opportunities through improved
            mode selection.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconReporting role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Scheduling insights</FeatureTitle>
          <FeatureDescription>
            Reduce unnecessary costs and poor asset utilization incurred from
            inefficient dock management .
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconContainerLift role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Accessorials</FeatureTitle>
          <FeatureDescription>
            Eliminate cancellations and TONU fees for accessorials by location,
            mode, customer and product type.
          </FeatureDescription>
        </StyledFeature>
      </StyledFeatureGroup>
    </Container>
  </Section>
);
