import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';
import { LogoTitle } from './Content';

import MicrosoftExcelLogo from '@/public/images/logos/logo-microsoft_excel.svg';

const MicrosoftExcelWrapper = styled(FlexRowFullCentered)`
  width: 26px;
  height: 25px;

  ${screen.md} {
    width: 32px;
    height: 32px;
  }

  ${screen.lg} {
    width: 49px;
    height: 48px;
  }
`;

export const MicrosoftExcelCard = (props) => (
  <CardLayout {...props}>
    <MicrosoftExcelWrapper>
      <MicrosoftExcelLogo />
    </MicrosoftExcelWrapper>
    <LogoTitle>Microsoft Excel</LogoTitle>
  </CardLayout>
);
