import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { SectionPanel } from '@/components/Grid/Panel';
import {
  Wrapper as ContentWrapper,
  Title,
  Description,
  Text,
  Link,
  WideImageOnMobile,
} from '@/components/Grid/Content/';
import { FlexColumnFullCentered } from '@/components/Grid/Flex';

const LeftContentWrapper = styled(ContentWrapper)`
  padding-left: 0 !important;
`;

const ImageWrapper = styled(FlexColumnFullCentered)`
  ${screen.lg} {
    justify-content: flex-start;
  }
`;

export const ContentSection = () => (
  <>
    <SectionPanel reverse>
      <ImageWrapper>
        <WideImageOnMobile
          src={`/images/ftl/half-section_carrier.png`}
          alt="Are you a carrier"
        />
      </ImageWrapper>

      <LeftContentWrapper>
        <Title>Our Carriers</Title>
        <Description>
          Work with carriers that are constantly screened for safety and
          compliance standards
        </Description>
        <Text>
          With Loadsmart, you can ship confidently. Our carrier network is
          constantly screened for insurance, historical performance, reliability
          and safety. Only the best work with Loadsmart.
        </Text>
        <Link href={`/carrier`}>Are you a carrier? Visit our carrier page</Link>
      </LeftContentWrapper>
    </SectionPanel>
  </>
);
