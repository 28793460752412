import React from 'react';

//componets
import { MirandaBrandButton } from '@/components/Button';
// styles
import {
  HeroWrapper,
  HeroContainer,
  FlexRow,
  StyledTitle,
  StyledDescription,
} from './Hero.styles';

export function Hero() {
  return (
    <HeroWrapper>
      <HeroContainer>
        <StyledTitle>
          Lower freight costs 20% with Loadsmart Managed Transportation
        </StyledTitle>
        <StyledDescription>
          Leverage a dedicated team of seasoned logistics experts to help find
          savings, build a custom solution, and execute end-to-end logistics on
          your behalf.
        </StyledDescription>
        <FlexRow>
          <MirandaBrandButton as="a" href="#hubspot-form">
            Schedule an assessment
          </MirandaBrandButton>
        </FlexRow>
      </HeroContainer>
    </HeroWrapper>
  );
}
