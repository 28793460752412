import styled, { css } from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { InvertedSection } from '@/components/Grid';

// context containers
import { BackgroundImages } from '@/containers/BackgroundImages/';

const responsiveBackground = {
  sm: `url(/images/mode-optimization/small_hero_lessCO2.jpg);`,
  md: `url(/images/mode-optimization/medium_hero_lessCO2.jpg);`,
  lg: `url(/images/mode-optimization/large_hero_lessCO2.jpg);`,
  xxlg: `url(/images/mode-optimization/xxlarge_hero_lessCO2.jpg);`,
};

export const BackgroundImageSection = styled(InvertedSection)`
  ${BackgroundImages(responsiveBackground)};
  padding-top: 280px;

  ${screen.md} {
    padding-top: 312px;
  }

  ${screen.lg} {
    padding-top: 303px;
  }

  ${screen.xxlg} {
    padding-top: 314px;
  }
`;

export const centeredText = css`
  ${screen.md} {
    text-align: center;
  }
`;
