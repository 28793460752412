import ReactPlayer from 'react-player/lazy';

//styles
import {
  OurStoryWrapper,
  HeaderSection,
  Title,
  Description,
  VideoContainer,
  VideoWrapper,
} from './OurStory.styles';

//containers
import { Container } from '@/components/Grid';

export default function OurStory() {
  return (
    <OurStoryWrapper>
      <Container>
        <HeaderSection>
          <Title>Our story</Title>
          <Description>
            At Loadsmart, building is in our DNA. Since 2014 we’ve built
            solutions that push the boundaries of innovation for our customers,
            and in the process built a company worthy of a unique reputation.
            Our vision, to become the nexus of moving freight, is shared by
            world class investors, including BlackRock, Maersk Growth, Ports
            America, TFI International, Chromo Invest and Connor Capital, and,
            who believe that with the right combination of technology and
            people, we can move the logistics industry into the future.
          </Description>
        </HeaderSection>
      </Container>

      <VideoContainer>
        <VideoWrapper>
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=2YNy6FevODs?rel=0"
            controls
            width="100%"
            height="100%"
          />
        </VideoWrapper>
      </VideoContainer>
    </OurStoryWrapper>
  );
}

export { OurStory };
