/* eslint-disable @next/next/no-img-element */

//styles
import { Container } from '@/components/Grid';
import {
  TestimonialsWrapper,
  ImageSpacer,
  ContentHolder,
  SectionTag,
  QuoteText,
  Client,
  Name,
  Description,
} from './Testimonial.styles';

export function Testimonial() {
  return (
    <>
      <TestimonialsWrapper>
        <Container>
          <ImageSpacer role="presentation" />
          <ContentHolder>
            <SectionTag>Customer Stories</SectionTag>
            <QuoteText>
              “This is incredible work... it’s just amazing what you’ve done.”
            </QuoteText>
            <Client>
              <Name>Danillo Figueiredo</Name>
              <Description>VP of Supply Chain, 1440 Foods</Description>
            </Client>
          </ContentHolder>
        </Container>
      </TestimonialsWrapper>
    </>
  );
}
