import React from 'react';
import {
  IconLowerCosts,
  IconFasterService,
  IconVisibility,
  IconPackageTracking,
  IconOnePlatform,
  IconReliability,
} from '@loadsmart/icons';
import { Container } from '@/components/Grid';
import {
  Feature,
  FeatureGroup,
  FeatureSection as Section,
  FeatureIcon,
  FeatureTitle,
  FeatureDescription,
  responsiveIconStyle,
} from '@/components/Feature';

export const FeatureSection = () => (
  <Section>
    <Container>
      <FeatureGroup>
        <Feature>
          <FeatureIcon>
            <IconLowerCosts role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>LOWER COSTS</FeatureTitle>
          <FeatureDescription>
            Get one invoice for your entire move, minimizing unnecessary
            accessorial costs.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconFasterService role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>FASTER, MORE FLEXIBLE SERVICE</FeatureTitle>
          <FeatureDescription>
            Ensure your multimodal shipments arrive fast and on-time without
            having to rely on fixed train schedules.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconVisibility role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>UNPARALLALED VISIBILITY</FeatureTitle>
          <FeatureDescription>
            Follow goods from the moment they are available at the port through
            at their final destination.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconPackageTracking role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>PACKAGE LEVEL TRACKING</FeatureTitle>
          <FeatureDescription>
            Know what goods, down to the individual package level, have been
            loaded on to what trucks and their location at all times.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconOnePlatform role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>ONE VENDOR, ONE PLATFORM</FeatureTitle>
          <FeatureDescription>
            Cut down administrative overhead and work with one provider for your
            entire move.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconReliability role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>EXCEPTIONAL RELIABILITY</FeatureTitle>
          <FeatureDescription>
            Rest easy knowing our award-winning operations team is backing you
            up on every move.
          </FeatureDescription>
        </Feature>
      </FeatureGroup>
    </Container>
  </Section>
);
