/* eslint-disable @next/next/no-img-element */
// components
import { MirandaSecondaryButton } from '@/components/Button/';
import { Container } from 'components/Grid';

// styles
import {
  SectionWrapper,
  Title,
  SectionPanel,
  Picture,
  Wrapper,
  MobileHide,
  Content,
} from './FeaturedIcons.styles';

export function FeaturedIcons() {
  return (
    <SectionWrapper>
      <Container>
        <SectionPanel>
          <Picture>
            <source
              srcSet={`/images/managed-transportation/mt-services@2x.png`}
              media="(min-width: 1920px)"
            />
            <source
              srcSet={`/images/managed-transportation/mt-services@2x.png`}
              media="(min-width: 1024px)"
            />
            <source
              srcSet={`/images/managed-transportation/mt-services@2x.png`}
              media="(min-width: 768px)"
            />
            <img
              src={`/images/managed-transportation/mt-services@2x.png`}
              alt="Screenshot Shipper Guide"
              width="748"
              height="500"
            />
          </Picture>
          <Wrapper>
            <Title>
              Managed Transportation that drives efficiency and cost savings
            </Title>

            <Content>
              Let Loadsmart handle your end-to-end logistics,
              <br /> so your team can focus on what matters most
            </Content>

            <MobileHide>
              <MirandaSecondaryButton as="a" href="#hubspot-form">
                Start saving on transportation
              </MirandaSecondaryButton>
            </MobileHide>
          </Wrapper>
        </SectionPanel>
      </Container>
    </SectionWrapper>
  );
}
