import styled from 'styled-components';
import { screen } from '@/helpers/screen';

export const SlideWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;

  ${screen.md} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const SlidePanel = styled.article`
  width: 100%;

  text-align: center;

  ${screen.md} {
    padding: 0 20px 0 0;

    text-align: left;
  }

  ${screen.lg} {
    padding: 0 25px 0 0;
  }

  ${screen.xxlg} {
    padding: 0 30px 0 0;
  }
`;

export const Content = styled.div`
  margin: 16px 0;
  color: ${({ theme }) => theme.color.neutral.darkest};
  font-size: 12px;
  line-height: 22px;

  ul {
    width: 80%;
    margin: 8px auto;

    li {
      margin-bottom: 8px;

      font-weight: 700;
      span {
        font-weight: 600;
      }
    }
  }

  ${screen.md} {
    margin: 28px 0;

    ul {
      width: 100%;

      li {
        margin-bottom: 0;

        list-style: disc inside;
      }
    }
  }

  ${screen.lg} {
    margin: 36px 0;

    font-size: 16px;
    line-height: 30px;
  }
  ${screen.xxlg} {
    margin: 42px 0;

    font-size: 20px;
    line-height: 38px;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.color.neutral.darkest};
  font-size: 16px;
  line-height: 22px;

  ${screen.md} {
    line-height: 26px;
  }
  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }
  ${screen.xxlg} {
    font-size: 40px;
    line-height: 58px;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.color.greens.stronger};
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;

  ${screen.md} {
    font-size: 15px;
    line-height: 18px;
  }
  ${screen.lg} {
    font-size: 20px;
    line-height: 23px;
  }
  ${screen.xxlg} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const SystemImage = styled.figure`
  position: relative;

  width: 100%;
  height: auto;
  margin: auto 0 30px;

  img {
    width: 100%;
    min-width: 100%;
    height: auto;
  }

  ${screen.md} {
    width: 50%;
    min-width: 312px;
    margin: auto 0;
  }

  ${screen.lg} {
    min-width: 456px;
  }

  ${screen.xxlg} {
    min-width: 650px;
  }
`;
