import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';

import E2OpenLogo from '@/public/images/logos/logo-e2open-white.svg';

const E2OpenWrapper = styled(FlexRowFullCentered)`
  width: 94px;
  height: 14px;

  ${screen.md} {
    width: 120px;
    height: 18px;
  }

  ${screen.lg} {
    width: 150px;
    height: 23px;
  }

  ${screen.xxlg} {
    width: 190px;
    height: 29px;
  }
`;

export const E2OpenCard = (props) => (
  <CardLayout {...props}>
    <E2OpenWrapper>
      <E2OpenLogo />
    </E2OpenWrapper>
  </CardLayout>
);
