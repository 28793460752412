import { useState } from 'react';

//styles
import {
  CollapsedtListWrapper,
  CollapsedtListItem,
  CollapseTrigger,
  Header,
  Icon,
  Description,
} from './CollapsedtList.styles';

export default function CollapsedtList({ content }) {
  return (
    <CollapsedtListWrapper>
      {content.map((item, index) => {
        return (
          <CollapseBox
            key={`CollapsedtListItem-${index}`}
            collapse={index == 0 ? true : false}
            title={item.title}
            description={item.description}
          />
        );
      })}
    </CollapsedtListWrapper>
  );
}

export const CollapseBox = ({ title, description, collapse }) => {
  const [collapsed, setCollapsed] = useState(collapse);

  return (
    <CollapsedtListItem className={collapsed ? `collapsed` : ``}>
      <CollapseTrigger onClick={() => setCollapsed(!collapsed)}>
        <Header>{title}</Header>
        <Icon className={collapsed ? `collapsed` : ``} />
      </CollapseTrigger>
      <Description as="div" collapsed={collapsed}>
        {description}
      </Description>
    </CollapsedtListItem>
  );
};

export { CollapsedtList };
