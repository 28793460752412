import React from 'react';

//components
import { Container } from '@/components/Grid';
import { Title, Text } from '@/components/Grid/Content';
import { FlexColumnFullCentered } from '@/components/Grid/Flex';

// context containers
import { BackgroundImageSection, centeredText } from './LessCO2Section.styles';

export const LessCO2Section = () => (
  <BackgroundImageSection>
    <Container>
      <FlexColumnFullCentered>
        <Title>Move more with less CO2</Title>
        <Text css={centeredText}>
          Whether you’re quoting instant rates for multiple modes or working
          with your transportation specialist, you’ll always have the ability to
          move your freight with the lowest environmental impact. Reduce your
          company’s carbon footprint by moving your traditional FTL loads with
          rail or by sharing a less-than or partial truckload.
        </Text>
      </FlexColumnFullCentered>
    </Container>
  </BackgroundImageSection>
);
