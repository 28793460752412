//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { defaultOptions } from '@/vendor/HubspotFormIntegration';
import { LoadsmartLinkSectionContent } from '@/containers/LinkSection';

//context containers
import {
  HeroSection,
  FeatureSection,
  PanelsSection,
  ContentSection,
} from './containers/index';

//styles
import { PageWrapper } from './FullTruckLoad.styles';

function FullTruckLoad() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <HeroSection />
        <FeatureSection />
        <PanelsSection />
        <LoadsmartLinkSectionContent />
        <ContentSection />
        <HubspotForm {...defaultOptions}>
          <HubspotFormTitle>Interested? Get in touch.</HubspotFormTitle>
          <HubspotFormDescription>
            Our team is here to answer your questions and offer a personalized
            tour of Loadsmart.
          </HubspotFormDescription>
        </HubspotForm>
      </>
      <Footer />
    </PageWrapper>
  );
}

export default FullTruckLoad;
export { FullTruckLoad };
