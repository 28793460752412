//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { enterpriseShippingOptions } from '@/vendor/HubspotFormIntegration';
import { FreightDataReportSection } from '@/containers/FreightDataReportSection';
import { MeetOurTeamSection } from '@/containers/MeetOurTeamSection';

//context containers
import {
  HeroSection,
  IntroductionSection,
  AwardsSection,
  TMSSection,
  PreferenceSection,
} from './containers/';

//styles
import { PageWrapper } from './EnterpriseShipping.styles';

function EnterpriseShipping() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <HeroSection />
        <IntroductionSection />
        <AwardsSection />
        <TMSSection />
        <FreightDataReportSection />
        <PreferenceSection />
        <MeetOurTeamSection />
        <HubspotForm {...enterpriseShippingOptions}>
          <HubspotFormTitle>
            Talk to a Transportation Specialist
          </HubspotFormTitle>
          <HubspotFormDescription>
            Our team of industry experts and technicians are here to answer your
            questions.
          </HubspotFormDescription>
        </HubspotForm>
      </>
      <Footer />
    </PageWrapper>
  );
}

export default EnterpriseShipping;
export { EnterpriseShipping };
