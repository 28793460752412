/* eslint-disable @next/next/no-img-element */
//assets
import { Container } from '@/components/Grid/Container';

import {
  FeaturedSection,
  HeaderWrapper,
  ImageWrapper,
  Footer,
} from './ImageSection.styles';

import { Paragraph1, Paragraph2, Title1 } from '@/components/Text';

export const ImageSection = ({ content }) => (
  <FeaturedSection>
    <Container>
      <HeaderWrapper>
        {content.title && <Title1>{content.title}</Title1>}
        {content.description && <Paragraph1>{content.description}</Paragraph1>}
      </HeaderWrapper>
      <ImageWrapper>
        {content.image && (
          <img
            src={content.image.path}
            alt={content.image.alt}
            width="1286"
            height="870"
          />
        )}
      </ImageWrapper>
      <Footer>
        {content.footer && <Paragraph2>{content.footer}</Paragraph2>}
      </Footer>
    </Container>
  </FeaturedSection>
);
