import styled from 'styled-components';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//helpers
import { screen } from '@/helpers/screen';

//containers
import { Container } from '@/components/Grid';

export const OurStoryWrapper = styled.section`
  background: rgb(${getToken('color-background-primary')});
  padding: 50px 0;

  ${screen.lg} {
    padding: 80px 0;
  }
  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const HeaderSection = styled.header`
  width: 100%;
  text-align: center;
`;

export const Title = styled.h2`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  font-size: 20px;
  line-height: 30px;

  margin: 0 auto;

  ${screen.md} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const Description = styled.p`
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};

  font-size: 12px;
  line-height: 16.8px;

  ${screen.md} {
    font-size: 14px;
    line-height: 21px;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 36px;
  }
`;

export const VideoContainer = styled(Container)`
  padding: 40px 0 0;
  width: 100%;
  max-width: calc(100% - 38px);

  ${screen.md} {
    padding: 50px 0 0;
    width: calc(100% - 168px);
    max-width: 605px;
  }

  ${screen.lg} {
    padding: 60px 0 0;
    width: calc(100% - 266px);
    max-width: 835px;
  }

  ${screen.xxlg} {
    width: calc(100% - 803px);
    max-width: 1100px;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 20px;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  background: rgb(${getToken('color-background-secondary-inverted')});
  box-shadow: 0px 16px 32px 0px rgba(35, 23, 5, 0.26);

  .react-player {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
`;
