// components
import { Container } from 'components/Grid';

// styles
import {
  HeroWrapper,
  Title,
  Paragraph,
  ButtonWrapper,
  CTA,
} from './Hero.styles';

export function Hero() {
  return (
    <HeroWrapper
      bg={{
        xxlarge: 'images/careers/herobg/hero-loadsmart-careers-xxlg.jpg',
        large: 'images/careers/herobg/hero-loadsmart-careers-lg.jpg',
        medium: 'images/careers/herobg/hero-loadsmart-careers-md.jpg',
        small: 'images/careers/herobg/hero-loadsmart-careers-sm.jpg',
      }}
    >
      <Container>
        <Title>
          Join the nerds of logistics with a career in freight tech.
        </Title>
        <Paragraph>
          Loadsmart is a collection of industry veterans and data scientists
          using innovative technology to fearlessly reinvent the future of
          freight by helping shippers, brokers, warehouses and carriers to move
          more with less.
        </Paragraph>
        <ButtonWrapper>
          <CTA
            as="a"
            target={`_blank`}
            rel={`noreferrer noopener`}
            href="https://jobs.lever.co/loadsmart/"
          >
            <span>Explore open positions </span>
          </CTA>
        </ButtonWrapper>
      </Container>
    </HeroWrapper>
  );
}
