// components
import { Container } from 'components/Grid';

// styles
import {
  IntroductionTextWrapper,
  Title,
  Content,
} from './IntroductionText.styles';

export function IntroductionText() {
  return (
    <IntroductionTextWrapper>
      <Container>
        <Title>
          Carry Your Carrier Into The Digital Age
          <br /> With Our Truck Management System.
        </Title>
        <Content>
          There’s not enough time in the day or people in the office to get what
          you need done, and you don’t have a time machine or a budget to hire
          more resources. Traditionally, this is the end of the road for
          truckers, but today with freight technology that provides data
          insights and visibility into your operations, you can fill trucks at
          better rates, reduce back-office expenses, and drive profitability.
        </Content>
      </Container>
    </IntroductionTextWrapper>
  );
}
