import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

// components
import { Hero } from '@/containers/Hero/';

export const HeroCover = styled(Hero)`
  background-image: url(/images/leadership/hero-small@2x.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${screen.md} {
    background-image: url(/images/leadership/hero-medium.jpg);
  }

  ${screen.lg} {
    background-image: url(/images/leadership/hero-large.jpg);
  }

  ${screen.xxlg} {
    background-image: url(/images/leadership/hero-xxlarge.jpg);
  }
`;
