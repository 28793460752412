import React from 'react';
import PropTypes from 'prop-types';

// styles
import { SlideWrapper, Title, SlidePanel, Content } from './Slide.styles';

export function Slide({ property }) {
  return (
    <SlideWrapper>
      <SlidePanel>
        <Title>{property.title}</Title>
        <Content>{property.content}</Content>
      </SlidePanel>
    </SlideWrapper>
  );
}

Slide.propTypes = {
  property: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  }).isRequired,
};
