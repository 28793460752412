/* eslint-disable @next/next/no-img-element */
import Lottie from 'react-lottie';

//assets
import animationData from '@/datafiles/lottie/link/animation.json';

//icons
import {
  IconInstantPricing,
  IconTenderAcceptance,
  IconArtificialIntelligence,
  IconSmartTracking,
  IconInvoice,
} from '@loadsmart/icons';
import {
  IconFastPayment,
  IconImproveEfficiency,
  IconFasterService,
} from '@loadsmart/icons';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { tmsIntegrationOptions } from '@/vendor/HubspotFormIntegration';

// context containers
import {
  HeroSection,
  FeatureSection,
  ExternalVideoSection,
  SingleColumnSection,
  ContentSection,
} from './containers/index';

//styles
import { PageWrapper } from './Integrations.styles';

//content
const lottieDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};

const heroContent = {
  page: 'e2open',
  logo: {
    src: `/images/logos/logo-e2open-white.svg`,
    alt: 'e2open',
  },
  title: `Enhance your e2open TMS with Loadsmart Dynamic Pricing`,
  description: `Loadsmart’s integration with e2open’s TMS gives shippers access to reliable service, flexible capacity and customizable spot and contract solutions directly in your TMS experience.`,
  images: {
    sm: `/images/integrations/e2open/small_hero.jpg`,
    md: `/images/integrations/e2open/medium_hero.jpg`,
    lg: `/images/integrations/e2open/large_hero.jpg`,
    xxlg: `/images/integrations/e2open/xxlarge_hero.jpg`,
  },
};

const featuresContent = {
  title: 'Benefits',
  description: (
    <>
      Shippers using{' '}
      <a href="https://www.e2open.com/" target="_blank" rel="noreferrer">
        e2open
      </a>
      ’s TMS have instant access to Loadsmart’s brokerage solutions.
      <br /> As a premier carrier for{' '}
      <a href="https://www.e2open.com/" target="_blank" rel="noreferrer">
        e2open
      </a>
      , shippers get access to Loadsmart’s instant freight pricing.
    </>
  ),
  features: [
    {
      title: (
        <>
          INSTANT SPOT AND
          <br /> CONTRACT RATES
        </>
      ),
      icon: <IconInstantPricing />,
    },
    {
      title: '100% TENDER ACCEPTANCE',
      icon: <IconTenderAcceptance />,
    },
    {
      title: 'NO COST, QUICK SETUP',
      icon: <IconArtificialIntelligence />,
    },
    {
      title: 'REAL-TIME VISIBILITY',
      icon: <IconSmartTracking />,
    },
    {
      title: 'AUTOMATED SAVINGS',
      icon: <IconInvoice />,
    },
  ],
};

const copyContent = {
  title: 'Supercharge your routing guide',
  introduction: `Loadsmart’s integration with e2open allows for instantly bookable truckload rates in your Routing Guide. By inserting real-time market rates directly into e2open’s Transportation Management, Loadsmart enables you to take advantage of market conditions when current prices are below the static options provided by other carriers. Loadsmart accepts 100% of tenders instantly — so whenever our rate is best, the load can be immediately covered.`,
  media: <Lottie options={lottieDefaultOptions} />,
  benefits: [
    {
      icon: <IconFastPayment width={66} height={66} />,
      text: 'Save when the market rate is below your static contracted options',
    },
    {
      icon: <IconImproveEfficiency width={66} height={66} />,
      text: 'Improve service by lowering the number of shipments sent to the spot market',
    },
    {
      icon: <IconFasterService width={66} height={66} />,
      text: 'Reduce time spent securing coverage for your freight',
    },
  ],
};

const automaticSavingsContent = {
  title: 'Automatic Savings',
  introduction: (
    <>
      <p>
        Loadsmart provides instant,{' '}
        <a href="https://lp.loadsmart.com/hubfs/5041527/Loadsmart_e2Open_TMS_OnePager.pdf">
          dynamic pricing
        </a>{' '}
        on any lane in e2open’s TMS with the opportunity to achieve savings in
        all modes. Each rate comes with guaranteed tender acceptance, meaning no
        price changes or load givebacks (really).
      </p>
      <p>
        Watch,{' '}
        <i>Smart Ways to Move Freight and Capture Savings in Any Market</i>, to
        view our latest on-demand webinar and learn:
      </p>
    </>
  ),
  media: (
    <a
      href="https://lp.loadsmart.com/webinar-smart-ways-to-move-freight?utm_source=loadsmart&utm_medium=cta&utm_campaign=e2open-ondemand-es-brokerage-q3-23&utm_content=tms-page"
      target="_blank"
      rel="noreferrer noopener"
    >
      <img
        src="/images/integrations/e2open/webinar-screen.jpg"
        alt="Webinar"
        widht=""
        height=""
      />
    </a>
  ),
  cta: {
    title: 'View On-Demand Webinar',
    url: 'https://lp.loadsmart.com/webinar-smart-ways-to-move-freight?utm_source=loadsmart&utm_medium=cta&utm_campaign=e2open-ondemand-es-brokerage-q3-23&utm_content=tms-page',
  },
  benefits: [
    {
      text: 'How e2open and Loadsmart work together to provide shippers automated cost savings',
    },
    {
      text: 'Cost saving strategies to optimize your transportation network to beat market conditions',
    },
    {
      text: 'Market insights and recommendations',
    },
  ],
};

const benefitsContent = {
  title: 'Service that matters, from partners you trust',
  text: `Whether you move 1 load or 1000s, Loadsmart offers shippers guaranteed on-time service, real-time tracking updates directly in within your TMS and a dedicated account team to manage your freight, start to finish.`,
  nobackground: true,
  cta: {
    title: 'View Case Studies',
    url: 'https://lp.loadsmart.com/case-studies?utm_source=loadsmart&utm_medium=hyperlink&utm_campaign=e2open-tms-es-brokerage-q1-23&utm_content=e2open-integration',
  },
  benefits: false,
};

function E2Open() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <HeroSection content={heroContent} />
        <FeatureSection content={featuresContent} showHeader={true} />
        <ContentSection content={copyContent} />
        <ExternalVideoSection content={automaticSavingsContent} />
        <SingleColumnSection content={benefitsContent} />
        <HubspotForm {...tmsIntegrationOptions}>
          <HubspotFormTitle>Ready to learn more?</HubspotFormTitle>
          <HubspotFormDescription>
            Fill out the form below to and a member of the Loadsmart team will
            be in touch.
          </HubspotFormDescription>
        </HubspotForm>
      </>
      <Footer />
    </PageWrapper>
  );
}

export default E2Open;
export { E2Open };
