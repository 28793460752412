/* eslint-disable @next/next/no-img-element */
import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import {
  ReversedCenteredWrapper,
  Title,
  Text,
  Link as BaseLink,
  SubTitle,
} from '@/components/Grid/Content';
import { SectionPanel } from '@/components/Grid/Panel';
import { FlexRowFullCentered } from '@/components/Grid/Flex';

//context components
const TitleSectionStyled = styled(SectionPanel)`
  margin: 50px 0 0;

  ${screen.md} {
    margin: 100px 0;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 390px;
  margin: auto;
  padding: 0 20px;

  ${screen.md} {
    max-width: 100%;
  }
`;

const SectionStyled = styled(SectionPanel)`
  height: auto;
  margin: 0;

  ${screen.md} {
    height: 265px;
    margin: 0;
    overflow-x: hidden;
  }

  ${screen.lg} {
    height: 370px;
    margin: 0;
  }

  ${screen.xxlg} {
    height: 520px;
    margin: 0;
  }
`;

const TitleStyled = styled(Title)`
  text-align: center !important;
`;

const SubTitleStyled = styled(SubTitle)`
  margin: 6px 0 0px;

  ${screen.md} {
    margin: 8px 0 40px;
  }
`;

const Link = styled(BaseLink)`
  ${FlexRowFullCentered};

  ${screen.md} {
    justify-content: start;
  }
`;

const Column = styled.div`
  display: block;
`;

const ResponsiveImage = styled.picture`
  position: relative;

  display: inlne-flex;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  margin-top: 5px;

  text-align: center;

  img,
  source {
    position: relative;

    width: 100%;
    max-width: 400px;
    height: auto;
    margin: auto;
  }

  ${screen.md} {
    text-align: left;

    img,
    source {
      position: absolute;

      width: 110%;
      max-width: unset;
    }
  }
`;

export const InstantQuoteSection = () => (
  <>
    <TitleSectionStyled>
      <Container>
        <TitleStyled>Solutions for However You Move Freight</TitleStyled>
      </Container>
    </TitleSectionStyled>

    <SectionStyled reverse>
      <ResponsiveImage>
        <source
          srcSet={`/images/smb-shipping/Loadsmart-screen-quotes.png`}
          media="(min-width: 768px)"
        />
        <img
          src={`/images/smb-shipping/Loadsmart-screen-quotes-small.png`}
          alt="Loadsmart Quotes Screen"
          width="759"
          height="458"
        />
      </ResponsiveImage>
      <ReversedCenteredWrapper>
        <Column>
          <Title>Instant Quote and Book Spot Loads</Title>
          <SubTitleStyled>Reduce costs by up to 24%</SubTitleStyled>
          <Text>
            Maximize your cost savings, increase flexibility, and receive
            multiple instantly bookable mode options for a single shipment.
          </Text>

          <Link href="#hubspot-form">Learn more</Link>
        </Column>
      </ReversedCenteredWrapper>
    </SectionStyled>
  </>
);
