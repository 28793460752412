import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//icons
import {
  IconFasterService,
  IconTenderAcceptance,
  IconCalculatedRisks,
  IconSupport,
} from '@loadsmart/icons';

//components
import { Container } from '@/components/Grid';
import {
  Feature,
  FeatureGroup,
  FeatureSection as Section,
  FeatureIcon,
  FeatureTitle,
  FeatureDescription,
  responsiveIconStyle,
} from '@/components/Feature';

//context components
const StyledSection = styled(Section)`
  margin-top: 0px;
`;

const StyledFeatureGroup = styled(FeatureGroup)`
  padding: 5px 0 0;

  ${screen.md} {
    margin: 0 -22px;
  }

  ${screen.lg} {
    margin: 0 -35px;
  }

  ${screen.xxlg} {
    margin: 0 -39px;
  }
`;

const StyledFeature = styled(Feature)`
  width: 100%;
  margin: 10px auto 10px;

  ${screen.md} {
    width: 48%;
    margin: 10px auto 20px;
  }

  ${screen.lg} {
    margin: 10px auto 25px;
  }

  ${screen.xxlg} {
    margin: 10px auto 35px;
  }
`;

export const AddValuesSection = () => (
  <StyledSection>
    <Container>
      <StyledFeatureGroup>
        <StyledFeature>
          <FeatureIcon>
            <IconFasterService role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Mode Optimization</FeatureTitle>
          <FeatureDescription>
            With reliable options and instantly bookable rates across modes
            including rail, LTL, PTL, and FTL, you can optimize your shipments
            for sustainability, cost, and expediency.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconTenderAcceptance role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>100% Tender Acceptance</FeatureTitle>
          <FeatureDescription>
            As an industry first, Loadsmart enables instant quoting for hundreds
            of loads giving you real time market rates on all of your loads and
            the ability to book instantly.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconCalculatedRisks role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Peace Of Mind</FeatureTitle>
          <FeatureDescription>
            While you still have full visibility into the shipment process with
            updates from pickup through delivery, we take full responsibility
            for your loads with full cargo and liability insurance.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconSupport role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Account Managers</FeatureTitle>
          <FeatureDescription>
            An industry expert whose main focus is the seamless request and
            delivery of your loads. Their availability to you is constant if any
            issues arise, so a solution can be quickly implemented.
          </FeatureDescription>
        </StyledFeature>
      </StyledFeatureGroup>
    </Container>
  </StyledSection>
);
