import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

// components
import { Container as BaseContainer } from 'components/Grid';

export const ModalBoxesWrapper = styled.section`
  background: rgb(${getToken('color-background-primary')});
  padding: 50px 0;

  ${screen.lg} {
    padding: 70px 0;
  }
  ${screen.xxlg} {
    padding: 80px 0;
  }
`;

export const Title = styled.h2`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  margin: 0;
  margin-bottom: 26px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  br {
    display: none;
  }

  span {
    color: rgb(${getToken('color-brand')});
  }

  ${screen.md} {
    margin-bottom: 50px;

    br {
      display: inline-flex;
    }
  }
  ${screen.lg} {
    margin-bottom: 66px;

    br {
      display: none;
    }
  }

  ${screen.xxlg} {
    margin-bottom: 92px;

    font-size: 26px;
    line-height: 39px;
  }
`;

export const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 0 40px;
  gap: 20px;

  ${screen.lg} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const BoxItem = styled.article`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.3);

  width: 100%;
  ${screen.md} {
    width: calc(50% - 20px);
  }

  ${screen.lg} {
    width: auto;
  }

  ${screen.xxlg} {
    padding: 50px;
  }
`;

export const BoxTitle = styled.div`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-brand')});
  font-weight: ${getToken('font-weight-bold')};
  font-style: italic;
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 30px;

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const BoxCopy = styled.div`
  p {
    font-family: ${getToken('platform-font-default')};
    color: rgb(${getToken('color-text-primary')});
    font-size: 14.4px;
    line-height: 31.68px;
    margin: 4px 0;

    ${screen.xxlg} {
      font-size: 18px;
      line-height: 36px;
    }
  }
`;

export const ImageWrap = styled.div`
  margin-bottom: 50px;

  img {
    width: 100%;
    height: auto;
  }
`;
