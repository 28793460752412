import PropTypes from 'prop-types';
import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container } from '@/components/Grid';
import {
  Feature,
  FeatureGroup,
  FeatureSection as Section,
  FeatureIcon,
  FeatureTitle,
  responsiveIconStyle,
  FeatureDescription,
} from '@/components/Feature';

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;

const TitlteWrapper = styled.div`
  margin-bottom: 20px;

  ${screen.md} {
    margin-bottom: 30px;
  }

  ${screen.xxlg} {
    margin-bottom: 50px;
  }

  & > * {
    text-align: center;
  }
`;

export const FeatureSection = ({ content, showHeader }) => (
  <Section>
    <Container>
      <TitlteWrapper>
        {showHeader && content.title && <Title>{content.title}</Title>}
      </TitlteWrapper>
      {content.description && (
        <FeatureDescription>{content.description}</FeatureDescription>
      )}
      <FeatureGroup>
        {content.features &&
          content.features.map((item, key) => {
            return (
              <Feature key={`feature-cap-${key}`}>
                <FeatureIcon role="img" css={responsiveIconStyle}>
                  {item.icon}
                </FeatureIcon>
                <FeatureTitle>{item.title}</FeatureTitle>
              </Feature>
            );
          })}
      </FeatureGroup>
    </Container>
  </Section>
);

FeatureSection.defaultProps = {
  showHeader: false,
};

FeatureSection.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    features: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        icon: PropTypes.element,
      })
    ).isRequired,
  }),
  showHeader: PropTypes.bool,
};
