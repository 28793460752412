import styled from 'styled-components';
import { IconArrowsExpandSide } from '@loadsmart/icons';
import { screen } from '@/helpers/screen';

export const Arrow = styled.button`
  position: absolute;
  top: calc(50% - 20px);
  z-index: 1;
  cursor: pointer;
  width: 21px;
  height: 40px;
  padding: 0px;

  color: white;

  background: none;
  border: none;
  outline: none;

  transition: opacity 0.25s;
`;

const RightArrow = styled(Arrow)`
  ${({ showRightArrowMobile }) => !showRightArrowMobile && `display: none;`};

  ${screen.md} {
    ${({ showRightArrow }) => !showRightArrow && `display: none;`};
  }

  right: 0px;
`;

const LeftArrow = styled(Arrow)`
  left: 0px;

  & > svg {
    transform: rotate(180deg);
  }

  ${({ showLeftArrow }) => !showLeftArrow && `display: none;`};
`;

export const Arrows = ({
  showLeftArrow = true,
  showRightArrow = true,
  showRightArrowMobile = true,
  onRightArrowClick: emitRightArrowClickEvent = () => {},
  onLeftArrowClick: emitLeftArrowClickEvent = () => {},
}) => {
  const handleEvent = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleLeftArrowClick = (event) => {
    handleEvent(event);
    emitLeftArrowClickEvent();
  };

  const handleRightArrowClick = (event) => {
    handleEvent(event);
    emitRightArrowClickEvent();
  };

  return (
    <>
      <LeftArrow onClick={handleLeftArrowClick} showLeftArrow={showLeftArrow}>
        <IconArrowsExpandSide />
      </LeftArrow>
      <RightArrow
        onClick={handleRightArrowClick}
        showRightArrow={showRightArrow}
        showRightArrowMobile={showRightArrowMobile}
      >
        <IconArrowsExpandSide />
      </RightArrow>
    </>
  );
};
