import styled from 'styled-components';
import { screen } from '@/helpers/screen';

export const FloatTeamHeroBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background-color: #d3d9d9;
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: calc(100% - 40px);
  max-width: 316px;
  margin-top: -210px;
  padding: 38px 26px;

  background-color: ${({ theme }) => theme.color.neutral.lightest};
  ${screen.md} {
    max-width: 674px;
    margin-top: -150px;
    padding: 38px 18px;
  }
  ${screen.lg} {
    max-width: 1040px;
    margin-top: -227px;
    padding: 38px;
  }
  ${screen.xxlg} {
    max-width: 1592px;
    margin-top: -332px;
    padding: 75px 116px 76px;
  }
`;

export const Title = styled.h1`
  width: 100%;

  ${({ theme }) => theme.font};
  color: ${({ theme }) => theme.color.neutral.darkest};
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  span {
    color: #01cb2c;
  }

  ${screen.md} {
    flex-direction: row;
    gap: 10px;

    font-size: 34px;
    line-height: 40px;
  }
  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }
  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;

export const Subtitle = styled.p`
  ${({ theme }) => theme.font};
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.color.neutral.darkest};

  margin-top: 16px;

  ${screen.md} {
    margin-top: 28px;
  }
  ${screen.lg} {
    margin-top: 36px;

    font-size: 16px;
    line-height: 30px;
  }
  ${screen.xxlg} {
    margin-top: 42px;

    font-size: 20px;
    line-height: 38px;
  }
`;
