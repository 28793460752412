import styled from 'styled-components';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//helpers
import { screen } from '@/helpers/screen';

export const SectionWrapper = styled.section`
  background: rgb(${getToken('color-background-primary')});
`;

export const Title = styled.h2`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  margin: 50px 0 0px;
  font-size: 25px;
  line-height: 39px;
  text-align: center;

  ${screen.md} {
    font-size: 26px;
  }

  ${screen.lg} {
    margin: 80px 0 0px;
  }

  ${screen.xxlg} {
    margin: 100px 0 0px;
  }
`;

export const ListContainer = styled.ul`
  display: grid;
  gap: 16px 20px;
  margin: 50px auto;
  padding: 0px;
  list-style: none;

  ${screen.md} {
    grid-template-columns: repeat(1, 1fr);
  }

  ${screen.lg} {
    margin: 50px auto 80px;
  }

  ${screen.xxlg} {
    grid-template-columns: repeat(2, 1fr);
    margin: 50px auto 100px;
  }
`;

export const AwardsWrapper = styled.li`
  margin: 0 auto;
  padding: 0;

  text-align: center;
  width: 315px;

  ${screen.md} {
    width: 670px;
  }
`;

export const Year = styled.h3`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-success-60')});
  font-size: 20px;
  line-height: 36px;
  margin-bottom: 0px;
  text-align: start;
  position: relative;
  margin: 0;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 2px solid rgb(${getToken('color-success-60')});
    max-width: 78%;
    margin-left: auto;

    ${screen.md} {
      max-width: 89%;
    }
  }
`;

export const Text = styled.p`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-text-primary')});
  font-size: 20px;
  line-height: 36px;

  margin: 28px 0 0;

  ul {
    list-style-type: disc;
    text-align: start;

    a {
      text-decoration: underline;
    }
  }
`;
