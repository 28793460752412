import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { InvertedSection } from '@/components/Grid';

//icons
import Cargowill from '@/public/images/smb-shipping/icon_logo_cargowill.svg';

const responsive = `
    width: 100%;
    height: 100%;
`;

export const ResponsiveImage = styled.img`
  width: 100%;
  height: auto;
`;

export const CargowillLogo = styled(Cargowill)`
  ${responsive};
`;

export const BackgroundSection = styled(InvertedSection)`
  box-sizing: border-box;
  min-height: 574px;

  background: ${`#25405c url(/images/smb-shipping/testimonials/caroussel-bg-mobile@2x.png);`};
  background-position: top center;
  background-size: cover;

  ${screen.md} {
    min-height: 390px;

    background-image: ${`url(/images/smb-shipping/testimonials/caroussel-bg-desktop.png);`};
  }

  ${screen.lg} {
    min-height: 594px;
  }

  ${screen.xxlg} {
    min-height: 660px;
  }
`;

export const SimpleTextTitleStyled = styled.h3`
  text-align: center;
  padding: 0 20px;

  color: #ffffff;
  font-size: 24px;
  line-height: 36px;

  ${screen.md} {
    padding: 0 50px;

    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    padding: 0;

    font-size: 48px;
    line-height: 72px;
  }
`;

export const TestimonialSlider = styled.div`
  margin-top: 20px;

  ${screen.md} {
    margin-top: 30px;
  }

  ${screen.lg} {
    margin-top: 40px;
  }

  ${screen.xxlg} {
    margin-top: 50px;
  }

  & > button {
    width: 25px;
    height: 35px;
    margin-top: -20px;

    cursor: pointer;

    ${screen.md} {
      width: 28px;
      height: 38px;
    }

    ${screen.lg} {
      width: 40px;
      height: 48px;
    }

    ${screen.xxlg} {
      width: 55px;
      height: 55px;
    }
  }
`;

export const Testimonial = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 20px 0 40px;
  padding: 0 35px;

  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    margin: 30px 0 40px;
    padding: 0 110px;
  }

  ${screen.lg} {
    margin: 40px 0 40px;

    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    margin: 50px 0 40px;

    font-size: 20px;
    line-height: 38px;
  }
`;

export const TestimonialAuthor = styled.div`
  margin-bottom: 30px;

  color: #fff;
  font-size: 16px;
  line-height: 30px;
  text-align: center;

  ${screen.xxlg} {
    font-size: 21px;
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  margin: 20px auto;

  text-align: center;

  svg {
    width: 78px;
    height: auto;
  }

  ${screen.md} {
    margin: 10px auto;

    svg {
      width: 99px;
      height: 55px;
    }
  }
`;
