import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// styles
import { ElementRotator, RotatorItem } from './TitleRotator.styles';

export function TitleRotator({ wordsArray }) {
  const [wordCounting, setWordCounting] = useState(0);

  const changeWord = useCallback(() => {
    setWordCounting((wordCounting) => (wordCounting + 1) % wordsArray.length);
  }, [wordsArray.length]);

  useEffect(() => {
    const timer = window.setInterval(() => {
      changeWord();
    }, 2500);
    return () => {
      window.clearInterval(timer);
    };
  }, [changeWord]);

  return (
    <ElementRotator>
      {wordsArray.map((word, index) => {
        const keyIndex = `rotator-${word}`;
        const isvisible = index === wordCounting;
        return (
          <RotatorItem
            key={keyIndex}
            className={classnames({
              'is-visible': isvisible,
              'is-hidden': !isvisible,
            })}
          >
            {word}
          </RotatorItem>
        );
      })}
    </ElementRotator>
  );
}

TitleRotator.propTypes = {
  wordsArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};
