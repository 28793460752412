import styled from 'styled-components';

//helpers
import { screen, intSizes } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const TestimonialsWrapper = styled.section`
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: rgb(${getToken('color-background-inverted')});

  & > div {
    display: grid;

    ${screen.md} {
      grid-template-columns: 211px auto;
    }

    ${screen.lg} {
      grid-template-columns: 312px auto;
    }

    ${screen.xxlg} {
      grid-template-columns: 440px auto;
    }
  }
`;

export const ImageSpacer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 320px;
  height: auto;
  padding: 0 16px;

  background: rgb(${getToken('color-background-inverted')})
    url(/images/managed-transportation/1440-Foods-Logo-nobg.png) center center
    no-repeat;
  background-size: contain;

  ${screen.md} {
    background: rgb(${getToken('color-background-inverted')});
    padding: 16px;
    min-height: 380px;
  }

  ${screen.lg} {
    min-height: 465px;
  }

  ${screen.xxlg} {
    min-height: 560px;
  }

  &::after,
  &::before {
    display: block;
    content: '';
    height: 100%;
    width: 100vw;
    top: 0;
    right: 100%;
    position: absolute;
    background-color: inherit;
    z-index: 0;
  }

  &::after {
    z-index: 1;

    ${screen.md} {
      right: 0;
      width: calc(211px + ((100vw - 768px) / 2));
      background: url(/images/managed-transportation/1440-Foods-Logo-nobg.png)
        center no-repeat;
      background-color: inherit;
      background-size: contain;
    }

    ${screen.lg} {
      width: calc(312px + ((100vw - 1020px) / 2));
    }

    ${screen.xxlg} {
      width: calc(440px + ((100vw - 1440px) / 2));
    }
  }

  &::before {
    right: auto;
    left: 100%;

    ${screen.md} {
      right: 100%;
      left: auto;
    }
  }

  &::after {
    z-index: 1;

    ${screen.md} {
      right: 0;
      width: calc(211px + ((100vw - 768px) / 2));
      background: url(/images/managed-transportation/1440-Foods-Logo-nobg.png)
        center no-repeat;
      background-color: inherit;
      background-size: contain;
    }
    ${screen.lg} {
      width: calc(312px + ((100vw - 1020px) / 2));
    }
    ${screen.xxlg} {
      width: calc(440px + ((100vw - 1440px) / 2));
    }
  }

  &::before {
    right: auto;
    left: 100%;

    ${screen.md} {
      right: 100%;
      left: auto;
    }
  }
`;

export const ContentHolder = styled.div`
  background: rgb(${getToken('color-background-inverted')});
  padding: 0;
  position: relative;
  margin: auto 0;
  padding: 40px 0;

  ${screen.md} {
    padding: 20px 40px;
  }

  ${screen.lg} {
    padding: 30px 60px;
  }

  ${screen.xxlg} {
    padding: 30px 80px;
  }
`;

export const SectionTag = styled.div`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-primary-20')});
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin: 0;

  ${screen.md} {
    font-size: 14px;
    line-height: 21px;
    text-align: start;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const QuoteText = styled.div`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-brand')});
  font-size: 38px;
  line-height: 47.5px;
  text-align: center;
  margin: 16px 0;

  ${screen.md} {
    text-align: start;
  }
  ${screen.lg} {
    margin: 32px 0;
  }
  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72.5px;
  }
`;

export const Client = styled.div``;

export const Name = styled.span`
  display: flex;
  justify-content: center;
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-primary-20')});
  font-size: 20px;
  line-height: 30px;
  margin: 0;

  ${screen.md} {
    justify-content: start;
  }
`;

export const Description = styled.span`
  display: flex;
  justify-content: center;
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-primary-20')});
  font-size: 16px;
  line-height: 24px;
  margin: 0;

  ${screen.md} {
    justify-content: start;
  }
`;
