import styled, { css } from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

import { LinkSectionLayout } from '@/containers/LinkSection/Layout';
import { Description } from '@/containers/LinkSection/Content';
import { TMSIntegrationsContent } from '@/containers/LinkSection';

const Title = styled.h2`
  margin-bottom: 10px;

  font-size: 24px;
  line-height: 36px;

  ${screen.md} {
    margin-bottom: 20px;

    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    margin-bottom: 10px;

    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin-bottom: 42px;

    font-size: 48px;
    line-height: 72px;
  }
`;

const removeBreak = css`
  ${screen.lg} {
    max-width: 100%;
  }

  ${screen.xxlg} {
    max-width: 100%;
  }
`;

export const TMSIntegrationsSection = () => (
  <LinkSectionLayout>
    <Title>TMS Integrations</Title>
    <Description css={removeBreak}>
      We’ve partnered with industry leading TMS providers so you can integrate
      with Loadsmart for free and in as little as five minutes.
    </Description>

    <TMSIntegrationsContent />
  </LinkSectionLayout>
);
