import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

export const VideoEmbeded = styled.div`
  position: relative;

  display: block;
  width: 100%;
  padding-bottom: 56.25%;

  & > iframe {
    position: absolute;
    top: 0;
    right: 0;

    width: 100%;
    height: 100%;

    border: 0;
  }
`;

export const VideoEmbededSmall = styled(VideoEmbeded)`
  padding-bottom: 56.25%;

  ${screen.md} {
    padding-bottom: 28%;
  }
`;
