import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

import { MirandaButton } from '@/components/Button/BaseButton';

export const SectionWrapper = styled.section`
  padding: 40px 0;
  background-color: rgb(${getToken('color-background-primary')});
  text-align: center;

  ${screen.md} {
    padding: 60px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0 64px;
  }
`;

export const TitleWrapper = styled.div`
  margin: 0 0 32px;

  ${screen.xxlg} {
    margin: 0 0 48px;
  }
`;

export const Picture = styled.picture`
  display: inline-block;
  box-sizing: border-box;
  margin: auto;
  max-width: 329px;

  img,
  source {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
    filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  }

  ${screen.md} {
    max-width: 690px;
  }

  ${screen.lg} {
    max-width: 826px;
  }

  ${screen.xxlg} {
    max-width: 1086px;
  }
`;

export const StyledButton = styled(MirandaButton)`
  padding: 16px;
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-inverted')});
  background-color: rgb(${getToken('color-primary-60')});
  font-weight: ${getToken('font-weight-bold')};
  margin-bottom: 50px;

  ${screen.md} {
    padding: 16px 52px;
  }

  ${screen.xxlg} {
    margin-bottom: 48px;
  }
`;

export const Title = styled.h2`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-primary-60')});
  text-align: center;

  font-size: 38px;
  line-height: 47.5px;
  margin: 0px auto 32px;

  br {
    display: none;
  }

  ${screen.lg} {
    font-size: 48px;
    line-height: 60px;
  }

  ${screen.xxlg} {
    br {
      display: inline-block;
    }
  }
`;

export const Description = styled.p`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-tertiary')});
  font-size: 20px;
  line-height: 30px;
  margin: 0;

  ${screen.md} {
    font-size: 20px;
    line-height: 36px;
  }

  ${screen.lg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const IntroText = styled.p`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-text-primary')});
  font-size: 14px;
  line-height: 21px;
  margin: 32px auto 0px;

  ${screen.lg} {
    font-size: 20px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    max-width: 1097px;
  }
`;
