//styles
import { OutlinedBoxWrapper, InnerBox } from './OutlinedBox.styles';

export default function OutlinedBox({ children }) {
    return (
        <OutlinedBoxWrapper>
            <InnerBox>{children}</InnerBox>
        </OutlinedBoxWrapper>
    );
}

export { OutlinedBox };
