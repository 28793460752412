// components
import { Container } from '@/components/Grid';

// styles
import {
  IntroductionTextWrapper,
  Title,
  Content,
} from './IntroductionText.styles';

export function IntroductionText() {
  return (
    <IntroductionTextWrapper>
      <Container>
        <Title>
          Consistent service, fair rates, and solutions to navigate any market
          condition.
        </Title>
        <Content>
          Move your loads with less hassle. Loadsmart offers instantly bookable
          rates with capacity 100% guaranteed. In a volatile market, the
          traditional and manual ways of moving freight are outdated. Bring your
          supply chain into the future – automate freight brokerage processes to
          drive efficiencies, lower shipping costs, and improve service.
        </Content>
      </Container>
    </IntroductionTextWrapper>
  );
}
