/* eslint-disable @next/next/no-img-element */

//components
import { Container } from '@/components/Grid/Container';

import {
  GridSection,
  StyledFeatureGroup,
  StyledFeatureTitle,
  StyledFeatureDescription,
  ImageWrapper,
  Principle,
  ButtonWrapper,
  TitleWrapper,
  TitleSection,
} from './ImageGridSection.styles';
import { MirandaButton } from '@/components/Button';

export const ImageGridSection = () => (
  <GridSection>
    <Container>
      <TitleWrapper>
        <TitleSection>Where to find us</TitleSection>
      </TitleWrapper>
      <StyledFeatureGroup>
        <Principle>
          <div>
            <StyledFeatureTitle>Chicago</StyledFeatureTitle>
            <StyledFeatureDescription>
              Loadsmart was founded in New York and is headquartered in Chicago,
              IL. Our teams operate remotely from different parts of the United
              States as well as in several locations across Latin America.
            </StyledFeatureDescription>
          </div>
          <ImageWrapper>
            <picture>
              <source
                srcSet={`/images/careers/offices/chicago-xxlg.jpg`}
                media="(min-width: 1920px)"
              />
              <source
                srcSet={`/images/careers/offices/chicago-lg.jpg`}
                media="(min-width: 1024px)"
              />
              <source
                srcSet={`/images/careers/offices/chicago-md.jpg`}
                media="(min-width: 768px)"
              />
              <img
                src={`/images/careers/offices/chicago-sm.jpg`}
                alt="Chicago Headquarter"
                width="440"
                height="345"
              />
            </picture>
          </ImageWrapper>
        </Principle>
        <Principle>
          <div>
            <StyledFeatureTitle>New York</StyledFeatureTitle>
            <StyledFeatureDescription>
              With an empire state of mind, Loadsmart began its humble start in
              the big apple. With a small team of scrappy tech minded logistics
              pros ready to disrupt the industry.
            </StyledFeatureDescription>
          </div>
          <ImageWrapper>
            <picture>
              <source
                srcSet={`/images/careers/offices/new-york-xxlg.jpg`}
                media="(min-width: 1920px)"
              />
              <source
                srcSet={`/images/careers/offices/new-york-lg.jpg`}
                media="(min-width: 1024px)"
              />
              <source
                srcSet={`/images/careers/offices/new-york-md.jpg`}
                media="(min-width: 768px)"
              />
              <img
                src={`/images/careers/offices/new-york-sm.jpg`}
                alt="New York Headquarter"
                width="440"
                height="345"
              />
            </picture>
          </ImageWrapper>
        </Principle>
        <Principle>
          <div>
            <StyledFeatureTitle>Worldwide</StyledFeatureTitle>
            <StyledFeatureDescription>
              With Loadies all over the world we continue to grow our global
              presence of logistics professionals all over the world.
            </StyledFeatureDescription>
          </div>
          <ImageWrapper>
            <picture>
              <source
                srcSet={`/images/careers/offices/worldwide-xxlg.jpg`}
                media="(min-width: 1920px)"
              />
              <source
                srcSet={`/images/careers/offices/worldwide-lg.jpg`}
                media="(min-width: 1024px)"
              />
              <source
                srcSet={`/images/careers/offices/worldwide-md.jpg`}
                media="(min-width: 768px)"
              />
              <img
                src={`/images/careers/offices/worldwide-sm.jpg`}
                alt="Worldwide teams"
                width="440"
                height="345"
              />
            </picture>
          </ImageWrapper>
        </Principle>
      </StyledFeatureGroup>

      <ButtonWrapper>
        <MirandaButton
          as="a"
          href="https://jobs.lever.co/loadsmart/"
          target="_blank"
        >
          Explore open positions
        </MirandaButton>
      </ButtonWrapper>
    </Container>
  </GridSection>
);
