import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';
import { Title, Description } from '@/containers/Hero/Hero.styles';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const HeroWrapper = styled.header`
  width: 100%;
  min-height: 692px;
  background: rgb(${getToken('color-background-secondary-inverted')})
    url(/images/managed-transportation/hero-xxlg.jpg) center no-repeat;
  background-size: cover;
  box-sizing: content-box;
`;

export const HeroContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;

  ${screen.md} {
    max-width: 733px;
  }

  ${screen.lg} {
    max-width: 1034px;
    padding-top: 80px;
  }

  ${screen.xxlg} {
    max-width: 1100px;
    padding-top: 100px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${screen.md} {
    flex-direction: row;
  }
`;

export const StyledTitle = styled(Title)`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-bold')};
  margin: 24px 0 0;
  font-size: 38px;
  line-height: 47.5px;
  text-wrap: balance;
  text-align: center;

  ${screen.md} {
    font-size: 48px;
    line-height: 60px;
  }

  ${screen.lg} {
    font-size: 58px;
    line-height: 72.5px;
  }
`;

export const StyledDescription = styled(Description)`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-regular')};
  margin: 24px 0 34px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-wrap: balance;

  ${screen.md} {
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0 26px;
    max-width: 640px;
  }

  ${screen.lg} {
    margin: 24px 0;
    max-width: 800px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 30px;
    max-width: 820px;
  }
`;
