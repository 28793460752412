import styled from 'styled-components';
import { screen } from '@/helpers/screen';
import { Container } from 'components/Grid';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const ShadowBoxWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px 2px !important;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgb(${getToken('color-background-primary')});

  ${screen.lg} {
    padding: 0 60px 20px;
  }
`;

export const InnerBox = styled.div`
  position: relative;
  mix-blend-mode: normal;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  padding: 16px;
  border: solid 2px transparent;
  border-top-width: 0;

  ${screen.lg} {
    padding: 8px;
  }

  ${screen.lg} {
    padding: 18px;
  }

  ${screen.xxlg} {
    padding: 18px 24px;
  }
`;
