import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SolutionsWrapper = styled.section`
  padding: 50px 0;

  text-align: center;

  background: rgb(${getToken('color-neutral-100')});

  ${screen.lg} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 38px 0;
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: calc(375px - 6rem);

  ${screen.md} {
    flex-direction: row;
    max-width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &.variant {
    max-width: 289px;
  }

  ${screen.md} {
    margin-left: 36px;
    max-width: 303px;

    &.variant {
      max-width: 303px;
    }
  }

  ${screen.lg} {
    max-width: 438px;

    &.variant {
      max-width: 355px;
    }
  }

  ${screen.xxlg} {
    margin-left: 48px;
    max-width: 695px;

    &.variant {
      max-width: 636px;
    }
  }
`;

export const Title = styled.h1`
  margin: 0 auto;

  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-brand')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  &.variant {
    font-size: 26px;
    line-height: 39px;
  }

  ${screen.md} {
    text-align: start;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 47px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;

    &.variant {
      font-size: 48px;
      line-height: 60px;
    }
  }
`;

export const Text = styled.p`
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-regular')};
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  margin: 32px 0;

  &.variant {
    font-size: 16px;
    line-height: 21px;
  }

  ${screen.md} {
    text-align: start;
  }

  ${screen.lg} {
    font-size: 14px;
    line-height: 21px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 36px;

    &.variant {
      font-size: 20px;
      line-height: 36px;
    }
  }
`;

export const Link = styled.a`
  display: inline-flex;
  width: fit-content;
  margin: 0;
  img,
  source {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 16px;
  max-width: 210px;

  ${screen.md} {
    margin: 0;
  }

  ${screen.lg} {
    gap: 22px;
    max-width: 288px;
  }

  ${screen.xxlg} {
    gap: 36px;
    max-width: 100%;
  }
`;

export const Picture = styled.picture`
  display: grid;
  box-sizing: border-box;

  img,
  source {
    width: 100%;
    max-width: 341px;
    height: auto;
    margin: auto auto 36px;

    ${screen.md} {
      max-width: 382px;
      margin: auto;
    }

    ${screen.lg} {
      max-width: 620px;
    }

    ${screen.xxlg} {
      max-width: 897px;
    }
  }
`;
