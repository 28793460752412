import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';

import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';

import BlueYonderLogo from '@/public/images/logos/logo-blue_yonder.svg';

const BlujayWrapper = styled(FlexRowFullCentered)`
  width: 94px;
  height: 14px;

  ${screen.md} {
    width: 120px;
    height: 18px;
  }

  ${screen.lg} {
    width: 150px;
    height: 23px;
  }

  ${screen.xxlg} {
    width: 190px;
    height: 29px;
  }
`;

export const BlueYonderCard = (props) => (
  <CardLayout {...props}>
    <BlujayWrapper>
      <BlueYonderLogo />
    </BlujayWrapper>
  </CardLayout>
);
