import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';

import TranzActLogo from '@/public/images/logos/logo-tranz_act.svg';

const TranzActWrapper = styled(FlexRowFullCentered)`
  width: 75px;
  height: 19px;

  ${screen.md} {
    width: 80px;
    height: 20px;
  }

  ${screen.lg} {
    width: 110px;
    height: 28px;
  }

  ${screen.xxlg} {
    width: 140px;
    height: 35px;
  }
`;

export const TranzActCard = (props) => (
  <CardLayout {...props}>
    <TranzActWrapper>
      <TranzActLogo />
    </TranzActWrapper>
  </CardLayout>
);
