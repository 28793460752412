import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';

//assets
import { IconClose } from '@loadsmart/icons';

//styles
import {
  VideoHeroWrapper,
  ContainerStyled,
  Thumb,
  ThumbImage,
  PlayButton,
  VideoContainer,
  VideoWrapper,
  StopButton,
  IconPlay,
} from './VideoHero.styles';

export function VideoHero() {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <VideoHeroWrapper>
      <ContainerStyled>
        {/* {!playVideo ? ( */}
        <Thumb
        // onClick={() => {
        //   setPlayVideo(true);
        // }}
        // role="button"
        >
          <ThumbImage
            src="/images/carrier-tms/video-hero/screen-video-xxlarge.png"
            alt="Carrier TMS screenshot"
          />
          {/* <PlayButton>
              <IconPlay />
            </PlayButton> */}
        </Thumb>
        {/* ) : (
          <VideoWrapper>
            <VideoContainer>
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/watch?v=M-cUfo6nbGw?rel=0"
                playing
                controls
                width="100%"
                height="100%"
              />
            </VideoContainer>
            <StopButton
              onClick={() => {
                setPlayVideo(false);
              }}
            >
              <IconClose />
            </StopButton>
          </VideoWrapper>
        )} */}
      </ContainerStyled>
    </VideoHeroWrapper>
  );
}
