import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { SectionPanel } from '@/components/Grid/Panel';

export const SidePanels = styled(SectionPanel)`
  margin-top: 0px;
`;

export const CustomSidePanels = styled(SectionPanel)`
  margin-top: 0px;

  ${screen.md} {
    margin-top: 40px;
  }
  ${screen.lg} {
    margin-top: 60px;
  }
  ${screen.xxlg} {
    margin-top: 80px;
  }
`;

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;
