import { Subtitle, Paragraph1 } from 'components/Text';
import { OutlinedBox } from 'components/';

//styles
import {
  FeaturedListWrapper,
  FeaturedListItem,
  IconWrapper,
} from './FeaturedList.styles';

export default function FeaturedList({ content }) {
  return (
    <FeaturedListWrapper>
      {content &&
        content.map((item, key) => {
          return (
            <FeaturedListItem key={`featuredListImte-${key}`}>
              <OutlinedBox>
                {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
                <Subtitle as="h3">{item.title}</Subtitle>
                <Paragraph1>{item.description}</Paragraph1>
              </OutlinedBox>
            </FeaturedListItem>
          );
        })}
    </FeaturedListWrapper>
  );
}

export { FeaturedList };
