import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const IntroductionTextWrapper = styled.section`
  padding: 100px 0 0;
  background-color: rgb(255, 255, 255);
  text-align: center;
`;

export const Title = styled.h2`
  margin: 0px;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  text-wrap: balance;

  ${screen.lg} {
    br {
      display: none;
    }
  }

  ${screen.xxlg} {
    font-size: 38px;
    line-height: 47.5px;
  }
`;

export const Content = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-tertiary')});
  font-weight: ${getToken('font-weight-regular')};
  margin: 32px auto 0;

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 30px;
    margin: 32px auto 0;
    max-width: 1209px;
  }
`;
