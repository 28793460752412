import styled from 'styled-components';
import { FlexColumnFullCentered } from '@/components/Grid/Flex';
import {
  OracleCard,
  ManhattanCard,
  MercuryGateCard,
  E2OpenCard,
  BlueYonderCard,
  OneNetworkCard,
  KuebixCard,
  TaiCard,
  WinCard,
  TranzActCard,
  UrouteCard,
} from './Card';
import { LinkSectionLayout, WrapSectionLayoutFix, marginFix } from './Layout';
import {
  ResponsiveLogo,
  Subtitle,
  Description,
  DescriptionBreak,
  CardSectionTitle,
  LearnMore,
} from './Content';

const UrouteCardSpaced = styled(UrouteCard)`
  ${marginFix};
`;
const ManhattanCardSpaced = styled(ManhattanCard)`
  ${marginFix};
`;
const OracleCardSpaced = styled(OracleCard)`
  ${marginFix};
`;
const MercuryGateCardSpaced = styled(MercuryGateCard)`
  ${marginFix};
`;
const BlueYonderCardSpaced = styled(BlueYonderCard)`
  ${marginFix};
`;
const E2OpenCardSpaced = styled(E2OpenCard)`
  ${marginFix};
`;
const OneNetworkCardSpaced = styled(OneNetworkCard)`
  ${marginFix};
`;
const KuebixCardSpaced = styled(KuebixCard)`
  ${marginFix};
`;
const TaiCardSpaced = styled(TaiCard)`
  ${marginFix};
`;
const WinCardSpaced = styled(WinCard)`
  ${marginFix};
`;
const TranzActCardSpaced = styled(TranzActCard)`
  ${marginFix};
`;

export const TMSIntegrationsContent = () => (
  <WrapSectionLayoutFix>
    <OracleCardSpaced className="full-mobile" link="/integrations/oracle/" />
    <MercuryGateCardSpaced
      className="full-mobile"
      link="/integrations/mercury-gate/"
    />
    <E2OpenCardSpaced className="full-mobile" link="/integrations/e2open/" />
    <BlueYonderCardSpaced
      className="full-mobile"
      link="/integrations/blue-yonder/"
    />
    <ManhattanCardSpaced
      className="full-mobile"
      link="/integrations/manhattan/"
    />
    <KuebixCardSpaced className="full-mobile" />
    <TaiCardSpaced className="full-mobile" />
    <WinCardSpaced className="full-mobile" />
    <TranzActCardSpaced className="full-mobile" />
    <OneNetworkCardSpaced className="full-mobile" />
    <UrouteCardSpaced className="full-mobile" />
  </WrapSectionLayoutFix>
);

const TMSIntegrationSection = () => (
  <FlexColumnFullCentered>
    <CardSectionTitle>TMS INTEGRATIONS</CardSectionTitle>

    <TMSIntegrationsContent />
  </FlexColumnFullCentered>
);

export const LoadsmartLinkSectionContent = () => (
  <LinkSectionLayout>
    <ResponsiveLogo />
    <Subtitle>
      Price and book shipments from wherever you manage freight
    </Subtitle>
    <Description>
      Loadsmart Link enables you to quote and book shipments from the tools and
      systems you use every day.
      <DescriptionBreak />
      Add rates to your Microsoft or Google spreadsheets with our Simple Quote
      tool or integrate your TMS to price and tender thousands of shipments in
      seconds.
    </Description>

    <TMSIntegrationSection />

    <LearnMore href={`/link`}>Learn more</LearnMore>
  </LinkSectionLayout>
);
