import styled from 'styled-components';

//helpers
import { screen, intSizes } from '@/helpers/screen';

//components
import { Container } from '@/components/Grid/Container';
import { Text } from '@/components/Grid/Content';

export const ContentEmbeded = styled(Container)`
  margin: 30px auto;

  @media (max-width: ${intSizes.md}px) {
    max-width: 100%;
  }

  ${screen.md} {
    margin: 50px auto;
  }
  ${screen.lg} {
    margin: 70px auto;
  }
  ${screen.xxlg} {
    margin: 100px auto;
  }
`;

export const VideoEmbeded = styled.div`
  position: relative;

  display: block;
  width: 100%;
  padding-bottom: 56.25%;

  & > iframe {
    position: absolute;
    top: 0;
    right: 0;

    width: 100%;
    height: 100%;

    border: 0;
  }
`;

export const SimpleText = styled(Text)`
  text-align: center;

  ${screen.md} {
    text-align: left;
  }
`;

export const IntroductionTitle = styled(SimpleText)`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;
