import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container, InvertedSection } from '@/components/Grid';

const Layout = styled(InvertedSection)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/port-drayage/fullscreen_section-small_background.png);

  ${screen.md} {
    background-image: url(/images/port-drayage/fullscreen_section-medium_background.png);
    text-align: left;
  }

  ${screen.lg} {
    background-image: url(/images/port-drayage/fullscreen_section-large_background.png);
  }

  ${screen.xxlg} {
    background-image: url(/images/port-drayage/fullscreen_section-xxlarge_background.png);
  }
`;

const Title = styled.h1`
  font-size: 25px;
  line-height: 36px;
  margin-bottom: 16px;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 55px;
    margin-bottom: 30px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
    margin-bottom: 28px;
  }
`;

const Text = styled.p`
  font-size: 12px;
  line-height: 22px;

  ${screen.md} {
    font-size: 12px;
    line-height: 22px;
    max-width: 498px;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
    max-width: 639px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
    max-width: 821px;
  }
`;

export const BackgroundImageSection = () => (
  <Layout>
    <Container>
      <Title>Multimodal Transportation</Title>
      <Text>
        Loadsmart’s Multimodal Services offering bridges port drayage, transload
        and over-the-road truckload shipping using advanced technology.
        <br />
        <br />
        Instantly execute multimodal shipments and obtain full transparency
        across the entire shipment lifecycle — from the port, to the transload
        facility, onto the vehicles, and, ultimately, to the intended
        destinations — all from a single digital logistics partner.
      </Text>
    </Container>
  </Layout>
);
