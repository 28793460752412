import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';

import WinLogo from '@/public/images/logos/logo-win.svg';

const WinWrapper = styled(FlexRowFullCentered)`
  width: 56px;
  height: 16px;

  ${screen.md} {
    width: 60px;
    height: 16px;
  }

  ${screen.lg} {
    width: 80px;
    height: 22px;
  }

  ${screen.xxlg} {
    width: 113px;
    height: 30px;
  }
`;

export const WinCard = (props) => (
  <CardLayout {...props}>
    <WinWrapper>
      <WinLogo />
    </WinWrapper>
  </CardLayout>
);
