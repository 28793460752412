import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';
import { sleep } from '@/helpers/sleep';

//components
import { BaseButton, GhostButton } from '@/components/Button';

//containers
import { Hero } from '@/containers/Hero';
import { Title, Description } from '@/containers/Hero/Hero.styles';

const responsiveBackground = {
  sm: `url(/images/link/small_hero.jpg)`,
  md: `url(/images/link/medium_hero.jpg)`,
  lg: `url(/images/link/large_hero.jpg)`,
  xxlg: `url(/images/link/xxlarge_hero.jpg)`,
};

const smallFont = css`
  font-size: 12px;
`;

const ctaStyle = css`
  width: 100%;
  margin: 12px 0;

  white-space: nowrap;
  text-transform: uppercase;

  ${screen.md} {
    width: auto;
    margin-right: 15px;
  }
`;

const FlexRowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const HeroBackground = styled(Hero)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${(props) => props.image?.sm && `background-image: ${props.image?.sm}`};

  ${screen.md} {
    ${(props) => props.image?.md && `background-image: ${props.image?.md}`};
  }

  ${screen.lg} {
    ${(props) => props.image?.lg && `background-image: ${props.image?.lg}`};
  }

  ${screen.xxlg} {
    ${(props) => props.image?.xxlg && `background-image: ${props.image?.xxlg}`};
  }
`;

export const HeroSection = () => {
  const router = useRouter();
  const handleFormJump = async (event) => {
    event.preventDefault();
    // analytics.track(' Page / Request Demo');
    await sleep(300);
    router.push('#hubspot-form');
  };

  return (
    <HeroBackground image={responsiveBackground}>
      <Title>TMS Integrations</Title>
      <Description>
        Instantly bookable rates and dynamic pricing, no matter the mode or
        market - all within your TMS.
      </Description>
      <FlexRowWrap>
        <BaseButton as="a" onClick={handleFormJump} css={[ctaStyle, smallFont]}>
          Talk to a Transportation Specialist
        </BaseButton>
        <GhostButton as="a" onClick={handleFormJump} css={smallFont}>
          REQUEST A DEMO
        </GhostButton>
      </FlexRowWrap>
    </HeroBackground>
  );
};
