import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const LifeLoadVideoWrapper = styled.section`
  padding: 0 0 80px;
  background-color: rgb(${getToken('color-background-tertiary')});
  text-align: center;

  ${screen.lg} {
    padding: 2px 0 80px;
  }

  ${screen.xxlg} {
    padding: 15px 0 88px;
  }
`;

export const Title = styled.h2`
  margin: 0px;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  text-align: center;

  ${screen.md} {
    font-size: 38px;
    line-height: 47.5px;
  }

  ${screen.lg} {
    br {
      display: none;
    }
  }
`;

export const VideoWrapper = styled.div`
  margin: 48px auto 0;
  height: 182px;
  width: 100%;
  position: relative;

  ${screen.md} {
    align-self: center;
    height: 356px;
    width: 656px;
  }

  ${screen.lg} {
    height: 412px;
    width: 760px;
  }

  ${screen.xxlg} {
    height: 586px;
    width: 1080px;
  }
`;

export const CTAWrapper = styled.div`
  display: flex;
  padding: 48px 0 0;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;

  ${screen.md} {
    gap: 32px;
    flex-direction: row;
  }
`;
