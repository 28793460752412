import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Button } from '@/components/Button';
import { InvertedSection } from '@/components/Grid';
import { FlexColumnFullCentered } from '@/components/Grid/Flex';
import LoadsmartTransit from '@/public/images/multimodal/icon_logo-loadsmart_transit.svg';

export const Layout = styled(InvertedSection)`
  padding-top: 0;
  box-sizing: border-box;

  ${screen.md} {
    padding-top: 40px;
    background-image: url(/images/multimodal/fullscreen_section-medium_image.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  ${screen.lg} {
    background-image: url(/images/multimodal/fullscreen_section-large_image.png);
    height: 625px;
  }

  ${screen.xxlg} {
    background-image: url(/images/multimodal/fullscreen_section-xxlarge_image.png);
    height: 984px;
  }
`;

export const ResponsiveImage = styled.img`
  width: 100%;
  height: auto;
`;

export const WideImageWrapper = styled(FlexColumnFullCentered)`
  height: 250px;
  overflow: hidden;
  margin-bottom: 30px;

  ${screen.md} {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${screen.md} {
    max-width: 50%;
    text-align: left;
    align-items: flex-start;
  }
`;

export const LoadsmartTransitLogo = styled(LoadsmartTransit)`
  color: #14d64d;
  width: 158px;
  height: 39px;
  margin: 40px auto 20px;

  ${screen.md} {
    margin: 20px 0;
    width: 179px;
    height: 44px;
  }

  ${screen.lg} {
    width: 259px;
    height: 64px;
  }

  ${screen.xxlg} {
    width: 303px;
    height: 75px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 22px;

  ${screen.md} {
    font-size: 16px;
    line-height: 26px;
  }

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 44px;
  }
`;

export const Text = styled.p`
  margin-bottom: 40px;
  font-size: 12px;
  line-height: 22px;

  ${screen.md} {
    font-size: 12px;
    line-height: 22px;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
  }
`;

export const ResponsiveButton = styled(Button)`
  margin: 0;
  width: 100%;

  ${screen.md} {
    width: 158px;
  }

  ${screen.lg} {
    width: 227px;
  }

  ${screen.xxlg} {
    width: 300px;
  }
`;
