import styled from 'styled-components';
import { screen, intSizes } from '@/helpers/screen';

const CardWrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 152px;
  height: 44px;

  color: white;

  background: #121d2b;

  &.full-mobile {
    transition: all 0.13s ease-in-out;

    @media (max-width: calc(${intSizes.md}px - 1px)) {
      width: 100%;
    }

    &:hover[href] {
      background: #333e48;

      transition: all 0.1s ease-in-out;
    }
  }

  ${screen.md} {
    width: 161px;
    height: 44px;
  }

  ${screen.lg} {
    width: 232px;
    height: 62px;
  }

  ${screen.xxlg} {
    width: 325px;
    height: 87px;
  }
`;

export const CardLayout = ({ link, children, ...other }) => {
  return link ? (
    <CardWrapper as="a" href={link} {...other}>
      {children}
    </CardWrapper>
  ) : (
    <CardWrapper {...other}>{children}</CardWrapper>
  );
};
