// components
import { Container } from 'components/Grid';
import {
  MirandaSecondaryButton,
  MirandaHollowSecondaryButton,
} from '@/components/Button/BaseButton';

// styles
import { ShipWithUsWrapper, Title, Content } from './ShipWithUs.styles';

export function ShipWithUs() {
  return (
    <ShipWithUsWrapper>
      <Container>
        <Title>Find a load last minute, or build one from scratch.</Title>
        <Content>
          <MirandaSecondaryButton
            as="a"
            href={`${process.env.SHIPPERGUIDE_URL}/signup`}
          >
            Ship With Loadsmart
          </MirandaSecondaryButton>
          <MirandaHollowSecondaryButton
            as="a"
            href={`${process.env.CARRIER_URL}/#/signup`}
          >
            Haul With Loadsmart
          </MirandaHollowSecondaryButton>
        </Content>
      </Container>
    </ShipWithUsWrapper>
  );
}
