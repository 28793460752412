// components
import { Container } from '@/components/Grid/Container';

// styles
import {
  SolutionsWrapper,
  Title,
  SubTitle,
  Text,
  StyledButton,
} from './TransformYourFleetSection.styles';

export function TransformYourFleetSection() {
  return (
    <SolutionsWrapper>
      <Container>
        <Title>Turn your private fleet’s empty miles into revenue.</Title>
        <SubTitle>Meet Fleet Optmizer - No More Empty Trucks.</SubTitle>
        <Text>
          We turn empty miles and idle trucks into revenue without compromising
          a fleet’s primary haul. Fleets can easily connect their capacity to
          Loadsmart and start finding new loads that meet their network’s unique
          requirements.
        </Text>
        <StyledButton
          as="a"
          href="https://lp.loadsmart.com/loadsmart-fleet-optimization"
          target="_blank"
        >
          Transform your fleet now
        </StyledButton>
      </Container>
    </SolutionsWrapper>
  );
}
