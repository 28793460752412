//componets
import { MirandaGradientButton } from '@/components/Button';

// styles
import {
  HeroWrapper,
  HeroContainer,
  FlexRow,
  StyledTitle,
  StyledDescription,
} from './Hero.styles';

export function Hero() {
  return (
    <HeroWrapper>
      <HeroContainer>
        <FlexRow>
          <StyledTitle>
            <span>Get fast and reliable delivery</span> with Loadsmart Expedited
            Shipping.
          </StyledTitle>
          <StyledDescription>
            Extensive coverage combined with advanced technology and
            professional teams ensures superior OTD and happy customers.
          </StyledDescription>
          <MirandaGradientButton as="a" href="#hubspot-form">
            <span>Learn more</span>
          </MirandaGradientButton>
        </FlexRow>
      </HeroContainer>
    </HeroWrapper>
  );
}
