/* eslint-disable @next/next/no-img-element */
// styles
import {
  ModalBoxesWrapper,
  Container,
  Content,
  BoxItem,
  BoxTitle,
  BoxCopy,
  ImageWrap,
} from './ModalBoxes.styles';

//components
import { Paragraph1, Title1 } from '@/components/Text';

const feed = [
  {
    title: 'SPRINTER VAN',
    image: {
      path: '/images/expedited-shipping/trucks/sprinter.png',
      alt: 'Sprinter Van',
    },
    space: '1-3 pallets',
    scale: ' 3,500 lbs.',
    door: '168” L x 53” W x 72” H',
  },
  {
    title: 'SMALL STRAIGHT TRUCKS',
    image: {
      path: '/images/expedited-shipping/trucks/sm-straight.png',
      alt: 'Small Straight Trucks',
    },
    space: '3-8 pallets',
    scale: '5,500 lbs.',
    door: '18’ L x 96” W x 92” H',
  },
  {
    title: 'LARGE STRAIGHT TRUCKS',
    image: {
      path: '/images/expedited-shipping/trucks/lg-straight.png',
      alt: 'Large Straight Trucks',
    },
    space: '8-12 pallets',
    scale: '10,000 lbs.',
    door: '26’ L x 96” W x 96” H',
  },
];

export function ModalBoxes() {
  return (
    <ModalBoxesWrapper>
      <Container>
        <div>
          <Title1>Our Ground Expedite Fleet</Title1>
        </div>

        {feed && (
          <Content>
            {feed.map((item, key) => {
              return (
                <BoxItem key={item.title.trim() + key}>
                  {item.image && (
                    <ImageWrap>
                      <img
                        src={item.image.path}
                        alt={item.image.alt}
                        width="342"
                        height="134"
                      />
                    </ImageWrap>
                  )}
                  <BoxTitle>{item.title}</BoxTitle>
                  <BoxCopy>
                    <p>
                      Cargo space avaliable: <strong>{item.space}</strong>
                    </p>
                    <p>
                      Can scale up to <strong>{item.scale}</strong>
                    </p>
                    <p>
                      Door Opening: <strong>{item.door}</strong>
                    </p>
                  </BoxCopy>
                </BoxItem>
              );
            })}
          </Content>
        )}
        <div>
          <Paragraph1>
            *Equipment dimensions are not standardized. Accurate dimensions of
            the freight are needed to ensure the appropriate equipment is
            secured
          </Paragraph1>
        </div>
      </Container>
    </ModalBoxesWrapper>
  );
}
