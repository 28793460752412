// styles
import {
  FloatHeroBoxWrapper,
  Container,
  Title,
  Subtitle,
  Button,
} from './FloatHeroBox.styles';

export function FloatHeroBox() {
  return (
    <FloatHeroBoxWrapper>
      <Container>
        <Title>
          Find, book, and move more freight <br /> from top shippers.
        </Title>
        <Subtitle>
          Carriers trust Loadsmart to keep their trucks full. Gain access to
          freight technology, fleet management services, and instantly bookable
          shipments directly through our industry-wide connectivity to reliable
          shipper loads.
        </Subtitle>
        <Button href="https://carrier.loadsmart.com/#/signup" target="_blank">
          Go to the Loadboard
        </Button>
      </Container>
    </FloatHeroBoxWrapper>
  );
}
