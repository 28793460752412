//components
import { Container } from '@/components/Grid/Container';
import { Section } from '@/components/Grid/Section';

import {
  ContentEmbeded,
  VideoEmbeded,
  SimpleText,
  IntroductionTitle,
} from './LeadershipVideo.styles';

function LeadershipVideo() {
  return (
    <Section>
      <Container>
        <IntroductionTitle>
          Industry Veterans. Business Leaders. <br />
          Loadsmart Leadership.
        </IntroductionTitle>
        <SimpleText>
          Loadsmart is a Chicago-based freight tech company founded in 2014. As
          the ‘nerds of logistics’, we seek intelligence in data to solve deep
          rooted inefficiencies in the industry. Pairing advanced technologies
          with deep-seated industry expertise, Loadsmart fuels growth,
          simplifies operational complexity and bolsters efficiency for carriers
          and shippers alike. We give our customers access to our data
          connections that link supply and demand in addition to a suite of
          award-winning solutions to strike the perfect balance of cost and
          service.
        </SimpleText>
        <SimpleText>
          Meet our leadership team who believe in a more efficient and
          environmentally responsible way to move more with less.
        </SimpleText>
      </Container>

      <ContentEmbeded>
        <VideoEmbeded>
          <iframe
            src="https://www.youtube.com/embed/1au4qMXL6i8"
            title="The Loadsmart"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </VideoEmbeded>
      </ContentEmbeded>
    </Section>
  );
}

export default LeadershipVideo;
export { LeadershipVideo };
