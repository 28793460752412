import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const HeroWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.color.neutral.lightest};
  background-position: center center;
  background-size: cover;

  padding-top: 60px;
  margin-bottom: 30px;

  ${(props) =>
    props.image?.small && `background-image: url(${props.image.small});`}

  ${screen.md} {
    margin-bottom: 120px;
  }

  ${screen.lg} {
    padding-top: 90px;
    margin-bottom: 210px;
  }

  ${screen.xxlg} {
    margin-bottom: 240px;
  }
`;

export const ContainerStyled = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  max-width: 100%;
  width: 100%;

  ${screen.md} {
    max-width: 622px;
  }
  ${screen.lg} {
    max-width: 894px;
  }
  ${screen.xxlg} {
    max-width: 1140px;
    margin: 0 auto;
  }
`;

export const Logo = styled.div`
  height: 57.5px;
  width: 230px;
  margin: auto auto 36px;

  img,
  svg {
    max-width: 100%;
    height: auto;
  }

  ${screen.lg} {
    height: 65px;
    width: 260px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  margin: 0 auto;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};

  font-size: 38px;
  line-height: 47.5px;

  max-width: 290px;

  ${screen.md} {
    max-width: unset;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const Subtitle = styled.div`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('font-family-title')};
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin: 16px auto 20px;
  max-width: 290px;

  ${screen.md} {
    max-width: 100%;
    margin: 16px auto;
  }

  ${screen.lg} {
    margin: 24px auto;
  }

  ${screen.xxlg} {
    margin: 24px 0 26px;
    margin-inline: 0;
  }
`;

export const ChildrenWrapper = styled.div`
  text-align: center;

  ${(props) =>
    props.gradient &&
    `background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(114.92deg, #84F11E -33.79%, #46E575 -8.04%, #00D7D7 117.74%);
    background-position: center 40px;
    background-repeat: no-repeat;
    mix-blend-mode: normal;`}

  img, source {
    width: 100%;
    max-width: 374px;
    height: auto;
    margin: 0 -1.6px -55px;

    ${screen.md} {
      max-width: 725px;
      margin: 0 -4.6px -240px;
    }

    ${screen.lg} {
      max-width: 1106px;
      margin: 0 -2.6px -279px;
    }

    ${screen.xxlg} {
      max-width: 1475px;
      margin: 0 -3.525px -310px;
    }
  }
`;
