/* eslint-disable @next/next/no-img-element */
import React from 'react';
import PropTypes from 'prop-types';

// styles
import { SlidePanel, SystemImage } from './Slide.styles';

export function Slide({ property }) {
  return (
    <SlidePanel>
      <SystemImage>
        <img
          src={`${property.image.src}`}
          alt={property.image.alt}
          width="1096"
          height="422"
        />
      </SystemImage>
    </SlidePanel>
  );
}

Slide.propTypes = {
  property: PropTypes.shape({
    name: PropTypes.string,
    active: PropTypes.bool,
    keyword: PropTypes.string,
    color: PropTypes.string,
    logo: PropTypes.element,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.string,
    link: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  }).isRequired,
};
