import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//containers
import { Hero } from '@/containers/Hero';
import { Title, Description } from '@/components/Grid/Content/';

const HeroCover = styled(Hero)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/images/ftl/hero/small_hero.png);

  ${screen.md} {
    background-image: url(/images/ftl/hero/medium_hero.png);
  }

  ${screen.lg} {
    background-image: url(/images/ftl/hero/large_hero.png);
  }

  ${screen.xxlg} {
    background-image: url(/images/ftl/hero/xxlarge_hero.png);
  }
`;

export const HeroSection = () => (
  <HeroCover>
    <Title>Full Truckload</Title>
    <Description>
      Price and book truckload shipments in seconds using our free web-based
      platform Transit or integrate with Loadsmart Link and do it all from your
      native TMS or spreadsheets.
    </Description>
  </HeroCover>
);
