// containers
import { Navigation } from '@/containers-m/Navigation';
import { Footer } from '@/containers-m/Footer';
import { WeInNumbers } from '@/containers-m/WeInNumbers';

//context containers
import {
  Hero,
  VideoHero,
  OfferList,
  MissionBanner,
  OurStory,
  BackgroundImageSection,
  Awards,
} from './containers';

//styles
import { PageWrapper } from './About.styles';

const bgSection2 = {
  title: 'Become a Loadie!',
  description:
    'We’re always looking to connect with world-class thinkers, strategists, doers, and leaders. Check out our open positions below.',
  image: {
    xxlarge: 'images/about/become-a-loadie/become_a_loadie_xxlarge@2x.jpg',
    large: 'images/about/become-a-loadie/become_a_loadie_lg@2x.jpg',
    medium: 'images/about/become-a-loadie/become_a_loadie_md@2x.jpg',
    small: 'images/about/become-a-loadie/become_a_loadie_sm@2x.jpg',
  },
  CTA: {
    label: 'Discover Open Positions!',
    link: 'https://jobs.lever.co/loadsmart/',
    external: true,
  },
  biggerBg: true,
};

function About() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <Hero />
        <VideoHero />
        <OfferList />
        <MissionBanner />
        <OurStory />
        <WeInNumbers />
        <BackgroundImageSection content={bgSection2} />
        <Awards />
      </>
      <Footer />
    </PageWrapper>
  );
}

export default About;
export { About };
