import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

import { Wrapper as BaseWrapper } from '/components/Grid/Content';

export const SectionWrapper = styled.section`
  padding: 40px 0;
  background-color: rgb(${getToken('color-background-primary')});
  text-align: center;

  ${screen.md} {
    padding: 60px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0 64px;
  }
`;

export const Wrapper = styled(BaseWrapper)`
  justify-content: center;
  align-items: center;
  padding: 0 0 36px 0;

  ${screen.md} {
    padding: 0 0 50px 0;
  }

  ${screen.lg} {
    align-items: start;
    padding-right: 14px;
  }

  ${screen.xxlg} {
    padding-right: 175px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 10px;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-primary-60')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 26px;
  line-height: 39px;
  text-align: center;

  ${screen.md} {
    font-size: 38px;
    line-height: 47.5px;
  }

  ${screen.lg} {
    text-align: left;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const Content = styled.p`
  margin: 16px 0;
  font-family: ${getToken('platform-font-default')};
  color: rgb(${getToken('color-text-tertiary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin: 32px 0 0;

  br {
    display: none;
  }

  ${screen.md} {
    margin: 32px 0;
    font-size: 20px;
    line-height: 30px;

    br {
      display: inline-block;
    }
  }

  ${screen.lg} {
    text-align: left;

    br {
      display: none;
    }
  }

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const MobileHide = styled.div`
  display: none;
  ${screen.md} {
    display: inline-flex;
  }
`;

export const Picture = styled.picture`
  display: inline-block;
  box-sizing: border-box;
  margin: auto;

  ${screen.md} {
    padding: 0;
  }

  img,
  source {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
  }
`;

export const SectionPanel = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${screen.lg} {
    flex-direction: row-reverse;
  }
`;
