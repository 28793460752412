import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Link as BaseLink } from '@/components/Grid/Content';
import { FlexColumnFullCentered } from '@/components/Grid/Flex';
import { InvertedSection } from '@/components/Grid';
import { SubTitle } from '@/components/Grid/Content';

export const Link = styled(BaseLink)`
  text-align: center;
  display: inline-block;
  width: fit-content;
  margin: auto;

  ${screen.md} {
    text-align: left;
    margin: 0;
  }
`;

export const GreySection = styled(InvertedSection)`
  background-color: #d3d9d9;
`;

export const ResponsiveImage = styled.picture`
  box-sizing: border-box;
  max-width: 100%;
  height: auto;
  margin-top: 5px;

  text-align: center;

  img,
  source,
  svg {
    max-width: 100%;
    height: auto;
  }

  ${screen.md} {
    margin-top: 30px;
    padding-right: 5%;

    text-align: left;
  }

  ${screen.lg} {
    margin-top: 40px;
  }
`;

export const ChartWrapper = styled(FlexColumnFullCentered)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;

  ${screen.md} {
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0;
    padding-left: 20px;

    min-width: 347px;
  }
`;

export const Column = styled.div`
  display: grid;

  p:nth-child(3) {
    margin-top: 0;
  }

  ${screen.md} {
    width: 50%;
    height: 100%;
  }
`;

export const SubTitleStyled = styled(SubTitle)`
  padding-bottom: 15px;

  font-size: 16px;
  line-height: 26px;

  margin: 0px;

  ${screen.md} {
    padding-bottom: 0;

    font-size: 16px;
    line-height: 26px;
  }

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 44px;
  }
`;
