import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import {
  Feature,
  FeatureTitle,
  FeatureDescription,
} from '@/components/Feature';
import { Container } from '@/components/Grid/Container';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const Section = styled.section`
  background-color: rgb(${getToken('color-background-secondary')});
`;

export const TeamContainer = styled(Container)`
  border-top: 1px solid rgb(${getToken('color-primary-20')});
  padding: 80px 0;

  ${screen.md} {
    padding: 50px 0 80px;
  }

  ${screen.lg} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const TeamGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &:nth-child(1) {
    margin-bottom: 0px;

    ${screen.lg} {
      margin-bottom: 80px;
    }

    ${screen.xxlg} {
      margin-bottom: 131px;
    }
  }
`;

export const Team = styled(Feature)`
  box-sizing: border-box;
  margin: 0;
  align-items: center;
  padding: 0 5px 32px;

  ${screen.md} {
    align-items: flex-start;
    width: 50% !important;
    padding: 0 41px 50px 0;
  }

  ${screen.lg} {
    width: 25% !important;
    padding: 0 16px 0 0;
  }

  ${screen.xxlg} {
    padding: 0 16px;
  }
`;

export const Title = styled(FeatureTitle)`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  text-transform: none;
  text-align: center;
  margin: 0;
  font-size: 26px;
  line-height: 39px;

  ${screen.md} {
    text-align: left;
  }
`;

export const Description = styled(FeatureDescription)`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-text-primary')});
  text-align: center;
  margin: 10px 0 0;
  font-size: 20px;
  line-height: 36px;

  ${screen.md} {
    text-align: left;
  }
`;
