import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import {
  Title,
  SubTitle,
  Text,
  ResponsiveImage,
} from '@/components/Grid/Content';
import { Container } from '@/components/Grid/Container';
import { Section } from '@/components/Grid/Section';
import { Divider } from '@/components/Grid/Divider';

//containers
import {
  DarkSlider,
  Slide,
  SliderImage,
  SliderTitle,
} from '@/containers/Slider';

//context components
const DividerStyled = styled(Divider)`
  margin: 10px 0 30px;

  ${screen.md} {
    margin: 10px 0 40px;
  }

  ${screen.lg} {
    margin: 50px 0 80px;
  }
`;

const TitleStyled = styled(Title)`
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0px;

  ${screen.lg} {
    font-size: 28px;
    line-height: 40px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;

export const MeetOurTeamSection = () => (
  <>
    <Section>
      <Container>
        <TitleStyled>
          Meet The Team Who Will Find the Right Solution For You
        </TitleStyled>
      </Container>

      <div role="decoration">
        <Container>
          <DividerStyled color="#c5cbcb" />
        </Container>
      </div>

      <Container>
        <DarkSlider slidesPerPage={2} slidesPerPageOnMobile={1}>
          <Slide>
            <SliderImage>
              <ResponsiveImage
                src={`/images/smb-shipping/team/loadsmart-connor-boscaccy@2x.jpg`}
                alt="Connor Boscaccy Picture"
                width="620"
                height="437"
              />
            </SliderImage>
            <SliderTitle>
              <SubTitle>Connor Boscaccy</SubTitle>
            </SliderTitle>
            <Text>
              Connor’s biggest strengths are building strong relationships with
              customers and making sure there is full transparency throughout
              the whole process. As a recent college grad and student athlete,
              he understands that being successful means hard work and
              dedication. Connor wants to work with you to earn your business
              and become your preferred contact for full truckload and less than
              truckload shipments.
            </Text>
          </Slide>
          <Slide>
            <SliderImage>
              <ResponsiveImage
                src={`/images/smb-shipping/team/loadsmart-tyler-patty@2x.jpg`}
                alt="Tyler Patty Picture"
                width="620"
                height="437"
              />
            </SliderImage>
            <SliderTitle>
              <SubTitle>Tyler Patty</SubTitle>
            </SliderTitle>
            <Text>
              Tyler enjoys building personal relationships with his customers in
              order to serve their needs in the best and most cost-effective
              ways possible. He relies heavily on exceptional organizational
              skills as well as constant transparency to prevent obstacles and
              turn the inevitable challenges into new opportunities. He is
              always ready for the chance to show new customers what he can do.
            </Text>
          </Slide>
        </DarkSlider>
      </Container>
    </Section>
  </>
);
