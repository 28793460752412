import React from 'react';

// styles
import {
  HeroWrapper,
  HeroContainer,
  StyledTitle,
  StyledDescription,
} from './Hero.styles';

export function Hero() {
  return (
    <HeroWrapper>
      <HeroContainer>
        <StyledTitle>
          We are Loadsmart.
          <br /> We are the future of freight.
        </StyledTitle>
        <StyledDescription>
          Our global workforce of freight and logistics technology experts are
          driving innovation and empowering shippers, carriers and warehouses
          move more with less.
        </StyledDescription>
      </HeroContainer>
    </HeroWrapper>
  );
}
