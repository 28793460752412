/* eslint-disable @next/next/no-img-element */
// components
import { Container } from 'components/Grid';
// styles
import {
  SectionWrapper,
  TitleWrapper,
  Picture,
  StyledButton,
  Description,
  Title,
  IntroText,
} from './ImageCTA.styles';

export function ImageCTA() {
  return (
    <SectionWrapper>
      <Container>
        <TitleWrapper>
          <Title>
            Business intel to reduce costs and
            <br /> deliver better customer experiences
          </Title>
          <Description>
            Finding insights can be difficult, but it doesn't have to be.
          </Description>
          <IntroText>
            Whether it’s negotiating better rates, finding the optimal spot to
            contract mix, benchmarking or order optimization, Loadsmart makes
            your data easy to understand and take action on.
          </IntroText>
        </TitleWrapper>
        <StyledButton as="a" href="#hubspot-form">
          Turn your data in to savings
        </StyledButton>
        <Picture>
          <source
            srcSet={`/images/managed-transportation/mt-charts@2x.png`}
            media="(min-width: 1920px)"
          />
          <source
            srcSet={`/images/managed-transportation/mt-charts@2x.png`}
            media="(min-width: 1024px)"
          />
          <source
            srcSet={`/images/managed-transportation/mt-charts@2x.png`}
            media="(min-width: 768px)"
          />
          <img
            srcSet={`/images/managed-transportation/mt-charts@2x.png`}
            alt="Shipper Guide dashboard screen"
            width="677"
            height="520"
          />
        </Picture>
      </Container>
    </SectionWrapper>
  );
}
