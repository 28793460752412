import styled from 'styled-components';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
//helpers
import { screen } from '@/helpers/screen';
import { CardLayout } from './Layout';
import { LogoTitle } from './Content';

import CurlLogo from '@/public/images/logos/logo-curl.svg';

const CurlWrapper = styled(FlexRowFullCentered)`
  width: 20px;
  height: 27px;

  ${screen.md} {
    width: 21px;
    height: 28px;
  }

  ${screen.lg} {
    width: 32px;
    height: 44px;
  }

  ${screen.xxlg} {
    width: 37px;
    height: 50px;
  }
`;

export const CurlCard = (props) => (
  <CardLayout {...props}>
    <CurlWrapper>
      <CurlLogo />
    </CurlWrapper>
    <LogoTitle>Curl</LogoTitle>
  </CardLayout>
);
