import styled from 'styled-components';
import { screen } from '@/helpers/screen';

export const FloatHeroBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background: linear-gradient(91.13deg, #eff4f5 10.93%, #e7edef 94.82%);
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;

  width: calc(100% - 56px);
  max-width: 367px;
  margin-top: -250px;
  padding: 17px 15px 30px;

  background-color: ${({ theme }) => theme.color.neutral.lightest};

  ${screen.md} {
    max-width: 721px;
    margin-top: -146px;
    padding: 30px 19px;
  }

  ${screen.lg} {
    max-width: 1040px;
    margin-top: -230px;
    padding: 30px 36px;
  }

  ${screen.xxlg} {
    width: calc(100% - 462px);
    max-width: 1458px;
    margin-top: -330px;
    padding: 60px 107px;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => theme.font};
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.color.neutral.darkest};

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  width: 100%;

  font-weight: 700;

  ${screen.md} {
    align-items: flex-start;

    font-size: 34px;
    line-height: 40px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 48px;
    line-height: 54px;
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72px;
  }
`;

export const Subtitle = styled.p`
  ${({ theme }) => theme.font};
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.neutral.darkest};

  margin-top: 10px;

  text-align: center;

  font-weight: 500;

  ${screen.md} {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 21px;
    line-height: 34px;
  }

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 38px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 16px;

  ${screen.md} {
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;

    max-width: 100%;
    max-height: 35px;
    margin-top: 20px;
  }

  ${screen.lg} {
    gap: 28px;
    max-height: 44px;
    margin-top: 20px;
  }

  ${screen.xxlg} {
    gap: 20px;
    max-height: 66px;
    margin-top: 47px;
  }
`;

export const Button = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 10px 54px;

  color: ${({ theme }) => theme.color.neutral.lightest};
  font-weight: 700;

  font-size: 12px;
  line-height: 15px;
  text-transform: none;

  background: ${({ theme }) => theme.color.greens.strong};
  border-radius: 4px;

  transition: all 0.1s ease-in-out;

  ${screen.md} {
    margin: 0px;
    padding: 9px 48px;

    font-size: 12px;
    line-height: 16px;
  }

  ${screen.lg} {
    padding: 11px 81px 12px;

    font-size: 16px;
    line-height: 21px;
  }

  ${screen.xxlg} {
    padding: 14px 103px;

    font-size: 26px;
    line-height: 38px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.accent};
    border-color: ${({ theme }) => theme.color.accent};

    transition: all 0.2s ease-in-out;
  }
`;
