import Lottie from 'react-lottie';

//assets
import animationData from '@/datafiles/lottie/link/animation.json';

//icons
import {
  IconInstantPricing,
  IconTenderAcceptance,
  IconArtificialIntelligence,
  IconSmartTracking,
  IconOpportunity,
} from '@loadsmart/icons';
import { IconPackageTracking, IconVisibility } from '@loadsmart/icons';
import {
  IconFastPayment,
  IconImproveEfficiency,
  IconFasterService,
} from '@loadsmart/icons';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { tmsIntegrationOptions } from '@/vendor/HubspotFormIntegration';

// context containers
import {
  HeroSection,
  FeatureSection,
  BackgroundImageSection,
  ContentSection,
} from './containers/index';

//styles
import { PageWrapper } from './Integrations.styles';

//content
const heroContent = {
  page: 'Manhattan',
  logo: {
    src: `/images/logos/logo-manhattan.svg`,
    alt: 'Manhattan',
  },
  title: (
    <>
      Activate Automated Pricing And Sourcing In Your Manhattan Active
      <sup>™</sup> TM
    </>
  ),
  description: `Loadsmart’s integration with Manhattan Associates allows shippers to automatically quote freight, source capacity, and track loads directly within their Manhattan Active Transportation Management System on the spot market and contract lanes.`,
  images: {
    sm: `/images/integrations/manhattan/small_hero.png`,
    md: `/images/integrations/manhattan/medium_hero.png`,
    lg: `/images/integrations/manhattan/large_hero.png`,
    xxlg: `/images/integrations/manhattan/xxlarge_hero.png`,
  },
};
const featuresContent = {
  title: 'Capabilities',
  features: [
    {
      title: 'INSTANT RATES',
      icon: <IconInstantPricing />,
    },
    {
      title: '100% TENDER ACCEPTANCE',
      icon: <IconTenderAcceptance />,
    },
    {
      title: 'AI-POWERED SOURCING',
      icon: <IconArtificialIntelligence />,
    },
    {
      title: 'NEAR REAL-TIME TRACKING',
      icon: <IconSmartTracking />,
    },
    {
      title: 'MULTIMODE FLEXIBILITY',
      icon: <IconOpportunity />,
    },
  ],
};
const benefitsContent = {
  text: `  Loadsmart’s partnerships with leading ELD providers such as KeepTruckin, combined with smart apps and
                other technologies like geofencing, means we always know the real-time location of your shipments and
                can proactively mitigate service issues before they occur.`,
  benefits: [
    {
      icon: <IconPackageTracking width={66} height={66} />,
      copy: 'View real-time tracking updates directly from within Manhattan Active TM',
    },
    {
      icon: <IconVisibility width={66} height={66} />,
      copy: 'Proactively identify and mitigate service issues before they occur',
    },
  ],
};
const lottieDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};
const copyContent = {
  title: 'Discover dynamic rates and instant capacity',
  introduction: `Loadsmart provides instant quote and tender integration capability into Manhattan Active TM,
                        allowing shippers to instantly quote full truck loads (FTL) - including reefer, into real-time
                        market rates. Manhattan Associates shippers benefit from Loadsmart’s guarantee of tender
                        acceptance on every load and the added real-time availability of active rates ensures active
                        rates ensures fair prices and reduces tender rejections.`,
  media: <Lottie options={lottieDefaultOptions} />,
  benefits: [
    {
      icon: <IconFastPayment width={66} height={66} />,
      text: 'Save when the market rate is below your static contracted options',
    },
    {
      icon: <IconImproveEfficiency width={66} height={66} />,
      text: 'Improve service by lowering the number of shipments sent to the spot market',
    },
    {
      icon: <IconFasterService width={66} height={66} />,
      text: 'Reduce time spent securing coverage for your freight',
    },
  ],
};

function Manhattan() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <HeroSection content={heroContent} />
        <FeatureSection content={featuresContent} showHeader={true} />
        <ContentSection content={copyContent} />
        <BackgroundImageSection content={benefitsContent} />
        <HubspotForm {...tmsIntegrationOptions}>
          <HubspotFormTitle>Ready to learn more?</HubspotFormTitle>
          <HubspotFormDescription>
            Fill out the form below to and a member of the Loadsmart team will
            be in touch.
          </HubspotFormDescription>
        </HubspotForm>
      </>
      <Footer />
    </PageWrapper>
  );
}

export default Manhattan;
export { Manhattan };
