import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container } from '@/components/Grid/Container';

export const Cover = styled.aside`
  padding: 240px 0 40px;

  background-image: url(/images/leadership/bg-small@2x.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${screen.md} {
    padding: 96px 0;

    background-image: url(/images/leadership/bg-medium.jpg);
  }

  ${screen.lg} {
    padding: 165px 0;

    background-image: url(/images/leadership/bg-large.jpg);
  }

  ${screen.xxlg} {
    padding: 160px 0;

    background-image: url(/images/leadership/bg-xxlarge.jpg);
  }
`;

export const Text = styled.p`
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    line-height: 26px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 42px;
  }
`;

export const Subtitle = styled(Text)`
  padding-top: 20px;

  font-size: 12px;
  line-height: 20px;

  ${screen.lg} {
    font-size: 21px;
    line-height: 34px;
  }

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 38px;
  }
`;

export const TextContainer = styled(Container)`
  width: 100%;
  margin: auto auto 0;
  padding: 0 20px;

  ${screen.md} {
    width: 50%;
    margin-left: 0;
    padding: 0;

    br {
      display: none;
    }
  }
`;
