import styled, { keyframes } from 'styled-components';
import { screen } from '@/helpers/screen';

const slideLeft = keyframes`
    0% {
        -webkit-transform: translateX(-30%);
        transform: translateX(-30%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
`;

export const FeaturesCarouselWrapper = styled.section`
  width: 100%;
  padding: 30px 0 20px;
  overflow: hidden;

  text-align: center;

  ${screen.md} {
    padding: 80px 0 30px;
  }
  ${screen.lg} {
    padding: 70px 0 45px;
  }
  ${screen.xxlg} {
    padding: 94px 0 50px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 24px;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    margin-bottom: 40px;

    font-size: 16px;
    line-height: 26px;
  }

  ${screen.lg} {
    margin-bottom: 50px;

    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    margin-bottom: 55px;

    font-size: 30px;
    line-height: 42px;
  }
`;

export const LogosNavigation = styled.nav`
  display: grid;
  grid-template-columns: repeat(${(props) => props.items}, 70px);

  align-items: flex-start;
  justify-content: space-between;
  width: calc(${(props) => props.items} * 76px);

  transition: all 0.3s ease-in-out;

  ${screen.md} {
    grid-template-columns: repeat(${(props) => props.items}, auto);
    gap: 10px;
    width: 100%;
    margin-left: 0 !important;
  }
`;

export const LogoItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  max-width: 100%;
  height: 100%;
  margin: auto;
  padding: 0 5px;

  color: #979797;

  cursor: pointer;

  svg {
    display: inline-flex;
    width: 32px;
    max-width: 100%;
    height: auto;
    margin: 0 auto;

    path {
      fill: #979797;
    }
  }

  span {
    display: inline-block;
    width: 100%;
    max-width: 168px;
    margin: 10px auto;

    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
  }

  &.active,
  &:hover {
    color: ${({ theme }) => theme.color.greens.stronger};

    svg {
      path {
        fill: ${({ theme }) => theme.color.greens.stronger};
      }
    }
  }

  ${screen.md} {
    width: auto;

    svg {
      width: 44px;
    }

    span {
      margin: 12px auto;

      font-size: 12px;
      line-height: 18px;
    }
  }

  ${screen.lg} {
    svg {
      width: 52px;
    }

    span {
      margin: 16px auto;

      font-size: 16px;
      line-height: 22px;
    }
  }

  ${screen.xxlg} {
    svg {
      width: 66px;
    }

    span {
      margin: 20px auto;

      font-size: 21px;
      line-height: 28px;
    }
  }
`;

export const BulletNavigation = styled.nav`
  display: none;
  justify-content: center;

  ${screen.md} {
    display: flex;
  }
`;

export const BulletItem = styled.button`
  display: flex;
  width: 11px;
  height: 11px;
  margin: 5px;
  padding: 0;

  background: ${({ theme }) => theme.color.greys.x500};
  border: none;
  border-radius: 100%;
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  span {
    display: none;
    overflow: hidden;

    text-indent: -299em;
  }

  &:hover,
  &.active {
    background: ${({ theme }) => theme.color.neutral.darkest};

    transition: all 0.15s ease-in-out;
  }

  ${screen.lg} {
    width: 15px;
    height: 15px;
    margin: 10px;
  }
  ${screen.xxlg} {
    width: 20px;
    height: 20px;
    margin: 15px;
  }
`;

export const SlidesList = styled.div`
  min-height: 685px;
  padding: 0 0 20px;

  ${screen.md} {
    min-height: 360px;
    padding: 24px 0;
  }
  ${screen.lg} {
    min-height: 440px;
    padding: 32px 0;
  }
  ${screen.xxlg} {
    min-height: 580px;
    padding: 40px 0;
  }
`;

export const SlideContainer = styled.div`
  display: none;

  &.active {
    display: flex;

    -webkit-animation: ${slideLeft} 0.5s;
    animation: ${slideLeft} 0.5s;
  }

  &:nth-child(even) {
    & > div {
      ${screen.md} {
        flex-direction: row-reverse;
      }

      article {
        ${screen.md} {
          padding: 0 0 0 20px;
        }
        ${screen.lg} {
          padding: 0 0 0 25px;
        }
        ${screen.xxlg} {
          padding: 0 0 0 30px;
        }
      }
    }
  }
`;

export const NavCarousel = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  width: 315px;
  height: 92px;
  margin-bottom: 18px;

  ${screen.md} {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: auto;
  }
`;

export const NavCarouselTrail = styled.div`
  max-width: 100%;
  overflow: hidden;

  ${screen.md} {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const NextArrow = styled.button`
  display: inline-flex;
  align-items: center;
  box-sizing: content-box;
  padding: 0;

  color: #d3d9d9;

  background: transparent;
  border: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: #b3b9b9;
  }

  svg {
    display: inline-flex;
    width: 32px;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  ${screen.md} {
    display: none;
  }
`;
export const PrevArrow = styled(NextArrow)`
  transform: rotate(180deg);
`;
