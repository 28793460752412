import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        opacity: 0;
    }
    60% {
        -webkit-transform: translateY(20%);
        transform: translateY(20%);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
`;

const slideOut = keyframes`
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    60% {
        -webkit-transform: translateY(120%);
        transform: translateY(120%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
`;

export const ElementRotator = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
`;

export const RotatorItem = styled.span`
  position: absolute;
  left: 0;

  width: 100%;

  opacity: 0;

  &.is-visible {
    top: 0;

    opacity: 1;

    -webkit-animation: ${slideIn} 0.6s;
    animation: ${slideIn} 0.6s;
  }

  &.is-hidden {
    position: absolute;

    -webkit-animation: ${slideOut} 0.6s;
    animation: ${slideOut} 0.6s;
  }
`;
