import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//components
import { Container } from 'components/Grid';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const FeaturedWrapper = styled.section`
  padding: 60px 0 20px;
  background-color: rgb(${getToken('color-background-tertiary')});
  text-align: center;

  ${screen.md} {
    padding: 60px 0 28px;
  }
  ${screen.lg} {
    padding: 80px 0 48px;
  }
  ${screen.xxlg} {
    padding: 100px 0 68px;
  }
`;

export const FeaturedContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${screen.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ContainerList = styled.div`
  ${screen.lg} {
    max-width: 616px;
  }
  ${screen.xxlg} {
    max-width: 784px;
  }
`;

export const Title = styled.h3`
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-family: ${getToken('platform-font-title')};
  font-size: 38px;
  line-height: 47px;
  text-align: center;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding-bottom: 48px;

  br {
    display: none;
  }

  ${screen.md} {
    flex-direction: row;
    flex-wrap: wrap;

    font-size: 26px;
    line-height: 39px;

    padding-bottom: 40px;
  }
  ${screen.lg} {
    flex-direction: column;
    flex-wrap: unset;
    align-items: flex-start;
    text-align: left;

    font-size: 48px;
    line-height: 60px;

    padding-bottom: 0;

    br {
      display: inline-block;
    }
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72px;
  }
`;

export const RotatorWrapper = styled.span`
  height: 38px;
  width: 315px;
  text-align: center;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-brand')});

  ${screen.md} {
    position: relative;
    width: 180px;
  }

  ${screen.lg} {
    width: 270px;
    height: auto;

    span {
      text-align: start;
    }
  }

  ${screen.xxlg} {
    width: 320px;
  }
`;

export const FeaturedList = styled.div`
  display: grid;
  gap: 0px;
  ${screen.md} {
    grid-template-columns: repeat(2, 1fr);
    gap: 48px;
  }

  ${screen.xxlg} {
    gap: 64px;
  }
`;

export const FeaturedItem = styled.article`
  padding: 0 0 40px;
  text-align: center;

  svg {
    width: 50px;
    color: rgb(${getToken('color-text-primary')});
  }

  ${screen.md} {
    text-align: start;
    padding: 0 0 32px;
  }
`;

export const Subtitle = styled.h3`
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  padding-top: 8px;
  margin: 0;
  font-size: 20px;
  line-height: 32px;
`;

export const Content = styled.p`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};
  padding: 8px 0 0;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
`;
