import styled from 'styled-components';

//
import { screen } from '@/helpers/screen';
import conditional from '@/helpers/conditional';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//
import { Wrapper as BaseWrapper } from '/components/Grid/Content';
import { Container } from 'components/Grid';

export const SidePanelsWrapper = styled.section`
  padding: 20px 0 0;
  text-align: center;
`;

export const EvenSection = styled.div`
  background: rgb(${getToken('color-background-secondary')});
`;

export const SectionTitle = styled.h1`
  margin-bottom: 20px;

  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  ${screen.md} {
    margin-bottom: 32px;

    font-size: 28px;
    line-height: 40px;
  }
  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin-bottom: 40px;

    font-size: 48px;
    line-height: 72px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 10px;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 26px;
  line-height: 39px;
  text-align: center;

  ${screen.md} {
    text-align: left;
  }
  ${screen.lg} {
    font-size: 38px;
    line-height: 47.5px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const Subtitle = styled.h3`
  margin: 0;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-tertiary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  ${screen.md} {
    text-align: left;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const Content = styled.p`
  margin: 16px 0;
  font-family: ${getToken('platform-font-default')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};
  font-size: 14px;
  line-height: 21px;

  ${screen.md} {
    margin: 22px 0;
  }

  ${screen.lg} {
    margin: 36px 0;

    font-size: 20px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    margin: 42px 0;
  }
`;

export const Link = styled.a`
  display: inline-flex;
  width: fit-content;
  margin: auto;
  font-family: ${getToken('platform-font-default')};
  color: rgb(${getToken('color-primary-60')});
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
  font-weight: ${getToken('font-weight-regular')};

  ${screen.md} {
    margin: 0;
    display: none;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 30px;
  }

  &:hover {
    color: rgb(${getToken('color-primary-100')});
  }
`;

export const Picture = styled.picture`
  display: inline-block;
  box-sizing: border-box;
  margin: auto;

  &.gif {
    img,
    source {
      ${screen.md} {
        width: 347px;
        max-width: 347px;
      }
      ${screen.lg} {
        width: 455px;
        max-width: 455px;
      }

      ${screen.xxlg} {
        width: 670px;
        max-width: 670px;
      }
    }
  }

  ${screen.md} {
    padding: ${conditional({
      0: (props) => props.direction === 'reverse',
      '0 20px 0 0': (props) => props.direction !== 'reverse',
    })};
  }

  img,
  source {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
  }
`;

export const SectionPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  ${screen.md} {
    flex-direction: ${conditional({
      'row-reverse': (props) => props.direction === 'reverse',
      row: (props) => props.direction !== 'reverse',
    })};
    padding: 50px 0;
  }
`;

export const CustomContainer = styled(Container)`
  padding: 20px 0;
`;

export const Wrapper = styled(BaseWrapper)`
  justify-content: center;
  padding: 0;

  ${screen.md} {
    padding-right: 14px;
  }

  ${screen.xxlg} {
    padding-right: 28px;
  }
`;

export const ReversedWrapper = styled(Wrapper)`
  padding: 0;

  ${screen.md} {
    padding-left: 0;
  }
  ${screen.lg} {
    padding-left: 14px;
  }
  ${screen.xxlg} {
    padding-left: 28px;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 180px;
  position: relative;

  ${screen.md} {
    padding-right: 20px;
    align-self: center;
    height: 210px;
    min-width: 397px;
  }
  ${screen.lg} {
    height: 290px;
    min-width: 536px;
  }
  ${screen.xxlg} {
    align-self: unset;
    height: auto;
    min-width: 900px;
  }
`;

export const LogoWrapper = styled.div`
  height: 25px;

  ${screen.md} {
    height: 30px;
  }

  ${screen.lg} {
    height: 41px;
  }

  svg,
  path {
    height: 100%;
    color: rgb(${getToken('color-primary-100')});
    fill: rgb(${getToken('color-primary-100')});
  }
`;

export const MobileHide = styled.div`
  display: none;
  ${screen.md} {
    display: inline-flex;
  }
`;
