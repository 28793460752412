import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

import { InvertedSection } from '@/components/Grid';

import SmartSchedulingLogo from '@/public/images/multimodal/icon_logo-smart_scheduling.svg';

import { SectionPanel } from '@/components/Grid/Panel';

export const SidePanels = styled(SectionPanel)`
  margin-top: 0px;
`;

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

export const LogoWrapper = styled.div`
  height: 44px;
  margin-bottom: 18px;

  color: white;

  ${screen.lg} {
    height: 55px;
  }

  ${screen.xxlg} {
    height: 86px;
    margin-bottom: 37px;
  }
`;

export const ResponsiveSmartSchedulingLogo = styled(SmartSchedulingLogo)`
  width: auto;
  height: 100%;
`;

export const GradientSection = styled(InvertedSection)`
  color: white;

  background: linear-gradient(177.2deg, #394e62 5.03%, #21334c 89.92%);
`;

export const FloatResponsiveImage = styled.img`
  width: auto;
  max-height: 200px;

  ${screen.md} {
    align-self: flex-end;
    width: 459px;
    max-height: initial;
  }

  ${screen.lg} {
    width: 621px;
  }

  ${screen.xxlg} {
    width: 864px;
  }
`;
