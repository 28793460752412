import styled from 'styled-components';

import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PartnersWrapper = styled.section`
  padding: 60px 0 45px;

  background: rgb(${getToken('color-background-inverted')});

  ${screen.md} {
    padding: 60px 0 38px;
  }
  ${screen.lg} {
    padding: 100px 0 69px;
  }
  ${screen.xxlg} {
    padding: 100px 0 60px;
  }
`;

export const Header = styled.header`
  padding-bottom: 25px;

  text-align: center;

  ${screen.md} {
    padding-bottom: 18px;
  }
  ${screen.lg} {
    padding-bottom: 29px;
  }
  ${screen.xxlg} {
    padding-bottom: 60px;
  }
`;

export const Title = styled.h3`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-brand')});
  font-weight: ${getToken('font-weight-bold')};
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin: 0px;

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }
  ${screen.xxlg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const PartnersList = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 0;
  margin: 0;

  list-style: none;

  ${screen.md} {
    grid-template-columns: repeat(4, 1fr);

    column-gap: 40px;

    &:nth-child(2) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
`;

export const PartnerItem = styled.li`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto;

  img,
  svg {
    max-width: 100%;
    height: 33px;
  }

  ${screen.md} {
    margin: 22px auto;

    img,
    svg {
      height: auto;
    }
  }

  ${screen.lg} {
    margin: 31px auto;
  }

  ${screen.xxlg} {
    margin: 40px auto;
  }
`;

export const ELDText = styled.h1`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-bold')};

  margin: 20px auto 0px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    margin: 60px auto 0px;

    font-size: 26px;
    line-height: 39px;
  }
`;
