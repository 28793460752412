import styled from 'styled-components';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { smbShippingOptions } from '@/vendor/HubspotFormIntegration';
import {
  HeroSection,
  IntroductionSection,
  AddValuesSection,
  TestimonialSection,
  InstantQuoteSection,
  RFPGuideSection,
  MeetOurTeamSection,
  CarrierSection,
} from './containers';

//context components
export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

const SMBShipping = () => {
  return (
    <>
      <PageWrapper>
        <Navigation />
        <>
          <HeroSection />
          <IntroductionSection />
          <AddValuesSection />
          <TestimonialSection />
          <InstantQuoteSection />
          <RFPGuideSection />
          <CarrierSection />
          <MeetOurTeamSection />
          <HubspotForm {...smbShippingOptions}>
            <HubspotFormTitle>
              Talk to a Transportation Specialist
            </HubspotFormTitle>
            <HubspotFormDescription>
              Our team of industry experts and technicians are here to answer
              your questions.
            </HubspotFormDescription>
          </HubspotForm>
        </>
        <Footer />
      </PageWrapper>
    </>
  );
};

export default SMBShipping;
export { SMBShipping };
