//assets
import { Container } from '@/components/Grid/Container';

import {
  Featured,
  FeaturedTitle,
  FeaturedDescription,
  FeaturedIcon,
  FeaturedSection,
  FeatureGroup,
  HeaderWrapper,
} from './FeaturedItems.styles';

import { Paragraph1, Title1 } from '@/components/Text';

export const FeaturedItems = ({ content }) => (
  <FeaturedSection>
    <Container>
      <HeaderWrapper>
        {content.title && <Title1>{content.title}</Title1>}
        {content.description && <Paragraph1>{content.description}</Paragraph1>}
      </HeaderWrapper>

      {content.items.length > 0 && (
        <FeatureGroup>
          {content.items.map((item, key) => {
            return (
              <Featured key={item.title.trim() + key}>
                <FeaturedIcon>{item.icon}</FeaturedIcon>
                <FeaturedTitle>{item.title}</FeaturedTitle>
                <FeaturedDescription>{item.description}</FeaturedDescription>
              </Featured>
            );
          })}
        </FeatureGroup>
      )}
    </Container>
  </FeaturedSection>
);
