import styled from 'styled-components';
import { screen } from '@/helpers/screen';

export const IntroductionTextWrapper = styled.section`
  padding: 40px 0 40px;

  text-align: center;

  background: linear-gradient(91.13deg, #eff4f5 10.93%, #e7edef 94.82%);

  ${screen.md} {
    padding: 40px 0px;
  }

  ${screen.lg} {
    padding: 69px 0 72px;
  }

  ${screen.xxlg} {
    padding: 100px 0 104px;
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 316px;
  margin: 0 auto;

  ${screen.md} {
    max-width: 674px;
  }

  ${screen.lg} {
    max-width: 970px;
  }

  ${screen.xxlg} {
    max-width: 1380px;
  }
`;

export const Title = styled.h1`
  margin: 0 auto;

  color: ${({ theme }) => theme.color.greens.stronger};
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  text-align: center;

  ${screen.md} {
    margin-bottom: 28px;

    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    margin-bottom: 36px;

    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin-bottom: 45px;

    font-size: 48px;
    line-height: 72px;
  }
`;

export const Content = styled.p`
  font-size: 12px;
  line-height: 22px;

  text-align: center;

  ${screen.md} {
    font-size: 12px;
    line-height: 22px;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 38px;
  }

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 54px;
  }
`;
