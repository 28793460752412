import styled from 'styled-components';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//helpers
import { screen } from '@/helpers/screen';

export const SectionWrapper = styled.section`
  background: rgb(${getToken('color-background-primary')});
`;

export const Title = styled.h2`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  margin: 50px 0 0px;
  font-size: 25px;
  line-height: 39px;
  text-align: center;

  ${screen.md} {
    font-size: 26px;
  }

  ${screen.lg} {
    margin: 80px 0 10px;
  }

  ${screen.xxlg} {
    margin: 100px 0 30px;
  }
`;

export const ListContainer = styled.ul`
  display: grid;
  gap: 45px 22px;
  margin: 50px auto 100px;
  padding: 0px;
  list-style: none;

  ${screen.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${screen.xxlg} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const PhotoWrapper = styled.li`
  margin: 0;
  padding: 0;

  text-align: center;

  ${screen.xxlg} {
    &:nth-child(1) {
      grid-column: 2;
    }
    &:nth-child(3) {
      grid-column: 1;
    }
  }
`;

export const Picture = styled.img`
  max-width: 100%;
  height: auto;
  margin: 0px auto 8px;

  vertical-align: middle;
`;

export const Name = styled.h3`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
`;

export const Text = styled.p`
  margin: 11px 0;
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-text-primary')});
  font-size: 12px;
  line-height: 16.8px;

  margin: 8px 0 24px;
`;

export const Link = styled(Text)`
  font-weight: ${getToken('font-weight-bold')};
  text-decoration: underline;
`;
