/* eslint-disable @next/next/no-img-element */
// components
import { MirandaSecondaryButton } from '@/components/Button/';
import { Container } from 'components/Grid';

// styles
import {
  SectionWrapper,
  Title,
  SectionPanel,
  Picture,
  Wrapper,
  MobileHide,
  Content,
} from './ImageCTA.styles';

export function ImageCTA() {
  return (
    <SectionWrapper>
      <Container>
        <SectionPanel>
          <Picture>
            <source
              srcSet={`/images/managed-transportation/mt-services@2x.png`}
              media="(min-width: 1920px)"
            />
            <source
              srcSet={`/images/managed-transportation/mt-services@2x.png`}
              media="(min-width: 1024px)"
            />
            <source
              srcSet={`/images/managed-transportation/mt-services@2x.png`}
              media="(min-width: 768px)"
            />
            <img
              src={`/images/managed-transportation/mt-services@2x.png`}
              alt="MTS plan: Plan, Execute, Settle, Measure, Improve"
              width="748"
              height="500"
            />
          </Picture>
          <Wrapper>
            <Title>Managed Transportation that drive cost savings</Title>

            <Content>
              Save 20% annually on freight costs, while spending less time. With
              Loadsmart’s Managed Transportation services, all your logistics
              needs are handled by a dedicated team of specialists with the
              latest technology.
            </Content>

            <MirandaSecondaryButton as="a" href="/managed-transportation">
              Start Saving On Transportation
            </MirandaSecondaryButton>
          </Wrapper>
        </SectionPanel>
      </Container>
    </SectionWrapper>
  );
}
