//components
import { Container } from '@/components/Grid';
import { SectionTitle } from '@/components/SectionTitle';
import { ContentArrow } from '@/components/ContentArrow';

//styles
import {
  ColumnFeaturesWrapper,
  TitleWrapper,
  Columns,
  CTAWrapper,
} from './ColumnFeatures.styles';

//assets
import RocketNoteIcon from '@/public/images/icons/loadsmart-collection-2/icon-Rocket.svg';
import CodingIcon from '@/public/images/icons/loadsmart-collection-2/icon-Coding.svg';
import RisingIcon from '@/public/images/icons/loadsmart-collection-2/icon-Rising.svg';
import LoupeIcon from '@/public/images/icons/loadsmart-collection-2/icon-Loupe.svg';
import { MirandaSecondaryButton, MirandaButton } from '@/components/Button';

export function ColumnFeatures() {
  return (
    <>
      <ColumnFeaturesWrapper>
        <Container>
          <TitleWrapper>
            <SectionTitle
              title={`Start seeing savings in 90 days or less`}
              description={
                <>
                  Whether you’re managing operations in-house or changing
                  providers,
                  <br /> Loadsmart can rapidly deploy a robust freight network
                </>
              }
              variant="center"
            />
          </TitleWrapper>
        </Container>
        <Container>
          <Columns>
            <ContentArrow
              content={{
                icon: <LoupeIcon width="64" height="64" />,
                title: 'Assess',
                period: '2-3 weeks',
                description: (
                  <>
                    Review and analyze transportation KPIs, data, processes and
                    infrastructure to identify opportunities for improvement and
                    cost savings.
                  </>
                ),
              }}
            />
            <ContentArrow
              content={{
                icon: <CodingIcon width="64" height="64" />,
                title: 'Design',
                period: '2 weeks',
                description: (
                  <>
                    Define the Loadsmart Solution elements that will deliver
                    performance improvement and cost savings identified during
                    the assessment phase.
                  </>
                ),
              }}
            />
            <ContentArrow
              content={{
                icon: <RocketNoteIcon width="64" height="64" />,
                title: 'Deploy',
                period: '6-10 weeks',
                description: (
                  <>
                    Full implementation of the Managed Transportation Solution,
                    including delivery team, processes, tools and technology,
                    and performance measurement.
                  </>
                ),
              }}
            />
            <ContentArrow
              content={{
                icon: <RisingIcon width="64" height="64" />,
                title: 'Deliver',
                period: '90 days and beyond',
                description: (
                  <>
                    Improve performance and realize cost savings goals, while
                    utilizing agility, expertise and experience to adapt to the
                    ever-changing market and continuously improve the program.
                  </>
                ),
              }}
            />
          </Columns>
        </Container>
        <Container>
          <CTAWrapper>
            <MirandaSecondaryButton as="a" href="#hubspot-form">
              Schedule an assessment
            </MirandaSecondaryButton>{' '}
            <MirandaButton
              as="a"
              href="https://lp.loadsmart.com/hubfs/5041527/Managed%20Trans%20Process%20Checklist.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              Download our implementation checklist
            </MirandaButton>
          </CTAWrapper>
        </Container>
      </ColumnFeaturesWrapper>
    </>
  );
}
