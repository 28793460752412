import styled from 'styled-components';
//helpers
import { screen } from '@/helpers/screen';
import { FlexRowFullCentered } from '@/components/Grid/Flex';
import { CardLayout } from './Layout';

import KuebixLogo from '@/public/images/logos/logo-kuebix.svg';

const KuebixWrapper = styled(FlexRowFullCentered)`
  width: 74px;
  height: 26px;

  ${screen.md} {
    width: 75px;
    height: 26px;
  }

  ${screen.lg} {
    width: 106px;
    height: 38px;
  }

  ${screen.xxlg} {
    width: 150px;
    height: 54px;
  }
`;

export const KuebixCard = (props) => (
  <CardLayout {...props}>
    <KuebixWrapper>
      <KuebixLogo />
    </KuebixWrapper>
  </CardLayout>
);
