import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const IntroductionTextWrapper = styled.section`
  padding: 30px 0 50px;
  background-color: rgba(${getToken('color-background-tertiary')});
  text-align: center;

  ${screen.md} {
    padding: 20px 0 70px;
  }

  ${screen.md} {
    padding: 40px 0 70px;
  }

  ${screen.xxlg} {
    padding: 50px 0 100px;
  }
`;

export const Title = styled.h2`
  margin: 0px;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  br {
    display: none;
  }

  ${screen.md} {
    font-size: 26px;
    line-height: 39px;

    br {
      display: inline-block;
    }
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 47px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;

export const Content = styled.p`
  font-size: 12px;
  line-height: 17px;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-tertiary')});
  font-weight: ${getToken('font-weight-regular')};
  margin: 16px auto 0px;

  ${screen.md} {
    font-size: 14px;
    line-height: 21px;
    margin: 54px auto 0px;
    max-width: 674px;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 24px;
    margin: 36px auto 0px;
    max-width: 966px;
  }

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 36px;
    margin: 43px auto 0px;
    max-width: 1360px;
  }
`;
