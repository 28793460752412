import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const CustomersLogosWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 36px 0;

  text-align: center;

  background-color: rgb(${getToken('color-background-primary')});

  ${screen.md} {
    padding: 62px 0;
  }
  ${screen.xxlg} {
    padding: 62px 0 72px;
  }
`;

export const LogosList = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${screen.md} {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const Title = styled.h3`
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-primary-60')});
  font-weight: ${getToken('font-weight-regular')};
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  margin: 0 0 37px;

  ${screen.xxlg} {
    margin: 0 0 32px;
  }
`;

const Logo = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: auto;
  margin: 20px 0;

  &:nth-child(1) {
    margin: 0 0 20px;
  }

  &:nth-child(5) {
    margin: 20px 0 0;
  }

  ${screen.md} {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      margin: auto;
    }
  }
`;

export const LogoScottsMiracle = styled(Logo)`
  width: 230px;

  ${screen.md} {
    width: 100px;
  }
  ${screen.lg} {
    width: 150px;
  }
  ${screen.xxlg} {
    width: 208px;
  }
`;

export const LogoBackyard = styled(Logo)`
  width: 156px;

  ${screen.md} {
    width: 80px;
  }
  ${screen.lg} {
    width: 100px;
  }
  ${screen.xxlg} {
    width: 140px;
  }
`;

export const LogoAthletic = styled(Logo)`
  width: 142px;

  ${screen.md} {
    width: 80px;
  }
  ${screen.lg} {
    width: 100px;
  }
  ${screen.xxlg} {
    width: 129px;
  }
`;

export const LogoSteeleSolutions = styled(Logo)`
  width: 146px;

  ${screen.md} {
    width: 100px;
  }
  ${screen.xxlg} {
    width: 131px;
  }
`;

export const LogoOlympicSteel = styled(Logo)`
  width: 226px;

  ${screen.md} {
    width: 100px;
  }
  ${screen.lg} {
    width: 150px;
  }
  ${screen.xxlg} {
    width: 204px;
  }
`;
