import React from 'react';

//assets
import Basicblock from '@/public/images/carrier-tms/partners/basicblock-logo.svg';
import Bigroad from '@/public/images/carrier-tms/partners/bigroad-logo.svg';
import Fleetcomplete from '@/public/images/carrier-tms/partners/fleetcomplete-logo.svg';
import Isaac from '@/public/images/carrier-tms/partners/isaac-logo.svg';
import Motive from '@/public/images/carrier-tms/partners/motive-logo.svg';
import Peoplenet from '@/public/images/carrier-tms/partners/peoplenet-logo.svg';
import RTS from '@/public/images/carrier-tms/partners/rts-logo.svg';
import Verizon from '@/public/images/carrier-tms/partners/verizon-logo.svg';
import Wex from '@/public/images/carrier-tms/partners/wex-logo.svg';

//components
import { Container } from 'components/Grid';

//styles
import {
  PartnersWrapper,
  Header,
  Title,
  PartnersList,
  PartnerItem,
  ELDText,
} from './Partners.styles';

export default function Partners() {
  return (
    <>
      <PartnersWrapper>
        <Header>
          <Title>OUR INTEGRATION PARTNERS</Title>
        </Header>
        <Container>
          <PartnersList>
            <PartnerItem>
              <Isaac width="307" />
            </PartnerItem>
            <PartnerItem>
              <Motive width="240" />
            </PartnerItem>
            <PartnerItem>
              <Fleetcomplete width="302" />
            </PartnerItem>
            <PartnerItem>
              <Basicblock width="252" />
            </PartnerItem>
          </PartnersList>
          <PartnersList>
            <PartnerItem>
              <RTS width="223" />
            </PartnerItem>
            <PartnerItem>
              <Peoplenet width="276" />
            </PartnerItem>
            <PartnerItem>
              <Bigroad width="247" />
            </PartnerItem>
            <PartnerItem>
              <Verizon width="218" />
            </PartnerItem>
            <PartnerItem>
              <Wex width="160" />
            </PartnerItem>
          </PartnersList>
          <ELDText>+ 25 ELDs</ELDText>
        </Container>
      </PartnersWrapper>
    </>
  );
}

export { Partners };
