export const AWARDS = [
  {
    year: '2023',
    awards: [
      {
        link: 'https://blog.foodshippers.org/2023-top-food-chain-technology-list-announced-by-fsas-food-chain-digest',
        name: 'Food Shippers of America’s 2023 “Top Food Chain Technology List”',
      },
      {
        link: 'https://www.blueprism.com/customers/awards/',
        name: 'Best Newcomer Americas by our RPA vendor (SS&C Blue Prism)',
      },
      {
        link: 'https://www.logisticstransportationreview.com/loadsmart',
        name: 'Logistics & Transportation Review Magazine - Top 10 Freight Brokerage Service Provider 2023',
      },
      {
        link: 'https://www.iso.io/blog/iso-awards/',
        name: 'ISO Technologies 2022 Excellence in Service Award',
      },
    ],
  },
  {
    year: '2022',
    awards: [
      {
        link: 'https://blog.loadsmart.com/2022/11/16/loadsmart-ranked-number-214th-fastest-growing-company-in-north-america-on-the-2022-deloitte-technology-fast-500',
        name: 'Deloitte Technology Fast 500 (#214)',
      },
      {
        link: 'https://www.youtube.com/watch?v=XHM_jeQebqE',
        name: 'Illuminate 2022: Excellence in Engineering Operations',
      },
      {
        link: 'https://www.freightwaves.com/news/freightwaves-names-2023-freighttech-100',
        name: 'FreightWaves FreightTech 100',
      },
      { name: 'Named No. 881 on the annual Inc. 5000 list' },
      {
        link: 'https://www.ey.com/en_us/news/2022/04/ey-announces-eoy-2022-midwest-finalists',
        name: 'Entrepreneur of the Year Midwest Finalists (founders) - E&Y',
      },
      {
        link: 'https://www.forbes.com/lists/americas-best-startup-employers/?sh=2fb4b27b2ad7',
        name: 'Forbes 2022 America’s Best Startup Employers',
      },
      {
        link: 'https://www.freightwaves.com/news/freighttech-25',
        name: '2022 FreightTech 25 #16',
      },
    ],
  },
  {
    year: '2021',
    awards: [
      {
        link: 'https://www.ey.com/en_us/news/2021/06/ey-eoy-2021-midwest-finalists',
        name: 'Entrepreneur of the Year Midwest Finalists (founders) - E&Y',
      },
    ],
  },
  {
    year: '2020',
    awards: [
      { name: 'Top 25 Companies Disrupting Freight - FreightWaves (#18)' },
      {
        link: 'https://blog.foodshippers.org/2023-top-food-chain-technology-list-announced-by-fsas-food-chain-digest',
        name: 'EY Entrepreneur Of The Year® 2020 New York Award Finalist',
      },
      {
        link: 'https://www.cbinsights.com/research/artificial-intelligence-top-startups-2020/',
        name: 'Top AI 100: The Artificial Intelligence Startups - CB Insights',
      },
    ],
  },
  {
    year: '2019',
    awards: [
      { name: 'Top 25 Companies Disrupting Freight - FreightWaves (#15)' },
      {
        link: 'https://www.prnewswire.com/news-releases/loadsmarts-automated-freight-brokerage-system-awarded-company-of-the-year-by-frost--sullivan-300793548.html',
        name: 'Automated On-Demand Freight Brokerage, Company of the Year - Frost and Sullivan',
      },
    ],
  },
  {
    year: '2018',
    awards: [
      {
        name: 'Top 25 Companies Disrupting Freight - FreightWaves (#22)',
      },

      {
        link: 'https://blog.loadsmart.com/2018/09/06/cb-insights-selects-loadsmart-as-startup-disrupting-the-supply-chain-logistics-industry',
        name: '125 Startups Disrupting the Supply Chain - CB Insights',
      },
      {
        link: 'https://www.globenewswire.com/en/news-release/2018/09/10/1568811/0/en/Loadsmart-named-a-Cool-Vendor-by-Gartner-in-Cool-Vendors-Intelligent-Supply-Chain-Execution-Technologies-by-Gartner.html',
        name: 'Cool Vendor in Intelligent Supply Chain Execution Technologies',
      },
    ],
  },
  {
    year: '2017',
    awards: [
      {
        name: 'DOGA Award in Design and Architecture for Interaction Design (UX) - DOGA Oslo, Norway',
      },
      {
        link: 'https://blog.loadsmart.com/2017/05/02/loadsmart-honored-by-aba-with-2017-stevie-award',
        name: 'Most Innovative Tech Company of the Year - Stevie® Award',
      },
    ],
  },
];
