//assets
import Curiosity from '@/public/images/icons/loadsmart-collection-2/icon-Curiosity.svg';
import Quality from '@/public/images/icons/loadsmart-collection-2/icon-Quality.svg';
import Results from '@/public/images/icons/loadsmart-collection-2/icon-Results.svg';
import Struggle from '@/public/images/icons/loadsmart-collection-2/icon-Struggle.svg';
import Teamwork from '@/public/images/icons/loadsmart-collection-2/icon-Teamwork.svg';

//components
import { Container } from '@/components/Grid/Container';
import { Title } from '@/components/Grid';
import {
  FeatureSection as Section,
  FeatureIcon,
  FeatureTitle,
  FeatureDescription,
} from '@/components/Feature';

//styles
import { FeatureGroup, Feature, TitleWrapper } from './FeatureSection.styles';

export const FeatureSection = () => {
  return (
    <Section>
      <Container>
        <TitleWrapper>
          <Title>Our values</Title>
        </TitleWrapper>
        <FeatureGroup>
          <Feature>
            <FeatureIcon>
              <Teamwork role="img" />
            </FeatureIcon>
            <FeatureTitle>TEAMWORK</FeatureTitle>
            <FeatureDescription>
              {
                'Be open to new ideas and cross department collaboration. Help others.'
              }
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureIcon>
              <Struggle role="img" />
            </FeatureIcon>
            <FeatureTitle>COMMITMENT</FeatureTitle>
            <FeatureDescription>
              {
                'Commitment is the act of taking responsibility from start to finish. Own it and know when to disagree and commit. Build something you are proud of.'
              }
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureIcon>
              <Curiosity role="img" />
            </FeatureIcon>
            <FeatureTitle>CURIOSITY</FeatureTitle>
            <FeatureDescription>
              {
                'Ask for the WHY? Challenge the way we do things. Learn something new every day. Take no product, processor decision for granted.'
              }
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureIcon>
              <Quality role="img" />
            </FeatureIcon>
            <FeatureTitle>CLARITY</FeatureTitle>
            <FeatureDescription>
              {
                'Seek clarity out of each meeting, project and conversation. Leave nothing up for assumption.'
              }
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureIcon>
              <Results role="img" />
            </FeatureIcon>
            <FeatureTitle>RESULTS</FeatureTitle>
            <FeatureDescription>
              {'Make it happen, get the job done.'}
            </FeatureDescription>
          </Feature>
        </FeatureGroup>
      </Container>
    </Section>
  );
};
