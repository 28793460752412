import styled from 'styled-components';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//helpers
import { screen } from '@/helpers/screen';

export const ContentArrowWrapper = styled.article`
  display: flex;
  flex-direction: column;
  margin: 8px auto;
`;

export const ArrowHeader = styled.div`
  display: grid;
  gap: 0 16px;
  grid-template-areas:
    'a b'
    'a b'
    'a c';
  justify-content: start;
  position: relative;
  padding: 16px;
  background: rgb(${getToken('color-primary-60')});
  height: 100px;

  &::before {
    content: ' ';
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    left: -10px;
    border-style: solid;
    border-width: 50px 0 50px 30px;
    border-color: rgb(${getToken('color-primary-60')})
      rgb(${getToken('color-primary-60')}) rgb(${getToken('color-primary-60')})
      #fff;
    z-index: 0;

    ${screen.xxlg} {
      left: 0;
    }
  }

  &::after {
    content: ' ';
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    top: 0;
    right: -10px;
    border-style: solid;
    border-width: 50px 0 50px 30px;
    border-color: #fff #fff #fff rgb(${getToken('color-primary-60')});
    z-index: 10;

    ${screen.xxlg} {
      right: -20px;
    }
  }
`;

export const ContentArrowIcon = styled.span`
  display: grid;
  width: 64px;
  height: 64px;
  grid-area: a;
  margin-left: 40px;
`;

export const ContentArrowTitle = styled.h4`
  grid-area: b;
  display: inline-flex;
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-inverted')});
  margin: 0;
  font-size: 26px;
  line-height: 39px;
`;

export const ContentArrowPeriod = styled.div`
  grid-area: c;
  color: rgb(${getToken('color-text-inverted')});

  font-size: 14px;
  line-height: 22px;

  ${screen.md} {
    font-size: 16px;
    line-height: 30px;
  }
`;

export const ContentArrowDescription = styled.p`
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-text-primary')});
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  padding: 24px;
  text-wrap: balance;

  a {
    color: rgb(${getToken('color-brand')});
    text-decoration: underline;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 36px;
  }
`;
