import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Feature, FeatureTitle, FeatureGroup } from '@/components/Feature';
import { Link as BaseLink } from '@/components/Grid/Content';
import { FeatureDescription } from '@/components/Feature';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SolutionsWrapper = styled.section`
  padding: 22px 54px 27px;

  text-align: center;

  background: rgb(${getToken('color-background-tertiary')});

  ${screen.md} {
    padding: 50px 0px;
  }

  ${screen.lg} {
    padding: 70px 0px;
  }

  ${screen.xxlg} {
    padding: 100px 0 104px;
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 316px;
  margin: 0 auto;

  ${screen.md} {
    max-width: 674px;
  }

  ${screen.lg} {
    max-width: 968px;
  }

  ${screen.xxlg} {
    max-width: 1380px;
  }
`;

export const Title = styled.h1`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  margin: 0 auto;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  ${screen.md} {
    margin-bottom: 24px;

    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    margin-bottom: 50px;

    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin-bottom: 100px;

    font-size: 48px;
    line-height: 72px;
  }
`;

export const StyledFeatureGroup = styled(FeatureGroup)`
  ${screen.md} {
    margin: 0 -22px;
  }

  ${screen.lg} {
    margin: 0 -10px;
  }

  ${screen.xxlg} {
    margin: 0 -39px;
  }
`;

export const StyledFeature = styled(Feature)`
  width: 246px;
  margin: 16px 0;

  svg {
    width: 48px;
    height: 48px;

    color: rgb(${getToken('color-text-primary')});
  }

  ${screen.md} {
    width: 188px;
    margin: 0px 13px;
  }

  ${screen.lg} {
    width: 313px;
    margin: 0px 10px;

    svg {
      width: 85px;
      height: 85px;
    }
  }

  ${screen.xxlg} {
    width: 460px;
    margin: 0px 8px;
  }
`;

export const StyledFeatureTitle = styled(FeatureTitle)`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  margin: 10px 0px;
  font-size: 14px;
  line-height: 18px;
  text-transform: none;

  br {
    display: none;
  }

  ${screen.lg} {
    font-size: 36px;
    line-height: 48px;

    br {
      display: inline-block;
    }
  }

  ${screen.xxlg} {
    br {
      display: none;
    }
  }
`;

export const StyledFeatureDescription = styled(FeatureDescription)`
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-regular')};
  font-size: 12px;
  line-height: 18px;

  ${screen.lg} {
    font-size: 20px;
    line-height: 38px;
  }
`;

export const Link = styled(BaseLink)`
  margin-top: auto;
  padding-top: 10px;
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 12px;
  line-height: 18px;

  ${screen.lg} {
    font-size: 20px;
    line-height: 38px;
  }
`;
