import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Title, Text, Link as BaseLink } from '@/components/Grid/Content';
import { SectionPanel } from '@/components/Grid/Panel';
import { FlexRowFullCentered } from '@/components/Grid/Flex';

//context components
const SectionStyled = styled(SectionPanel)`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 57% 0 50px 0;

  background: ${`#fff url(/images/smb-shipping/carrier/carrier-small@2x.jpg);`}
    top center;
  background-repeat: no-repeat;
  background-size: contain;

  ${screen.md} {
    flex-direction: row;
    height: 500px;
    margin-top: 0;
    padding: 0;
    overflow-x: hidden;

    background-image: ${`url(/images/smb-shipping/carrier/carrier-medium.jpg);`};
    background-size: cover;
  }

  ${screen.lg} {
    height: 654px;
    margin-top: 0;

    background-image: ${`url(/images/smb-shipping/carrier/carrier-large.jpg);`};
  }

  ${screen.xxlg} {
    height: 690px;
    margin-top: 0;

    background-image: ${`url(/images/smb-shipping/carrier/carrier-xxlarge.jpg);`};
  }
`;

const Link = styled(BaseLink)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${screen.md} {
    justify-content: start;
  }
`;

const Column = styled.div`
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  ${screen.md} {
    margin: 0;
  }
`;

const CenteredWrapper = styled.div`
  display: flex;
  width: 80%;
  max-width: 316px;
  margin: 0 auto;

  ${screen.md} {
    max-width: 360px;
    margin: 0;
  }

  ${screen.lg} {
    max-width: 572px;
  }

  ${screen.xxlg} {
    max-width: 900px;
  }
`;

export const CarrierSection = () => (
  <>
    <SectionStyled>
      <CenteredWrapper>
        <Column>
          <Title>Meet Your Small Business Carriers</Title>

          <Text>
            We match your loads with the carrier who can provide the best
            service. Increasingly, that means leveraging small business carriers
            that make their living on the quality of the loads they move–not the
            quantity.
          </Text>
          <Text>
            <strong>
              In March 2021, 82% of freight moved by Loadsmart was hauled by
              carriers with 50 or fewer trucks, and 48% of all freight was moved
              by those with 10 or fewer trucks.
            </strong>{' '}
            We’re not just a random call center; our technology allows us to
            build direct relationships with carrier partners–no matter how many
            trucks they operate–24/7.
          </Text>

          <Link href="https://blog.loadsmart.com/2021/05/03/move-over-big-carriers-small-carriers-moved-over-80-of-loadsmart-freight-in-march/">
            Learn more
          </Link>
        </Column>
      </CenteredWrapper>
    </SectionStyled>
  </>
);
