import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Container, InvertedSection } from '@/components/Grid';
import { Button } from '@/components/Button';

const Layout = styled(InvertedSection)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 40px !important;

  text-align: center;

  ${screen.md} {
    padding: 0 0 60px !important;

    text-align: left;
  }

  ${screen.lg} {
    padding: 0 0 100px !important;
  }
`;

const ContainerStyled = styled(Container)`
  margin: auto;
  text-align: center;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 16px;
  font-size: 25px;
  line-height: 36px;

  ${screen.md} {
    margin-bottom: 20px;

    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    margin-bottom: 30px;

    font-size: 38px;
    line-height: 55px;
  }

  ${screen.xxlg} {
    margin-bottom: 28px;

    font-size: 48px;
    line-height: 72px;
  }
`;

const SubTitle = styled.h2`
  margin-top: 15px;
  margin-bottom: 28px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;

  ${screen.md} {
    line-height: 26px;
  }

  ${screen.lg} {
    margin-bottom: 40px;

    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 42px;
  }
`;

const Text = styled.div`
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  margin: auto;

  ${screen.md} {
    max-width: 498px;

    font-size: 12px;
    line-height: 22px;
  }

  ${screen.lg} {
    max-width: 639px;

    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    max-width: 821px;

    font-size: 20px;
    line-height: 38px;
  }
`;

export const SingleColumnSection = ({ content }) => (
  <Layout>
    <ContainerStyled>
      {content.title && <Title>{content.title}</Title>}
      <Text>
        {content?.text && content.text}
        <br />
        <br />
      </Text>
      {content?.cta && (
        <>
          <SubTitle>
            See what other shippers say about Loadsmart’s reliable service
          </SubTitle>
          <Button as="a" href={content.cta.url}>
            {content.cta.title}
          </Button>
        </>
      )}
    </ContainerStyled>
  </Layout>
);
