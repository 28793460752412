/* eslint-disable @next/next/no-img-element */
// components
import { Container } from '@/components/Grid/Container';

// styles
import {
  SidePanelsWrapper,
  SectionTitle,
  SectionIntro,
  SectionPanel,
  LogoWrapper,
  Title,
  Subtitle,
  Content,
  Link,
  Picture,
  Wrapper,
  FlexRow,
} from './SidePanels.styles';

const panels = [
  {
    title: 'Truck Management Software',
    logo: null,
    subtitle:
      'Optimize dispatch. Reduce overhead costs. Scale trucking fleets.',
    content: (
      <>
        <p>
          Make managing your day-to-day operations easier with a truck
          management system for carriers that provides data insights and
          visibility into your operations, fills your truck at better rates,
          reduces back-office expenses, and drives profitability.
        </p>
      </>
    ),

    link: {
      external: false,
      url: '/carrier/tms/',
      label: 'Start for free',
    },
    buttons: null,
    image: {
      path: {
        small: '/images/carrier/panels/item-01-xxlarge.png',
        medium: '/images/carrier/panels/item-01-lg.png',
        large: '/images/carrier/panels/item-01-lg.png',
        xxlarge: '/images/carrier/panels/item-01-xxlarge.png',
      },
      alt: 'Carrier TMS dashboard screeenshots',
    },
  },
  {
    title: 'Loadboard & QuickPay',
    logo: null,
    subtitle:
      'Preferred lanes, load recommendations, and ability to Instantly book loads',
    content: (
      <>
        <p>
          Our loadboard is the fastest, easiest way to find the right freight to
          keep your trucks full. Quickly sort through thousands of available
          loads by pickup location, destination, rate, and other factors.
        </p>
      </>
    ),

    link: {
      external: true,
      url: `${process.env.CARRIER_URL}/#/signup`,
      label: 'Start Finding Loads',
    },
    image: {
      scale: true,
      path: {
        small: '/images/carrier/panels/item-02-xxlarge.png',
        medium: '/images/carrier/panels/item-02-lg.png',
        large: '/images/carrier/panels/item-02-lg.png',
        xxlarge: '/images/carrier/panels/item-02-xxlarge.png',
      },
      alt: 'Loadsmart Loadboard dashboard screeenshots',
    },
  },
  {
    title: 'Direct Shipper Freight',
    logo: null,
    subtitle: (
      <>
        Carriers want consistent
        <br /> business from good shippers
      </>
    ),
    content: (
      <>
        <p>
          Get direct access to unique shipper freight opportunities, Loadsmart
          loads, and broker loads. Bid on spot and contract freight coming from
          shippers manage contracts from shippers.
        </p>
      </>
    ),
    link: {
      external: true,
      url: 'https://carrier.loadsmart.com/#/login?href=https%3A%2F%2Fcarrier.loadsmart.com%2F%23%2Floads',
      label: 'Visit our Load Board',
    },
    buttons: null,
    image: {
      scale: true,
      path: {
        small: '/images/carrier/panels/item-03-xxlarge.png',
        medium: '/images/carrier/panels/item-03-xxlarge.png',
        large: '/images/carrier/panels/item-03-lg.png',
        xxlarge: '/images/carrier/panels/item-03-xxlarge.png',
      },
      alt: 'Shipper Guide dashboard screeenshots',
    },
  },
];

export function SidePanels() {
  return (
    <SidePanelsWrapper>
      <Container>
        <SectionTitle>
          Find the best loads. Manage your fleet.
          <br /> Get paid faster.
        </SectionTitle>
        <SectionIntro>
          Free solutions to keep your trucks moving
          <br /> and your days easier
        </SectionIntro>
        {panels.map((item, key) => {
          const panelKey = `sidepanel-${key}`;
          return (
            <SectionPanel key={panelKey} direction={key % 2 ? `reverse` : ``}>
              <Picture
                scale={item.image.scale ? 'scaleImage' : null}
                direction={key % 2 ? `reverse` : ``}
              >
                <source
                  srcSet={`${item.image.path.xxlarge}`}
                  media="(min-width: 1920px)"
                />
                <source
                  srcSet={`${item.image.path.large}`}
                  media="(min-width: 1024px)"
                />
                <source
                  srcSet={`${item.image.path.medium}`}
                  media="(min-width: 768px)"
                />
                <img
                  src={`${item.image.path.small}`}
                  alt={item.image.alt}
                  width="677"
                  height="520"
                />
              </Picture>
              <Wrapper
                scale={item.image.scale ? 'scaleImage' : null}
                direction={key % 2 ? `reverse` : ``}
              >
                {item.logo && <LogoWrapper>{item.logo}</LogoWrapper>}
                {item.title && <Title>{item.title}</Title>}
                {item.subtitle && <Subtitle>{item.subtitle}</Subtitle>}
                <Content scale={item.image.scale ? 'scaleImage' : null}>
                  {item.content}
                </Content>
                <Link
                  href={item.link.url}
                  target={item.link.external ? '_blank' : null}
                  rel={item.link.external ? 'noreferrer noopener' : null}
                >
                  {item.link.label}
                </Link>
                {item.buttons &&
                  item.buttons.appleBadge &&
                  item.buttons.googleBadge && (
                    <FlexRow>
                      <Link href={item.buttons.appleUrl}>
                        <img
                          src={`${item.buttons.appleBadge}`}
                          alt={item.buttons.alt}
                          width="197"
                          height="66"
                        />
                      </Link>
                      <Link href={item.buttons.googleUrl}>
                        <img
                          src={`${item.buttons.googleBadge}`}
                          alt={item.buttons.alt}
                          width="221"
                          height="66"
                        />
                      </Link>
                    </FlexRow>
                  )}
              </Wrapper>
            </SectionPanel>
          );
        })}
      </Container>
    </SidePanelsWrapper>
  );
}
