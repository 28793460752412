import styled from 'styled-components';
import { screen } from '@/helpers/screen';
import { Container } from 'components/Grid';

export const OpaqueBoxWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: ${(props) => props.theme.color.neutral.lightest};
`;

export const InnerBox = styled.div`
  position: relative;
  background: linear-gradient(
      109.7deg,
      rgba(255, 255, 255, 0.8) -1.69%,
      rgba(255, 255, 255, 0) 44.12%
    ),
    linear-gradient(114.92deg, #84f11e -33.79%, #46e575 -8.04%, #00d7d7 117.74%);
  mix-blend-mode: normal;
  width: 100%;
  margin: auto;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

  padding: 44px 40px;

  ${screen.md} {
    padding: 48px 46px;
  }

  ${screen.lg} {
    padding: 57px 66px;
  }

  ${screen.xxlg} {
    padding: 70px 100px;
  }
`;
