//components
import { Container } from '@/components/Grid';
import { ContentPill } from '@/components/ContentPill';
import { SectionTitle } from '@/components/SectionTitle';
import { MirandaGradientButton } from '@/components/Button/BaseButton';

//styles
import {
  SignupWrapper,
  TitleWrapper,
  CTAs,
  CTAMobile,
  Columns,
  IconFill,
} from './Signup.styles';

//assets

import {
  IconOnePlatform,
  IconFlatOrganization,
  IconTMSReady,
  IconContractDeal,
} from '@loadsmart/icons';

export default function Signup() {
  return (
    <SignupWrapper>
      <Container>
        <TitleWrapper>
          <SectionTitle
            as="h2"
            title={
              <>
                Here are 4 <br />
                ways to sign <br />
                up and move <br />
                flatbed loads
              </>
            }
            variant="left"
          />
          <CTAs>
            <MirandaGradientButton
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              className="noborder"
              href={`${process.env.SHIPPERGUIDE_URL}/fbm-login`}
            >
              <span>Access Flatbed Messenger</span>
            </MirandaGradientButton>
          </CTAs>
        </TitleWrapper>
        <Columns>
          <ContentPill
            content={{
              icon: (
                <IconFill>
                  <IconOnePlatform width="50" height="50" />
                </IconFill>
              ),
              title: 'Online',
              description: (
                <>
                  Find capacity and get rates in just a few clicks by signing up
                  or logging in{' '}
                  <a href={`${process.env.SHIPPERGUIDE_URL}/fbm-login`}>
                    online
                  </a>
                  .
                </>
              ),
              link: null,
            }}
          />
          <ContentPill
            content={{
              icon: (
                <IconFill>
                  <IconFlatOrganization width="50" height="50" />
                </IconFill>
              ),
              title: 'Rapid Email Bid',
              description: (
                <>Send an email bid to get an instant, real-time rate.  </>
              ),
              link: null,
            }}
          />
          <ContentPill
            content={{
              icon: (
                <IconFill>
                  <IconTMSReady width="50" height="50" />
                </IconFill>
              ),
              title: 'Direct Integration',
              description: (
                <>
                  Instantly quote and book in your spot portal and TMS – no
                  extra implementation costs.
                </>
              ),
              link: null,
            }}
          />
          <ContentPill
            content={{
              icon: (
                <IconFill>
                  <IconContractDeal width="50" height="50" />
                </IconFill>
              ),
              title: 'Contracted Freight',
              description: (
                <>
                  Secure a long-term rate and manage loads through a dedicated
                  representative.
                </>
              ),
              link: null,
            }}
          />
        </Columns>
        <CTAMobile>
          <MirandaGradientButton
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            className="noborder"
            href={`${process.env.SHIPPERGUIDE_URL}/fbm-login`}
          >
            <span>Access Flatbed Messenger</span>
          </MirandaGradientButton>
        </CTAMobile>
      </Container>
    </SignupWrapper>
  );
}

export { Signup };
