import React from 'react';

// styles
import {
  MissionBannerWrapper,
  MissionBannerContainer,
  MissionBannerContent,
  StyledTitle,
  StyledDescription,
} from './MissionBanner.styles';

export function MissionBanner() {
  return (
    <MissionBannerWrapper>
      <MissionBannerContainer>
        <MissionBannerContent>
          <StyledTitle>Our mission is simple:</StyledTitle>
          <StyledDescription>
            Provide transportation services + technology to reduce your freight
            spend, increase your service levels and impact your team’s
            productivity.
          </StyledDescription>
        </MissionBannerContent>
      </MissionBannerContainer>
    </MissionBannerWrapper>
  );
}
