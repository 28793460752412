// styles
import {
  LoaderWrapper,
  SpinnerWrapper,
  CircleSpinner,
  CircleWrapper,
  Circle,
} from './Loader.styles';

export function Loader() {
  return (
    <LoaderWrapper>
      <SpinnerWrapper>
        <CircleSpinner>
          <CircleWrapper viewBox="25 25 50 50">
            <Circle
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="#a7a9ab"
              strokeWidth="4"
            />
          </CircleWrapper>
        </CircleSpinner>
      </SpinnerWrapper>
    </LoaderWrapper>
  );
}
