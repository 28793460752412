// styles
import { HeroWrapper } from './Hero.styles';

export function Hero() {
  return (
    <HeroWrapper
      bg={{
        xxlarge: 'images/carrier/hero/loadsmart-hero-xxlg@2x.jpg',
        large: 'images/carrier/hero/loadsmart-hero-xxlg@2x.jpg',
        medium: 'images/carrier/hero/loadsmart-hero-xxlg@2x.jpg',
        small: 'images/carrier/hero/loadsmart-hero-small@2x.jpg',
      }}
    />
  );
}
