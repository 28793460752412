//assets
import BlueYonderLogo from '@/public/images/logos/logo-blue_yonder.svg';
import E2OpenLogo from '@/public/images/logos/logo-e2open.svg';
import ManhattanBlackLogo from '@/public/images/logos/logo-manhattan_black.svg';
import MercuryGateLogo from '@/public/images/logos/logo-mercury_gate.svg';
import OracleLogo from '@/public/images/logos/logo-oracle.svg';

//styles
import {
  Container,
  TMSWrapper,
  TMSLogoWrapper,
  ImageWrapper,
  Link,
  Title,
  Subtitle,
  Content,
} from './TMSIntegration.style';

export const TMSIntegration = () => (
  <>
    <TMSWrapper>
      <Container>
        <Title>TMS Integrations</Title>
        <Subtitle>
          Instantly bookable rates and dynamic pricing, no matter the mode or
          market - all within your TMS.
        </Subtitle>
        <Content>
          No more email and phone calls to find rates. Access instantly bookable
          rates with guaranteed capacity all in your transportation management
          system.
        </Content>
        <Link href="/link/">Learn more</Link>

        <TMSLogoWrapper>
          <ImageWrapper
            href="/integrations/oracle/"
            title="Oracle integration page"
          >
            <OracleLogo alt="Oracle logo" />
          </ImageWrapper>
          <ImageWrapper
            href="/integrations/mercury-gate/"
            title="Mercury Gate integration page"
          >
            <MercuryGateLogo alt="Mercury Gate logo" />
          </ImageWrapper>
          <ImageWrapper
            href="/integrations/blue-yonder/"
            title="Blue Yonder integration page"
          >
            <BlueYonderLogo alt="Blue Yonder logo" />
          </ImageWrapper>
          <ImageWrapper
            href="/integrations/e2open/"
            title="E2Open integration page"
          >
            <E2OpenLogo alt="E2Open logo" />
          </ImageWrapper>
          <ImageWrapper
            href="/integrations/manhattan/"
            title="Manhattan integration page"
          >
            <ManhattanBlackLogo alt="Manhattan logo" />
          </ImageWrapper>
        </TMSLogoWrapper>
      </Container>
    </TMSWrapper>
  </>
);
