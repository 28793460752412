import ReactPlayer from 'react-player/lazy';

//styles
import {
  VideoHeroWrapper,
  ContainerStyled,
  VideoContainer,
} from './VideoHero.styles';

export function VideoHero() {
  return (
    <VideoHeroWrapper>
      <ContainerStyled>
        <VideoContainer>
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=M2yaGJh3Q-U?rel=0"
            controls
            width="100%"
            height="100%"
          />
        </VideoContainer>
      </ContainerStyled>
    </VideoHeroWrapper>
  );
}
