export const faqContent = [
  {
    title: 'What is Flatbed Messenger?',
    description: (
      <>
        Flatbed Messenger is a solution for shippers that was built in
        partnership with The Home Depot. It makes flatbed (FBE) capacity easily
        available for shippers, whether they have spot or contract shipment
        needs. Flatbed Messenger offers shippers access to premium flatbed
        carriers and service – at a fraction of the normal costs.
      </>
    ),
  },
  {
    title: 'Who benefits from Flatbed Messenger?',
    description: (
      <>
        Both shippers and carriers! Shippers who need more capacity get access
        to quality carriers, and carriers avoid empty miles by filling their
        backhauls.
      </>
    ),
  },
  {
    title: 'How do I sign up to haul Flatbed loads?',
    description: (
      <>
        <ol>
          <li>
            Going online to{' '}
            <a
              href={`${process.env.CARRIER_URL}/#/loads`}
              target="_blank"
              rel="noreferrer noopener"
            >
              carrier.loadsmart.com/#/loads
            </a>{' '}
            to log-in to an existing account or sign up for free.
          </li>
          <li>
            Spot Portal:  Get instantly bookable flatbed quotes in your personal
            spot portal.
          </li>
          <li>
            TMS Integration: Get instant bid responses and book shipments
            directly in your TMS - no extra implementation costs.
          </li>
          <li>
            Rapid Email Bid Response: Send an email bid to get an instant,
            real-time rate. 
          </li>
          <li>
            Contracted Freight: Secure a long-term rate and manage loads through
            a dedicated representative.
          </li>
        </ol>
      </>
    ),
  },
];
