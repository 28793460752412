import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';
import LoadsmartLink from '@/public/images/logos/logo-loadsmart_link.svg';

export const ResponsiveLogo = styled(LoadsmartLink)`
  margin: 0 auto 20px;
  display: block;
  width: 180px;

  ${screen.md} {
    margin: 0 0 43px;
  }

  ${screen.lg} {
    width: 225px;
  }

  ${screen.xxlg} {
    width: 306px;
  }
`;

export const Subtitle = styled.p`
  margin: 20px 0 29px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;

  ${screen.md} {
    margin: 26px 0 10px;
    line-height: 26px;
  }

  ${screen.lg} {
    margin: 43px 0 20px;
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 30px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  margin-bottom: 49px;
  font-size: 12px;
  line-height: 22px;

  ${screen.md} {
    margin-bottom: 19px;
  }

  ${screen.lg} {
    margin-bottom: 39px;
    font-size: 16px;
    line-height: 30px;
    max-width: 880px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
    max-width: 1054px;
  }
`;

export const DescriptionBreak = styled.br`
  display: none;

  ${screen.md} {
    display: inline;
  }
`;

export const CardSectionTitle = styled.p`
  margin: 20px 0;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  ${screen.md} {
    font-size: 10px;
    line-height: 12px;
    margin: 0 0 10px;
  }

  ${screen.lg} {
    font-size: 14px;
    line-height: 38px;
    margin: 0 0 20px;
  }

  ${screen.xxlg} {
    font-size: 18px;
  }
`;

export const LearnMore = styled.a`
  display: block;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  margin-top: 31px;

  ${screen.md} {
    margin-top: 48px;
  }

  ${screen.lg} {
    margin-top: 79px;
  }
`;
