import styled from 'styled-components';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//helpers
import { screen } from '@/helpers/screen';

export const FormWrapper = styled.section`
  background: rgb(${getToken('color-background-tertiary')});
  padding: 0 0 40px;

  ${screen.md} {
    padding: 40px 0;
  }

  ${screen.lg} {
    padding: 60px 0;
  }
  ${screen.xxlg} {
    padding: 80px 0;
  }
`;
