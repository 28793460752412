import styled from 'styled-components';

//components
import {
  MirandaButton,
  MirandaSecondaryButton,
} from '@/components/Button/BaseButton';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  text-align: center;
  padding: 68px 0 88px;
  background-color: rgb(${getToken('color-background-secondary')});

  &.inverted {
    background-color: rgb(${getToken('color-neutral-100')});
  }
`;

export const Title = styled.h1`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 38px;
  line-height: 47px;

  &.inverted {
    color: rgb(${getToken('color-text-inverted')});
    font-size: 38px;
    line-height: 47px;

    ${screen.xxlg} {
      font-size: 48px;
      line-height: 60px;
    }
  }

  ${screen.xxlg} {
    br {
      display: none;
    }
  }
`;

export const Paragraph = styled.p`
  color: rgb(${getToken('color-text-tertiary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 20px;
  line-height: 39px;

  &.inverted {
    color: rgb(${getToken('color-text-secondary-inverted')});
  }

  ${screen.xxlg} {
    font-size: 26px;
    margin: 0 auto;
    margin-bottom: 36px;
    max-width: 1130px;
  }
`;

export const ButtonPrimary = styled(MirandaButton)`
  border: 1px solid rgb(${getToken('color-background-primary')});
`;

export const ButtonSecondary = styled(MirandaSecondaryButton)`
  border: 1px solid rgb(${getToken('color-text-inverted')});
`;
